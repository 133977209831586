import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row, TabContent, TabPane } from 'reactstrap';
import * as route from "../../api/routes";

//SimpleBar
import SimpleBar from "simplebar-react";
import {useRedux} from "../../hooks";

import {SalePlanParamValueType, SalePlanType} from "../../redux/Sale/Plan/types";

import {useTranslation} from "react-i18next";
import ListItem from "../../blocks/Sale/PlanParam/ListItem";
import {convertToMomentLocale} from "../../helpers/functions";
import {format} from "date-fns";

const PlanDropdown = () => {

    const { t, i18n } = useTranslation(['common']);
    const { useAppSelector } = useRedux();

    const {
        salePlan,
    } = useAppSelector(state => ({
        salePlan: state.SalePlan,
    }));

    const [isTopPlanDropdown, setIsTopPlanDropdown] = useState(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(undefined);


    useEffect(()=>{

        if (salePlan?.currentPlan && salePlan.currentPlan?.uuid && salePlan.currentPlan.uuid.length>0){
            setCurrentPlan({...salePlan.currentPlan});
        } else {
            setCurrentPlan(undefined);
        }

    }, [salePlan?.currentPlan]);

    const toggleTopPlanDropdown = () => {
        setIsTopPlanDropdown(!isTopPlanDropdown);
    };

    return (
        <React.Fragment>
            {currentPlan !== undefined && currentPlan.uuid !== undefined && currentPlan.uuid.length >0 &&
            <Dropdown isOpen={isTopPlanDropdown} toggle={toggleTopPlanDropdown}
                      className={"dropdown ms-sm-3 header-item topbar-license" + ((currentPlan.daysBeforeLicenseExpired && currentPlan.daysBeforeLicenseExpired > 0) ? " bg-light" : "")}>
                <DropdownToggle type="button" tag="button" className="btn" id="page-header-license-dropdown">
                        <span className="d-flex align-items-center">
                            <span className="text-start user-name-text">
                                <i className="bx bx-power-off align-bottom fs-22"/>
                            </span>

                            <span className="text-start ms-xl-2">
                                <span
                                    className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{currentPlan.name}</span>
                                <span className="d-none d-xl-block ms-1 fs-12 text-muted">
                                    {currentPlan.activeTo && currentPlan.activeTo > 0 ?
                                        <div
                                            className={"fareTopComment" + ((currentPlan.daysBeforeLicenseExpired && currentPlan.daysBeforeLicenseExpired > 0) ? " text-danger" : "")}>{
                                            format(new Date(currentPlan.activeTo*1000),'E, dd MMM yyyy', {locale: convertToMomentLocale(i18n.language)})
                                            }
                                        </div>
                                        :
                                        <div
                                            className="fareTopComment text-success">{t('plan:upgrade')}</div>
                                    }
                            </span>
                            </span>
                        </span>
                </DropdownToggle>


                <DropdownMenu className="dropdown-menu-xl dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> {t('plan:yourPlan')}: {currentPlan.name} </h6>
                                </Col>
                                <Col className="col-auto dropdown-tabs">
                                    <div className="fareTopInfoContRight">

                                        <Link to={route.SALE_PLANS} className="btn btn-sm btn-primary btn-label waves-effect waves-light"><i className="ri-thumb-up-line label-icon align-middle fs-16 me-2"></i> {t('plan:upgrade')}</Link>


                                        {/*<Link to={route.SALE_PLANS} type="button" target="_blank" rel="noreferrer"*/}
                                        {/*   className="btn btn-primary waves-effect waves-light btn-sm">*/}
                                        {/*    {t('plan:upgrade')}*/}
                                        {/*</Link>*/}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <TabContent activeTab={"1"} id="fareItemsTabContent">
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                <div className="py-2 ps-2">
                                    {currentPlan.productParamValues &&
                                        (currentPlan.productParamValues || []).map((item:SalePlanParamValueType, key:number)=> (
                                            item.isPrimary === true && (item.limit !==undefined || item.isPromo !== undefined) &&
                                            <ListItem item={item} key={key}/>
                                        ))
                                    }
                                </div>
                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
            }
        </React.Fragment>
    );
};

export default PlanDropdown;