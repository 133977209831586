//@ts-nocheck
import React, {useState} from "react";
import { Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as route from "../../api/routes";
import '../../i18/config';
import {useTranslation} from "react-i18next";

import {StatPeerChannelDataType} from "../../redux/Stat/PeerChannel/types";
import {ChartTypes} from "../../data/chartTypes";
import ChannelActivitiesChartItem from "./ChannelActivitiesChartItem";

interface InputProps {
    items: [] | Array<StatPeerChannelDataType>;
}

const ChannelActivitiesChart = ({ items }: InputProps) => {
    const { t, i18n } = useTranslation();

    const [ chartType, setChartType ] = useState<string>(ChartTypes.LINE);

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('channel:channelActivities')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_ACTIVITY_PLAN}>{t('peerActivity:manageActivities')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div className="d-flex flex-row justify-content-end">
                    <button type="button" className={"btn btn-icon waves-effect waves-light me-2 "+(chartType === ChartTypes.BAR ? 'btn-primary': 'btn-outline-primary')} onClick={()=>setChartType(ChartTypes.BAR)}><i
                        className="ri-bar-chart-2-fill"/>
                    </button>
                    <button type="button" className={"btn btn-icon waves-effect waves-light me-2 "+(chartType === ChartTypes.LINE ? 'btn-primary': 'btn-outline-primary')} onClick={()=>setChartType(ChartTypes.LINE)}><i
                        className="ri-line-chart-line"/>
                    </button>
                </div>
                
                <div className="apex-charts" >

                    <ChannelActivitiesChartItem items={items} chartType={chartType}/>

                </div>
            </CardBody>
        </Card>
    );
}

export default ChannelActivitiesChart;