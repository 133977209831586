import React, {useEffect, useState} from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../../../hooks";
import { Col, Row } from 'reactstrap';

import TaskTimeForm from "./TaskTimeForm";
import {TaskTimeType} from "../../../redux/Task/TaskTime/types";
import UserItemLine from "../../Main/User/ListItem";
import { isNumber } from "lodash";
import {sendTaskTime} from "../../../redux/Task/Task/actions";


interface ItemProps {
    item: TaskTimeType;
    onEdit: (event:any) => void;
    onRemove: (event:any) => void;
}
const ListItem = ({ item, onEdit, onRemove }: ItemProps) => {

    const { t,i18n } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();

    const [isEdit, setEdit] = useState<boolean>(false);
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);

    const {
        taskState,
        isSendingTaskTime
    } = useAppSelector(state => ({
        taskState: state.Task,
        isSendingTaskTime: state.Task.isSendingTaskTime
    }));

    const showActions = (item?.actions &&
        (
            item.actions?.canEdit
            ||
            item.actions?.canRemove
        )) ? true : false;

    useEffect(() => {
        if (item?.minutes !== undefined && item?.minutes  !== null && isNumber(item?.minutes) && item?.minutes>0){

            setHours(item.minutes / 60 | 0);
            setMinutes(item.minutes % 60 | 0);

        } else {
            setHours(0);
            setMinutes(0);
        }

    },[item?.minutes]);

    useEffect(() => {
        if (taskState?.isSendingTaskTime === false && isEdit && item?.uuid === taskState?.selectedTaskTime?.uuid) {
            setEdit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, taskState?.isSendingTaskTime]);

    const handleCloseEdit = () => {
        setEdit(false);
    }

    return (
        <>
        {item?.uuid &&
            <>
                {isEdit ?
                    <div className="mb-2">
                        <TaskTimeForm
                            isSending={isSendingTaskTime}
                            item={item}
                            onSend={sendTaskTime}
                            onClose={handleCloseEdit}
                        />
                    </div>
                    :
                    <Row className="mb-2">
                        <Col lg={3} xs={12}>
                            <div className="d-flex flex-column justify-content-center flex-grow-1 h-100">
                            {item?.owner &&
                                <UserItemLine item={item.owner}/>
                            }
                            </div>
                        </Col>
                        <Col lg={2} xs={12}>
                            <div className="d-flex flex-column justify-content-center flex-grow-1 h-100">
                                <div className="d-flex flex-row align-items-center">
                            {hours && hours > 0 ?
                                <span className="me-2">{hours} <small>{t('common:hrs')}</small></span>
                                : ''
                            }
                            {minutes && minutes > 0 ?
                                <span>{minutes} <small>{t('common:min')}</small></span>
                                : ''
                            }
                            </div>
                            </div>
                        </Col>
                        <Col lg={5} xs={12}>
                            <div className="d-flex flex-column justify-content-center flex-grow-1 h-100">
                            {item?.text && item.text.length>0 && item.text}
                            </div>
                        </Col>
                        {
                            showActions &&
                        <Col lg={2} xs={12}>
                                <div className="d-flex flex-row justify-content-end">
                                    <div className="d-flex align-items-center">
                                        {item?.actions?.canRemove &&
                                            <button
                                                onClick={()=>{ onRemove(item) }}
                                                title={t('common:delete')}
                                                className="btn btn-outline-danger btn-icon waves-effect waves-light ms-2">
                                                <i className="ri-delete-bin-5-line"/>
                                            </button>
                                        }
                                        {item?.actions?.canEdit &&
                                            <button
                                                onClick={()=>{ setEdit(!isEdit) }}
                                                title={t('common:edit')}
                                                className="btn btn-outline-primary btn-icon waves-effect waves-light ms-2">
                                                <i className="ri-pencil-fill"/>
                                            </button>
                                        }
                                    </div>
                                </div>
                        </Col>
                        }
                    </Row>
                }
            </>
        }
        </>
    );
};

export default ListItem;
