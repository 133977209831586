import { takeEvery, fork, put, all, call } from "typed-redux-saga";

import {mainDeviceApiResponseError, mainDeviceApiResponseSuccess} from "../Device/actions";
import {defaultBrowserFingerPrintInputProps, getBrowserFingerPrintData} from "../../../components/FingerPrint/browser";
import {MainDeviceActionTypes} from "./types";
import {
    getUserDevices as getUserDevicesApi,
    approveUserDevice as approveUserDeviceApi,
    removeUserDevice as removeUserDeviceApi
} from "../../../api";
import {showErrorNotification, showSuccessNotification} from "../../../helpers/notifications";

 function* getCurrentDevice() {
    try {
        const response: Promise<any> = yield call(getBrowserFingerPrintData, defaultBrowserFingerPrintInputProps);

        yield put(mainDeviceApiResponseSuccess(MainDeviceActionTypes.GET_CURRENT_DEVICE, response));

    } catch (error: any) {
        yield put(mainDeviceApiResponseError(MainDeviceActionTypes.GET_CURRENT_DEVICE, error));
    }
}
function* getAllowedDevices() {
    try {
        const response: Promise<any> = yield call(getUserDevicesApi);
        yield put(mainDeviceApiResponseSuccess(MainDeviceActionTypes.GET_MAIN_ALLOWED_DEVICES, response));
    } catch (error: any) {
        yield put(mainDeviceApiResponseError(MainDeviceActionTypes.GET_MAIN_ALLOWED_DEVICES, error));
    }
}

function* onApproveMainDevice({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(approveUserDeviceApi, data.data);
        yield put(
            mainDeviceApiResponseSuccess(MainDeviceActionTypes.UPDATE_MAIN_ALLOWED_DEVICE, response)
        );
        yield call(showSuccessNotification, response);
    } catch (error: any) {
        yield put(mainDeviceApiResponseError(MainDeviceActionTypes.UPDATE_MAIN_ALLOWED_DEVICE, error));
        yield call(showErrorNotification, error);
    }
}

function* onRemoveMainDevice({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(removeUserDeviceApi, data.data);
        yield put(
            mainDeviceApiResponseSuccess(MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE, response)
        );
        yield call(showSuccessNotification, response);
    } catch (error: any) {
        yield put(mainDeviceApiResponseError(MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE, error));
        yield call(showErrorNotification, error);
    }
}

export function* watchGetCurrentDevice() {
    yield takeEvery(MainDeviceActionTypes.GET_CURRENT_DEVICE, getCurrentDevice);
}
export function* watchGetAllowedDevices() {
    yield takeEvery(MainDeviceActionTypes.GET_MAIN_ALLOWED_DEVICES, getAllowedDevices);
}
export function* watchOnApproveMainDevice() {
    yield takeEvery(MainDeviceActionTypes.UPDATE_MAIN_ALLOWED_DEVICE, onApproveMainDevice);
}
export function* watchOnRemoveMainDevice() {
    yield takeEvery(MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE, onRemoveMainDevice);
}
function* mainDeviceSaga() {
    yield all([
        fork(watchGetCurrentDevice),
        fork(watchGetAllowedDevices),
        fork(watchOnApproveMainDevice),
        fork(watchOnRemoveMainDevice)
    ]);
}

export default mainDeviceSaga;