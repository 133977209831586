import React, { useRef } from "react";
import {PeriodType} from "../redux/Stat/DashboardContent/types";
import {useRedux} from "../hooks";
import Flatpickr from "react-flatpickr";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import '../i18/config';
import {useTranslation} from "react-i18next";
import {UTCDate} from "@date-fns/utc";
import {format} from "date-fns";
import {convertToMomentLocale} from "../helpers/functions";


interface PeriodSelectProps {
    onDispatch: (data:any) => void
    periodFrom?: number,
    periodTo?: number,
}

const PeriodSelect = ({onDispatch, periodTo, periodFrom}:PeriodSelectProps) => {

    const { i18n } = useTranslation();

    const { dispatch } = useRedux();
    const dateSelectRef = useRef(null);

    const handlePeriodChange = (selectedDates, dateStr:number, instance : any) => {

        if (selectedDates.length===2) {
            const periodFrom = new UTCDate(selectedDates[0]).getTime()/1000;
            const periodTo = new UTCDate(selectedDates[1]).getTime()/1000;

            const periodChange: PeriodType = {
                periodFrom: periodFrom,
                periodTo: periodTo,
                selectedDate: dateStr
            }

            dispatch(onDispatch(periodChange));
        }
    }
    const handleDateSelectButton = () => {
        dateSelectRef.current.flatpickr.open();
    }

    let options = {
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        inline: false,
        enableTime: false,
        altInput: true,
        allowInput: false,
        mode: 'range',
        locale : {...FlatpickrLanguages[i18n.language]}
     }

     if (periodFrom !== undefined && periodTo !== undefined){
         options['defaultDate']=[
             format(new Date(periodFrom*1000),'yyyy-MM-dd'),
             format(new Date(periodTo*1000),'yyyy-MM-dd')
         ];
     } else if (periodFrom !== undefined){
         options['defaultDate']=format(new Date(periodFrom*1000),'yyyy-MM-dd');
     }

return(
    <div className="input-group">
        <Flatpickr
            className="form-control border-0 dash-filter-picker shadow rangePickerInput"
            ref={dateSelectRef}
            options={options}
            onChange={(selectedDates, dateStr, instance) => {
                handlePeriodChange(selectedDates, dateStr,instance);
            }}
        />
        <a className="input-group-text bg-primary border-primary text-white" onClick={handleDateSelectButton}><i className="ri-calendar-2-line"/></a>
    </div>
);


}

export default PeriodSelect;