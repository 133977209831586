import React, {useEffect, useRef, useState} from "react";
import Flatpickr from "react-flatpickr";
import {Button} from "reactstrap";

import FlatpickrLanguages from "flatpickr/dist/l10n";

import '../i18/config';
import {useTranslation} from "react-i18next";

interface dateOptions{
    altInput: boolean;
    altFormat: string;
    dateFormat?: string;
    enableTime: boolean;
    defaultDate?: any;
    minDate?: string;
    locale? : any;
}

interface InputTextProps {
  value: null | number;
  id: string;
  placeholder: null | string;
  onChange: (value: number) => void;
  required: null | boolean;
  maxLength: null | number;
  className: null | string;
  minDate: null | any;
}

const getDefaultOptions = (translation: {language: string}, minDate: Date) => ({
    altInput: true,
    altFormat: "F j, Y H:i",
    dateFormat: "U",
    enableTime: true,
//    minDate: minDate,
    locale: {...FlatpickrLanguages[translation.language]}
});
const InputDateTimePicker = ({ id, placeholder, value, onChange, required, maxLength, className, minDate}: InputTextProps) => {

    const { i18n } = useTranslation();

    const fp = useRef(null);

    const defaultOptions = getDefaultOptions(i18n, minDate);

    const [currentValue, setCurrentValue] = useState<number>( null );

    const openCalendar = () => {
        if (!fp?.current?.flatpickr) return;
        fp.current.flatpickr.open();
    }

    useEffect(() => {
        if (value!== undefined && value !== null && value !== 0) {
            setCurrentValue(value * 1000);
        } else {
            setCurrentValue(null);
        }
    }, [value]);

    const handleDateChange = (selectedDates, dateStr) => {
        if (dateStr > 0) {
            onChange(dateStr);
        } else {
            onChange(null);
        }
    };

  return (
      <>
          <div className="input-group position-relative">
              <Flatpickr
                  id={ id }
                  ref={fp}
                  className={"rangePickerInput "+ (className || "form-control")}
                  options={defaultOptions}
                  value={currentValue}
                  placeholder={placeholder && placeholder}
                  onChange={handleDateChange}
              >
              </Flatpickr>
              <Button data-toggle className="input-group-text bg-primary border-primary text-white position-relative z-0"  onClick={openCalendar}>
                    <i className="ri-calendar-2-line"/>
              </Button>
          </div>
      </>
  );
};
export default InputDateTimePicker;
