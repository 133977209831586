import * as React from "react";
import {useEffect, useState} from "react";
import {ProductStoreValue, ProductType, SaleProductPayload} from "../../../redux/Sale/Product/types";
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination, Autoplay, Thumbs} from "swiper/modules";
import {MainFileType, MainFileVideoTypes, MainFileImageTypes} from "../../../redux/Main/File/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledTooltip,
    Input,
    Label
} from "reactstrap";
import {ProductParamListValueType, ProductParamType, ProductParamTypes} from "../../../redux/Sale/ProductParam/types";
import {ProductParamValueType} from "../../../redux/Sale/ProductParamValue/types";
import {useRedux} from "../../../hooks";
import {useParams} from "react-router";
import {getSaleEditProduct} from "../../../redux/Sale/Product/actions";
import Loader from "../../../components/Loader";
import * as route from "../../../api/routes";
import {ProductPriceType} from "../../../redux/Sale/Price/types";
import {SaleStoreType} from "../../../redux/Sale/Store/types";
import {getSaleStores} from "../../../redux/Sale/Store/actions";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import ChannelItemProductIntegration from "../../Content/PeerChannel/channelItemProductIntegration";
import {PriceTypeType} from "../../../redux/Sale/PriceType/types";
import PriceTypeItemLine from "../PriceType/PriceTypeItemLine";
import {ProductIntegrationType} from "../../../redux/Sale/ProductIntegration/types";
import {getSalePriceTypes} from "../../../redux/Sale/PriceType/actions";
import BackButton from "../../../components/BackButton";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {convertToMomentLocale, isNumeric} from "../../../helpers/functions";
import UpgradeFarePage from "../../../components/UpgradeFarePage";
import {getSaleProductParams} from "../../../redux/Sale/ProductParam/actions";
import productParam from "../ProductParam";
import SaleIntegrationItemProductIntegration from "../Integration/SaleIntegrationItemProductIntegration";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {getSaleIntegrations} from "../../../redux/Sale/Integration/actions";
import {SaleIntegrationHandlerCodes, SaleIntegrationHandlerTypes} from "../../../redux/Sale/IntegrationHandler/types";
import {format} from "date-fns";

interface DimensionWidgetProps {
    label: string;
    icon: string;
    value: number;
    valueSuffix?: string;
}

const DimensionWidget = ({label, icon, value, valueSuffix}:DimensionWidgetProps) => {

    const FormatValue = (value:number) =>
        new Intl.NumberFormat('en-US', {
            style: 'decimal',
            notation: 'compact'
        }).format(value);

    return (
        <div className="priceBlock me-2 mb-2">
            <div className="p-2 border border-dashed rounded">
                <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                        <div className="avatar-title rounded bg-transparent text-success fs-24">
                            <i className={icon}/>
                        </div>
                    </div>
                    <div className="flex-grow-1 mw-0">
                        <p className="mb-1 text-truncate"><span className="text-muted">{label}</span></p>
                        <h5 className="mb-0">
                            {FormatValue(value)}
                            {valueSuffix &&
                                <span className="text-muted ms-1 font-weight-normal fs-12">{valueSuffix}</span>
                            }
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}


function SaleProductDetail() {

    const { dispatch, useAppSelector } = useRedux();
    const { t, i18n } = useTranslation(['common']);
    const { uuid } = useParams();

    const {
        saleProduct,
        saleStore,
        salePriceType,
        contentPeerChannels,
        salePlan,
        saleProductParam,
        saleIntegration
    } = useAppSelector(state => ({
        saleProduct: state.SaleProduct,
        saleStore: state.SaleStore,
        salePriceType: state.SalePriceType,
        contentPeerChannels: state.ContentPeerChannel,
        salePlan: state.SalePlan,
        saleProductParam: state.SaleProductParam,
        saleIntegration: state.SaleIntegration
    }));

    const propertiesTab = 'propertiesTab';
    const detailTextTab = 'detailTextTab';

    const [product, setProduct] = useState<ProductType>(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [activeTab, setActiveTab] = useState(propertiesTab);
    const [stores, setStores] = useState<SaleStoreType[]>([]);
    const [isFetched, setFetched] = useState<boolean>(false);
    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [priceTypes, setPriceTypes] = useState<PriceTypeType[]>([]);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [brandName, setBrandName] = useState<string>(null);
    const [productParams, setProductParams] = useState<ProductParamType[]>([]);
    const [saleIntegrations, setSaleIntegrations] = useState<SaleIntegrationType[]>([]);

    const [availableProductCartParamValues, setAvailableProductCartParamValues] = useState<ProductParamValueType[]>([]);
    const [selectedProductCartParamValues, setSelectedProductCartParamValues] = useState<ProductParamValueType[]>([]);

    const checkIsAllFetched = () => {
        if (
            saleStore.isFetched
            &&
            contentPeerChannels.isFetched
            &&
            salePriceType.isFetched
            &&
            saleProduct.isFetched
            &&
            saleProductParam.isFetched
            &&
            saleIntegration.isFetched
            &&
            (uuid === saleProduct.selectedItem.uuid)
        ){
            setFetched(true);
        }
    }

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_PRODUCTS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    useEffect(() => {
        if (uuid !== null){
            const requestData = [];
            requestData['uuid']=uuid;

            const saleProductPayload: SaleProductPayload = {
                data: requestData,
                modalEditTitle: '!productDetail'
            }
            dispatch(getSaleEditProduct(saleProductPayload));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getSaleStores({maxResults:1000}));
        dispatch(getContentPeerChannels());
        dispatch(getSalePriceTypes({maxResults: 1000}));
        dispatch(getSaleProductParams({maxResults:1000}));
        dispatch(getSaleIntegrations({maxResults: 1000}));
    },[dispatch]);

    useEffect(() => {

        if (saleStore?.items && (saleStore?.items || []).length>0){
            setStores([...saleStore.items]);
        } else {
            setStores([]);
        }

        checkIsAllFetched();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleStore?.items]);

    useEffect(() => {

        if (contentPeerChannels?.items && (contentPeerChannels?.items || []).length>0){
            setChannels([...contentPeerChannels.items]);
        } else {
            setChannels([]);
        }

        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerChannels?.items]);

    useEffect(() => {

        if (saleIntegration?.items && (saleIntegration?.items || []).length>0){
            setSaleIntegrations([...saleIntegration.items]);
        } else {
            setSaleIntegrations([]);
        }

        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleIntegration?.items]);

    useEffect(() => {

        if (salePriceType?.items && (salePriceType?.items || []).length>0){
            setPriceTypes([...salePriceType.items]);
        } else {
            setPriceTypes([]);
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePriceType?.items]);

    useEffect(() => {

        if (saleProductParam?.items && (saleProductParam?.items || []).length>0){
            setProductParams([...saleProductParam.items]);
        } else {
            setProductParams([]);
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleProductParam?.items]);

    const FormatCurrencyValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const FormatValue = (value:number) =>
        new Intl.NumberFormat('en-US', {
            style: 'decimal',
            notation: 'compact'
        }).format(value);

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        if (saleProduct?.selectedItem){
            setProduct({...saleProduct.selectedItem})
        }

        checkIsAllFetched();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleProduct?.selectedItem?.uuid, saleProduct?.selectedItem?.eTag]);

    useEffect(() => {

        let isSetBrand = false;

        if (product?.uuid){
            if (
                (!(product?.productParamValues && (product.productParamValues || []).length>0))
                &&
                (product?.detailText && product.detailText.length>0)
            ) {
                setActiveTab(detailTextTab);
            } else {

                const brandProperty = (product.productParamValues || []).filter((propertyItemValue:ProductParamValueType)=>{
                    return propertyItemValue.productParam.isBrand;
                }).shift();

                if (brandProperty?.uuid){
                    if (brandProperty.productParam.type===ProductParamTypes.LIST && ((brandProperty.productParam?.values || []).length>0)) {
                        const _value = brandProperty.productParam.values.filter((paramValue:ProductParamListValueType)=>{ return paramValue.uuid === brandProperty.value})?.shift()?.value;

                        setBrandName(_value);
                        isSetBrand=true;
                    } else {
                        setBrandName(brandProperty.value);
                        isSetBrand=true;
                    }
                }

                setActiveTab(propertiesTab);

                if (product?.offers && (product.offers || []).length>0){

                    let _availableProductParamValues=[];

                    product.offers.map((offer: ProductType) => {
                        if (offer?.productParamValues){
                            (offer?.productParamValues || []).map((productParamValue: ProductParamValueType) => {

                                if (productParamValue?.productParam?.uuid){

                                    let _checkProductParam = (productParams || []).filter((productParam:ProductParamType)=>{return productParam.uuid === productParamValue?.productParam?.uuid}).shift();

                                    if (_checkProductParam?.uuid && _checkProductParam?.isCart && _checkProductParam.isCart) {
                                        let _checkProductParamValue = (_availableProductParamValues || []).filter((checkProductParamValue: ProductParamValueType) => {
                                            return checkProductParamValue.productParam.uuid === productParamValue.productParam.uuid && checkProductParamValue.value === productParamValue.value;
                                        });

                                        if ((_checkProductParamValue || []).length < 1) {
                                            _availableProductParamValues.push(productParamValue);
                                        }
                                    }
                                }
                                return productParamValue;
                            });
                        }

                        return offer;
                    });

                    setAvailableProductCartParamValues(_availableProductParamValues);

                    let _selectedProductParamValues=[];

                    (product.productParamValues || []).map((productParamValue: ProductParamValueType)=>{

                        let _checkProductParam = (productParams || []).filter((productParam:ProductParamType)=>{return productParam.uuid === productParamValue?.productParam?.uuid}).shift();

                        if (_checkProductParam?.uuid && _checkProductParam?.isCart && _checkProductParam.isCart) {
                            _selectedProductParamValues.push(productParamValue);
                        }

                        return productParamValue;
                    });

                    setSelectedProductCartParamValues(_selectedProductParamValues);

                }
            }
        }

        if(!isSetBrand){
            setBrandName(null);
        }

    },[product, productParams]);

    const checkProductParamOffersPresent = (productParam: ProductParamType, value: string) => {

        let selectedOffer = ((product.offers || []).filter((offer:ProductType)=>{

            let isCurrentOffer = true;

            if (offer?.productParamValues){
                (offer?.productParamValues || []).map((productParamValue: ProductParamValueType) => {

                    if (productParamValue?.productParam?.uuid){

                        let _checkProductParam = (productParams || []).filter((productParam:ProductParamType)=>{return productParam.uuid === productParamValue?.productParam?.uuid}).shift();

                        if (_checkProductParam?.uuid && _checkProductParam?.isCart && _checkProductParam.isCart) {

                            if(productParamValue.productParam.uuid === productParam.uuid){
                                if (productParamValue.value !== value){
                                    isCurrentOffer = false;
                                }
                            } else {

                                let _checkSelectedProductCartParamValues = (selectedProductCartParamValues || []).filter((selectedProductCartParamValue: ProductParamValueType)=>{

                                    return selectedProductCartParamValue.productParam.uuid === productParamValue.productParam.uuid && selectedProductCartParamValue.value === productParamValue.value;

                                });

                                if ((_checkSelectedProductCartParamValues || []).length<1){
                                    isCurrentOffer = false;
                                }
                            }

                        }
                    }
                    return productParamValue;
                });
            }

            return isCurrentOffer;
        })).shift();

        if (selectedOffer?.uuid){
            return selectedOffer;
        }
        return false;
    }

    const handleChangeProductParam = (productParam: ProductParamType, value: string) => {

        let selectedOffer = checkProductParamOffersPresent(productParam,value);

        if (selectedOffer!== false && selectedOffer?.uuid){
            let _product = {
                ...product,
                ...selectedOffer
            }

            setProduct(_product);
        }
    }

  return (
      <>
          <BreadCrumb title={t('product:product')} pageTitle={t('menu:main')} />

      {isFetched ?
          <>
              { upgradeFare ?
                  <>
                      <div className="d-flex flex-row flex-grow-1">
                          <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                              <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('plan:restrictionProductsText')}
                          </div>
                      </div>
                      <UpgradeFarePage/>
                  </>
                  :
                  <>
          <Row>
              <Col lg={12}>
                  <Card>
                      <CardBody>
                          <Row className="gx-lg-5">
                              <Col xl={4} md={8} className="mx-auto">
                                  <div className="product-img-slider sticky-side-div">
                                      {product.media && product.media.length>1 &&
                                          <>
                                          <Swiper
                                              pagination={{ clickable: true, dynamicBullets: true }}
                                              modules={[Pagination, Thumbs]}
                                                  slidesPerView={1}
                                                  thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                                                  loop={false}
                                                  autoplay={{ delay: 2500, disableOnInteraction: false }}
                                                  className="swiper pagination-dynamic-swiper rounded mb-2" >
                                              { product.media.map((mediaItem:MainFileType, key:number)=> (
                                                  <SwiperSlide key={"swiperProductSlide_"+key}>
                                                      {(Object.values(MainFileImageTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                                          <img src={mediaItem.url} alt="" className="img-fluid d-block rounded"/>
                                                      }
                                                      {(Object.values(MainFileVideoTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                                          <video controls width="auto" height="auto" style={{maxWidth:'100%'}}>
                                                              <source src={mediaItem.downLoadUrl} type={"video/"+mediaItem.fileName.split('.').pop()}/>
                                                          </video>
                                                      }
                                                  </SwiperSlide>
                                              ))}
                                          </Swiper>
                                              <div className="product-nav-slider mt-2">
                                                  <Swiper
                                                      modules={[Thumbs]}
                                                      onSwiper={setThumbsSwiper}
                                                      slidesPerView={4}
                                                      freeMode={true}
                                                      watchSlidesProgress={true}
                                                      spaceBetween={10}
                                                      className="swiper product-nav-slider mt-2 overflow-hidden"
                                                  >
                                                      { product.media.map((mediaItem:MainFileType, key:number)=> (
                                                          <SwiperSlide key={"swiperProductSlideThumbs_"+key} className="nav-slide-item">
                                                              {(Object.values(MainFileImageTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                                                  <img src={mediaItem.url} alt="" className="img-fluid d-block rounded"/>
                                                              }
                                                              {(Object.values(MainFileVideoTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                                                  <video controls width="auto" height="auto" style={{maxWidth:'100%'}}>
                                                                      <source src={mediaItem.downLoadUrl} type={"video/"+mediaItem.fileName.split('.').pop()}/>
                                                                  </video>
                                                              }
                                                          </SwiperSlide>
                                                      ))}
                                                  </Swiper>
                                              </div>
                                          </>
                                      }
                                      {product.media && product.media.length === 1 &&
                                          <img src={[...product.media].shift().url} alt="" className="img-fluid d-block rounded"/>
                                      }
                                  </div>
                              </Col>

                              <Col xl={8}>
                                  <div className="mt-xl-0 mt-5">
                                      <div className="d-flex">
                                          <div className="flex-grow-1">
                                              <h4>{product.name}</h4>
                                              <div className="hstack gap-3 flex-wrap">
                                                  {brandName && brandName.length>0 &&
                                                      <>
                                                          <div className="text-muted">
                                                              {t('product:brand')}:{" "}
                                                              <span className="text-body fw-medium">{brandName}</span>
                                                          </div>
                                                          <div className="vr"></div>
                                                      </>
                                                  }
                                                  <div className="text-muted">
                                                      {t('category:category')}:{" "}
                                                      <span className="text-body fw-medium">
                                                        {product?.category?.uuid ? product.category.name : t('category:rootCategory')}
                                                     </span>
                                                  </div>
                                                  <div className="vr"></div>
                                                  <div className="text-muted">
                                                      {t('support:updatedAt')}:{" "}
                                                      <span className="text-body fw-medium">
                                                        {format(new Date(product?.updatedAt ? product.updatedAt : product.createdAt),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})}
                                                     </span>
                                                  </div>
                                                  {product?.article && product.article.length>0 &&
                                                      <>
                                                          <div className="vr"></div>
                                                          <div className="text-muted">
                                                              {t('product:article')}:{" "}
                                                              <span className="text-body fw-medium">
                                                          {product.article}
                                                      </span>
                                                          </div>
                                                      </>
                                                  }
                                                  {product?.offers && (product.offers || []).length>0 &&
                                                      <>
                                                      <div className="vr"></div>
                                                      <div className="text-muted">
                                                  {t('product:offers')}:{" "}
                                                      <span className="text-body fw-medium">
                                                          {(product.offers || []).length}
                                                      </span>
                                                      </div>
                                                      </>
                                                  }
                                              </div>
                                          </div>
                                          <div className="flex-shrink-0 d-flex flex-nowrap">
                                              <div className="me-2">
                                                  <BackButton id="sale-product-list-btn" className="btn-light"/>
                                                  <UncontrolledTooltip target="sale-product-list-btn" placement="top">
                                                      {t('product:allProducts')}
                                                  </UncontrolledTooltip>
                                              </div>
                                              <div>
                                                  <Link
                                                      id="sale-product-edit-btn"
                                                      to={route.SALE_PRODUCT_DETAIL_EDIT.replace(/:uuid/,product.uuid)}
                                                      className="btn btn-light waves-effect waves-light">
                                                      <i className={"ri-pencil-fill align-bottom fs-16"}/>
                                                  </Link>
                                                  <UncontrolledTooltip target="sale-product-edit-btn" placement="top">
                                                      {t('product:changeProduct')}
                                                  </UncontrolledTooltip>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="mt-4 d-flex flex-row flex-wrap">
                                          { (product.productPrices || []).map((productPrice:ProductPriceType)=>{
                                            return(
                                                <div className="priceBlock me-2 mb-2" key={"productPrice_"+productPrice.uuid}>
                                                    <div className="p-2 border border-dashed rounded">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar-sm me-2">
                                                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                                                    <i className="ri-price-tag-2-line"/>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 mw-0">
                                                                <p className="mb-1 text-truncate">
                                                                    <span className="text-muted">{t('price:priceType')}:</span> {productPrice.priceType.name}</p>
                                                                <h5 className="mb-0">
                                                                    {productPrice.currency.currencyFormat.replace(/#/,FormatCurrencyValue(productPrice.price))}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                          })}
                                      </div>

                                      <div className="mt-4 d-flex flex-row flex-wrap">
                                          { (product.storeValues || []).map((storeValue:ProductStoreValue)=>{
                                              return(
                                                  <div className="priceBlock me-2 mb-2" key={"storeValue_"+storeValue.uuid}>
                                                      <div className="p-2 border border-dashed rounded">
                                                          <div className="d-flex align-items-center">
                                                              <div className="avatar-sm me-2">
                                                                  <div className="avatar-title rounded bg-transparent text-primary fs-24">
                                                                      <i className="ri-stack-fill"/>
                                                                  </div>
                                                              </div>
                                                              <div className="flex-grow-1 mw-0">
                                                                  <p className="mb-1 text-truncate">
                                                                      <span className="text-muted">{t('store:balance')}:</span>{" "}
                                                                      {(stores || []).filter((item:SaleStoreType)=> {return item.uuid === storeValue.uuid}).shift().name}</p>
                                                                  <h5 className="mb-0">
                                                                      {FormatValue(storeValue.value)}
                                                                  </h5>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              );
                                          })}
                                      </div>

                                      {// eslint-disable-next-line
                                          (productParams || []).map((productParam:ProductParamType)=>{
                                            if (productParam?.isCart && productParam.isCart){
                                                let _productParamValue = (availableProductCartParamValues || []).filter((productParamValue:ProductParamValueType)=>{
                                                   return  productParamValue.productParam.uuid === productParam.uuid
                                                });

                                                if ((_productParamValue || []).length>0){
                                                    return(
                                                        <div key={"productParamCont_"+productParam.uuid} className={"pb-2"}>
                                                            <h5 className="fs-15">{productParam.name}</h5>
                                                            {productParam.type===ProductParamTypes.LIST ?
                                                                <div className="d-flex flex-wrap gap-2 align-items-center">
                                                                    {// eslint-disable-next-line
                                                                        (productParam.values || []).map((productParamListValue: ProductParamListValueType)=>{
                                                                        if (((availableProductCartParamValues || []).filter((productParamValue:ProductParamValueType)=>{
                                                                            return productParamValue.value === productParamListValue.uuid
                                                                        }) || []).length>0) {
                                                                            return (
                                                                                <span key={"productParamItem_"+productParam.uuid+"_"+productParamListValue.uuid}>
                                                                                    <Input
                                                                                        id={"productParam_"+productParam.uuid+"_"+productParamListValue.uuid}
                                                                                        type="radio"
                                                                                        className="btn-check"
                                                                                        name={"productParam_"+productParam.uuid}
                                                                                        checked={(selectedProductCartParamValues || []).filter((selectedProductParamValue: ProductParamValueType)=>{
                                                                                            return selectedProductParamValue.productParam.uuid === productParam.uuid && selectedProductParamValue.value === productParamListValue.uuid;
                                                                                        }).length>0}
                                                                                        onChange={
                                                                                            ()=>{handleChangeProductParam(productParam, productParamListValue.uuid)}
                                                                                        }
                                                                                        disabled={
                                                                                            checkProductParamOffersPresent(productParam,productParamListValue.uuid)===false
                                                                                        }
                                                                                    />
                                                                                    {productParam.isColor ?
                                                                                        <span
                                                                                            id={"labelProductParam_"+productParam.uuid+"_"+productParamListValue.uuid}
                                                                                        >
                                                                                            <button
                                                                                                type="button"
                                                                                                className={"btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 "+(((selectedProductCartParamValues || []).filter((selectedProductParamValue: ProductParamValueType)=>{
                                                                                                    return selectedProductParamValue.productParam.uuid === productParam.uuid && selectedProductParamValue.value === productParamListValue.uuid;
                                                                                                }).length>0) ?'border-info':'')}
                                                                                                ref={element => {
                                                                                                    if (element) element.style.setProperty('color', productParamListValue.color, 'important');
                                                                                                }}
                                                                                                onClick={
                                                                                                    ()=>{handleChangeProductParam(productParam, productParamListValue.uuid)}
                                                                                                }
                                                                                                disabled={
                                                                                                    checkProductParamOffersPresent(productParam,productParamListValue.uuid)===false
                                                                                                }
                                                                                            >
                                                                                                <i className="ri-checkbox-blank-circle-fill"/>
                                                                                            </button>
                                                                                         </span>
                                                                                        :
                                                                                    <Label
                                                                                        id={"labelProductParam_"+productParam.uuid+"_"+productParamListValue.uuid}
                                                                                        className={"btn p-0 d-flex justify-content-center align-items-center"
                                                                                            +(productParamListValue.value.length>3?' rounded-2 ps-1 pe-1':' avatar-xs rounded-circle')
                                                                                            +(((selectedProductCartParamValues || []).filter((selectedProductParamValue: ProductParamValueType)=>{
                                                                                            return selectedProductParamValue.productParam.uuid === productParam.uuid && selectedProductParamValue.value === productParamListValue.uuid;
                                                                                        }).length>0) ?' btn-primary border-primary':' btn-soft-primary')}
                                                                                        for={"productParam_"+productParam.uuid+"_"+productParamListValue.uuid}
                                                                                    >
                                                                                        {productParamListValue.value}
                                                                                    </Label>
                                                                                    }
                                                                                    <UncontrolledTooltip target={"labelProductParam_"+productParam.uuid+"_"+productParamListValue.uuid} placement="top">
                                                                                        {productParamListValue.value}
                                                                                        {
                                                                                            checkProductParamOffersPresent(productParam,productParamListValue.uuid)===false &&
                                                                                            <>
                                                                                                <br/>
                                                                                                {t('product:outOfStock')}
                                                                                            </>
                                                                                        }
                                                                                    </UncontrolledTooltip>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    })
                                                                    }
                                                                </div>
                                                                :
                                                                <>
                                                                {// eslint-disable-next-line
                                                                    (availableProductCartParamValues || []).map((productParamValue:ProductParamValueType)=>{
                                                                        if (productParamValue.productParam.uuid === productParam.uuid){
                                                                            return (
                                                                                <span key={"productParamItem_"+productParam.uuid+"_"+productParamValue.uuid}>
                                                                                    <Input
                                                                                        id={"productParam_"+productParam.uuid+"_"+productParamValue.uuid}
                                                                                        type="radio"
                                                                                        className="btn-check"
                                                                                        name={"productParam_"+productParam.uuid}
                                                                                        checked={(selectedProductCartParamValues || []).filter((selectedProductParamValue: ProductParamValueType)=>{
                                                                                            return selectedProductParamValue.productParam.uuid === productParam.uuid && selectedProductParamValue.value === productParamValue.value;
                                                                                        }).length>0}
                                                                                        onChange={()=>{handleChangeProductParam(productParam, productParamValue.value)}}
                                                                                        disabled={
                                                                                            checkProductParamOffersPresent(productParam,productParamValue.value)===false
                                                                                        }
                                                                                    />
                                                                                    <Label
                                                                                        className="btn btn-soft-primary rounded-2 p-0 d-flex justify-content-center align-items-center"
                                                                                        id={"productParam_"+productParam.uuid+"_"+productParamValue.uuid}
                                                                                    >
                                                                                        {productParamValue.value}
                                                                                    </Label>
                                                                                    <UncontrolledTooltip target={"productParam_"+productParam.uuid+"_"+productParamValue.uuid} placement="top">
                                                                                        {productParamValue.value}
                                                                                    </UncontrolledTooltip>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                </>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            }
                                      })}


                                      {/*<Row>*/}
                                      {/*    <Col xl={6}>*/}
                                      {/*        <div className=" mt-4">*/}
                                      {/*            <h5 className="fs-15">Sizes :</h5>*/}
                                      {/*            <div className="d-flex flex-wrap gap-2">*/}
                                      {/*                <Tooltip*/}
                                      {/*                    placement="top"*/}
                                      {/*                    isOpen={ssize}*/}
                                      {/*                    target="TooltipSSize"*/}
                                      {/*                    toggle={() => {*/}
                                      {/*                        setssize(!ssize);*/}
                                      {/*                    }}*/}
                                      {/*                >*/}
                                      {/*                    Out of Stock*/}
                                      {/*                </Tooltip>*/}
                                      {/*                <Tooltip*/}
                                      {/*                    placement="top"*/}
                                      {/*                    isOpen={msize}*/}
                                      {/*                    target="TooltipMSize"*/}
                                      {/*                    toggle={() => {*/}
                                      {/*                        setmsize(!msize);*/}
                                      {/*                    }}*/}
                                      {/*                >*/}
                                      {/*                    04 Items Available*/}
                                      {/*                </Tooltip>*/}
                                      {/*                <Tooltip*/}
                                      {/*                    placement="top"*/}
                                      {/*                    isOpen={lsize}*/}
                                      {/*                    target="TooltipLSize"*/}
                                      {/*                    toggle={() => {*/}
                                      {/*                        setlsize(!lsize);*/}
                                      {/*                    }}*/}
                                      {/*                >*/}
                                      {/*                    06 Items Available*/}
                                      {/*                </Tooltip>*/}
                                      {/*                <Tooltip*/}
                                      {/*                    placement="top"*/}
                                      {/*                    isOpen={xlsize}*/}
                                      {/*                    target="TooltipXlSize"*/}
                                      {/*                    toggle={() => {*/}
                                      {/*                        setxlsize(!xlsize);*/}
                                      {/*                    }}*/}
                                      {/*                >*/}
                                      {/*                    Out of Stock*/}
                                      {/*                </Tooltip>*/}

                                      {/*                <Input*/}
                                      {/*                    type="radio"*/}
                                      {/*                    className="btn-check"*/}
                                      {/*                    name="productsize-radio"*/}
                                      {/*                />*/}
                                      {/*                <Label*/}
                                      {/*                    className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"*/}
                                      {/*                    id="TooltipSSize"*/}
                                      {/*                >*/}
                                      {/*                    S*/}
                                      {/*                </Label>*/}

                                      {/*                <Input*/}
                                      {/*                    type="radio"*/}
                                      {/*                    className="btn-check"*/}
                                      {/*                    name="productsize-radio"*/}
                                      {/*                />*/}
                                      {/*                <Label*/}
                                      {/*                    className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"*/}
                                      {/*                    id="TooltipMSize"*/}
                                      {/*                >*/}
                                      {/*                    M*/}
                                      {/*                </Label>*/}

                                      {/*                <Input*/}
                                      {/*                    type="radio"*/}
                                      {/*                    className="btn-check"*/}
                                      {/*                    name="productsize-radio"*/}
                                      {/*                />*/}
                                      {/*                <Label*/}
                                      {/*                    className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"*/}
                                      {/*                    id="TooltipLSize"*/}
                                      {/*                >*/}
                                      {/*                    L*/}
                                      {/*                </Label>*/}

                                      {/*                <Input*/}
                                      {/*                    type="radio"*/}
                                      {/*                    className="btn-check"*/}
                                      {/*                    name="productsize-radio"*/}
                                      {/*                />*/}
                                      {/*                <Label*/}
                                      {/*                    className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"*/}
                                      {/*                    id="TooltipXlSize"*/}
                                      {/*                >*/}
                                      {/*                    XL*/}
                                      {/*                </Label>*/}
                                      {/*            </div>*/}
                                      {/*        </div>*/}
                                      {/*    </Col>*/}

                                      {/*    <Col xl={6}>*/}
                                      {/*        <div className=" mt-4">*/}
                                      {/*            <h5 className="fs-15">Colors :</h5>*/}
                                      {/*            <div className="d-flex flex-wrap gap-2">*/}
                                      {/*                <div*/}
                                      {/*                    data-bs-toggle="tooltip"*/}
                                      {/*                    data-bs-trigger="hover"*/}
                                      {/*                    data-bs-placement="top"*/}
                                      {/*                    title="Out of Stock"*/}
                                      {/*                >*/}
                                      {/*                    <button*/}
                                      {/*                        type="button"*/}
                                      {/*                        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-primary"*/}
                                      {/*                        disabled*/}
                                      {/*                    >*/}
                                      {/*                        <i className="ri-checkbox-blank-circle-fill"></i>*/}
                                      {/*                    </button>*/}
                                      {/*                </div>*/}
                                      {/*                <div*/}
                                      {/*                    data-bs-toggle="tooltip"*/}
                                      {/*                    data-bs-trigger="hover"*/}
                                      {/*                    data-bs-placement="top"*/}
                                      {/*                    title="03 Items Available"*/}
                                      {/*                >*/}
                                      {/*                    <button*/}
                                      {/*                        type="button"*/}
                                      {/*                        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-secondary"*/}
                                      {/*                    >*/}
                                      {/*                        <i className="ri-checkbox-blank-circle-fill"></i>*/}
                                      {/*                    </button>*/}
                                      {/*                </div>*/}
                                      {/*                <div*/}
                                      {/*                    data-bs-toggle="tooltip"*/}
                                      {/*                    data-bs-trigger="hover"*/}
                                      {/*                    data-bs-placement="top"*/}
                                      {/*                    title="03 Items Available"*/}
                                      {/*                >*/}
                                      {/*                    <button*/}
                                      {/*                        type="button"*/}
                                      {/*                        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-success"*/}
                                      {/*                    >*/}
                                      {/*                        <i className="ri-checkbox-blank-circle-fill"></i>*/}
                                      {/*                    </button>*/}
                                      {/*                </div>*/}
                                      {/*                <div*/}
                                      {/*                    data-bs-toggle="tooltip"*/}
                                      {/*                    data-bs-trigger="hover"*/}
                                      {/*                    data-bs-placement="top"*/}
                                      {/*                    title="02 Items Available"*/}
                                      {/*                >*/}
                                      {/*                    <button*/}
                                      {/*                        type="button"*/}
                                      {/*                        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-info"*/}
                                      {/*                    >*/}
                                      {/*                        <i className="ri-checkbox-blank-circle-fill"></i>*/}
                                      {/*                    </button>*/}
                                      {/*                </div>*/}
                                      {/*                <div*/}
                                      {/*                    data-bs-toggle="tooltip"*/}
                                      {/*                    data-bs-trigger="hover"*/}
                                      {/*                    data-bs-placement="top"*/}
                                      {/*                    title="01 Items Available"*/}
                                      {/*                >*/}
                                      {/*                    <button*/}
                                      {/*                        type="button"*/}
                                      {/*                        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-warning"*/}
                                      {/*                    >*/}
                                      {/*                        <i className="ri-checkbox-blank-circle-fill"></i>*/}
                                      {/*                    </button>*/}
                                      {/*                </div>*/}
                                      {/*                <div*/}
                                      {/*                    data-bs-toggle="tooltip"*/}
                                      {/*                    data-bs-trigger="hover"*/}
                                      {/*                    data-bs-placement="top"*/}
                                      {/*                    title="04 Items Available"*/}
                                      {/*                >*/}
                                      {/*                    <button*/}
                                      {/*                        type="button"*/}
                                      {/*                        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-danger"*/}
                                      {/*                    >*/}
                                      {/*                        <i className="ri-checkbox-blank-circle-fill"></i>*/}
                                      {/*                    </button>*/}
                                      {/*                </div>*/}
                                      {/*                <div*/}
                                      {/*                    data-bs-toggle="tooltip"*/}
                                      {/*                    data-bs-trigger="hover"*/}
                                      {/*                    data-bs-placement="top"*/}
                                      {/*                    title="03 Items Available"*/}
                                      {/*                >*/}
                                      {/*                    <button*/}
                                      {/*                        type="button"*/}
                                      {/*                        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-light"*/}
                                      {/*                    >*/}
                                      {/*                        <i className="ri-checkbox-blank-circle-fill"></i>*/}
                                      {/*                    </button>*/}
                                      {/*                </div>*/}
                                      {/*                <div*/}
                                      {/*                    data-bs-toggle="tooltip"*/}
                                      {/*                    data-bs-trigger="hover"*/}
                                      {/*                    data-bs-placement="top"*/}
                                      {/*                    title="04 Items Available"*/}
                                      {/*                >*/}
                                      {/*                    <button*/}
                                      {/*                        type="button"*/}
                                      {/*                        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-dark"*/}
                                      {/*                    >*/}
                                      {/*                        <i className="ri-checkbox-blank-circle-fill"></i>*/}
                                      {/*                    </button>*/}
                                      {/*                </div>*/}
                                      {/*            </div>*/}
                                      {/*        </div>*/}
                                      {/*    </Col>*/}
                                      {/*</Row>*/}

                                      {product?.description && product.description.length>0 &&
                                      <div className="mt-4 text-muted">
                                          <h5 className="fs-15">{t('common:shortDescription')}:</h5>
                                          {product?.description && product?.description.length>0 &&
                                          <p>
                                              {product.description}
                                          </p>
                                          }
                                      </div>
                                      }

                                      {/*<Row>*/}
                                      {/*    <Col sm={6}>*/}
                                      {/*        <div className="mt-3">*/}
                                      {/*            <h5 className="fs-14">Features :</h5>*/}
                                      {/*            <ul className="list-unstyled">*/}
                                      {/*                <li className="py-1">*/}
                                      {/*                    <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}*/}
                                      {/*                    Full Sleeve*/}
                                      {/*                </li>*/}
                                      {/*                <li className="py-1">*/}
                                      {/*                    <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}*/}
                                      {/*                    Cotton*/}
                                      {/*                </li>*/}
                                      {/*                <li className="py-1">*/}
                                      {/*                    <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}*/}
                                      {/*                    All Sizes available*/}
                                      {/*                </li>*/}
                                      {/*                <li className="py-1">*/}
                                      {/*                    <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}*/}
                                      {/*                    4 Different Color*/}
                                      {/*                </li>*/}
                                      {/*            </ul>*/}
                                      {/*        </div>*/}
                                      {/*    </Col>*/}
                                      {/*    <Col sm={6}>*/}
                                      {/*        <div className="mt-3">*/}
                                      {/*            <h5 className="fs-15">Services :</h5>*/}
                                      {/*            <ul className="list-unstyled product-desc-list">*/}
                                      {/*                <li className="py-1">10 Days Replacement</li>*/}
                                      {/*                <li className="py-1">*/}
                                      {/*                    Cash on Delivery available*/}
                                      {/*                </li>*/}
                                      {/*            </ul>*/}
                                      {/*        </div>*/}
                                      {/*    </Col>*/}
                                      {/*</Row>*/}

                                      {
                                          (
                                              (product?.productParamValues && (product.productParamValues || []).length>0)
                                              ||
                                              (product?.detailText && product.detailText.length>0)
                                          ) &&
                                      <div className="product-content mt-5">
                                          <h5 className="fs-15 mb-3">{t('product:productDescription')}:</h5>
                                          <Nav tabs className="nav-tabs-custom nav-success">
                                              {product?.productParamValues && (product.productParamValues || []).length>0 &&
                                                  <NavItem>
                                                      <NavLink
                                                          style={{ cursor: "pointer" }}
                                                          className={classnames({
                                                              active: activeTab === propertiesTab,
                                                          })}
                                                          onClick={() => {
                                                              toggleTab(propertiesTab);
                                                          }}
                                                      >
                                                          {t('product:specification')}
                                                      </NavLink>
                                                  </NavItem>
                                              }
                                              {product?.detailText && product.detailText.length>0 &&
                                                  <NavItem>
                                                      <NavLink
                                                          style={{ cursor: "pointer" }}
                                                          className={classnames({
                                                              active: activeTab === detailTextTab,
                                                          })}
                                                          onClick={() => {
                                                              toggleTab(detailTextTab);
                                                          }}
                                                      >
                                                          {t('product:details')}
                                                      </NavLink>
                                                  </NavItem>
                                              }
                                          </Nav>

                                          <TabContent
                                              activeTab={activeTab}
                                              className="border border-top-0 p-4"
                                              id="nav-tabContent"
                                          >
                                              {product?.productParamValues && (product.productParamValues || []).length>0 &&
                                                  <TabPane
                                                      id="nav-specification"
                                                      tabId={propertiesTab}
                                                  >
                                                      <div className="table-responsive">
                                                          <table className="table mb-0">
                                                              <tbody>
                                                              {(product.productParamValues || []).map((propertyItemValue:ProductParamValueType, key:number)=>{
                                                                  return(
                                                                      <tr key={'propertyItemValue_'+propertyItemValue.uuid}>
                                                                          <th scope="row" style={{ width: "50%" }}
                                                                              className={key===product.productParamValues.length-1 ? "border-bottom-0": ''}
                                                                          >
                                                                              {propertyItemValue.productParam.name}
                                                                          </th>
                                                                          <td
                                                                              className={key===product.productParamValues.length-1 ? "border-bottom-0": ''}
                                                                          >
                                                                              {propertyItemValue.productParam.type === ProductParamTypes.BOOLEAN ?
                                                                                  <>
                                                                                      {
                                                                                          propertyItemValue.value ?
                                                                                              <i className="ri-checkbox-circle-fill text-success"></i>
                                                                                              :
                                                                                              <i className="ri-indeterminate-circle-line text-primary"></i>
                                                                                      }
                                                                                  </>
                                                                                  :
                                                                                  propertyItemValue.productParam.type === ProductParamTypes.LIST && ((propertyItemValue.productParam?.values || []).length>0) ?
                                                                                      propertyItemValue.productParam.values.filter((paramValue:ProductParamListValueType)=>{ return paramValue.uuid === propertyItemValue.value})?.shift()?.value
                                                                                      :
                                                                                  propertyItemValue.value
                                                                              }
                                                                          </td>
                                                                      </tr>
                                                                  );
                                                              })}
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                  </TabPane>
                                              }
                                              {product?.detailText && product.detailText.length>0 &&
                                                  <TabPane
                                                      id="nav-detail"
                                                      tabId={detailTextTab}
                                                  >
                                                      <div>
                                                          <div dangerouslySetInnerHTML={{ __html : product.detailText}} />
                                                      </div>
                                                  </TabPane>
                                              }
                                          </TabContent>
                                      </div>
                                      }

                                      {(product?.width || product?.height || product?.depth || product?.weight) ?
                                      <div className="product-content mt-5 d-flex flex-row">
                                          {product?.width &&
                                              <DimensionWidget
                                              icon={"mdi mdi-axis-x-arrow"}
                                              label={t('common:width')}
                                              value={product.width}
                                              valueSuffix={'mm'}
                                          />
                                          }
                                          {product?.height &&
                                              <DimensionWidget
                                                  icon={"mdi mdi-axis-z-arrow"}
                                                  label={t('common:height')}
                                                  value={product.height}
                                                  valueSuffix={'mm'}
                                              />
                                          }
                                          {product?.depth &&
                                              <DimensionWidget
                                                  icon={"mdi mdi-axis-y-arrow"}
                                                  label={t('common:depth')}
                                                  value={product.depth}
                                                  valueSuffix={'mm'}
                                              />
                                          }
                                          {product?.weight &&
                                              <DimensionWidget
                                                  icon={"mdi mdi-weight-gram"}
                                                  label={t('common:weight')}
                                                  value={product.weight}
                                                  valueSuffix={'g'}
                                              />
                                          }
                                      </div>
                                          : <></>
                                      }

                                      <div className="product-content mt-5">
                                          <h5 className="fs-15 mb-3">{t('integration:integrations')}:</h5>
                                          {
                                              (priceTypes || []).map((priceType:PriceTypeType)=>(
                                                      priceType.enabled &&
                                                      <div key={'integrationPriceCont_'+priceType.uuid} className="mb-4">
                                                          <div className="mb-2">
                                                              <PriceTypeItemLine item={priceType}/>
                                                          </div>
                                                          <div className="d-flex flex-row gap-3">
                                                              {(channels || []).map((channel:ContentPeerChannelType)=>(
                                                                  channel.connector.handler.canECommerce && channel.isManaged &&
                                                                  <ChannelItemProductIntegration
                                                                      item={channel}
                                                                      isEnabled={
                                                                          (product?.integrations || []).filter((integration:ProductIntegrationType)=>{return integration.priceType.uuid === priceType.uuid && integration?.channel?.uuid === channel.uuid})?.shift()?.enabled || false
                                                                      }
                                                                      currency={
                                                                          (product?.integrations||[]).filter((integration:ProductIntegrationType)=>{return integration.priceType.uuid === priceType.uuid && integration?.channel?.uuid === channel.uuid})?.shift()?.currency || null
                                                                        }
                                                                      onChange={()=>{}}
                                                                      disabled={true}
                                                                      key={'integrationPriceCont_'+priceType.uuid+'_channel_'+channel.uuid}
                                                                  />
                                                              ))
                                                              }
                                                              {(saleIntegrations || []).map((saleIntegration:SaleIntegrationType)=>(
                                                                  (saleIntegration.handler.type === SaleIntegrationHandlerTypes.OUTBOUND &&
                                                                  (
                                                                  saleIntegration.handler.code === SaleIntegrationHandlerCodes.WIDGET_WEB
                                                                  ||
                                                                  saleIntegration.handler.code === SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                                                                  )) &&
                                                                  <SaleIntegrationItemProductIntegration
                                                                      item={saleIntegration}
                                                                      isEnabled={
                                                                          (product?.integrations || []).filter((integration:ProductIntegrationType)=>{return integration.priceType.uuid === priceType.uuid && integration?.saleIntegration?.uuid === saleIntegration.uuid})?.shift()?.enabled || false
                                                                      }
                                                                      currency={
                                                                          (product?.integrations||[]).filter((integration:ProductIntegrationType)=>{return integration.priceType.uuid === priceType.uuid && integration?.saleIntegration?.uuid === saleIntegration.uuid})?.shift()?.currency || null
                                                                      }
                                                                      onChange={()=>{}}
                                                                      disabled={true}
                                                                      key={'integrationPriceCont_'+priceType.uuid+'_channel_'+saleIntegration.uuid}
                                                                  />
                                                              ))
                                                              }
                                                          </div>
                                                      </div>
                                                  )
                                              )
                                          }
                                      </div>
                                  </div>
                              </Col>
                          </Row>

                      </CardBody>
                  </Card>
              </Col>
          </Row>
                      <Row className="mb-4">
                          <Col md={8}>
                              <div className="d-flex align-items-start gap-3">
                                  <Link
                                      to={route.SALE_PRODUCTS_LIST}
                                      className={"btn btn-primary waves-effect waves-light btn-label"}>
                                      <i className={"ri-arrow-left-line align-middle fs-16 me-2 label-icon"}/>
                                      {t('product:backToProducts')}
                                  </Link>

                              </div>
                          </Col>
                          <Col md={4}>
                          </Col>
                      </Row>
          </>
              }
              </>
          :
          <>
              <Loader/>
          </>
      }
    </>
  );
}

export default SaleProductDetail;
