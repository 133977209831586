import { useEffect, useState } from "react";
import {useRedux} from "../hooks";
import {ContentProjectType} from "../redux/Content/Project/types";
import * as route from "../api/routes";

import {useTranslation} from "react-i18next";
import {EventUserBadgeType} from "../redux/Event/Badge/types";
import {layoutTypes} from "../constants/Layout";
import {useLocation} from "react-router-dom";

export interface NavLinkType {
    id?: any,
    label: string,
    link?: string,
    parentId?: string,
    subItems?: Array<NavLinkType>,
    stateVariables? : any;
    click?: (event:any) => void;
    badgeName?: string,
    badgeColor?: string,
    isChildItem?: boolean,
    childItems?: Array<NavLinkType>,
    isHeader?: boolean,
    icon?:string,
    isNew?: boolean
}

export enum MenuItem {
    DASHBOARD = 'dashboard',
    DASHBOARD_CONTENT_MARKETING = 'dashboardContentMarketing',
    DASHBOARD_SALES = 'dashboardSales',
    DASHBOARD_TASKS = 'dashboardTasks',
    SERVICES = 'services',
    SERVICES_ACCOUNTS = 'servicesAccounts',
    SERVICES_CHANNELS = 'servicesChannels',
    SERVICES_COUNTERS = 'servicesCounters',
    ACTIVITIES = 'activities',
    ACTIVITIES_PLAN = 'activitiesPlan',
    ACTIVITIES_KANBAN = 'activitiesKanBan',
    ACTIVITIES_IDEAS = 'activitiesIdeas',
    SOCIAL_COMMERCE = 'socialCommerce',
    SOCIAL_COMMERCE_PRODUCTS = 'socialCommerceProducts',
    SOCIAL_COMMERCE_IMPORT = 'socialCommerceImport',
    TEMPLATES = 'templates',
    TEMPLATES_PATTERNS = 'templatePatterns',
    TEMPLATES_QUICK_ANSWERS = 'templateQuickAnswers',
    MESSENGER = 'messenger',
    MESSENGER_FULL = 'messengerFull',
    MESSENGER_COMMENTS = 'messengerComments',
    DIMENSIONS = 'dimensions',
    DIMENSIONS_GOALS = 'dimensionsGoals',
    STATISTICS = 'statistics',
    STATISTICS_CHANNELS = 'statisticChannels',
    COMPARE_CHANNELS = 'compareChannels',
    NOTIFICATIONS = 'notifications',
    NOTIFICATIONS_PERSONAL = 'notificationsPersonal',
    NOTIFICATIONS_VERSION_UPDATES = 'notificationsVersionUpdates',
    SETTINGS = 'settings',
    SETTINGS_PROFILE ='settingsProfile',
    SETTINGS_WIZARD='settingsWizard',
    SETTINGS_PLANS ='settingsPlans',
    SETTINGS_ORDERS='settingsOrders',
    SETTINGS_ACTIVITIES_TYPES = 'settingsActivitiesTypes',
    SETTINGS_PROJECTS = 'settingsProjects',
    SETTINGS_USERS = 'settingsUsers',
    SETTINGS_GROUPS = 'settingsGroups',
    SUPPORT_TICKETS = 'supportTickets',
    SUPPORT_SERVICE_TICKETS = 'supportServiceTickets',
    MAIN_ABOUT = 'mainAbout',
    SALE_STORES = 'saleStores',
    SALE_CURRENCIES = 'saleCurrencies',
    SALE_CURRENCY_RATES = 'saleCurrencyRates',
    SALE_PRICE_TYPES = 'salePriceTypes',
    SALE_PAYMENT_SYSTEMS = 'salePaymentSystems',
    SALE_DELIVERY_SYSTEMS = 'saleDeliverySystems',
    SALE_PRODUCT_PARAMS = 'saleProductParams',
    SALE_SETTINGS = 'saleSettings',
    SALE_ORDERS = 'saleOrders',
    SALE_ORDER_STATUSES = 'saleOrderStatuses',
    SALE_INTEGRATIONS = 'saleIntegrations',
    SALE_PRODUCTS = 'saleProducts',
    SALE_CATEGORIES = 'saleCategories',
    SALE_CUSTOMERS= 'saleCustomers',
    EVENT_WEBHOOKS= 'eventWebhooks',
    TASKS = 'tasks',
    TASK_TASKS = 'taskTasks',
    FUNNELS = 'funnels'
}

const grantedMenuItems=[MenuItem.SUPPORT_TICKETS, MenuItem.MAIN_ABOUT];

const Navdata = () => {

    const { t, i18n } = useTranslation(['common']);
    const location = useLocation()
    const { dispatch, useAppSelector } = useRedux();

    const {
        isSupportServiceUser,
        mainUser,
        eventUserBadge,
        layoutType
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
        eventUserBadge: state.EventUserBadge,
        isSupportServiceUser: state.Login.isSupportService,
        layoutType: state.Layout.layoutType
    }));

    const dashboardWithoutProjects:Array<NavLinkType> = [
        {
            id: MenuItem.DASHBOARD_CONTENT_MARKETING,
            label: t("menu:contentMarketing"),
            link: route.MAIN_DASHBOARD_CONTENT_MARKETING,
            parentId: MenuItem.DASHBOARD,
        },
        {
            id: MenuItem.DASHBOARD_SALES,
            label: t("menu:salesDashboard"),
            link: route.MAIN_DASHBOARD_SALES,
            parentId: MenuItem.DASHBOARD,
        },
        {
            id: MenuItem.DASHBOARD_TASKS,
            label: t("menu:tasksDashboard"),
            link: route.MAIN_DASHBOARD_TASKS,
            parentId: MenuItem.DASHBOARD,
        }
    ]

    const [dashboardSubItems, setDashboardSubItems] = useState<NavLinkType[]>(dashboardWithoutProjects);

    const [permissions, setPermissions] = useState<MenuItem[]>([]);
    const [badges, setBadges] = useState<EventUserBadgeType[]>([]);
    const [supportBadges, setSupportBadges] = useState<EventUserBadgeType[]>([]);

    useEffect(() => {
        if (mainUser.currentUser!== undefined && mainUser.currentUser.permissions !== undefined && mainUser.currentUser.permissions.permittedAreas !== undefined) {
            setPermissions([...mainUser.currentUser.permissions.permittedAreas]);
        } else {
            setPermissions([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainUser?.currentUser?.uuid]);

    useEffect(() => {
        setBadges(eventUserBadge?.cabinetItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventUserBadge?.cabinetItems]);

    useEffect(() => {
        setSupportBadges(eventUserBadge?.supportItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventUserBadge?.supportItems]);

    const {
        contentProjects,
    } = useAppSelector(state => ({
        contentProjects: state.ContentProjects,
    }));

    useEffect(()=>{

        let dashboardItems = [];
        if (contentProjects?.items && contentProjects.items.length>0) {
            dashboardItems = contentProjects.items.map((contentProject: ContentProjectType) => {
                return {
                    id: "dashboardProject" + contentProject.uuid,
                    label: contentProject.name,
                    link: route.MAIN_DASHBOARD_CONTENT_PROJECT.replace(/#idProject#/g, contentProject.uuid),
                    parentId: MenuItem.DASHBOARD,
                }
            });
        }

        if (dashboardItems && dashboardItems.length >0 ){
            setDashboardSubItems([...dashboardItems, ...dashboardWithoutProjects]);
        } else {
            setDashboardSubItems(dashboardWithoutProjects);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, contentProjects?.items, i18n.language]);

    const [isDashboard, setIsDashboard] = useState(false);
    const [isServices, setIsServices] = useState(false);
    const [isActivities, setIsActivities] = useState(false);
    const [isSocialCommerce, setIsSocialCommerce] = useState(false);
    const [isTemplates, setIsTemplates] = useState(false);
    const [isMessenger, setIsMessenger] = useState(false);
    const [isDimensions, setIsDimensions] = useState(false);
    const [isStatistics, setIsStatistics] = useState(false);
    const [isNotifications, setIsNotifications] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [isSupport, setIsSupport] = useState(false);
    const [isTask, setIsTask] = useState(false);
    const [isFunnel, setIsFunnel] = useState(false);
    const [isSupportService, setIsSupportService] = useState(false);

    const [isCurrentState, setIsCurrentState] = useState(MenuItem.DASHBOARD);

    function updateIconSidebar(e) {

        if (e && e.target && e.target.getAttribute("data-subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...(iconItems ||[])];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                let id = item.getAttribute("data-subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    function activateNavBar(isShow:boolean){

        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            if (isShow) {
                document.body.classList.remove('twocolumn-panel');
            } else {
                document.body.classList.add('twocolumn-panel');
            }
        }
    }

    useEffect(() => {
        if (isCurrentState !== MenuItem.DASHBOARD) {
            setIsDashboard(false);
        }
        if (isCurrentState !== MenuItem.SERVICES) {
            setIsServices(false);
        }
        if (isCurrentState !== MenuItem.ACTIVITIES) {
            setIsActivities(false);
        }
        if (isCurrentState !== MenuItem.SOCIAL_COMMERCE) {
            setIsSocialCommerce(false);
        }
        if (isCurrentState !== MenuItem.TEMPLATES) {
            setIsTemplates(false);
        }
        if (isCurrentState !== MenuItem.MESSENGER) {
            setIsMessenger(false);
        }
        if (isCurrentState !== MenuItem.DIMENSIONS) {
            setIsDimensions(false);
        }
        if (isCurrentState !== MenuItem.STATISTICS) {
            setIsStatistics(false);
        }
        if (isCurrentState !== MenuItem.NOTIFICATIONS) {
            setIsNotifications(false);
        }
        if (isCurrentState !== MenuItem.SETTINGS) {
            setIsSettings(false);
        }
        if (isCurrentState !== MenuItem.SUPPORT_TICKETS) {
            setIsSupport(false);
        }
        if (isCurrentState !== MenuItem.SUPPORT_SERVICE_TICKETS) {
            setIsSupportService(false);
        }
        if (isCurrentState !== MenuItem.TASKS) {
            setIsTask(false);
        }
        if (isCurrentState !== MenuItem.FUNNELS) {
            setIsFunnel(false);
        }

    }, [
        // history,
        isCurrentState,
        isDashboard,
        isServices,
        isActivities,
        isSocialCommerce,
        isTemplates,
        isMessenger,
        isDimensions,
        isStatistics,
        isNotifications,
        isSettings,
        isSupport,
        isSupportService,
        isTask,
        isFunnel
    ]);


    const menuItems:Array<NavLinkType> = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: MenuItem.DASHBOARD,
            label: t('menu:dashboards'),
            icon: "ri-dashboard-2-line",
            link: [...dashboardSubItems].shift().link || '/#',
            stateVariables: isDashboard,
            click: function (e) {
                    //e.preventDefault();
                    if (!isDashboard){
                        setIsCurrentState(MenuItem.DASHBOARD);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsDashboard(!isDashboard);
                    updateIconSidebar(e);
            },
            subItems: dashboardSubItems
        },
        {
            id: MenuItem.SERVICES,
            label: t('menu:servicesAccounts'),
            icon: "ri-share-line",
            link: route.CONTENT_SERVICES_LIST,
            stateVariables: isServices,
             click: function (e) {
                 //   e.preventDefault();
                    if (!isServices) {
                        setIsCurrentState(MenuItem.SERVICES);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    updateIconSidebar(e);
                    setIsServices(!isServices);
            },
            subItems: [
                {
                    id: MenuItem.SERVICES_ACCOUNTS,
                    label: t('menu:servicesAccounts'),
                    link: route.CONTENT_SERVICES_LIST,
                    parentId: MenuItem.SERVICES,
                },
                {
                    id: MenuItem.SERVICES_CHANNELS,
                    label: t('menu:channels'),
                    link: route.CONTENT_CHANNELS_LIST,
                    parentId: MenuItem.SERVICES,
                },
                {
                    id: MenuItem.SERVICES_COUNTERS,
                    label: t('menu:counters'),
                    link: route.CONTENT_STAT_COUNTERS_LIST,
                    parentId: MenuItem.SERVICES,
                },
            ]
        },
        {
            id: MenuItem.ACTIVITIES,
            label: t('menu:activities'),
            icon: "ri-pages-line",
            link: route.CONTENT_ACTIVITY_PLAN,
            stateVariables: isActivities,
            click: function (e) {
                   // e.preventDefault();
                    if (!isActivities){
                        setIsCurrentState(MenuItem.ACTIVITIES);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsActivities(!isActivities);
                    updateIconSidebar(e);
            },
            subItems: [
                {
                    id: MenuItem.ACTIVITIES_PLAN,
                    label: t('menu:activitiesPlan'),
                    link: route.CONTENT_ACTIVITY_PLAN,
                    parentId: MenuItem.ACTIVITIES,
                },
                {
                    id: MenuItem.ACTIVITIES_KANBAN,
                    label: t('menu:activitiesKanBan'),
                    link: route.CONTENT_ACTIVITY_KANBAN,
                    parentId: MenuItem.ACTIVITIES,
                    isNew: true
                },
                {
                    id: MenuItem.ACTIVITIES_IDEAS,
                    label: t('menu:activitiesIdeas'),
                    link: route.CONTENT_ACTIVITY_IDEAS,
                    parentId: MenuItem.ACTIVITIES,
                    isNew: true
                },
            ]
        },
        {
            id: MenuItem.SOCIAL_COMMERCE,
            label: t('menu:socialCommerce'),
            icon: "ri-shopping-cart-2-line",
            link: route.SALE_ORDERS_LIST,
            badgeName:
                (((badges||[]).filter((item)=>{return item.type===MenuItem.SALE_ORDERS}).pop()?.count || 0) > 0)
                &&
                (((badges||[]).filter((item)=>{return item.type===MenuItem.SALE_ORDERS}).pop()?.count || 0)).toString(),
            badgeColor: 'primary',
            stateVariables: isSocialCommerce,
            click: function (e) {
                   // e.preventDefault();
                    if (!isSocialCommerce) {
                        setIsCurrentState(MenuItem.SOCIAL_COMMERCE);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsSocialCommerce(!isSocialCommerce);
                    updateIconSidebar(e);
            },
            subItems: [
                {
                    id: MenuItem.SALE_ORDERS,
                    label: t('menu:orders'),
                    link: route.SALE_ORDERS_LIST,
                    parentId: MenuItem.SOCIAL_COMMERCE,
                    badgeName: ((badges||[]).filter((item)=>{return item.type===MenuItem.SALE_ORDERS}).pop()?.count || 0 )> 0 && (badges||[]).filter((item)=>{return item.type===MenuItem.SALE_ORDERS}).pop()?.count.toString(),
                    badgeColor: 'primary'
                },
                {
                    id: MenuItem.SALE_CUSTOMERS,
                    label: t('menu:saleCustomers'),
                    link: route.SALE_CUSTOMERS_LIST,
                    parentId: MenuItem.SOCIAL_COMMERCE,
                },
                {
                    id: MenuItem.SALE_PRODUCTS,
                    label: t('menu:products'),
                    link: route.SALE_PRODUCTS_LIST,
                    parentId: MenuItem.SOCIAL_COMMERCE,
                },
                {
                    id: MenuItem.SALE_CATEGORIES,
                    label: t('menu:saleCategories'),
                    link: route.SALE_CATEGORIES_LIST,
                    parentId: MenuItem.SOCIAL_COMMERCE,
                },
                {
                    id: MenuItem.SALE_INTEGRATIONS,
                    label: t('menu:integrations'),
                    link: route.SALE_INTEGRATIONS_LIST,
                    parentId: MenuItem.SOCIAL_COMMERCE,
                },
                {
                    id: MenuItem.SALE_STORES,
                    label: t('menu:stores'),
                    link: route.SALE_STORES_LIST,
                    parentId: MenuItem.SOCIAL_COMMERCE,
                },

                        {
                            id: MenuItem.SALE_CURRENCIES,
                            label: t('menu:currencies'),
                            link: route.SALE_CURRENCIES_LIST,
                            parentId: MenuItem.SOCIAL_COMMERCE,
                        },
                        {
                            id: MenuItem.SALE_CURRENCY_RATES,
                            label: t('menu:currencyRates'),
                            link: route.SALE_CURRENCY_RATES_LIST,
                            parentId: MenuItem.SOCIAL_COMMERCE,
                        },
                        {
                            id: MenuItem.SALE_PRICE_TYPES,
                            label: t('menu:priceTypes'),
                            link: route.SALE_PRICE_TYPES_LIST,
                            parentId: MenuItem.SOCIAL_COMMERCE,
                        },
                        {
                            id: MenuItem.SALE_PAYMENT_SYSTEMS,
                            label: t('menu:paymentSystems'),
                            link: route.SALE_PAYMENT_SYSTEMS_LIST,
                            parentId: MenuItem.SOCIAL_COMMERCE,
                        },
                        {
                            id: MenuItem.SALE_DELIVERY_SYSTEMS,
                            label: t('menu:deliverySystems'),
                            link: route.SALE_DELIVERY_SYSTEMS_LIST,
                            parentId: MenuItem.SOCIAL_COMMERCE,
                        },
                        {
                            id: MenuItem.SALE_PRODUCT_PARAMS,
                            label: t('menu:productParams'),
                            link: route.SALE_PRODUCT_PARAMS_LIST,
                            parentId: MenuItem.SOCIAL_COMMERCE,
                        },

                        {
                            id: MenuItem.SALE_ORDER_STATUSES,
                            label: t('menu:orderStatuses'),
                            link: route.SALE_ORDER_STATUSES_LIST,
                            parentId: MenuItem.SOCIAL_COMMERCE,
                        },
            ]
        },
        {
            id: MenuItem.TASKS,
            label: t('menu:tasks'),
            icon: "ri-survey-line",
            link: route.TASK_LIST,
            badgeName:
                (((badges||[]).filter((item)=>{return item.type===MenuItem.TASKS}).pop()?.count || 0) > 0)
                &&
                (((badges||[]).filter((item)=>{return item.type===MenuItem.TASKS}).pop()?.count || 0)).toString(),
            badgeColor: 'primary',
            stateVariables: isTask,
            click: function (e) {

                    //e.preventDefault();
                    if (!isTask){
                        setIsCurrentState(MenuItem.TASKS);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    updateIconSidebar(e);
                    setIsTask(!isTask);

            },
            subItems: [
                {
                    id: MenuItem.TASK_TASKS,
                    label: t('menu:tasks'),
                    link: route.TASK_LIST,
                    parentId: MenuItem.TASKS,
                    badgeName: ((badges||[]).filter((item)=>{return item.type===MenuItem.TASKS}).pop()?.count || 0 )> 0 && (badges||[]).filter((item)=>{return item.type===MenuItem.TASKS}).pop()?.count.toString(),
                    badgeColor: 'primary'
                }
            ]
        },
        {
            id: MenuItem.FUNNELS,
            label: t('menu:funnels'),
            icon: "ri-filter-line",
            link: route.FUNNELS,
            badgeName:
                (((badges||[]).filter((item)=>{return item.type===MenuItem.FUNNELS}).pop()?.count || 0) > 0)
                &&
                (((badges||[]).filter((item)=>{return item.type===MenuItem.FUNNELS}).pop()?.count || 0)).toString(),
            badgeColor: 'primary',
            stateVariables: isFunnel,
            click: function (e) {
                    //e.preventDefault();
                    //TODO remove
                    //
                    // if (!isTask){
                    //     setIsCurrentState(MenuItem.FUNNELS);
                    //     activateNavBar(true);
                    // } else {
                    //     activateNavBar(false);
                    // }
                    // updateIconSidebar(e);
                    // setIsFunnel(!isTask);
            }
        },
        {
            id: MenuItem.TEMPLATES,
            label: t('menu:templates'),
            icon: "ri-character-recognition-line",
            link: route.CONTENT_PATTERNS_LIST,
            stateVariables: isTemplates,
            click: function (e) {
                  //  e.preventDefault();
                    if (!isTemplates) {
                        setIsCurrentState(MenuItem.TEMPLATES);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsTemplates(!isTemplates);
                    updateIconSidebar(e);
            },
            subItems: [
                {
                    id: MenuItem.TEMPLATES_PATTERNS,
                    label: t('menu:patterns'),
                    link: route.CONTENT_PATTERNS_LIST,
                    parentId: MenuItem.TEMPLATES,
                },
                {
                    id: MenuItem.TEMPLATES_QUICK_ANSWERS,
                    label: t('menu:quickAnswers'),
                    link: route.CONTENT_QUICKANSWERS_LIST,
                    parentId: MenuItem.TEMPLATES,
                },
            ]
        },
        {
            id: MenuItem.MESSENGER,
            label: t('menu:messenger'),
            icon: "ri-question-answer-line",
            link: route.MESSENGER_MESSENGER,
            badgeName: (((badges||[]).filter((item)=>{return item.type===MenuItem.MESSENGER}).pop()?.count || 0)+((badges||[]).filter((item)=>{return item.type===MenuItem.MESSENGER_COMMENTS}).pop()?.count || 0)) > 0 && (((badges||[]).filter((item)=>{return item.type===MenuItem.MESSENGER}).pop()?.count || 0)+((badges||[]).filter((item)=>{return item.type===MenuItem.MESSENGER_COMMENTS}).pop()?.count || 0)).toString(),
            badgeColor: 'primary',
            stateVariables: isMessenger,
            click: function (e) {
                    //e.preventDefault();
                    if (!isMessenger) {
                        setIsCurrentState(MenuItem.MESSENGER);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsMessenger(!isMessenger);
                    updateIconSidebar(e);

            },
            subItems: [
                {
                    id: MenuItem.MESSENGER_FULL,
                    label: t('menu:messenger'),
                    link: route.MESSENGER_MESSENGER,
                    parentId: MenuItem.MESSENGER,
                    badgeName: ((badges||[]).filter((item)=>{return item.type===MenuItem.MESSENGER}).pop()?.count || 0 )> 0 && (badges||[]).filter((item)=>{return item.type===MenuItem.MESSENGER}).pop()?.count.toString(),
                    badgeColor: 'primary'
                },
                {
                    id: MenuItem.MESSENGER_COMMENTS,
                    label: t('menu:comments'),
                    link: route.MESSENGER_COMMENTS,
                    parentId: MenuItem.MESSENGER,
                    badgeName: ((badges||[]).filter((item)=>{return item.type===MenuItem.MESSENGER_COMMENTS}).pop()?.count || 0) >0 && (badges||[]).filter((item)=>{return item.type===MenuItem.MESSENGER_COMMENTS}).pop()?.count.toString(),
                    badgeColor: 'primary'
                },
            ]
        },
        {
            id: MenuItem.DIMENSIONS,
            label: t('menu:dimensions'),
            icon: "ri-pencil-ruler-2-line",
            link: route.CONTENT_PEERTARGETS_LIST,
            stateVariables: isDimensions,
            click: function (e) {
                   // e.preventDefault();
                    if (!isDimensions) {
                        setIsCurrentState(MenuItem.DIMENSIONS);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsDimensions(!isDimensions);
                    updateIconSidebar(e);
            },
            subItems: [
                {
                    id: MenuItem.DIMENSIONS_GOALS,
                    label: t('menu:goals'),
                    link: route.CONTENT_PEERTARGETS_LIST,
                    parentId: MenuItem.DIMENSIONS,
                },
            ]
        },
        {
            id: MenuItem.STATISTICS,
            label: t('menu:stat'),
            icon: "ri-pie-chart-line",
            link: route.STAT_CONTENT_CHANNELS,
            stateVariables: isStatistics,
            click: function (e) {
                   // e.preventDefault();
                    if (!isStatistics) {
                        setIsCurrentState(MenuItem.STATISTICS);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsStatistics(!isStatistics);
                    updateIconSidebar(e);
            },
            subItems: [
                {
                    id: MenuItem.STATISTICS_CHANNELS,
                    label: t('menu:channelsStat'),
                    link: route.STAT_CONTENT_CHANNELS,
                    parentId: MenuItem.STATISTICS,
                },
                {
                    id: MenuItem.COMPARE_CHANNELS,
                    label: t('menu:compareChannels'),
                    link: route.COMPARE_CONTENT_CHANNELS,
                    parentId: MenuItem.STATISTICS,
                    isNew: true
                },
            ]
        },
        {
            id: MenuItem.NOTIFICATIONS,
            label: t('menu:notifications'),
            icon: "bx bx-bell",
            link: route.EVENT_PERSONAL_NOTIFICATIONS,
            stateVariables: isNotifications,
            badgeName: (((badges||[]).filter((item)=>{return item.type===MenuItem.NOTIFICATIONS_PERSONAL}).pop()?.count || 0)+((badges||[]).filter((item)=>{return item.type===MenuItem.NOTIFICATIONS_VERSION_UPDATES}).pop()?.count || 0)) > 0 && (((badges||[]).filter((item)=>{return item.type===MenuItem.NOTIFICATIONS_PERSONAL}).pop()?.count || 0)+((badges||[]).filter((item)=>{return item.type===MenuItem.NOTIFICATIONS_VERSION_UPDATES}).pop()?.count || 0)).toString(),
            badgeColor: 'primary',
            click: function (e) {

                    //e.preventDefault();
                    if (!isNotifications) {
                        setIsCurrentState(MenuItem.NOTIFICATIONS);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsNotifications(!isNotifications);
                    updateIconSidebar(e);

            },
            subItems: [
                {
                    id: MenuItem.NOTIFICATIONS_PERSONAL,
                    label: t('menu:myNotifications'),
                    link: route.EVENT_PERSONAL_NOTIFICATIONS,
                    parentId: MenuItem.NOTIFICATIONS,
                    badgeName: ((badges||[]).filter((item)=>{return item.type===MenuItem.NOTIFICATIONS_PERSONAL}).pop()?.count || 0) >0 && (badges||[]).filter((item)=>{return item.type===MenuItem.NOTIFICATIONS_PERSONAL}).pop()?.count.toString(),
                    badgeColor: 'primary'
                },
                {
                    id: MenuItem.NOTIFICATIONS_VERSION_UPDATES,
                    label: t('menu:versionUpdates'),
                    link: route.MAIN_VERSION_UPDATES,
                    parentId: MenuItem.NOTIFICATIONS,
                },
            ]
        },
        {
            id: MenuItem.SETTINGS,
            label: t('menu:settings'),
            icon: "ri-settings-3-line",
            link: route.MAIN_PERSONAL_PROFILE,
            stateVariables: isSettings,
            click: function (e) {
                    //e.preventDefault();
                    if (!isSettings) {
                        setIsCurrentState(MenuItem.SETTINGS);
                        activateNavBar(true);
                    } else {
                        activateNavBar(false);
                    }
                    setIsSettings(!isSettings);
                    updateIconSidebar(e);
            },
            subItems: [
                {
                    id: MenuItem.SETTINGS_PROFILE,
                    label: t('menu:profile'),
                    link: route.MAIN_PERSONAL_PROFILE,
                    parentId: MenuItem.SETTINGS,
                },
                {
                    id: MenuItem.SETTINGS_PLANS,
                    label: t('menu:plans'),
                    link: route.SALE_PLANS,
                    parentId: MenuItem.SETTINGS,
                },
                {
                    id: MenuItem.SETTINGS_ORDERS,
                    label: t('menu:orders'),
                    link: route.PLAN_ORDERS,
                    parentId: MenuItem.SETTINGS,
                },
                {
                    id: MenuItem.SETTINGS_WIZARD,
                    label: t('menu:wizard'),
                    link: route.MAIN_WIZARD_INIT,
                    parentId: MenuItem.SETTINGS,
                },
                {
                    id: MenuItem.SETTINGS_ACTIVITIES_TYPES,
                    label: t('menu:activitiesTypes'),
                    link: route.CONTENT_PEER_ACTIVITY_TYPES_LIST,
                    parentId: MenuItem.SETTINGS,
                },
                {
                    id: MenuItem.SETTINGS_PROJECTS,
                    label: t('menu:projects'),
                    link: route.CONTENT_PROJECTS_LIST,
                    parentId: MenuItem.SETTINGS,
                },
                {
                    id: MenuItem.SETTINGS_USERS,
                    label: t('menu:users'),
                    link: route.MAIN_USERS_LIST,
                    parentId: MenuItem.SETTINGS,
                },
                {
                    id: MenuItem.EVENT_WEBHOOKS,
                    label: t('menu:eventWebhooks'),
                    link: route.EVENT_WEBHOOKS_LIST,
                    parentId: MenuItem.SETTINGS,
                },
                {
                    id: MenuItem.MAIN_ABOUT,
                    label: t('menu:about'),
                    link: route.MAIN_ABOUT,
                    parentId: MenuItem.SETTINGS,
                },
            ]
        },
        {
            id: MenuItem.SUPPORT_TICKETS,
            label: t('menu:supportTicket'),
            icon: "ri-customer-service-2-line",
            link: route.SUPPORT_TICKETS_LIST,
            badgeName: ((supportBadges||[]).filter((item)=>{return item.type===MenuItem.SUPPORT_TICKETS}).pop()?.count || 0 ) >0 && (supportBadges||[]).filter((item)=>{return item.type===MenuItem.SUPPORT_TICKETS}).pop()?.count.toString(),
            stateVariables: isSupport,
            badgeColor: 'primary',
            click: function (e) {
                // e.preventDefault();
                if (!isSupport) {
                    setIsCurrentState(MenuItem.SUPPORT_TICKETS);
                    activateNavBar(false);
                } else {
                    activateNavBar(false);
                }
                setIsSupport(!isSupport);
                updateIconSidebar(e);
            }
        },
        {
            id: MenuItem.SUPPORT_SERVICE_TICKETS,
            label: t('menu:supportService'),
            icon: "ri-customer-service-fill",
            link: route.SUPPORT_SERVICE_TICKETS_LIST,
            badgeName: ((supportBadges||[]).filter((item)=>{return item.type===MenuItem.SUPPORT_SERVICE_TICKETS}).pop()?.count || 0) > 0 && (supportBadges||[]).filter((item)=>{return item.type===MenuItem.SUPPORT_SERVICE_TICKETS}).pop()?.count.toString(),
            stateVariables: isSupportService,
            badgeColor: 'primary',
            click: function (e) {
                // e.preventDefault();
                if (!isSupportService) {
                    setIsSupportService(!isSupportService);
                    setIsCurrentState(MenuItem.SUPPORT_SERVICE_TICKETS);
                    activateNavBar(false);
                    updateIconSidebar(e);
                }
            },
        },
    ];

    menuItems.map((item:NavLinkType, key:number)=> {

        if (grantedMenuItems.includes(item.id)){
            return item;
        }

        if (isSupportServiceUser && item.id === MenuItem.SUPPORT_SERVICE_TICKETS){
            return item;
        }

        if (!permissions.includes(item.id)) {
            menuItems.splice(key, 1);
        } else {

            menuItems[key].subItems=(item.subItems || []).filter((subItem: NavLinkType, subItemKey:number)=>{
                if (grantedMenuItems.includes(subItem.id)){
                    return true;
                }
                return permissions.includes(subItem.id);
            });

        }
        return item;
    });

    return menuItems;
};
export default Navdata;