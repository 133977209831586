import React, {useState} from "react";
import {
    Card,
    CardBody,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Button,
    Row,
    UncontrolledDropdown
} from 'reactstrap';


import {ContentPeerUserType} from "../../../redux/Content/PeerUser/types";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import ChannelItem from "../PeerChannel/channelItem";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {convertToMomentLocale, getHandlerIconByCode, showDateRelative} from "../../../helpers/functions";
import {format} from "date-fns";

interface ItemProps {
    item: ContentPeerUserType;
    channel?: ContentPeerChannelType;
    onEdit?: (event:any) => void;
    onRemove?: (event:any) => void;
    onUnsubscribe?: (event:any) => void;
}
const ListItem = ({ item, onEdit, onRemove, onUnsubscribe, channel }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);

    const showActions = (channel && channel.isManaged && channel.connector && channel.connector.handler && channel.connector.handler.canUnSubscribeMember);

    return (
        <Col>
            <Card className="team-box">
                {/*<div className="team-cover">*/}
                {/*    <div className="teamCoverBg"/>*/}
                {/*    /!*<img src={item.backgroundImg} alt="" className="img-fluid" />*!/*/}
                {/*</div>*/}
                <CardBody className="p-4">
                    <Row className="align-items-center team-row">
                        <Col className="team-settings">
                            <Row>
                                <Col className="text-nowrap">
                                    {item.activeTo &&
                                        <p className="text-muted mb-0">{t('common:until')}:<br/> {
                                            format(new Date(item.activeTo*1000),'E, dd MMM yyyy', {locale: convertToMomentLocale(i18n.language)})
                                        }</p>
                                    }

                                </Col>
                                {showActions &&
                                <UncontrolledDropdown direction='start' className="col text-end">
                                    <DropdownToggle tag="a" id="dropdownMenuLink2" role="button" className="text-muted">
                                        <i className="ri-more-fill fs-17"/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => onUnsubscribe(item)}><i className="ri-eye-line me-2 align-middle"/>{t('common:unsubscribe')}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                }
                            </Row>
                        </Col>
                        <Col lg={4} className="col">
                            <div className="team-profile-img">

                                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0 position-relative">
                                    {item.avatar != null ?
                                        <img src={item.avatar} alt="" className="img-fluid d-block rounded-circle" />

                                        :
                                        <div className={"avatar-title rounded-circle"}>
                                            {item.name[0]}
                                        </div>}
                                    { item.handler && item.handler.code &&
                                    <div className="avatar-xs position-absolute handlerIconTopLeft">
                                        <img src={getHandlerIconByCode(item.handler.code)} className="img-fluid p-1 rounded-circle"
                                        alt=""/>
                                    </div>
                                    }
                                </div>
                                <div className="team-content">
                                    <h5 className="fs-16 mb-2">{item.name}</h5>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="col">
                            <Row className="text-muted text-center">
                                <Col xs={6} className="border-end border-end-dashed d-flex flex-column align-items-center">
                                    {item.channels ? item.channels.map((channel: ContentPeerChannelType, key: number) => (
                                        <ChannelItem item={channel} key={'userChannel_' + channel.uuid}/>
                                    )):''}
                                    <p className="text-muted mb-0">{t('channel:channels')}</p>
                                </Col>
                                <Col xs={6}>
                                    { item.dateTime &&
                                    <p className="text-muted mb-0">
                                        {showDateRelative(new Date(item.dateTime),convertToMomentLocale(i18n.language))}
                                    </p>
                                    }
                                    {/*<h5 className="mb-1">{item.taskCount}</h5>*/}
                                    <p className="text-muted mb-0">{t('common:lastUpdate')}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2} className="col">
                            <div className="text-end">
                                <Button className="btn btn-light view-btn">{t('common:viewProfile')}</Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    );
};

export default ListItem;
