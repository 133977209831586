import React from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {SupportTicketMessageType, TicketMessageStatus} from "../../../redux/Support/TicketMessage/types";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import {MainFileType} from "../../../redux/Main/File/types";
import FileItem from "../Ticket/fileItem";
import RewardFormSimple from "../../Main/Reward/RewardFormSimple";
import {onSendSupportTicketMessageReward} from "../../../redux/Support/TicketMessage/actions";
import {convertToMomentLocale, showDateRelative} from "../../../helpers/functions";

interface ItemProps {
    item: SupportTicketMessageType;
    onRemove: (event:any) => void;
    isReward: boolean;
    isSupportService: boolean;
}
const ListItem = ({ item, onRemove, isReward, isSupportService }: ItemProps) => {

    const { t,i18n } = useTranslation(['common']);
    const logoSm = require("../../../resources/images/mcLogoCircle.svg");

    const showActions = (item.actions &&
        (
            item.actions.canEdit
            ||
            item.actions.canRemove
        )) ? true : false;

    const handlerTicketMessageScoreSend = (event:any, dispatch:any) =>{
        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(onSendSupportTicketMessageReward(event));
        }
    }

    return (
        <div className={"d-flex mb-4 p-2 pt-3 ps-3 rounded"+(item.isSupportAnswer ? ' bg-primary bg-opacity-10': '')}>
            <div className="flex-shrink-0">
                {item?.owner && item.owner.avatar ?
                    <img src={item.owner.avatar} alt="" className="avatar-xs rounded-circle" />
                    :
                    <span className="fs-23 text-muted">
                        {item.owner.name ?
                            item.owner.name[0]
                            :
                            '#'
                        }
                    </span>
                }
            </div>
            <div className="flex-grow-1 ms-3">
                <h5 className="fs-14 d-inline-flex">
                    <div className="d-flex align-items-center">
                    {item?.owner && item.owner.name ?
                        item.owner.name
                        :
                        item.owner.email
                    }
                        <div className="vr ms-2"></div>
                    {item.isSupportAnswer &&
                        <div className="d-inline-flex align-items-center">
                            <div className="avatar-xxs xxxs ms-2 me-2 d-flex flex-shrink-0">
                                <img src={logoSm.default} alt="Marketcells"/>
                            </div>
                            <div className="vr me-2"></div>
                            <span className="text-muted d-flex align-items-center">
                                Support Team
                            </span>
                            <div className="vr ms-2"></div>
                        </div>
                    }


                    <small className="text-muted ms-2">
                        {showDateRelative(new Date(item.dateTime),convertToMomentLocale(i18n.language))
                        }</small>
                    {isSupportService && !item.isSupportAnswer && item.status === TicketMessageStatus.NEW.status &&
                        <span className="badge rounded-pill bg-info ms-2">{t('support:newTicketMessageStatus')}</span>
                    }
                    {!isSupportService && item.isSupportAnswer && item.status === TicketMessageStatus.NEW.status &&
                        <span className="badge rounded-pill bg-info ms-2">{t('support:newTicketMessageStatus')}</span>
                    }
                    </div>
                </h5>
                    <p className="text-muted" style={{whiteSpace: "pre-wrap"}}>{item.text}</p>

                    {isReward &&
                        <RewardFormSimple
                            rewardItem={item}
                            onSend={handlerTicketMessageScoreSend}
                            initScore={item.score}
                        />
                    }

                    { item.media && item.media.length>0 &&
                                <Row>
                                    {(item.media || []).map((item:MainFileType, key:number)=>(
                                        <Col xxl={4} sm={4} key={'fileItem_'+key}>
                                            <FileItem file={item} />
                                        </Col>
                                    ))}
                                </Row>
                    }
                {/*<Link to="#" className="badge text-muted bg-light"><i className="mdi mdi-reply"></i> Reply</Link>*/}
            </div>
            {
                showActions &&
                <div className="flex-shrink-0">
                    <div className="d-flex align-items-center gap-1">
                        <UncontrolledDropdown>
                            <DropdownToggle type="button" className="btn btn-icon btn-sm fs-16 text-muted dropdown" tag="button">
                                <i className="ri-more-fill"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <li><DropdownItem onClick={()=>{onRemove(item)}}>
                                    <i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"/>{t('common:delete')}</DropdownItem></li>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            }
        </div>
    );
};

export default ListItem;
