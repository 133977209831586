import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    Card,
    CardBody,
    Col,
    Row,
    CardHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";

import {useRedux} from "../../../hooks";
import {
    cancelSaleOrderModalHide,
    cancelSaleOrderModalShow, getOrderComments,
    getSaleOrderDetail, hideOrderCommentRemoveModal,
    onCancelSaleOrder,
    onRemoveSaleOrder, removeOrderComment,
    removeSaleOrderModalHide,
    removeSaleOrderModalShow, sendOrderComment,
    setSaleOrderNeedReload, showOrderCommentRemoveModal
} from "../../../redux/Sale/Order/actions";
import {OrderType} from "../../../redux/Sale/Order/types";
import {newOrderItem, OrderItemType} from "../../../redux/Sale/OrderItem/types";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../../components/Loader";
import OrderItem from "../../../components/Sale/OrderItem";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {PaymentType} from "../../../redux/Sale/Payment/types";
import PaymentItem from "../../../components/Payment/PaymentItem";
import {DeliveryType} from "../../../redux/Sale/Delivery/types";
import DeliveryItem from "../../../components/Delivery/DeliveryItem";
import CancelOrderModal from "./CancelOrderModal";
import ChannelItemBigLine from "../../Content/PeerChannel/channelItemBigLine";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {convertToMomentLocale, formatNum, isNumeric} from "../../../helpers/functions";
import UpgradeFarePage from "../../../components/UpgradeFarePage";
import {ProductParamType} from "../../../redux/Sale/ProductParam/types";
import {getSaleProductParams} from "../../../redux/Sale/ProductParam/actions";
import {Icon} from "ts-react-feather-icons";
import {getSaleDeliverySystems} from "../../../redux/Sale/DeliverySystem/actions";
import {getSalePaymentSystems} from "../../../redux/Sale/PaymentSystem/actions";
import {DeliverySystemType} from "../../../redux/Sale/DeliverySystem/types";
import {PaymentSystemType} from "../../../redux/Sale/PaymentSystem/types";
import ModalDeliveryForm from "./modalDeliveryForm";
import {
    clearSaleOrderDeliveryItemNeedReload, editDataSaleOrderDeliveryItem,
    onRemoveSaleOrderDeliveryItem, removeSaleOrderDeliveryItemModalHide
} from "../../../redux/Sale/Delivery/actions";
import RemoveModal, {default as RemoveDeliveryItemModal} from "../../../components/RemoveForm";
import {default as RemovePaymentModal} from "../../../components/RemoveForm";
import {getSaleCurrencies} from "../../../redux/Sale/Currency/actions";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {NewSaleOrderDeliveryItemItem} from "../../../redux/Sale/Delivery/reducer";
import {NewSaleOrderPaymentItem} from "../../../redux/Sale/Payment/reducer";
import {
    clearSaleOrderPaymentNeedReload,
    editDataSaleOrderPayment,
    onRemoveSaleOrderPayment,
    removeSaleOrderPaymentModalHide
} from "../../../redux/Sale/Payment/actions";
import ModalPaymentForm from "./modalPaymentForm";
import {OrderCustomerType} from "../../../redux/Sale/OrderCustomer/types";
import {NewSaleOrderCustomerItem} from "../../../redux/Sale/OrderCustomer/reducer";
import {clearSaleOrderCustomerNeedReload, editDataSaleOrderCustomer} from "../../../redux/Sale/OrderCustomer/actions";
import OrderCustomerItem from "../../../components/Customer/OrderCustomerItem";
import ModalOrderCustomerForm from "./modalOrderCustomerForm";
import OrderSummary from "../../../components/Order/OrderSummary";
import {getSaleOrderStatuses} from "../../../redux/Sale/OrderStatus/actions";
import {SaleOrderStatusType} from "../../../redux/Sale/OrderStatus/types";
import OrderItemEdit from "../../../components/Order/OrderItemEdit";
import {PriceTypeType} from "../../../redux/Sale/PriceType/types";
import {getSalePriceTypes} from "../../../redux/Sale/PriceType/actions";
import {MainRoleType} from "../../../redux/Main/Role/types";
import {CabinetRoles} from "../../../redux/Main/Login/types";
import {newOrder} from "../../../redux/Sale/Order/reducer";
import * as route from "../../../api/routes";
import {CommentType} from "../../../redux/Main/Comment/types";
import classnames from "classnames";
import CommentForm from "../../Main/Comment/CommentForm";
import {NewCommentItem} from "../../../redux/Main/Comment/reducer";
import ListItem from "../../Main/Comment/ListItem";
import {default as RemoveCommentModal} from "../../../components/RemoveForm";
import BackButton from "../../../components/BackButton";
import {format} from "date-fns";

const SaleOrderDetailIndex = () => {

    const { t, i18n } = useTranslation(['common']);
    const navigate = useNavigate();

    const commentTab = 'commentTab';
    const historyTab = 'historyTab';

    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [productParams, setProductParams] = useState<ProductParamType[]>([]);
    const [deliverySystems, setDeliverySystems] = useState<DeliverySystemType[]>([]);
    const [paymentSystems, setPaymentSystems] = useState<PaymentSystemType[]>([]);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
    const [isFetched, setFetched] = useState<boolean>(false);
    const [deliverySum, setDeliverySum] = useState<number>(0);
    const [cartSum, setCartSum] = useState<number>(0);
    const [orderCustomer, setOrderCustomer] = useState<OrderCustomerType>(NewSaleOrderCustomerItem);
    const [orderStatusList, setOrderStatusList] = useState<SaleOrderStatusType[]>([]);
    const [isOrderItemsEdit, setOrderItemsEdit] = useState<boolean>(false);
    const [priceTypes, setPriceTypes] = useState<PriceTypeType[]>([]);
    const [isCanOrderEdit, setCanOrderEdit] = useState<boolean>(false);
    const backButton = useRef(null);
    const [activeTab, setActiveTab] = useState<string>(commentTab);
    const [comments, setComments] = useState<CommentType[]>([]);
    const [editComment, setEditComment] = useState<boolean>(false);

    const { uuid } = useParams();

    const { dispatch, useAppSelector } = useRedux();

    const {
        isOrderFetched,
        saleOrder,
        mainCabinet,
        salePlan,
        saleProductParam,
        saleDeliverySystem,
        salePaymentSystem,
        saleOrderDeliveryItem,
        saleOrderPayment,
        saleOrderCustomer,
        saleOrderStatus,
        salePriceType,
        saleCurrency,
        mainUser,
        isLoadingComments,
        isSendingComment
    } = useAppSelector(state => ({
        isOrderFetched: state.SaleOrder.isFetched,
        saleOrder: state.SaleOrder,
        mainCabinet: state.MainCabinet,
        salePlan: state.SalePlan,
        saleProductParam: state.SaleProductParam,
        saleDeliverySystem: state.SaleDeliverySystem,
        salePaymentSystem: state.SalePaymentSystem,
        saleOrderDeliveryItem: state.SaleOrderDeliveryItem,
        saleOrderPayment: state.SaleOrderPayment,
        saleOrderCustomer: state.SaleOrderCustomer,
        saleOrderStatus: state.SaleOrderStatus,
        salePriceType: state.SalePriceType,
        saleCurrency: state.SaleCurrency,
        mainUser: state.MainUser,
        isLoadingComments: state.SaleOrder.isLoadingComments,
        isSendingComment: state.SaleOrder.isSendingComment,
    }));

    const [order, setOrder] = useState<OrderType>(null);

    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        dispatch(getSaleProductParams({maxResults: 1000}));
        dispatch(getSaleDeliverySystems({maxResults: 1000}));
        dispatch(getSalePaymentSystems({maxResults: 1000}));
        dispatch(getSaleCurrencies({maxResults: 1000}));
        dispatch(getSaleOrderStatuses({maxResults: 1000}));
        dispatch(getSalePriceTypes({maxResults: 1000}));
    }, [dispatch]);

    useEffect(() => {

        if (!uuid.match(/new_/)){
            dispatch(getSaleOrderDetail({uuid: uuid}));
        }

    }, [dispatch, uuid]);

    useEffect(() => {
        if (order?.uuid === uuid && uuid !== null && saleOrder.isNeedReloadComments) {
            dispatch(getOrderComments(order));
        }
    }, [dispatch, uuid, order, saleOrder?.isNeedReloadComments]);

    useEffect(() => {
        if (saleOrder?.isSendingComment === false && editComment) {
            setEditComment(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, saleOrder?.isSendingComment]);

    useEffect(() => {

        if ((saleOrder?.current?.comments !== undefined) && (saleOrder?.current?.comments !== null)){
            setComments([...saleOrder?.current?.comments]);
        } else {
            setComments([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleOrder?.current?.comments, dispatch])

    useEffect(() => {

        let _isCanOrderEdit = false;

        if (mainUser?.currentUser?.roles){
            (mainUser?.currentUser?.roles || []).map((role:MainRoleType)=>{

                switch (role.code){
                    case CabinetRoles.ROLE_SALES_MANAGER:
                    case CabinetRoles.ROLE_ADMIN:
                    case CabinetRoles.ROLE_SALES_ADMIN:
                        _isCanOrderEdit=true;
                        break;
                }

                return role;
            })
        }

        setCanOrderEdit(_isCanOrderEdit);

    }, [dispatch, mainUser?.currentUser]);

    useEffect(() => {
        if (!isCanOrderEdit){
            setOrderItemsEdit(false);
        }
    }, [dispatch, isCanOrderEdit]);

    useEffect(() => {
        if (
            (saleOrderDeliveryItem?.isNeedOrderReload
            &&
            saleOrderDeliveryItem.isNeedOrderReload === true)
        ){
            dispatch(clearSaleOrderDeliveryItemNeedReload());
            dispatch(setSaleOrderNeedReload());
        }
    }, [dispatch, saleOrderDeliveryItem.isNeedOrderReload]);

    useEffect(() => {
        if (
            (saleOrderPayment?.isNeedOrderReload
                &&
                saleOrderPayment.isNeedOrderReload === true)
        ){
            dispatch(clearSaleOrderPaymentNeedReload());
            dispatch(setSaleOrderNeedReload());
        }
    }, [dispatch, saleOrderPayment.isNeedOrderReload]);

    useEffect(() => {
        if (
            (saleOrderCustomer?.isNeedOrderReload
                &&
                saleOrderCustomer.isNeedOrderReload === true)
        ){
            dispatch(clearSaleOrderCustomerNeedReload());
            dispatch(setSaleOrderNeedReload());
        }
    }, [dispatch, saleOrderCustomer.isNeedOrderReload]);

    useEffect(() => {
        if (
            (saleOrder?.isNeedReload
                && saleOrder.isNeedReload === true)
        ){

            if (saleOrder?.current === null) {
                if (backButton?.current){
                    backButton.current.click();
                }
            } else {
                if (saleOrder?.current?.uuid!==undefined && saleOrder.current.uuid!==null && saleOrder.current.uuid !== uuid){
                    navigate(route.SALE_ORDER_DETAIL.replace(/:uuid/,saleOrder.current.uuid),{replace: true});
                } else {
                    dispatch(getSaleOrderDetail({uuid: uuid}));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, saleOrder.isNeedReload]);

    useEffect(() => {
        if (saleProductParam?.items && (saleProductParam?.items || []).length>0){
            setProductParams([...saleProductParam.items]);
        } else {
            setProductParams([]);
        }
    }, [saleProductParam?.items]);

    useEffect(() => {
        if (salePriceType?.items && (salePriceType?.items || []).length>0){
            setPriceTypes([...salePriceType.items]);
        } else {
            setPriceTypes([]);
        }
    }, [salePriceType?.items]);

    useEffect(() => {
        if (saleDeliverySystem?.items && (saleDeliverySystem?.items || []).length>0){
            setDeliverySystems([...saleDeliverySystem.items]);
        } else {
            setDeliverySystems([]);
        }
    }, [saleDeliverySystem?.items]);

    useEffect(() => {
        if (salePaymentSystem?.items && (salePaymentSystem?.items || []).length>0){
            setPaymentSystems([...salePaymentSystem.items]);
        } else {
            setPaymentSystems([]);
        }
    }, [salePaymentSystem?.items]);

    useEffect(() => {
        if ((saleOrder.current !== undefined) && (saleOrder.current !== null) && (saleOrder.current.uuid !== undefined)){
            setOrder({...saleOrder.current});
            setFetched(true);
        } else {
            setOrder(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleOrder?.current])

    useEffect(() => {
        if ((saleOrderStatus?.items || []).length>0){
            setOrderStatusList([...saleOrderStatus.items]);
        } else {
            setOrderStatusList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleOrderStatus?.items]);

    useEffect(() => {
        if ((saleCurrency?.items || []).length>0){
            setCurrencies([...saleCurrency.items]);
        } else {
            setCurrencies([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleCurrency?.items]);

    useEffect(() => {
        if (uuid.match(/new_/)){

            let currencyUuid = uuid.split(/new_/).pop();
            let orderCurrency = (currencies || []).filter((currency:CurrencyType)=>{
                return currency.uuid === currencyUuid;
            }).shift();

            if (orderCurrency?.uuid){

                let createdOrder : OrderType = {
                    ...newOrder,
                    uuid: uuid && uuid,
                    currency:orderCurrency,
                    orderItems: [
                        {
                            ...newOrderItem,
                            currency: orderCurrency
                        }
                    ]
                }

                setOrder(createdOrder);
                setFetched(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencies]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    useEffect(() => {

        let _deliverySum = 0;
        let _cartSum = 0;

        if ((order?.deliveryItems || []).length>0){
            order.deliveryItems.map((deliveryItem:DeliveryType)=>{
                if (deliveryItem?.amount && isNumeric(deliveryItem.amount)){
                    _deliverySum+=deliveryItem.amount;
                }
                return deliveryItem;
            })
        }

        setDeliverySum(_deliverySum);

        if ((order?.orderItems || []).length>0){
            order.orderItems.map((orderItem:OrderItemType)=>{
                if (orderItem?.price && isNumeric(orderItem.price)){
                    _cartSum+=orderItem.price * orderItem.quantity;
                }
                // if (orderItem?.discount && isNumeric(orderItem.discount)){
                //     _cartSum-=orderItem.discount * orderItem.quantity;
                // }
                return orderItem;
            })
        }

        setCartSum(_cartSum);

        let _orderCustomer: OrderCustomerType = NewSaleOrderCustomerItem;

        _orderCustomer = {
            ..._orderCustomer,
            name: order?.customerName && order.customerName,
            phone: order?.customerPhone && order.customerPhone,
            email: order?.customerEmail && order.customerEmail,
            tin: order?.customerTin && order.customerTin,
            customer: order?.customer && {
                ...order.customer
            },
            saleOrder: order?.uuid && {
                uuid: order.uuid
            }
        }

        setOrderCustomer(_orderCustomer);

        if (uuid.match(/new_/)){
            setOrderItemsEdit(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, order]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_PRODUCTS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    const onCancelOrder = (event:OrderType, dispatch:any) => {
        if ((event !== undefined) && (event?.uuid?.length>0)){
            dispatch(onCancelSaleOrder(event));

            setTimeout(() => {
                dispatch(getSaleOrderDetail(event));
            }, 1000);
        }
    }

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const onDeliveryItemAdd = () => {

        let newSaleDeliveryItem: DeliveryType = NewSaleOrderDeliveryItemItem;

        newSaleDeliveryItem={
            ...newSaleDeliveryItem,
            currency: {
                ...order.currency
            },
            saleOrder:{
                uuid: order.uuid
            }
        }

        dispatch(editDataSaleOrderDeliveryItem({data: Object(newSaleDeliveryItem), modalEditTitle: 'newDeliveryItem'}))
    }

    const onDeliveryItemRemove= (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(onRemoveSaleOrderDeliveryItem({data:event, modalEditTitle: 'deleteDeliveryItem'}));
        }
    };

    const onDeliveryItemRemoveModalHide = () => {
        dispatch(removeSaleOrderDeliveryItemModalHide());
    }

    const onPaymentAdd = () => {

        let newSalePaymentItem: PaymentType = NewSaleOrderPaymentItem;

        newSalePaymentItem={
            ...newSalePaymentItem,
            currency: {
                ...order.currency
            },
            saleOrder:{
                uuid: order.uuid
            }
        }

        dispatch(editDataSaleOrderPayment({data: Object(newSalePaymentItem), modalEditTitle: 'newPayment'}))
    }

    const onPaymentRemove= (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(onRemoveSaleOrderPayment({data:event, modalEditTitle: 'deletePayment'}));
        }
    };

    const onPaymentRemoveModalHide = () => {
        dispatch(removeSaleOrderPaymentModalHide());
    }

    const onCustomerAdd = () => {

        let newOrderCustomer: OrderCustomerType = NewSaleOrderCustomerItem;

        newOrderCustomer={
            ...newOrderCustomer,
            saleOrder:{
                uuid: order.uuid
            }
        }

        dispatch(editDataSaleOrderCustomer({data: Object(newOrderCustomer), modalEditTitle: 'newCustomer'}))
    }

    const handleOrderItemsEdit = () => {
        setOrderItemsEdit(true)
    }

    const handleOrderItemsEditCancel = () => {
        if (order?.uuid?.match(/new_/)){
            if (backButton?.current){
                backButton.current.click();
            }
        } else {
            setOrderItemsEdit(false);
        }
    }

    const handleOrderRemove = () =>{
        dispatch(removeSaleOrderModalShow({data:Object(order), modalEditTitle:'deleteOrder'}));
    }

    const OrderHideRemoveModal = (dispatch:any) => {
        dispatch(removeSaleOrderModalHide())
    }

    const OrderOnRemove = (event:any, dispatch:any) => {
        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(onRemoveSaleOrder({data:event, modalEditTitle: 'deleteOrder'}));
        }
    }

    const handleOrderCommentForm = () => {
        setEditComment(!editComment);
    }
    const handlerOrderCommentRemoveModal = (item:CommentType) => {
        dispatch(showOrderCommentRemoveModal({data: item, removeCommentModalTitle: t('comment:deleteComment')}));
    }
    const handlerOrderCommentRemoveCancel = (dispatch:any) =>{
        dispatch(hideOrderCommentRemoveModal());
    }

    const handlerOrderCommentRemove = (event:any, dispatch:any) =>{
        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(removeOrderComment({data: event, removeCommentModalTitle: t('comment:deleteComment')}));
        }
    }

    return (
        <>
            {isFetched ?
                 upgradeFare ?
                    <>
                        <div className="d-flex flex-row flex-grow-1">
                            <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('plan:restrictionGoalText')}
                            </div>
                        </div>
                        <UpgradeFarePage/>
                    </>
                    :
                <>
                {order !== null ?
                    <>
                        <BreadCrumb title={(order?.code ? t('sale:order')+' #' + order.code :t('sale:newOrder'))} pageTitle={t('menu:main')}/>
                        <Row>
                            <Col xl={isOrderItemsEdit?12:9}>
                                <Card>
                                    <CardHeader>
                                        <div className="d-flex align-items-center">
                                            <h5 className="card-title flex-grow-0 mb-0">{t('sale:order')} #{order.code}</h5>

                                            <div className="card-title d-flex flex-grow-1 mb-0 ms-2">
                                                {order?.code &&
                                                    <>
                                                        {order.isPaid === true ?
                                                            <span
                                                                className="badge text-uppercase badge-soft-success fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPaid')}</span>
                                                            :
                                                            <span
                                                                className="badge text-uppercase badge-soft-danger fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPending')}</span>
                                                        }
                                                    </>
                                                }
                                            </div>

                                            {order.paymentUrl &&
                                                <div className="flex-shrink-0">
                                                    <a
                                                        href={order.paymentUrl}
                                                        target="_blank"
                                                        className="btn btn-success btn-sm"
                                                        rel="noreferrer"
                                                    >
                                                        <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                                                        {t('sale:invoiceLink')}
                                                    </a>
                                                </div>
                                            }
                                            {!isOrderItemsEdit && isCanOrderEdit &&
                                            <div className="flex-shrink-0">
                                                {order?.actions?.canEdit &&
                                                <button
                                                    onClick={handleOrderItemsEdit}
                                                    type="button"
                                                    title={t('common:edit')}
                                                    className="btn btn-outline-primary btn-icon waves-effect waves-light ms-2">
                                                    <i className="ri-pencil-fill"/>
                                                </button>
                                                }
                                                {order?.actions?.canRemove &&
                                                <button
                                                    onClick={handleOrderRemove}
                                                    type="button"
                                                    title={t('common:delete')}
                                                    className="btn btn-outline-danger btn-icon waves-effect waves-light ms-2">
                                                    <i className="ri-delete-bin-5-line"/>
                                                </button>
                                                }
                                            </div>
                                            }
                                        </div>
                                    </CardHeader>

                                    {isOrderItemsEdit && isCanOrderEdit ?
                                    <CardBody>
                                        <OrderItemEdit
                                            item={order}
                                            isLoading={saleOrder.isLoading}
                                            isStatusEdit={isOrderItemsEdit}
                                            priceTypes={priceTypes || []}
                                            productParams={productParams || []}
                                            handleEditCancel={handleOrderItemsEditCancel}
                                        />
                                    </CardBody>
                                    :
                                    <CardBody>
                                        <div className="table-responsive table-card">
                                            <table className="table table-nowrap align-middle table-borderless mb-0">
                                                <thead className="table-light text-muted">
                                                <tr>
                                                    <th scope="col">{t('sale:productDetails')}</th>
                                                    <th scope="col" className="text-end">{t('sale:itemPrice')}</th>
                                                    <th scope="col" className="text-end">{t('sale:quantity')}</th>
                                                    {/*<th scope="col">Rating</th>*/}
                                                    <th scope="col" className="text-end">
                                                        {t('sale:totalAmount')}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {order.orderItems.map((item: OrderItemType, key) => (
                                                    <OrderItem item={item} productParams={productParams} key={'orderItem_' + key}/>
                                                ))}
                                                <tr className="border-top border-top-dashed">
                                                    <td colSpan={3}></td>
                                                    <td colSpan={2} className="fw-medium p-0">
                                                        <table className="table table-borderless mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <td>{t('sale:subTotal')}:</td>
                                                                <td className="text-end">{order.currency.currencyFormat.replace(/#/,FormatValue(cartSum))}</td>
                                                            </tr>
                                                            {order?.discount && order.discount>0 ?
                                                            <tr>
                                                                <td>
                                                                    {t('sale:discount')}:
                                                                    {/*<span className="text-muted">(VELZON15)</span>{" "}*/}
                                                                </td>
                                                                <td className="text-end">{order.currency.currencyFormat.replace(/#/,FormatValue(order.discount * -1))}</td>
                                                            </tr>
                                                                :''
                                                            }
                                                            {deliverySum && deliverySum>0 ?
                                                                <tr>
                                                                    <td>
                                                                        {t('delivery:deliveryTotal')}:
                                                                        {/*<span className="text-muted">(VELZON15)</span>{" "}*/}
                                                                    </td>
                                                                    <td className="text-end">{order.currency.currencyFormat.replace(/#/,FormatValue(deliverySum))}</td>
                                                                </tr>
                                                                :''
                                                            }
                                                            {/*<tr>*/}
                                                            {/*    <td>Shipping Charge :</td>*/}
                                                            {/*    <td className="text-end">$65.00</td>*/}
                                                            {/*</tr>*/}
                                                            {/*<tr>*/}
                                                            {/*    <td>Estimated Tax :</td>*/}
                                                            {/*    <td className="text-end">$44.99</td>*/}
                                                            {/*</tr>*/}
                                                            <tr className="border-top border-top-dashed">
                                                                <th scope="row">{t('sale:total')} ({order.currency.code}):
                                                                </th>
                                                                <th className="text-end">{order.currency.currencyFormat.replace(/#/,FormatValue(order.sum))}</th>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                    }
                                </Card>

                                {!isOrderItemsEdit &&
                                    <>
                                        <Card>
                                            <CardHeader>
                                                <div>
                                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                                        <NavItem>
                                                            <NavLink
                                                                href="#"
                                                                className={classnames({ active: activeTab === commentTab })}
                                                                onClick={() => { toggleTab(commentTab); }}
                                                            >
                                                                {t('comment:comments')} {order?.commentsMeta?.totalCount !== null && <>({formatNum(order.commentsMeta.totalCount,0)})</>}
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                href="#"
                                                                className={classnames({ active: activeTab === historyTab })}
                                                                onClick={() => { toggleTab(historyTab); }}
                                                            >
                                                                {t('sale:orderHistory')}
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </div>
                                            </CardHeader>


                                        <CardBody>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={commentTab}>
                                                    <div className="d-flex justify-content-end flex-row">
                                                        {/*<h4 className="card-title mb-0 flex-grow-1">{t('comment:!#comments')}</h4>*/}
                                                        <div className="flex-shrink-0">
                                                            <button type="button"
                                                                    onClick={handleOrderCommentForm}
                                                                    className="btn btn-primary btn-label waves-effect waves-light"><i
                                                                className="ri-edit-box-fill label-icon align-middle fs-16 me-2"/>
                                                                {t('common:Add')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {(editComment || (comments || []).length>0 || (isLoadingComments && ((comments || []).length<1))) &&
                                                        <>
                                                            {editComment &&
                                                                <div className={"mb-4"}>
                                                                    <CommentForm
                                                                        isSending={isSendingComment}
                                                                        item={{...NewCommentItem, saleOrder: order}}
                                                                        onSend={sendOrderComment}
                                                                        onClose={handleOrderCommentForm}
                                                                    />
                                                                </div>
                                                            }
                                                            {isLoadingComments && ((comments || []).length<1) &&
                                                                <Loader/>
                                                            }
                                                            {(comments || []).map((item:CommentType)=>(
                                                                <ListItem
                                                                    item={item}
                                                                    isReward={false}
                                                                    onEdit={sendOrderComment}
                                                                    onRemove={handlerOrderCommentRemoveModal}
                                                                    isSending={isSendingComment}
                                                                    selectedComment={saleOrder.selectedComment}
                                                                    key={'orderComment_'+item.uuid}/>
                                                            ))
                                                            }
                                                        </>
                                                    }
                                                </TabPane>
                                                <TabPane tabId={historyTab}>
                                                    <div className="profile-timeline">
                                                        <div
                                                            className="accordion accordion-flush">


                                                            <div className="accordion-item border-0">
                                                                <div className="accordion-header" id="heading1">
                                                                    <div className=" accordion-button p-2 shadow-none">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 avatar-xs">
                                                                                <div
                                                                                    className="avatar-title bg-success rounded-circle">
                                                                                    <i className="ri-shopping-bag-line fs-20"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h6 className="fs-15 mb-0 fw-semibold">
                                                                                    {t('sale:orderPlaced')}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-body ms-2 ps-5 pt-0" id="heading1">
                                                                    <p className="text-muted mb-0">
                                                                        {
                                                                            format(new Date(order.createdAt),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {order?.status?.isFailure ?

                                                                <div className="accordion-item border-0">
                                                                    <div className="accordion-header" id="heading2">
                                                                        <div className=" accordion-button p-2 shadow-none">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 avatar-xs">

                                                                                    <div
                                                                                        className="avatar-title bg-danger rounded-circle">
                                                                                        <i className="ri-close-circle-line fs-20"/>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h6 className="fs-15 mb-0 fw-semibold">
                                                                                        {t('sale:orderCanceled')}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-body ms-2 ps-5 pt-0" id="heading1">
                                                                        <p className="text-muted mb-0">
                                                                            {
                                                                                order?.payments && ((order?.payments || []).length>0) && order?.payments[0]?.updatedAt &&
                                                                                format(new Date(order.payments[0].updatedAt),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                :
                                                                <>

                                                                    <div className="accordion-item border-0">
                                                                        <div className="accordion-header" id="heading2">
                                                                            <div className=" accordion-button p-2 shadow-none">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                                        {order.isPaid === true ?
                                                                                            <div
                                                                                                className="avatar-title bg-success rounded-circle">
                                                                                                <i className="ri-hand-heart-line fs-20"/>
                                                                                            </div>
                                                                                            :
                                                                                            <div
                                                                                                className="avatar-title bg-light text-success rounded-circle">
                                                                                                <i className="ri-hand-heart-line fs-20"/>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="fs-15 mb-0 fw-semibold">
                                                                                            {t('sale:orderPaid')}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="accordion-body ms-2 ps-5 pt-0"
                                                                             id="heading1">
                                                                            <p className="text-muted mb-0">
                                                                                {order.isPaid === true ?
                                                                                    <>
                                                                                        { order?.payments && ((order?.payments || []).length>0) && order?.payments[0]?.updatedAt &&
                                                                                            format(new Date(order.payments[0].updatedAt),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    t('sale:orderNotPaidYet')
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>

                                        </Card>






                                        </>
                                }
                            </Col>

                            {!isOrderItemsEdit &&
                            <Col xl={3}>
                                <Card>
                                    <CardHeader>
                                        <h5 className="card-title mb-0">
                                            <i className="ri-shopping-cart-2-line align-bottom me-1 text-muted font-weight-normal"/>{" "}
                                            {t('sale:orderSummary')}
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                       <OrderSummary
                                           item={order}
                                           orderStatusList={orderStatusList||[]}
                                           isLoading={saleOrder.isLoading}
                                           isCanOrderEdit={isCanOrderEdit}
                                       />
                                    </CardBody>
                                </Card>

                                {order?.channel && order.channel?.uuid &&
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">
                                                <i className="ri-share-line align-bottom me-1 text-muted font-weight-normal"/>{" "}
                                                {t('channel:channel')}
                                            </h5>
                                        </CardHeader>
                                        <CardBody>
                                            <ChannelItemBigLine item={order.channel}/>
                                        </CardBody>
                                    </Card>
                                }
                                <Card>
                                    <CardHeader>
                                            <div className="d-flex">
                                                <div className="d-flex flex-row flex-grow-1 align-items-center">
                                                    <h5 className="card-title flex-grow-1 mb-0">
                                                        <i className="ri-shield-user-line align-bottom me-1 text-muted font-weight-normal"/>{" "}
                                                        {t('sale:customerDetails')}
                                                    </h5>
                                                </div>
                                                {/*{isCanOrderEdit &&*/}
                                                {/*<div className="flex-shrink-0">*/}
                                                {/*    <div className="d-flex align-items-center">*/}
                                                {/*        <div className="d-flex">*/}
                                                {/*            <UncontrolledDropdown direction='start'>*/}
                                                {/*                <DropdownToggle tag="a" className="btn btn-sm btn-link text-muted text-decoration-none fs-15">*/}
                                                {/*                    <Icon name="more-horizontal" />*/}
                                                {/*                </DropdownToggle>*/}
                                                {/*                <DropdownMenu className="dropdown-menu-end">*/}
                                                {/*                    <DropdownItem className='edit-item-btn' onClick={() => onCustomerAdd()}><i*/}
                                                {/*                        className="ri-pencil-fill align-bottom text-muted me-2"/> {t('common:addNew')}*/}
                                                {/*                    </DropdownItem>*/}
                                                {/*                </DropdownMenu>*/}
                                                {/*            </UncontrolledDropdown>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*}*/}
                                            </div>
                                    </CardHeader>
                                    <CardBody>
                                        <OrderCustomerItem
                                            item={orderCustomer}
                                            number={1}
                                            isCanOrderEdit={isCanOrderEdit}
                                        />
                                    </CardBody>
                                </Card>

                                {((order?.country && order.country.length>0) || (order?.city && order.city.length>0)) &&
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">
                                                <i className="ri-road-map-line align-bottom me-1 text-muted font-weight-normal"/>{" "}
                                                {t('common:geoData')}
                                            </h5>
                                        </CardHeader>
                                        <CardBody>
                                            {(order?.country && order.country.length>0) &&
                                                <div className="d-flex align-items-center mb-2">
                                                    <div className="flex-shrink-0">
                                                        <p className="text-muted mb-0">{t('common:country')}:</p>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h6 className="mb-0">{order.country}</h6>
                                                    </div>
                                                </div>
                                            }
                                            {(order?.city && order.city.length>0) &&
                                                <div className="d-flex align-items-center mb-2">
                                                    <div className="flex-shrink-0">
                                                        <p className="text-muted mb-0">{t('common:city')}:</p>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h6 className="mb-0">{order.city}</h6>
                                                    </div>
                                                </div>
                                            }
                                        </CardBody>
                                    </Card>
                                }


                                <Card>
                                    <CardHeader>
                                        <div className="d-flex">
                                            <div className="d-flex flex-row flex-grow-1 align-items-center">
                                                <h5 className="card-title mb-0">
                                                    <i className="ri-secure-payment-line align-bottom me-1 text-muted font-weight-normal"/>{" "}
                                                    {t('sale:paymentDetails')}
                                                </h5>
                                            </div>
                                            {isCanOrderEdit &&
                                            <div className="flex-shrink-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <UncontrolledDropdown direction='start'>
                                                            <DropdownToggle tag="a" className="btn btn-sm btn-link text-muted text-decoration-none fs-15">
                                                                <Icon name="more-horizontal" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-end">
                                                                <DropdownItem className='edit-item-btn' onClick={() => onPaymentAdd()}><i
                                                                    className="ri-pencil-fill align-bottom text-muted me-2"/> {t('common:addNew')}
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </CardHeader>
                                    {order?.payments && (order.payments || []).length>0 &&
                                    <CardBody>
                                        {(order.payments || []).map((paymentItem: PaymentType, paymentItemKey: number) => (
                                            <PaymentItem
                                                item={paymentItem}
                                                number={paymentItemKey+1}
                                                key={'paymentItem_' + paymentItem.uuid}
                                                paymentUrl={order.paymentUrl && order.paymentUrl}
                                                isCanOrderEdit={isCanOrderEdit}
                                            />
                                        ))}
                                    </CardBody>
                                    }
                                </Card>
                                <Card>
                                    <CardHeader className="pt-2 pb-2">
                                        <div className="d-flex">
                                            <div className="d-flex flex-row flex-grow-1 align-items-center">
                                                <h5 className="card-title mb-0">
                                                    <i className="ri-truck-line align-bottom me-1 text-muted font-weight-normal"></i>{" "}
                                                    {t('sale:deliveryDetails')}
                                                </h5>
                                            </div>
                                            {isCanOrderEdit &&
                                            <div className="flex-shrink-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <UncontrolledDropdown direction='start'>
                                                            <DropdownToggle tag="a" className="btn btn-sm btn-link text-muted text-decoration-none fs-15">
                                                                <Icon name="more-horizontal" />
                                                            </DropdownToggle>

                                                            <DropdownMenu className="dropdown-menu-end">
                                                                <DropdownItem className='edit-item-btn' onClick={() => onDeliveryItemAdd()}><i
                                                                    className="ri-pencil-fill align-bottom text-muted me-2"/> {t('common:addNew')}
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </CardHeader>
                                    {order?.deliveryItems && (order.deliveryItems || []).length>0 &&
                                            <CardBody className="pb-0">
                                                {(order.deliveryItems || []).map((deliveryItem: DeliveryType, deliveryItemKey: number) => (
                                                    <DeliveryItem
                                                        item={deliveryItem}
                                                        number={deliveryItemKey+1}
                                                        key={'deliveryItem_' + deliveryItem.uuid}
                                                        isCanOrderEdit={isCanOrderEdit}
                                                    />
                                                ))}
                                            </CardBody>
                                    }
                                </Card>
                                {order?.customerComment && order.customerComment.length>0 &&
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">
                                                <i className="ri-chat-check-line align-bottom me-1 text-muted font-weight-normal"></i>{" "}
                                                {t('customer:customerComment')}
                                            </h5>
                                        </CardHeader>
                                        <CardBody>
                                            {order.customerComment}
                                        </CardBody>
                                    </Card>
                                }


                                {/*<Card>*/}
                                {/*    <CardHeader>*/}
                                {/*        <h5 className="card-title mb-0">*/}
                                {/*            <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}*/}
                                {/*            Billing Address*/}
                                {/*        </h5>*/}
                                {/*    </CardHeader>*/}
                                {/*    <CardBody>*/}
                                {/*        <ul className="list-unstyled vstack gap-2 fs-14 mb-0">*/}
                                {/*            <li className="fw-semibold fs-15">Joseph Parker</li>*/}
                                {/*            <li>+(256) 245451 451</li>*/}
                                {/*            <li>2186 Joyce Street Rocky Mount</li>*/}
                                {/*            <li>New York - 25645</li>*/}
                                {/*            <li>United States</li>*/}
                                {/*        </ul>*/}
                                {/*    </CardBody>*/}
                                {/*</Card>*/}



                            </Col>
                            }
                        </Row>
                        <CancelOrderModal
                            onCancel={(event) => onCancelOrder(event, dispatch)}
                            handleClose={() => dispatch(cancelSaleOrderModalHide())}
                            cancelItem={order}
                            isOpen={saleOrder.cancelModalShow}
                        />
                        <ModalDeliveryForm
                            deliverySystems={deliverySystems}
                            currencies={currencies}
                        />
                        <ModalPaymentForm
                            paymentSystems={paymentSystems}
                            currencies={currencies}
                        />
                        <ModalOrderCustomerForm
                        />
                        <RemoveDeliveryItemModal
                            onRemove={onDeliveryItemRemove}
                            handleClose={onDeliveryItemRemoveModalHide}
                            removeItem={saleOrderDeliveryItem.selectedItem}
                            isOpen={saleOrderDeliveryItem.removeModalShow}
                            modalTitle={saleOrderDeliveryItem.modalEditTitle}
                        />
                        <RemovePaymentModal
                            onRemove={onPaymentRemove}
                            handleClose={onPaymentRemoveModalHide}
                            removeItem={saleOrderPayment.selectedItem}
                            isOpen={saleOrderPayment.removeModalShow}
                            modalTitle={saleOrderPayment.modalEditTitle}
                        />
                        <RemoveModal
                            onRemove={OrderOnRemove}
                            handleClose={OrderHideRemoveModal}
                            removeItem={saleOrder.current}
                            isOpen={saleOrder.removeModalShow}
                            modalTitle={saleOrder.modalEditTitle}/>
                        <RemoveCommentModal
                            onRemove={handlerOrderCommentRemove}
                            handleClose={handlerOrderCommentRemoveCancel}
                            removeItem={saleOrder.selectedComment}
                            isOpen={saleOrder.removeCommentModalShow}
                            modalTitle={saleOrder.removeCommentModalTitle}/>
                    </>
                    :
                    <>
                        <div className="d-flex flex-row flex-grow-1">
                            <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('sale:orderNotFound')}
                            </div>
                        </div>
                    </>
                }
                    <Row>
                        <Col xxl={9}>
                            <div className="pb-4">
                                <BackButton name={t('sale:allOrders')}/>
                            </div>
                        </Col>
                    </Row>
                </>
                :
                <>
                    <BreadCrumb title={t('sale:order')} pageTitle={t('menu:main')} />
                    <Loader/>
                </>
            }
        </>
    );
};

export default SaleOrderDetailIndex;


