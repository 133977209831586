import React, {useState, useEffect} from 'react';
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {ContentPeerTargetType} from "../../redux/Content/PeerTarget/types";
import ChannelItemLine from "../../blocks/Content/PeerChannel/channelItemLine";
import PeerActivityLine from "../../blocks/Content/PeerActivity/PeerActivityLine";
import * as route from "../../api/routes";

import '../../i18/config';
import {useTranslation} from "react-i18next";
import UpgradeFare from "../UpgradeFare";

interface PeerTargetTableProps {
    items: [] | Array<ContentPeerTargetType>;
    isFareRestriction?: boolean
}

const PeerTargetTableSmall = ({items, isFareRestriction}:PeerTargetTableProps) => {

    const { t, i18n } = useTranslation();

    const [isRestriction, setRestriction] = useState<boolean>(isFareRestriction);

    useEffect(()=>{
        setRestriction(isFareRestriction);
    },[isFareRestriction])

    return (
         <Card className="card-height-100">
                    <CardHeader className="d-flex align-items-center">
                        <h4 className="card-title flex-grow-1 mb-0">{t('target:targets')}</h4>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown">
                                <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                                    <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem href={route.CONTENT_PEERTARGETS_LIST}>{t('target:manageTargets')}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive table-card">
                            <table className="table table-nowrap table-centered align-middle">
                                <thead className="bg-light text-muted">
                                <tr>
                                    <th scope="col">{t('target:target')}</th>
                                    <th scope="col">{t('common:progress')}</th>
                                    {/*<th scope="col" style={{ width: "10%" }}>{t('target:targetCloseDateSmall')}</th>*/}
                                </tr>
                                </thead>

                                <tbody>


                                {!isRestriction &&
                                <>
                                {(items || []).length<1 ?
                                    <>
                                        <tr>
                                            <td colSpan={2} className="border-0">
                                                <div className="text-center">
                                                    <span dangerouslySetInnerHTML={{__html: t('target:noTargetSet').replace(/%link%/,route.CONTENT_PEERTARGETS_LIST)}}/>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                    :
                                (items || []).map((item, key) => (<tr key={key}>
                                    <td className="fw-semibold">
                                        <div>
                                            {item.name}
                                        </div>
                                        <div className="mt-2">
                                            {item.activity ?
                                                <PeerActivityLine item={item.activity}/>
                                                : item.channel &&
                                                <ChannelItemLine item={item.channel}/>
                                            }
                                        </div>
                                        {/*<div className="mt-2">*/}
                                        {/*    <span className="badge badge-soft-primary me-1">{t('targetType:'+item.targetType.code)}</span>*/}
                                        {/*    { item.targetStatus === ContentPeerTargetStatusTypes.SUCCESS &&*/}
                                        {/*    <span className="badge bg-success">{t('targetStatus:success')}</span>*/}
                                        {/*    }*/}
                                        {/*    { item.targetStatus === ContentPeerTargetStatusTypes.FAIL &&*/}
                                        {/*    <span className="badge bg-danger">{t('targetStatus:fail')}</span>*/}
                                        {/*    }*/}
                                        {/*    { item.targetStatus === ContentPeerTargetStatusTypes.PROGRESS &&*/}
                                        {/*    <span className="badge bg-primary">{t('targetStatus:progress')}</span>*/}
                                        {/*    }*/}
                                        {/*</div>*/}
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center flex-grow-1 flex-column">
                                            <div className="flex-shrink-0 me-1 text-muted fs-13">
                                                <span className={"text-" + item.executedClass}>{Math.ceil(item.executedPercent)}%</span> {t('common:rangeSeparator')} 100%
                                            </div>
                                            <Progress
                                                animated
                                                className="animated-progress custom-progress progress-label d-flex flex-grow-1 flex-row w-100"
                                                value={Math.ceil(item.executedPercent)}
                                                color={item.executedClass}
                                            />

                                        </div>
                                    </td>
                                </tr>))}
                                </>
                                }
                                </tbody>
                            </table>
                        </div>

                    </CardBody>
             {isRestriction &&
                 <>
                     <div className="d-flex flex-column justify-content-center p-2 pb-0 flex-grow-1">
                         <UpgradeFare/>
                     </div>
                 </>
             }
                </Card>
    );
};

export default PeerTargetTableSmall;