// types
import {
  DashboardSaleActionTypes,
  DashboardSaleState,
} from "./types";
import {add} from "date-fns";
import {UTCDate} from "@date-fns/utc";

export const INIT_STATE_DASHBOARD_SALE: DashboardSaleState = {
  tiles: [],
  countriesTiles:[],
  currenciesTiles:[],
  statusesTiles: [],
  bestProductsTiles:[],
  bestProducts:[],
  bestChannelsTiles:[],
  bestChannels:[],
  bestCustomersTiles:[],
  bestCustomers:[],
  recentOrders:[],
  last12monthTiles:[],
  weekDaysTiles:[],
  periodFrom: add(new UTCDate(), {days: -7}).getTime()/1000,
  periodTo: new UTCDate().getTime()/1000,
  isFetched: false,
  isFetchedOrdersTiles: false,
  isFetchedCountriesTiles: false,
  isFetchedCurrenciesTiles: false,
  isFetchedStatusesTiles: false,
  isFetchedBestProducts: false,
  isFetchedBestChannels: false,
  isFetchedBestCustomers: false,
  isFetchedRecentOrders: false,
  isFetchedLast12monthTiles: false,
  isFetchedWeekDaysTiles:false,
  isLoading: false
};

const DashboardSale = (state = INIT_STATE_DASHBOARD_SALE, action: any) => {
  switch (action.type) {
    case DashboardSaleActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case DashboardSaleActionTypes.GET_STAT_ORDERS_TILES:
          return {
            ...state,
            tiles: [...action.payload.data.items],
            isFetchedOrdersTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_COUNTRIES_TILES:
          return {
            ...state,
            countriesTiles: [...action.payload.data.items],
            isFetchedCountriesTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_CURRENCIES_TILES:
          return {
            ...state,
            currenciesTiles: [...action.payload.data.items],
            isFetchedCurrenciesTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_STATUSES_TILES:
          return {
            ...state,
            statusesTiles: [...action.payload.data.items],
            isFetchedStatusesTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_PRODUCTS_TILES:
          return {
            ...state,
            bestProductsTiles: [...action.payload.data.items],
            bestProducts: [...action.payload.data.entities],
            isFetchedBestProducts: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CHANNELS_TILES:
          return {
            ...state,
            bestChannelsTiles: [...action.payload.data.items],
            bestChannels: [...action.payload.data.entities],
            isFetchedBestChannels: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CUSTOMERS_TILES:
          return {
            ...state,
            bestCustomersTiles: [...action.payload.data.items],
            bestCustomers: [...action.payload.data.entities],
            isFetchedBestCustomers: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_12MONTH_TILES:
          return {
            ...state,
            last12monthTiles: [...action.payload.data.items],
            isFetchedLast12monthTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_WEEKDAYS_TILES:
          return {
            ...state,
            weekDaysTiles: [...action.payload.data.items],
            isFetchedWeekDaysTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_RECENT_ORDERS:
          return {
            ...state,
            recentOrders: [...action.payload.data.items],
            isFetchedRecentOrders: true,
            isLoading: false
          };
        default:
          return { ...state };
      }

    case DashboardSaleActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case DashboardSaleActionTypes.GET_STAT_ORDERS_TILES:
          return {
            ...state,
            isFetchedOrdersTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_COUNTRIES_TILES:
          return {
            ...state,
            isFetchedCountriesTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_CURRENCIES_TILES:
          return {
            ...state,
            isFetchedCurrenciesTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_STATUSES_TILES:
          return {
            ...state,
            isFetchedStatusesTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_PRODUCTS_TILES:
          return {
            ...state,
            isFetchedBestProducts: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CHANNELS_TILES:
          return {
            ...state,
            isFetchedBestChannels: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CUSTOMERS_TILES:
          return {
            ...state,
            isFetchedBestCustomers: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_12MONTH_TILES:
          return {
            ...state,
            isFetchedLast12monthTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_ORDERS_WEEKDAYS_TILES:
          return {
            ...state,
            isFetchedWeekDaysTiles: true,
            isLoading: false
          };
        case DashboardSaleActionTypes.GET_STAT_RECENT_ORDERS:
          return {
            ...state,
            isFetchedRecentOrders: true,
            isLoading: false
          };
        default:
          return { ...state };
      }
    case DashboardSaleActionTypes.GET_STAT_ORDERS_TILES:
      return {
        ...state,
        isFetchedOrdersTiles: false
      };
    case DashboardSaleActionTypes.GET_STAT_ORDERS_COUNTRIES_TILES:
      return {
        ...state,
        isFetchedCountriesTiles: false
      };
    case DashboardSaleActionTypes.GET_STAT_ORDERS_CURRENCIES_TILES:
      return {
        ...state,
        isFetchedCurrenciesTiles: false
      };
    case DashboardSaleActionTypes.GET_STAT_ORDERS_STATUSES_TILES:
      return {
        ...state,
        isFetchedStatusesTiles: false
      };
    case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_PRODUCTS_TILES:
      return {
        ...state,
        isFetchedBestProducts: false
      };
    case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CHANNELS_TILES:
      return {
        ...state,
        isFetchedBestChannels: false
      };
    case DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CUSTOMERS_TILES:
      return {
        ...state,
        isFetchedBestCustomers: false
      };
    case DashboardSaleActionTypes.GET_STAT_ORDERS_12MONTH_TILES:
      return {
        ...state,
        isFetchedLast12monthTiles: false
      };
    case DashboardSaleActionTypes.GET_STAT_ORDERS_WEEKDAYS_TILES:
      return {
        ...state,
        isFetchedWeekDaysTiles: false,
      };
    case DashboardSaleActionTypes.GET_STAT_RECENT_ORDERS:
      return {
        ...state,
        isFetchedRecentOrders: false
      };
    case DashboardSaleActionTypes.SET_PERIOD:
      return {
        ...state,
        periodFrom: action.payload.periodFrom,
        periodTo: action.payload.periodTo,
        selectedDate: action.payload.selectedDate && action.payload.selectedDate
      };
    case DashboardSaleActionTypes.SELECT_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload,
      };
    default:
      return { ...state };
  }
};

export default DashboardSale;
