import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import {useTranslation} from "react-i18next";
import {useRedux} from "../../hooks";
import MessengerIndex from "./index";
import {toggleCollapsedMessenger} from "../../redux/Messenger/Chat/actions";

const MessengerIndexRightBar = () => {

  const [isCollapsed, setCollapsed] = useState<boolean>(true);

  const { t, i18n } = useTranslation(['common']);

  const { dispatch, useAppSelector } = useRedux();

  const {
    messengerChat,
  } = useAppSelector(state => ({
    messengerChat: state.MessengerChat,
  }));

  useEffect(() => {
    setCollapsed(messengerChat.isCollapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, messengerChat.isCollapsed]);


  return (
    <React.Fragment>
      <div className={"messengerSidebar"+(isCollapsed?' collapsed':'')}>
        <MessengerIndex/>
      </div>
      {!isCollapsed &&
      <div className={"messengerSidebarOverlay"+(!isCollapsed?' visible':'')} onClick={()=>{dispatch(toggleCollapsedMessenger())}}></div>
      }

    </React.Fragment>
  );
};

export default MessengerIndexRightBar;
