import React, {ReactNode} from 'react';
import { Card, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import TermsBlock from "./TermsBlock";


interface InputProps {
    title?: string;
    children: ReactNode;
}


const CoverEmpty = ({children, title}:InputProps) => {
    const { t } = useTranslation(['user']);

    if (title && title.length>0) {
        document.title=title;
    } else {
        document.title="Marketcells.io - digital marketing platform";
    }

    return (
        <>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        {children}
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} © Marketcells. Be smart! <i
                                        className="mdi mdi-heart text-danger"/></p>
                                    <TermsBlock/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>

        </>
    );
};

export default CoverEmpty;