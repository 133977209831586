// types
import {SaleCurrencyRateActionTypes, SaleCurrencyRateState, CurrencyRateType} from "./types";
import {format} from "date-fns";

export const NewSaleCurrencyRateItem:CurrencyRateType = {
  enabled: true,
  order: 500,
  dateTime: format(new Date(), 'yyyy-MM-dd')
}

export const INIT_STATE_SALE_CURRENCY_RATE: SaleCurrencyRateState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleCurrencyRateItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const SaleCurrencyRate = (state = INIT_STATE_SALE_CURRENCY_RATE, action: any) => {
  switch (action.type) {
    case SaleCurrencyRateActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleCurrencyRateActionTypes.GET_SALE_CURRENCY_RATES:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SaleCurrencyRateActionTypes.UPDATE_SALE_CURRENCY_RATE:
          return {
            ...state,
            selectedItem: NewSaleCurrencyRateItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE:
          return {
            ...state,
            selectedItem: NewSaleCurrencyRateItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case SaleCurrencyRateActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleCurrencyRateActionTypes.GET_SALE_CURRENCY_RATES:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case SaleCurrencyRateActionTypes.UPDATE_SALE_CURRENCY_RATE:
          return {
            ...state,
            isSending: false
          };
        case SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case SaleCurrencyRateActionTypes.EDIT_SALE_CURRENCY_RATE:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleCurrencyRateActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleCurrencyRateItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleCurrencyRateItem,
        removeModalShow: false
      }
    case SaleCurrencyRateActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleCurrencyRateActionTypes.UPDATE_SALE_CURRENCY_RATE:
      return {
        ...state,
        isSending: true
      };
    case SaleCurrencyRateActionTypes.GET_SALE_CURRENCY_RATES:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default SaleCurrencyRate;
