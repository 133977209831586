import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import {
    hideRightModalSaleOrderDeliveryItem,
    onSendDataSaleOrderDeliveryItem
} from "../../../redux/Sale/Delivery/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {DeliverySystemType} from "../../../redux/Sale/DeliverySystem/types";
import InputSelect from "../../../components/InputSelect";
import {DeliveryStatus, DeliveryType} from "../../../redux/Sale/Delivery/types";
import InputEmojiArea from "../../../components/InputEmojiArea";
import InputDateTimePicker from "../../../components/InputDateTimePicker";
import {SaleOrderDeliveryValidatorSchema} from "./deliveryValidator";
import {isNumeric} from "../../../helpers/functions";
import InputTextAddon from "../../../components/InputTextAddon";
import {CURRENCY_SYMBOL_EXTRACT_REGEX} from "../../../data/settings";
import {UTCDate} from "@date-fns/utc";
interface ItemProps {
    deliverySystems: DeliverySystemType[];
    currencies: CurrencyType[];
}

const ModalDeliveryForm = ({deliverySystems, currencies}:ItemProps) => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [deliveryStatusOptions, setDeliveryStatusOptions] = useState<any>([]);

    const {
        saleOrderDeliveryItemState,
        isSending
    } = useAppSelector(state => ({
        saleOrderDeliveryItemState: state.SaleOrderDeliveryItem,
        isSending: state.SaleOrderDeliveryItem.isSending
    }));

    const uuid = saleOrderDeliveryItemState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<DeliveryType>(saleOrderDeliveryItemState.selectedItem);

    useEffect(() => {

        if (saleOrderDeliveryItemState?.rightModalShow && saleOrderDeliveryItemState.rightModalShow) {
            setCurrentFormValues(saleOrderDeliveryItemState.selectedItem);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, saleOrderDeliveryItemState?.rightModalShow]);

    const setCurrentFormValues = (item: DeliveryType) => {
        let _formValues : any = item;

        if (item?.status && item.status.length>0){
            let statusKey = Object.keys(DeliveryStatus).filter((statusItemKey:string)=>{
                return DeliveryStatus[statusItemKey].status === item.status;
            }).shift();

            if (statusKey !== undefined && statusKey !== null){
                _formValues = {
                    ..._formValues,
                    status: {
                        uuid: DeliveryStatus[statusKey].status,
                        value: t('delivery:deliveryStatus'+DeliveryStatus[statusKey].label)
                    }
                }
            }
        }
        if (item?.shipmentDate !== undefined && item.shipmentDate !== null){
            _formValues = {
                ..._formValues,
                shipmentDate: new Date(item.shipmentDate).getTime()/1000

            }
        }
        if (item?.deliveryDate !== undefined && item.deliveryDate !== null){
            _formValues = {
                ..._formValues,
                deliveryDate: new Date(item.deliveryDate).getTime()/1000
            }
        }

        setFormValues(_formValues);
    }

    useEffect(() => {

        let _deliveryStatusOptions=[];

        Object.keys(DeliveryStatus).map((item:string)=>{
            _deliveryStatusOptions.push({uuid: DeliveryStatus[item].status, value: t('delivery:deliveryStatus'+DeliveryStatus[item].label)});
            return item;
        })

        setDeliveryStatusOptions([..._deliveryStatusOptions]);

    },[dispatch,t]);

    const handleClose = () => dispatch(hideRightModalSaleOrderDeliveryItem());

    const onDataSubmit = (formData:DeliveryType) => {
        
        let params: DeliveryType = {
            uuid: (uuid && uuid !== null) && uuid,
            amount: formData.amount ? formData.amount : 0,
            comment: formData.comment && formData.comment,
            address: formData.address && formData.address,
            weight: formData.weight ? formData.weight : 0,
            idTracking: formData.idTracking && formData.idTracking,
            trackingNumber: formData.trackingNumber && formData.trackingNumber,
            shipmentDate: formData.shipmentDate && isNumeric(formData.shipmentDate) &&
                (new UTCDate(parseInt(formData.shipmentDate)*1000).getTime()/1000).toString(),
            deliveryDate: formData.deliveryDate && isNumeric(formData.deliveryDate) &&
                (new UTCDate(parseInt(formData.deliveryDate)*1000).getTime()/1000).toString()
        };

        if (uuid === null && saleOrderDeliveryItemState?.selectedItem?.saleOrder?.uuid){
            params={...params,
                saleOrder:{
                    uuid: saleOrderDeliveryItemState.selectedItem.saleOrder.uuid
                }
            }
        }

        // if (formData['currency']['value'] !== undefined){
        //     params={...params,
        //         currency:{
        //             uuid: formData['currency']['value']
        //         }
        //     }
        // } else if (formData['currency'] !== undefined) {
        //     params={...params,
        //         currency:formData['currency']
        //     }
        // }

        if (formData['deliverySystem']['value'] !== undefined){
            params={...params,
                deliverySystem:{
                    uuid: formData['deliverySystem']['value']
                }
            }
        } else if (formData['deliverySystem'] !== undefined) {
            params={...params,
                deliverySystem:formData['deliverySystem']
            }
        }

        if (formData['status']['uuid'] !== undefined) {
            params={...params,
                status:formData['status']['uuid']
            }
        } else if (formData['status']['value'] !== undefined){
            params={...params,
                status:formData['status']['value']
            }
        } else if (formData['status'] !== undefined) {
            params={...params,
                status:formData['status']
            }
        }

        dispatch(onSendDataSaleOrderDeliveryItem(params));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleOrderDeliveryItemState.rightModalShow}
                    id="saleOrderDeliveryItemEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleOrderDeliveryValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('delivery:'+saleOrderDeliveryItemState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                                    <Row>
                                        <div className={ "mb-3"+ (touched.status ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-status-input"}>{t('common:status')}</Label>
                                            <Field
                                                id="choices-status-input"
                                                name={"status"}
                                                placeholder={t('common:chooseStatus')}
                                                component={InputSelect}
                                                value={getFieldProps('status').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("status",value);
                                                }}
                                                items={deliveryStatusOptions || []}
                                                className={"flex-grow-1 "+ (errors.status && touched.status ? 'form-control is-invalid' : '')}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.deliverySystem ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-deliverySystem-input"}>{t('delivery:deliveryService')}</Label>
                                            <Field
                                                id="choices-deliverySystem-input"
                                                name={"deliverySystem"}
                                                placeholder={t('delivery:chooseDeliveryService')}
                                                component={InputSelect}
                                                value={getFieldProps('deliverySystem').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("deliverySystem",value);
                                                }}
                                                items={deliverySystems || []}
                                                className={"flex-grow-1 "+ (errors.deliverySystem && touched.deliverySystem ? 'form-control is-invalid' : '')}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.amount ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-title-input">{t('sale:amount')}</Label>
                                            <Field
                                                id="form-amount-input"
                                                name="amount"
                                                placeholder={t('sale:enterAmount')}
                                                component={InputTextAddon}
                                                value = {getFieldProps('amount').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('amount', value);
                                                }}
                                                addon={getFieldProps('currency')?.value?.currencyFormat?.replace(CURRENCY_SYMBOL_EXTRACT_REGEX,'')}
                                                className={errors.amount && touched.amount && 'form-control is-invalid'}
                                            />
                                            {errors.amount && touched.amount ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.amount)}
                                                </div>
                                            ) :  touched.amount && !errors.amount ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    {/*<Row>*/}
                                    {/*    <div className={ "mb-3"+ (touched.currency ? " was-validated" : '')}>*/}
                                    {/*        <Label className="form-label" htmlFor={"choices-currency-input"}>{t('currency:currency')}</Label>*/}
                                    {/*        <Field*/}
                                    {/*            id="choices-currency-input"*/}
                                    {/*            name={"currency"}*/}
                                    {/*            placeholder={t('currency:chooseCurrency')}*/}
                                    {/*            component={InputSelect}*/}
                                    {/*            value={getFieldProps('currency').value}*/}
                                    {/*            onChange={(value: any) => {*/}
                                    {/*                setFieldValue("currency",value);*/}
                                    {/*            }}*/}
                                    {/*            items={currencies || []}*/}
                                    {/*            className={"flex-grow-1 "+ (errors.currency && touched.currency ? 'form-control is-invalid' : '')}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</Row>*/}
                                    <Row>
                                        <div className={ "mb-3"+ (touched.weight ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-weight-input">{t('common:weight')}</Label>
                                            <Field
                                                id="form-weight-input"
                                                name="weight"
                                                placeholder={t('common:enterWeight')}
                                                component={InputText}
                                                value = {getFieldProps('weight').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('weight', value);
                                                }}
                                                className={errors.weight && touched.weight && 'form-control is-invalid'}
                                            />
                                            {errors.weight && touched.weight ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.weight)}
                                                </div>
                                            ) :  touched.weight && !errors.weight ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="form-address-input">{t('common:address')}</Label>
                                            <div className="textAreaEmojiBlock">
                                                <Field
                                                    id="form-address-input"
                                                    name="address"
                                                    component={InputEmojiArea}
                                                    value = {getFieldProps('address').value}
                                                    onChange={(text:string) => {
                                                        setFieldValue('address', text);
                                                    }}
                                                    required={false}
                                                    placeholder={t('common:enterAddress')}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.trackingNumber ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-trackingNumber-input">{t('delivery:trackingNumber')}</Label>
                                            <Field
                                                id="form-trackingNumber-input"
                                                name="trackingNumber"
                                                placeholder={t('delivery:enterTrackingNumber')}
                                                component={InputText}
                                                value = {getFieldProps('trackingNumber').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('trackingNumber', value);
                                                }}
                                                className={errors.trackingNumber && touched.trackingNumber && 'form-control is-invalid'}
                                            />
                                            {errors.trackingNumber && touched.trackingNumber ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.trackingNumber)}
                                                </div>
                                            ) :  touched.trackingNumber && !errors.trackingNumber ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.idTracking ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-idTracking-input">{t('delivery:idTracking')}</Label>
                                            <Field
                                                id="form-idTracking-input"
                                                name="idTracking"
                                                placeholder={t('delivery:enterIdTracking')}
                                                component={InputText}
                                                value = {getFieldProps('idTracking').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('idTracking', value);
                                                }}
                                                className={errors.idTracking && touched.idTracking && 'form-control is-invalid'}
                                            />
                                            {errors.idTracking && touched.idTracking ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.idTracking)}
                                                </div>
                                            ) :  touched.idTracking && !errors.idTracking ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.shipmentDate ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-shipmentDate-input">{t('delivery:shipmentDate')}</Label>
                                            <Field
                                                id="form-shipmentDate-input"
                                                name="shipmentDate"
                                                component={InputDateTimePicker}
                                                value={getFieldProps('shipmentDate').value}
                                                onChange={(value: number) => {
                                                    setFieldValue('shipmentDate', value);
                                                }}
                                                placeholder={t('delivery:chooseShipmentDate')}
                                                className={errors.shipmentDate && touched.shipmentDate && 'form-control is-invalid'}
                                            />
                                            {errors.shipmentDate && touched.shipmentDate ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.shipmentDate)}
                                                </div>
                                            ) :  touched.shipmentDate && !errors.shipmentDate ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.deliveryDate ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-deliveryDate-input">{t('delivery:deliveryDate')}</Label>
                                            <Field
                                                id="form-deliveryDate-input"
                                                name="deliveryDate"
                                                component={InputDateTimePicker}
                                                value={getFieldProps('deliveryDate').value}
                                                onChange={(value: number) => {
                                                    setFieldValue('deliveryDate', value);
                                                }}
                                                placeholder={t('delivery:chooseDeliveryDate')}
                                                className={errors.deliveryDate && touched.deliveryDate && 'form-control is-invalid'}
                                            />
                                            {errors.deliveryDate && touched.deliveryDate ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.deliveryDate)}
                                                </div>
                                            ) :  touched.deliveryDate && !errors.deliveryDate ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="form-comment-input">{t('common:comment')}</Label>
                                            <div className="textAreaEmojiBlock">
                                                <Field
                                                    id="form-comment-input"
                                                    name="comment"
                                                    component={InputEmojiArea}
                                                    value = {getFieldProps('comment').value}
                                                    onChange={(text:string) => {
                                                        setFieldValue('comment', text);
                                                    }}
                                                    required={false}
                                                    placeholder={t('common:enterComment')}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default ModalDeliveryForm;