import React from "react";
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, Progress } from 'reactstrap';
import { Icon } from "ts-react-feather-icons";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
// interface
import {ContentPeerTargetStatusTypes, ContentPeerTargetType} from "../../../redux/Content/PeerTarget/types";
import {EntityActionsHandlersType} from "../../../redux/types";
import ChannelItemBigLine from "../PeerChannel/channelItemBigLine";
import PeerActivityLine from "../PeerActivity/PeerActivityLine";
import {handleCreateNewTaskByTarget} from "./ContentPeerTargetActions";
import {useRedux} from "../../../hooks";
import {convertToMomentLocale} from "../../../helpers/functions";
import {format} from "date-fns";

interface ItemProps {
    item: ContentPeerTargetType;
    actionHandlers:EntityActionsHandlersType;
}

const ListItem = ({ item, actionHandlers }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);
    const { dispatch } = useRedux();

    const showActions = item.actions &&
        (
            item.actions.canEdit
            ||
            item.actions.canRemove
        );

    return (
        <>

                                <Card className="card-height-100 card-animate" >
                                    <CardBody>
                                        <div className="d-flex mb-4 align-items-center">
                                            <h6 className="fs-16 mb-0 flex-grow-1 text-truncate">{item.name}</h6>


                                            <div className="flex-shrink-0">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <span className="badge badge-soft-primary me-1">{t('targetType:'+item.targetType.code)}</span>
                                                        { item.targetStatus === ContentPeerTargetStatusTypes.SUCCESS &&
                                                        <span className="badge bg-success">{t('targetStatus:success')}</span>
                                                        }
                                                        { item.targetStatus === ContentPeerTargetStatusTypes.FAIL &&
                                                        <span className="badge bg-danger">{t('targetStatus:fail')}</span>
                                                        }
                                                        { item.targetStatus === ContentPeerTargetStatusTypes.PROGRESS &&
                                                        <span className="badge bg-primary">{t('targetStatus:progress')}</span>
                                                        }
                                                    </div>
                                                    <div className="d-flex">
                                                    {showActions &&
                                                    <UncontrolledDropdown direction='start'>
                                                        <DropdownToggle tag="a"  className="btn btn-sm btn-link text-muted text-decoration-none fs-15">
                                                            <Icon name="more-horizontal" />
                                                        </DropdownToggle>

                                                        <DropdownMenu>
                                                            {item.actions && item.actions.canEdit &&
                                                            <DropdownItem tag="a" onClick={() => actionHandlers.onEdit(item)}><i
                                                                className="ri-edit-2-line align-bottom me-2 text-muted"/>{t('common:edit')}</DropdownItem>
                                                            }
                                                            <DropdownItem tag="a" onClick={() => handleCreateNewTaskByTarget(item, dispatch)}><i
                                                                className="ri-survey-line align-bottom text-muted me-2"/> {t('task:addNewTask')}</DropdownItem>
                                                            {item.actions && item.actions.canRemove &&
                                                            <DropdownItem tag="a" onClick={() => actionHandlers.onRemove(item)}><i
                                                                className="ri-delete-bin-5-line align-bottom me-2 text-muted"/>{t('common:delete')}</DropdownItem>
                                                            }
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {item.channel &&
                                            <div className="mb-4">
                                                <ChannelItemBigLine item={item.channel}/>
                                            </div>
                                        }
                                        {item.activity &&
                                            <div className="mb-4">
                                                <PeerActivityLine item={item.activity}/>
                                            </div>
                                        }

                                        <div className="d-flex flex-column">
                                            <div className="d-flex mb-3">
                                                <div className="flex-grow-1">
                                                    <h6 className="text-muted mb-0"><span className={"text-" + item.executedClass}>{Math.ceil(item.executedPercent)}%</span> {t('common:rangeSeparator')} 100%</h6>
                                                </div>
                                                {item.targetDate &&
                                                <div className="flex-shrink-0">
                                                    <span className="text-muted">{
                                                        format(new Date(item.targetDate),'E, dd MMM yyyy', {locale: convertToMomentLocale(i18n.language)})
                                                        }</span>
                                                </div>
                                                }
                                            </div>

                                            <div className="mb-1">
                                                <div className="flex-grow-1">
                                                <div>
                                                    <Progress
                                                        animated
                                                        className="animated-progress custom-progress progress-label"
                                                        value={Math.ceil(item.executedPercent)}
                                                        color={item.executedClass}
                                                    ><div className="label">{item.currentValue}</div></Progress>
                                                </div>
                                                </div>
                                            </div>

                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <span className="text-muted">{item.startValue}</span>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <span className="text-muted">{item.targetValue}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>

                                    <div className="card-footer border-top-dashed">

                                        {item.activity ?
                                        <div className="tasks-box">
                                            <p className="text-muted mb-0">{t('peerActivity:activity')}: {item.activity.name}</p>
                                            {item.activity.media &&
                                            <div className="tasks-img rounded mt-2" style={{ background: `url(${item.activity.media[0].url})` }}/>
                                            }
                                        </div>
                                            : item.channel &&
                                            <p className="text-muted mb-0">{t('channel:channel')}: {item.channel.name}</p>
                                        }

                                    </div>
                                </Card>

        </>
    );
};

export default ListItem;
