import React, {useCallback, useEffect, useState} from "react";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../../hooks";
import {OrderStatus, OrderType} from "../../redux/Sale/Order/types";
import {DeliveryType} from "../../redux/Sale/Delivery/types";
import {isNumeric} from "../../helpers/functions";
import {OrderItemType} from "../../redux/Sale/OrderItem/types";
import {PaymentStatus, PaymentType} from "../../redux/Sale/Payment/types";
import {
    Formik,
    Form,
    Field
} from 'formik';
import {SaleOrderStatusItemType, SaleOrderStatusType} from "../../redux/Sale/OrderStatus/types";
import InputSelect from "../InputSelect";
import {SaleOrderStatusValidatorSchema} from "./orderStatusValidator";
import ButtonSpinner from "../ButtonSpinner";
import {onChangeSaleOrderStatus} from "../../redux/Sale/Order/actions";
import InputSwitch from "../InputSwitch";

interface ItemProps {
    item: OrderType;
    orderStatusList: SaleOrderStatusType[],
    isLoading: boolean;
    isCanOrderEdit: boolean;
}
const OrderSummary = ({ item, orderStatusList, isLoading, isCanOrderEdit = false }: ItemProps) => {

    const { t, i18n } = useTranslation(['plan']);
    const { dispatch } = useRedux();

    const [deliverySum, setDeliverySum] = useState<number>(0);
    const [cartSum, setCartSum] = useState<number>(0);
    const [cartDiscountSum, setCartDiscountSum] = useState<number>(0);
    const [paidSum, setPaidSum] = useState<number>(0);
    const [isStatusEdit, setStatusEdit] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<SaleOrderStatusItemType>(null);

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const handleEdit = () => {
        setStatusEdit(true);
    }

    const handleEditCancel = () => {
        setStatusEdit(false);
    }

    useEffect(() => {

        let _deliverySum = 0;
        let _cartSum = 0;
        let _cartDiscountSum = 0;
        let _paidSum = 0;

        if ((item?.deliveryItems || []).length>0){
            item.deliveryItems.map((deliveryItem:DeliveryType)=>{
                if (deliveryItem?.amount && isNumeric(deliveryItem.amount)){
                    _deliverySum+=deliveryItem.amount;
                }
                return deliveryItem;
            })
        }

        setDeliverySum(_deliverySum);

        if ((item?.payments || []).length>0){
            item.payments.map((payment:PaymentType)=>{
                if (payment?.amount && payment.status===PaymentStatus.FINAL.class){
                    _paidSum+=payment.amount;
                }
                return payment;
            })
        }

        setPaidSum(_paidSum);

        if ((item?.orderItems || []).length>0){
            item.orderItems.map((orderItem:OrderItemType)=>{
                if (orderItem?.price && isNumeric(orderItem.price)){
                    _cartSum+=orderItem.price * orderItem.quantity;
                }
                if (orderItem?.discount && isNumeric(orderItem.discount)){
                    _cartDiscountSum+=orderItem.discount * orderItem.quantity;
                }
                return orderItem;
            })
        }

        setCartSum(_cartSum);
        setCartDiscountSum(_cartDiscountSum);

        let saleOrderStatusItem:SaleOrderStatusItemType={
            status: item.status,
            saleOrder: item?.uuid &&{
                uuid: item.uuid
            },
            isPaid: item.isPaid
        }

        setStatusEdit(false);
        setFormValues(saleOrderStatusItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, item]);

    const onDataSubmit = (formData:SaleOrderStatusItemType) =>{

        let params:SaleOrderStatusItemType={
            isPaid: formData.isPaid && formData.isPaid
        };

        if (item?.uuid){
            params={...params,
                saleOrder:{
                    uuid: item.uuid
                }
            }
        }

        if (formData['status']['uuid'] !== undefined) {
            params={...params,
                status:{
                    uuid:formData['status']['uuid']
                }
            }
        } else if (formData['status']['value'] !== undefined){
            params={...params,
                status:{
                    uuid: formData['status']['value']
                }
            }
        } else if (formData['status'] !== undefined) {
            params={...params,
                status:formData['status']
            }
        }

        dispatch(onChangeSaleOrderStatus(params));
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            handleEditCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (isStatusEdit) {
            document.addEventListener("keydown", escFunction, false);

            return () => {
                document.removeEventListener("keydown", escFunction, false);
            };
        }
    }, [escFunction, item, isStatusEdit]);

    return (
        <>
            <div className="orderSummaryCont">
                <div className="d-flex mb-2 border-bottom border-bottom-dashed pb-3 flex-column">
                    {!isStatusEdit ?
                        <>
                            <div className="d-flex flex-grow-1 mb-2 align-items-center">
                                <div className="flex-shrink-0">
                                    <p className="text-muted mb-0">{t('sale:status')}:</p>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    {item?.status?.isSuccess === true &&
                                       <span className={"badge text-uppercase badge-soft-"+OrderStatus.FINAL.class}> {item.status.name}</span>
                                    }
                                    {item?.status?.isNew === true &&
                                        <span className={"badge text-uppercase badge-soft-"+OrderStatus.NEW.class}> {item.status.name}</span>
                                    }
                                    {item?.status?.isFailure === true &&
                                        <span className={"badge text-uppercase badge-soft-"+OrderStatus.CANCELLED.class}> {item.status.name}</span>
                                    }
                                </div>
                                {isCanOrderEdit &&
                                <div className="flex-shrink-0">
                                    <button
                                        onClick={handleEdit}
                                        type="button"
                                        title={t('common:edit')}
                                        className="btn btn-outline-primary btn-icon waves-effect waves-light">
                                        <i className="ri-pencil-fill"/>
                                    </button>
                                </div>
                                }
                            </div>
                            <div className="d-flex flex-grow-1 align-items-center">
                                <div className="flex-shrink-0">
                                    <p className="text-muted mb-0">{t('sale:paymentStatus')}:</p>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    {item.isPaid === true ? <span className="badge text-uppercase badge-soft-success fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPaid')}</span> : <span className="badge text-uppercase badge-soft-danger fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPending')}</span>}
                                </div>
                            </div>
                        </>
                        :<>
                            <Formik
                                enableReinitialize={true}
                                initialValues={formValues}
                                validationSchema={SaleOrderStatusValidatorSchema}
                                onSubmit={(values, actions) => {
                                    onDataSubmit(values);
                                    actions.setSubmitting(false);
                                }}
                            >
                                {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                                    <Form noValidate={true} className="customValidation d-flex flex-grow-1 flex-column">
                                        <div className="d-flex flex-grow-1 mb-2">
                                            <div className="d-flex flex-grow-1 flex-column">
                                                <div className={"d-flex flex-grow-1 me-2 mb-3 mw-100 "+(touched.status ? " was-validated" : '')}>
                                                    <Field
                                                        id="choices-status-input"
                                                        name={"status"}
                                                        placeholder={t('common:chooseStatus')}
                                                        component={InputSelect}
                                                        value={getFieldProps('status').value}
                                                        onChange={(value: any) => {
                                                            setFieldValue("status",value);
                                                        }}
                                                        items={orderStatusList || []}
                                                        className={"flex-grow-1 "+ (errors.status && touched.status ? 'form-control is-invalid' : '')}
                                                    />
                                                </div>
                                                <div className="d-flex flex-grow-1">
                                                    <div className={"d-flex flex-grow-1 me-2"}>
                                                        {t('sale:paymentStatus')}
                                                    </div>
                                                    <div className="flex-shrink-0  me-2">
                                                        <Field
                                                            id="form-isPaid-input"
                                                            placeholder={t('sale:paymentStatusPaid')}
                                                            name="isPaid"
                                                            component={InputSwitch}
                                                            value = {getFieldProps('isPaid').value}
                                                            onChange={(enabled:boolean) => {
                                                                setFieldValue('isPaid', !getFieldProps('isPaid').value);
                                                            }}
                                                            className="ms-1 form-switch-sm"
                                                            required={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <button
                                                    type="submit"
                                                    title={t('common:edit')}
                                                    disabled={isLoading}
                                                    className="btn btn-outline-success btn-icon waves-effect waves-light">
                                                    {isLoading ?
                                                        <ButtonSpinner
                                                            className={"ms-0"}
                                                        />
                                                        :
                                                        <i className="ri-check-double-line"/>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    }
                </div>

                <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0">
                        <p className="text-muted mb-0">{t('sale:subTotal')}:</p>
                    </div>
                    <div className="flex-grow-1 ms-2 text-end">
                        {item.currency.currencyFormat.replace(/#/,FormatValue(cartSum))}
                    </div>
                </div>
                {cartDiscountSum>0 &&
                    <div className="d-flex align-items-center mb-2">
                        <div className="flex-shrink-0">
                            <p className="text-muted mb-0">{t('sale:discount')}:</p>
                        </div>
                        <div className="flex-grow-1 ms-2 text-end">
                            {item.currency.currencyFormat.replace(/#/,FormatValue(cartDiscountSum * -1))}
                        </div>
                    </div>
                }
                {deliverySum>0 &&
                    <div className="d-flex align-items-center mb-2">
                        <div className="flex-shrink-0">
                            <p className="text-muted mb-0">{t('delivery:deliveryTotal')}:</p>
                        </div>
                        <div className="flex-grow-1 ms-2 text-end">
                            {item.currency.currencyFormat.replace(/#/,FormatValue(deliverySum))}
                        </div>
                    </div>
                }
                <div className="d-flex align-items-center mb-2 border-top border-top-dashed pt-2">
                    <div className="flex-shrink-0">
                        <p className="text-muted mb-0">{t('sale:total')}:</p>
                    </div>
                    <div className="flex-grow-1 ms-2 text-end">
                        {item.currency.currencyFormat.replace(/#/,FormatValue(item.sum))}
                    </div>
                </div>
                <div className="d-flex align-items-center mb-2 border-top border-top-dashed pt-2">
                    <div className="flex-shrink-0">
                        <p className="text-muted mb-0">{t('sale:totalPaid')}:</p>
                    </div>
                    <div className="flex-grow-1 ms-2 text-end">
                        {item.currency.currencyFormat.replace(/#/,FormatValue(paidSum))}
                    </div>
                </div>
                { item.sum !== paidSum &&
                    <div className="d-flex align-items-center mb-2">
                        <div className="flex-shrink-0">
                            <p className="text-muted mb-0">{t('sale:balanceDue')}:</p>
                        </div>
                        <div className="flex-grow-1 ms-2 text-end">
                            {item.currency.currencyFormat.replace(/#/,FormatValue(item.sum-paidSum))}
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default OrderSummary;