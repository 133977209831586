import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {PlanOrderItemType, PlanOrderType} from "../../../redux/Sale/PlanOrder/types";
import * as route from "../../../api/routes";
import {Link} from "react-router-dom";
import {OrderStatus} from "../../../redux/Sale/Order/types";
import {LocaleCurrencySymbol} from "../../../redux/Sale/Currency/types";
import {convertToMomentLocale} from "../../../helpers/functions";
import {format} from "date-fns";


const PlanOrderColumns = ({ onDetail }:EntityActionsHandlersType) => {
    const { t, i18n } = useTranslation(['common']);

    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            ignoreRowClick: true,
            selector: (cell:any) => {

                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-light btn-sm" tag="a">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" container="root">
                            <DropdownItem tag="a" className='edit-item-btn' onClick={() => onDetail(cell)}><i
                                className="ri-eye-fill align-bottom me-2 text-muted"/> {t('sale:orderDetails')} </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
        {
            id: 'code',
            name: <span className='font-weight-bold fs-14'>{t('sale:code')}</span>,
            sortable: true,
            maxWidth: '50px',
            selector: (row:PlanOrderType) => {
                return (
                    <>
                        <Link to={route.PLAN_ORDER_DETAIL.replace(/:uuid/g, row.uuid)} className="fw-medium link-primary">{row.code}</Link>
                    </>
                )
            },
        },
        {
            id: 'status',
            name: <span className='font-weight-bold fs-14'>{t('sale:status')}</span>,
            maxWidth: '50px',
            selector: (row:PlanOrderType) => {
                if (row.status.isSuccess === true) {
                    return <span className={"badge text-uppercase badge-soft-"+OrderStatus.FINAL.class}> {row.status.name} </span>
                } else if (row.status.isNew === true) {
                    return <span className={"badge text-uppercase badge-soft-"+OrderStatus.NEW.class}> {row.status.name} </span>
                } else if (row.status.isFailure === true) {
                    return <span className={"badge text-uppercase badge-soft-"+OrderStatus.CANCELLED.class}> {row.status.name} </span>
                }
            },
        },
        // {
        //     name: <span className='font-weight-bold fs-14'>{t('sale:customer')}</span>,
        //     sortable: true,
        //     selector: (row:PlanOrderType) => {
        //         return row.customerName
        //     },
        // },
        {
            name: <span className='font-weight-bold fs-14'>{t('sale:product')}</span>,
            selector: (row:PlanOrderType) => {
                let orderItems='';

                (row.orderItems || []).map((item: PlanOrderItemType, key: number)=>{
                    // orderItems+='#'+(key+1)+' '+t('sale:plan')+': '+item.name;
                    orderItems+='#'+(key+1)+' '+item.name;
                    return item;
                });

                return <span>{orderItems}</span>
            },
        },
        {
            id:'createdAt',
            name: <span className='font-weight-bold fs-14'>{t('sale:createdAt')}</span>,
            maxWidth: '200px',
            sortable: true,
            selector: (row:PlanOrderType) => {
                return format(new Date(row.createdAt),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
            },
        },
        {
            id:'sum',
            name: <span className='font-weight-bold fs-14'>{t('sale:amount')}</span>,
            maxWidth: '100px',
            sortable: true,
            selector: (row:PlanOrderType) => {
                return LocaleCurrencySymbol[row.currency.code] + row.sum;
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('sale:paymentStatus')}</span>,
            maxWidth: '150px',
            selector: (row:PlanOrderType) => {
                let payments='';

                // (row.payments || []).map((item: PaymentType, key: number)=>{
                //     if (row.payments.length>1) {
                //         payments += '#' + (key + 1) + ' ' + item.paymentSystem.code + ' ';
                //     } else {
                //         payments += item.paymentSystem.code + ' ';
                //     }
                //     return item;
                // });

                return <span>{payments}  {row.isPaid === true ? <span className="badge text-uppercase badge-soft-success fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPaid')}</span> : <span className="badge text-uppercase badge-soft-danger fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPending')}</span>}</span>;
            },
        },
    ];

}

export default PlanOrderColumns;