import React from "react";
import {EventNotificationStatus, EventNotificationType} from "../../../redux/Event/Notification/types";
import {EntityActionsHandlersType} from "../../../redux/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {convertToMomentLocale, showDateRelative} from "../../../helpers/functions";

const EventNotificationColumns = ({ onEdit, onRemove }:EntityActionsHandlersType) => {

    const { t, i18n } = useTranslation(['common']);

    return [
        // {
        //     name: <span className='font-weight-bold fs-14'>ID</span>,
        //     selector: (row:EventNotificationType) => row.uuid,
        //     sortable: true,
        //     maxWidth: '50px',
        //     center: true,
        //     conditionalCellStyles: [{
        //         when: (row:any) => row,
        //         classNames: ['fs-14', 'p-2', 'lh-md']
        //     }]
        // },
        {
            name: <span className='font-weight-bold fs-14'>{t('common:name')}</span>,
            selector: (row:EventNotificationType) => {
                return (
                <>
                    <div className="d-flex">
                    <div className="avatar-xs me-3">
                        <span className={"avatar-title rounded-circle fs-16 text-bg-" + row.eventType.class }><i className={ row.eventType.icon }/>
                            </span>
                    </div>
                        <div className="flex-1 align-items-center flex-row d-flex">
                            <p className={"lh-base mb-0" +  (row.isImportant ? ' fw-semibold': '')}>
                                {t('eventType:'+row.eventType.code)}
                            </p>
                        </div>
                    </div>
                </>
                )
            },
            sortable: false,
            wrap: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }]
        },
        {
            id: 'createdAt',
            name: <span className='font-weight-bold fs-14'>{t('common:date')}</span>,
            selector: (row:EventNotificationType) => {
                return(
                    <>
                    {showDateRelative(new Date(row.dateTime),convertToMomentLocale(i18n.language))
                    }
                    </>
                )

            },
            sortable: true,
            maxWidth: '200px',
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }]
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('common:status')}</span>,
            sortable: false,
            maxWidth: '50px',
            center: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }],
            selector: (cell:any) => {
                switch (cell.status) {
                    case EventNotificationStatus.SEND:
                        return <span className="badge badge-soft-info"> {t('notificationStatus:'+EventNotificationStatus.SEND)} </span>;
                    case EventNotificationStatus.FINAL:
                        return <span className="badge badge-soft-success"> {t('notificationStatus:'+EventNotificationStatus.FINAL)} </span>;
                    default:
                        return <span className="badge badge-soft-light"> {t('notificationStatus:U')} </span>;
                }
            },
        },
    ];

}

export default EventNotificationColumns;