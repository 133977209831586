import React, {useState, useEffect, useCallback} from "react";
import {Card, CardBody, Progress, Row} from 'reactstrap';

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {useRedux} from "../../../hooks";

import {MainDeviceType} from "../../../redux/Main/Device/types";
import {handleApproveDevice, handleDeviceRemoveShow} from "./DeviceActions";
import {formatDistance, subDays } from 'date-fns'

import {convertToMomentLocale} from "../../../helpers/functions";

interface ItemProps {
    item: MainDeviceType;
    isCurrent?: boolean;
}

const ListItem = ({ item, isCurrent=false }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();

    const {
        mainDevice,
    } = useAppSelector(state => ({
        mainDevice: state.MainDevice,
    }));

    return (
        <>
            <Card className={"card-height-100 card-animate profile-project-card"+(item.isApproved?' profile-project-success':' profile-project-primary')}>
                <CardBody>
                <div className="d-flex flex-shrink-0 position-relative align-items-center">
                    <div className="avatar-sm me-3 d-flex flex-shrink-0 position-relative align-items-center">
                        <span className={"avatar-title rounded-circle"}>
                             <i className="ri-device-line"/>
                        </span>
                    </div>
                    <div className="flex-1 justify-content-center flex-column d-flex mw-0">
                        <div className="text-truncate">{item.vendor}</div>
                        <small className={"text-muted text-truncate"}>{item.timezone}</small>
                        <small className={"text-muted text-truncate"}>{item.userAgent}</small>
                        {item.dateTime &&
                            <small className={"text-muted text-truncate"}>{
                                formatDistance(subDays(new Date(), 3), new Date(), { addSuffix: true, locale: convertToMomentLocale(i18n.language) })}</small>
                        }
                        {isCurrent &&
                            <div className={"d-flex flex-row mt-1"}>
                            <span className="d-flex text-truncate badge bg-light text-success">{t('device:current')}</span>
                            </div>
                        }
                    </div>
                    <div className="d-flex flex-shrink-0  align-items-center">
                        {item.isApproved ?
                            <button
                                onClick={()=>{handleDeviceRemoveShow(item, dispatch)}}
                                title={t('common:delete')}
                                className="btn btn-outline-danger btn-icon waves-effect waves-light ms-2">
                                <i className="ri-delete-bin-5-line"/>
                            </button>
                            :
                            <button
                                onClick={()=>{handleApproveDevice(mainDevice.current, dispatch)}}
                                title={t('common:approve')}
                                className="btn btn-outline-success btn-icon waves-effect waves-light ms-2">
                                <i className="ri-check-double-line"/>
                            </button>
                        }

                    </div>
                </div>
            </CardBody>
            </Card>
        </>
    );
};

export default ListItem;
