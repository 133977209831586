import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {PeerActivityType} from "../../redux/Content/PeerActivity/types";
import * as route from "../../api/routes";
import { ApexOptions } from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import '../../i18/config';
import {useTranslation, TFunction} from "react-i18next";
import {convertToMomentLocale} from "../../helpers/functions";
import {format, eachDayOfInterval, Locale} from 'date-fns';

interface InputProps {
    items: [] | Array<PeerActivityType>;
    color: string
}

const daysOfWeek = eachDayOfInterval({ start: new Date(), end: new Date(new
    Date().setDate(new Date().getDate() + 6)) });

const generateWeekDayNames = (currentLocale: Locale) => {
    return daysOfWeek.map((day) => format(day, 'EEE', {locale: currentLocale}));
};

const useHeatmapData = (items:[] | Array<PeerActivityType>, t: TFunction) => {
    const [dataSeries, setDataSeries] = useState<any[]>([]);
    const [activityItems, setActivityItems] = useState<any[]>(items);
    const { i18n } = useTranslation();

    useEffect(()=>{
        setActivityItems([...items]);
    },[items]);

    useEffect(()=>{
        const arDayWeekNames = generateWeekDayNames(convertToMomentLocale(i18n.language));
        let arSeries=[];
        let tempDataSeries = [];
        for(let i=0; i<7; i++){
            arSeries[i]= [];
            for(let j = 0; j<24; j++){
                arSeries[i].push({x:j+t('common:hourShort'), y: 0});
            }
        }
        (activityItems || []).map((item:PeerActivityType, key:number)=>{
            let itemActiveFrom = new Date(item.activeFrom);

            arSeries[parseInt(format(itemActiveFrom,'i'))-1].map((item:any)=>{
                if (item.x === format(itemActiveFrom,'H')+t('common:hourShort')){
                    item.y++;
                }
                return item;
            });
            return item;
        });
        for (let seriesItemKey in arSeries){
            tempDataSeries.push({
                name: arDayWeekNames[seriesItemKey],
                data: arSeries[seriesItemKey]
            })
        }
        setDataSeries([...tempDataSeries]);
    },[activityItems,t, i18n.language])

    return dataSeries;
}

const HeatmapWeeklyActivities = ({ items, color }: InputProps) => {

    const { t, i18n } = useTranslation();
    const dataSeries = useHeatmapData(items, t);

    const options:ApexOptions = {
        chart: {
            height: 400,
            type: 'heatmap',
            offsetX: 0,
            offsetY: -8,
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 20,
            markers: {
                width: 20,
                height: 6,
                radius: 2,
            },
            itemMargin: {
                horizontal: 12,
                vertical: 0
            },
        },
        colors: [color],
        tooltip: {
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            notation: 'compact'
                        }).format(y);
                    }
                    return y;
                }
            }]
        }
    };

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('peerActivityType:activityTypes')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_ACTIVITY_PLAN}>{t('peerActivity:manageActivities')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div className="apex-charts" >
                    <ReactApexChart
                        options={options}
                        series={dataSeries}
                        type="heatmap"
                        height="436"
                        className="apex-charts"
                    />
                </div>
            </CardBody>
        </Card>
    );
}

export default HeatmapWeeklyActivities;