import {APIClient, setAuthorization} from "../apiCore";
import * as url from "../urls";
import {defaultBrowserFingerPrintInputProps, getBrowserFingerPrintData} from "../../components/FingerPrint/browser";

const api = new APIClient();

const postJwtLogin = async(data: any) => {
    setAuthorization(null);

    const response = await getBrowserFingerPrintData(defaultBrowserFingerPrintInputProps);

    let sendData = {
        ...data,
        device: response
    }

    return api.create(url.POST_FAKE_JWT_LOGIN, sendData);
}

const socialCheck = async(data: any) => {
    setAuthorization(null);

    const response = await getBrowserFingerPrintData(defaultBrowserFingerPrintInputProps);

    let sendData = {
        ...data,
        device: response
    }

    return api.create(url.SOCIAL_CHECK, sendData);
}

const logoutUser = async() => {
    const response = await getBrowserFingerPrintData(defaultBrowserFingerPrintInputProps);

    let sendData = {
        device: response
    }

    return api.create(url.AUTH_LOGOUT, sendData);
}

const getJWTToken = () => {
    return api.get(url.GET_JWT_KEY_AFTER_LOGIN);
}


export {
    postJwtLogin,
    logoutUser,
    getJWTToken,
    socialCheck
};
