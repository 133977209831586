import React from "react";
import {ContentPeerCommentType} from "../../../redux/Content/PeerComment/types";
import {convertToMomentLocale, getHandlerIconByCode} from "../../../helpers/functions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {format} from "date-fns";

interface ItemProps {
    item: ContentPeerCommentType;
}
const CommentItemLine = ({ item }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);

    return(
        <>
            <div className="d-flex flex-shrink-0 align-items-center">
                <div className="avatar-xxs me-2 d-flex flex-shrink-0 position-relative">
                        <span className={"avatar-title rounded-circle"}>{item.text[0]}</span>

                    { item.handler && item.handler.code &&
                    <div className="avatar-xs12 position-absolute handlerIconTopLeft">
                        <img src={getHandlerIconByCode(item.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                    </div>
                    }
                </div>
                <div className="d-flex flex-column flex-grow-1">
                    <div className="flex-1 flex-row text-truncate justify-content-center">
                        {item.text}
                    </div>
                    <div className="d-flex flex-row flex-nowrap">
                        {item.commentDate &&
                        <div className="badge badge-soft-primary me-2">{
                            format(new Date(item.commentDate),'E, dd MMM yyyy', {locale: convertToMomentLocale(i18n.language)})
                          }
                        </div>
                        }
                        {item.channel && item.channel.name &&
                        <div className="fs-10 align-items-center text-muted mb-0 d-flex flex-shrink-0 text-truncate">{item.channel.name}</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommentItemLine;