import {MainMetaType} from "../common";
import {MainCabinetType} from "../Cabinet/types";

export enum MainDeviceActionTypes {
  API_RESPONSE_SUCCESS = "@@mainDevice/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@mainDevice/API_RESPONSE_ERROR",
  GET_CURRENT_DEVICE = "@@mainDevice/GET_CURRENT_DEVICE",
  GET_MAIN_ALLOWED_DEVICES = "@@mainDevice/GET_MAIN_GRANTED_DEVICES",
  UPDATE_MAIN_ALLOWED_DEVICE = "@@mainDevice/UPDATE_MAIN_ALLOWED_DEVICE",
  REMOVE_MAIN_ALLOWED_DEVICE = "@@mainDevice/REMOVE_MAIN_ALLOWED_DEVICE",
  REMOVE_MAIN_ALLOWED_DEVICE_MODAL_SHOW = "@@mainDevice/REMOVE_MAIN_ALLOWED_DEVICE_MODAL_SHOW",
  REMOVE_MAIN_ALLOWED_DEVICE_MODAL_HIDE = "@@mainDevice/REMOVE_MAIN_ALLOWED_DEVICE_MODAL_HIDE",
  RIGHT_MODAL_SHOW = "@@mainDevice/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@mainDevice/RIGHT_MODAL_HIDE",
}

export interface MainDeviceState extends MainMetaType{
  items: Array<MainDeviceType>
  rightModalShow: boolean;
  removeModalShow: boolean;
  selectedItem: MainDeviceType;
  current: MainDeviceType;
  isSending: boolean;
  isLoading: boolean;
  isNeedReload: boolean;
  isLoaded: boolean;
}

export interface MainDeviceType{
  uuid?: string;
  fingerPrint?: string;
  platform?: string;
  vendor?: string;
  timezone?: string;
  lastLogin?: string;
  userAgent?: string;
  fingerPrintData?: MainDeviceFingerPrintType;
  isApproved?: boolean;
  dateTime?: string;
}

export interface MainDeviceFingerPrintType{
  canvas?: string,
  colorDepth?: number,
  cookieEnabled?: boolean,
  devicePixelRatio?: number,
  doNotTrack?: any,
  hardwareConcurrency?: number,
  height?: number,
  width?: number,
  language?: string,
  languages?: Array<string>,
  maxTouchPoints?: number,
  pixelDepth?: number,
  platform?: string,
  timezone?: string,
  timezoneOffset?: number,
  touchSupport?: boolean,
  userAgent?: string,
  vendor?: string,
  webgl?: string,
  webglInfo?: MainWebGLInfoType,
  fingerPrint?: string
}

export interface MainWebGLInfoType{
  VERSION?:string,
  SHADING_LANGUAGE_VERSION?: string,
  VENDOR?: string,
  SUPORTED_EXTENSIONS?: string
}

export interface MainDevicePayload {
  data: Array<any>;
  modalEditTitle : string;
}

export interface UserDeviceLocalStorageType {
  userUuid: string;
  deviceFingerPrint: string;
}