// types
import {
  DashboardContentActionTypes,
  DashboardContentState,
  StatProjectActivitiesType, StatProjectChannelsDataType, StatProjectCountersDataType, StatProjectCounterTilesType,
  StatProjectTilesType, StatProjectTopUsersType
} from "./types";
import {add} from "date-fns";
import {UTCDate} from "@date-fns/utc";

export const INIT_STATE_DASHBOARD_CONTENT: DashboardContentState = {
  tiles: [],
  channelsActivitiesData: [],
  counterTiles:[],
  countersData:[],
  targets: [],
  activities: [],
  scheduleActivities: [],
  channels:[],
  topusers:[],
  periodFrom: add(new UTCDate(), {days: -7}).getTime()/1000,
  periodTo: new UTCDate().getTime()/1000,
  isDashboardContentFetched: false,
  isDashboardContentTargetFetched: false
};

const DashboardContent = (state = INIT_STATE_DASHBOARD_CONTENT, action: any) => {
  switch (action.type) {
    case DashboardContentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case DashboardContentActionTypes.GET_STAT_CHANNELS_TILES:

          state.tiles =(state.tiles || []).filter((item:StatProjectTilesType, key:number)=>{
            if (item.project && action.payload.data.project && item.project.uuid === action.payload.data.project.uuid){
              return false;
            }
            return true
          });

          return {
            ...state,
            tiles: [...state.tiles, action.payload.data],
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_COUNTERS_TILES:

          state.counterTiles =(state.counterTiles || []).filter((item:StatProjectCounterTilesType, key:number)=>{
            if (item.project && action.payload.data.project && item.project.uuid === action.payload.data.project.uuid){
              return false;
            }
            return true
          });

          return {
            ...state,
            counterTiles: [...state.counterTiles, action.payload.data],
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_COUNTERS_DATA:

          state.countersData =(state.countersData || []).filter((item:StatProjectCountersDataType, key:number)=>{
            if (item.project && action.payload.data.project && item.project.uuid === action.payload.data.project.uuid){
              return false;
            }
            return true
          });

          return {
            ...state,
            countersData: [...state.countersData, action.payload.data],
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_CHANNELS_ACTIVITIES_DATA:

          state.channelsActivitiesData =(state.channelsActivitiesData || []).filter((item:StatProjectChannelsDataType, key:number)=>{
            if (item.project && action.payload.data.project && item.project.uuid === action.payload.data.project.uuid){
              return false;
            }
            return true
          });

          return {
            ...state,
            channelsActivitiesData: [...state.channelsActivitiesData, action.payload.data],
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_TARGETS:
          return {
            ...state,
            targets: action.payload.data.items,
            isDashboardContentTargetFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_CHANNELS:
          return {
            ...state,
            channels: action.payload.data.items,
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_ACTIVITIES:
          state.activities=(state.activities || []).filter((item:StatProjectActivitiesType, key:number)=>{
            if (item.project && action.payload.data.project && item.project.uuid === action.payload.data.project.uuid){
                return false;
            }
            return true
          });

          if (action.payload.data.eTag !== undefined){
            state.eTagActivities=action.payload.data.eTag;
          }

          return {
            ...state,
            activities: [...state.activities, action.payload.data],
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_SCHEDULE_STAT_ACTIVITIES:
          state.scheduleActivities=(state.scheduleActivities || []).filter((item:StatProjectActivitiesType, key:number)=>{
            if (item.project && action.payload.data.project && item.project.uuid === action.payload.data.project.uuid){
              return false;
            }
            return true
          });

          return {
            ...state,
            scheduleActivities: [...state.activities, action.payload.data],
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_TOP_USERS:
          state.topusers=(state.topusers || []).filter((item:StatProjectTopUsersType, key:number)=>{
            if (item.project && action.payload.data.project && item.project.uuid === action.payload.data.project.uuid){
              return false;
            }
            return true
          });

          return {
            ...state,
            topusers: [...state.topusers, action.payload.data],
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };

        default:
          return { ...state };
      }

    case DashboardContentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case DashboardContentActionTypes.GET_STAT_CHANNELS_TILES:
          return {
            ...state,
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_COUNTERS_TILES:
          return {
            ...state,
            isDashboardContentFetched: true,
            getDashboardContentLoading: false,
          };
        case DashboardContentActionTypes.GET_STAT_TARGETS:
          return {
            ...state,
            targets: [],
            isDashboardContentTargetFetched: true,
            getDashboardContentLoading: false,
          };
        default:
          return { ...state };
      }

    case DashboardContentActionTypes.SET_PERIOD:
      return {
        ...state,
        periodFrom: action.payload.periodFrom,
        periodTo: action.payload.periodTo,
        selectedDate: action.payload.selectedDate && action.payload.selectedDate
      };
    case DashboardContentActionTypes.GET_STAT_TARGETS:{
      return {
        ...state,
        isDashboardContentTargetFetched: false,
        getDashboardContentLoading: false,
      };
    }
    default:
      return { ...state };
  }
};

export default DashboardContent;
