import React, { useEffect, useState } from "react";
import {BrowserRouter, useParams} from 'react-router-dom'
import { Col, Row, Card } from 'reactstrap';
import {useRedux} from "../../../hooks";
import {
    getStatPeerActivity,
    getStatPeerActivityActivitiesData,
    getStatPeerActivityEngagementsData,
    getStatPeerActivityTargets,
    getStatPeerActivityTiles, setStatPeerActivityPeriod
} from "../../../redux/Stat/PeerActivity/actions";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import DetailViewItem from "../../Content/PeerActivity/DetailViewItem";
import {
    StatPeerActivityActivitiesDataType,
    StatPeerActivityPayloadType,
    StatPeerActivityTileType
} from "../../../redux/Stat/PeerActivity/types";
import StatTile from "../../../components/StatTile";
import ActivityActivitiesChart from "./ActivityActivitiesChart";
import ActivityEngagementsChart from "./ActivityEngagementsChart";
import {ContentPeerTargetType} from "../../../redux/Content/PeerTarget/types";
import PeerTargetTableSmall from "../../../components/Dashboard/PeerTargetTableSmall";
import {default as RightModalTarget} from "../../Content/PeerTarget/modalForm";
import {getContentPeerTargetTypes} from "../../../redux/Content/PeerTargetType/actions";
import DatePeriodSelect from "../../../components/DatePeriodSelect";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BackButton from "../../../components/BackButton";
import Loader from "../../../components/Loader";
import PeriodSelect from "../../../components/PeriodSelect";
import {setStatPeerChannelPeriod} from "../../../redux/Stat/PeerChannel/actions";
import * as route from "../../../api/routes";
import {PeriodDurationType, PeriodType} from "../../../redux/Stat/DashboardContent/types";
import {add, differenceInDays} from "date-fns";
import {UTCDate} from "@date-fns/utc";

const StatPeerActivityIndex = () => {

    const { uuidActivity } = useParams();
    const { t } = useTranslation(['menu']);

    const { dispatch, useAppSelector } = useRedux();
    const [activity, setActivity] = useState<PeerActivityType>(null);
    const [tiles, setTiles] = useState<StatPeerActivityTileType[]>([]);
    const [targets, setTargets] = useState<ContentPeerTargetType[]>([]);
    const [activitiesData, setActivitiesData] = useState<StatPeerActivityActivitiesDataType[]>([]);
    const [engagementsData, setEngagementsData] = useState<StatPeerActivityActivitiesDataType[]>([]);
    const [ peerActivityName, setPeerActivityName ] = useState<string>('');
    const [ isLoaded, setLoaded ] = useState<boolean>(false);
    const [ isTargetLoaded, setTargetLoaded ] = useState<boolean>(false);
    const [ periodType, setPeriodType ] = useState<PeriodDurationType>(PeriodDurationType.WEEK);

    const {
        statActivity,
        isStatPeerActivityContentFetched,
        isStatPeerActivityContentTargetFetched,
        periodFrom,
        periodTo
    } = useAppSelector(state => ({
        statActivity: state.StatPeerActivityContent,
        isStatPeerActivityContentFetched: state.StatPeerActivityContent.isStatPeerActivityContentFetched,
        isStatPeerActivityContentTargetFetched: state.StatPeerActivityContent.isStatPeerActivityContentTargetFetched,
        periodFrom: state.StatPeerActivityContent.periodFrom,
        periodTo: state.StatPeerActivityContent.periodTo
    }));

    useEffect(() => {
        if (uuidActivity && uuidActivity.length>0){
            dispatch(getStatPeerActivity({uuid:uuidActivity}));
        }
        dispatch(getContentPeerTargetTypes());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoaded(isStatPeerActivityContentFetched);
    },[isStatPeerActivityContentFetched]);

    useEffect(() => {
        setTargetLoaded(isStatPeerActivityContentTargetFetched);
    },[isStatPeerActivityContentTargetFetched]);

    useEffect(() => {

        if ((statActivity.activity !== undefined) && (statActivity.activity.uuid.length>0)){
            setActivity({...statActivity.activity});

            if (statActivity.activity.text !== undefined && statActivity.activity.text!== null) {
                setPeerActivityName(' - ' + statActivity.activity.text.substring(0, 15) + (statActivity.activity.text.length > 15 ? '...' : ''));
            } else {
                setPeerActivityName('');
            }

            const StatPeerActivityPayload:StatPeerActivityPayloadType = {
                activity:statActivity.activity,
                periodFrom: statActivity.periodFrom,
                periodTo: statActivity.periodTo,
            }
            dispatch(getStatPeerActivityTiles(StatPeerActivityPayload));
            dispatch(getStatPeerActivityTargets(StatPeerActivityPayload));
            dispatch(getStatPeerActivityActivitiesData(StatPeerActivityPayload));
            dispatch(getStatPeerActivityEngagementsData(StatPeerActivityPayload));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statActivity?.activity]);

    useEffect(() => {

        if ((statActivity.periodFrom !== undefined) && (statActivity.periodTo !== undefined)){

            const StatPeerActivityPayload:StatPeerActivityPayloadType = {
                activity:statActivity.activity,
                periodFrom: statActivity.periodFrom,
                periodTo: statActivity.periodTo,
            }

            dispatch(getStatPeerActivityTiles(StatPeerActivityPayload));
            dispatch(getStatPeerActivityTargets(StatPeerActivityPayload));
            dispatch(getStatPeerActivityActivitiesData(StatPeerActivityPayload));
            dispatch(getStatPeerActivityEngagementsData(StatPeerActivityPayload));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statActivity?.periodFrom, statActivity?.periodTo]);

    useEffect(() => {

        if ((statActivity.tiles !== undefined) && (statActivity.tiles.length>0)){
            setTiles([...statActivity.tiles]);
        } else {
            setTiles([]);
        }

    }, [statActivity?.tiles]);

    useEffect(() => {

        if ((statActivity.targets !== undefined) && (statActivity.targets.length>0)){
            setTargets([...statActivity.targets]);
        } else {
            setTargets([]);
        }

    }, [statActivity?.targets]);

    useEffect(() => {

        if ((statActivity.activitiesData !== undefined) && (statActivity.activitiesData.length>0)){
            setActivitiesData([...statActivity.activitiesData]);
        } else {
            setActivitiesData([]);
        }

    }, [statActivity?.activitiesData]);

    useEffect(() => {

        if ((statActivity.engagementsData !== undefined) && (statActivity.engagementsData.length>0)){
            setEngagementsData([...statActivity.engagementsData]);
        } else {
            setEngagementsData([]);
        }

    }, [statActivity?.engagementsData]);

    const handleLastWeekPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: add(new UTCDate(),{weeks: -1}).getTime() / 1000,
            periodTo: new UTCDate().getTime() / 1000
        }

        dispatch(setStatPeerActivityPeriod(periodData));
        setPeriodType(PeriodDurationType.WEEK);
    }

    const handleLastMonthPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: add(new UTCDate(),{months: -1}).getTime() / 1000,
            periodTo: new UTCDate().getTime() / 1000,
        }

        dispatch(setStatPeerActivityPeriod(periodData));
        setPeriodType(PeriodDurationType.MONTH);
    }


    return (
        <>
            <BreadCrumb title={t('menu:activityStat')+peerActivityName} pageTitle={t('menu:stat')} />

            <Row className="mb-3 pb-1">
                <Col xl={2}>
                    <BackButton name={t('common:backNavigation')}/>
                </Col>
                <Col xl={4}>
                        <div className="d-flex flex-row flex-grow-1 justify-content-center align-items-center" >
                            {!isLoaded &&
                            <div className="d-flex justify-content-center align-items-center calendarLoader">
                                <Loader addClassNames=""/>
                            </div>
                            }
                        </div>
                </Col>
                <Col xl={6}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1"/>
                        <div className="mt-3 mt-lg-0">
                            <Row className="g-3 mb-0 align-items-center">
                                <div className="col-sm-auto">
                                    {periodType === PeriodDurationType.DATE_RANGE ?
                                        <>
                                            <PeriodSelect
                                                onDispatch={setStatPeerActivityPeriod}
                                                periodFrom={periodFrom}
                                                periodTo={periodTo}/>
                                        </>
                                        :
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn "+(periodType===PeriodDurationType.WEEK?'btn-primary':'btn-soft-primary') }onClick={handleLastWeekPeriod}>{t('common:week')}</button>
                                        <button type="button" className={"btn "+(periodType===PeriodDurationType.MONTH?'btn-primary':'btn-soft-primary')} onClick={handleLastMonthPeriod}>{t('common:month')}</button>
                                        <button type="button" className="btn btn-soft-primary" onClick={()=>{setPeriodType(PeriodDurationType.DATE_RANGE)}}>
                                            <i className="ri-calendar-2-line"/>
                                        </button>
                                    </div>
                                    }
                                </div>
                                <div className="col-auto">
                                    <a href={route.CONTENT_ACTIVITY_PLAN} className="btn btn-primary"><i className="ri-pages-line align-middle me-1"/> {t('peerActivity:activityPlan')}</a>
                                </div>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>



                {activity &&
                    <>
                        <Row>
                            <Col xl={8}>
                                <Row>
                                    {(tiles || []).map((tile: StatPeerActivityTileType, key:number)=>(
                                        <Col md={3} key = {'activityTile_'+tile.label+'_'+key.toString()}>
                                            <StatTile
                                                label={tile.label}
                                                start={0}
                                                first={tile.value.first && tile.value.first}
                                                last={tile.value.last}
                                                isLoading={!isLoaded}
                                                deltaLabel={
                                                    differenceInDays(new UTCDate(statActivity.periodTo*1000), new UTCDate(statActivity.periodFrom*1000)).toString()
                                                }
                                            />
                                        </Col>
                                    ))}
                                </Row>
                                <Row>
                                    <Col>
                                        <ActivityActivitiesChart items={activitiesData}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ActivityEngagementsChart items={engagementsData}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={4}>
                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <Card className="overflow-hidden">*/}
                                {/*            <DatePeriodSelect onDispatch={setStatPeerActivityPeriod}/>*/}
                                {/*        </Card>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    <Col>
                                        <div className="detailActivityViewCardWide">
                                        <DetailViewItem item={activity}/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-2">
                                        {isTargetLoaded ?
                                            <PeerTargetTableSmall items={targets}/>
                                            :
                                            <Loader/>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                }
                <RightModalTarget/>
        </>
    );
};

export default StatPeerActivityIndex;


