import axios from "axios";
import {config} from "../config";
import * as url from "./urls";
import {
    deleteCookie,
    getCurrentCabinet,
    hideLoadingBar,
    setCurrentLanguage,
    showLoadingBar
} from "../helpers/functions";
import {showErrorNotification} from "../helpers/notifications";
import {defaultBrowserFingerPrintInputProps, getBrowserFingerPrintData} from "../components/FingerPrint/browser";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";


// intercepting to capture errors
axios.interceptors.response.use(
    function (response: any) {
        showLoadingBar(100);
        return response.data ? response.data : response;
    },
    async function (error: any) {
        showLoadingBar(100);
        const originalRequest = error.config;
        const {response} = error;

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (response.status) {
            case 401:

                if (error.response.status === 401
                    &&
                    !originalRequest._retry
                    &&
                    (
                    (response.data.message === 'Expired JWT Token')
                    ||
                    (response.data.message === 'Invalid JWT Token')
                    )
                ) {
                    if (isRefreshing) {
                        return new Promise(function(resolve, reject) {
                            failedQueue.push({ resolve, reject });
                        })
                            .then(token => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                return axios(originalRequest);
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    }

                    originalRequest._retry = true;

                    return new Promise(async function(resolve, reject) {

                        const currentUser = getLoggedinUser()
                        const browserFingerPrint = await getBrowserFingerPrintData(defaultBrowserFingerPrintInputProps);

                        if (currentUser.refresh_token !== undefined) {
                            isRefreshing = true;

                            axios
                                .post(url.POST_REFRESH_JWT_TOKEN, {
                                    refresh_token: currentUser.refresh_token
                                }, {headers:{'Content-Type': 'application/json', 'Authorization':'Bearer '+currentUser.token}})
                                .then((res:any) => {

                                    updateTokens(res, originalRequest, currentUser);

                                    // setAuthorization(res.token);
                                    // currentUser.token = res.token;
                                    // currentUser.refresh_token = res.refresh_token;
                                    //
                                    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.token;
                                    //
                                    // originalRequest.headers['Authorization'] = 'Bearer ' + res.token;
                                    //
                                    // setLoggeedInUser(currentUser);
                                    //
                                    // processQueue(null, res.token);

                                    resolve(axios(originalRequest));

                                })
                                .catch(async err => {

                                    await axios
                                        .post(url.POST_DEVICE_TOKEN, {
                                            user: {uuid: currentUser.uuid},
                                            device: {fingerPrint: browserFingerPrint.fingerPrint}
                                        }, {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer ' + currentUser.token
                                            }
                                        })
                                        .then((res: any) => {

                                            updateTokens(res, originalRequest, currentUser);
                                            resolve(axios(originalRequest));

                                        }).catch(err => {

                                            processQueue(err, null);
                                            showErrorNotification({message: 'Expired Token'});

                                            reject(err);
                                        }).then(() => {
                                            isRefreshing = false;
                                        });

                                })
                                .then(() => {
                                    isRefreshing = false;
                                });
                        }
                    });
                } else {
                    message = response.data;
                }
                break;
            case 404:
                message = "emptyData";
                break;
            case 400:
            case 500:
                if (response.data !== undefined) {
                    message = response.data
                } else {
                    message = error.message || error;
                }
                break
            default:
                message = error.message || error;
        }

        return Promise.reject(message);
    }
);

const updateTokens = (res: any, originalRequest: any, currentUser: any) =>{
    setAuthorization(res.token);
    currentUser.token = res.token;
    currentUser.refresh_token = res.refresh_token;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.token;
    originalRequest.headers['Authorization'] = 'Bearer ' + res.token;
    setLoggeedInUser(currentUser);
    processQueue(null, res.token);
}

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
    if (token == null) {
        axios.defaults.headers.common["Authorization"] = null;
    } else {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
};

class APIClient {
    /**
     * Fetches data from given url
     */


    get = (url: string, params?: {}) => {
        showLoadingBar(75);

        return axios.get(url, params);
    };

    /**
     * post given data to url
     */
    create = (url: string, data?: {}) => {
        showLoadingBar(75);

        return axios.post(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data?: {}) => {
        showLoadingBar(75);
        return axios.put(url, data);
    };

    /**
     * Delete
     */
    delete = (url: string, config?: {}) => {
        showLoadingBar(75);
        return axios.delete(url, {...config});
    };

    /*
     file upload update method
     */
    updateWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        showLoadingBar(75);
        return axios.put(url, formData, config);
    };

    /*
     file upload post method
     */
    createWithFile = (url: string, data: any) => {
        const formData = new FormData();

        formData.append('payload', JSON.stringify(data));

        if (data.attachmentFiles !== undefined) {
            data.attachmentFiles.forEach((file: any) => {
                formData.append("files[]", file);
            });
        }

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        showLoadingBar(75);
        return axios.post(url, formData, config);
    };
}

const getLoggedinUser = () => {
    const user = localStorage.getItem(config.LOCALSTORAGE_AUTH_USER);

    if ((!user) || (user === "undefined")) {
        return false;
    } else {
        let userObject;

        try {
            userObject = JSON.parse(user)
        } catch (e) {
            setLogOutUser();
            return false;
        }

        if (userObject !== null) {
            if (userObject.token !== undefined) {
                setAuthorization(userObject.token);
            } else {
                setLogOutUser();
                return false;
            }

            if (userObject.url !== undefined) {
                axios.defaults.baseURL = userObject.url;
            }
            getCurrentCabinet();

        } else {
            setLogOutUser();
            return false;
        }

        return JSON.parse(user);
    }
};

const checkJwtToken = () => {

    const currentUser = getLoggedinUser();

    if (currentUser !== null) {
        return axios.get(url.JWT_CHECK);
    }
}

const setLoggeedInUser = <T>(user: any) => {
    localStorage.setItem(config.LOCALSTORAGE_AUTH_USER, JSON.stringify(user));

    if (user.language !== undefined && user.language !== null && user.language.length>0){
        setCurrentLanguage(user.language);
    }

    if (user.token !== undefined) {
        setAuthorization(user.token);
    }
};

const setLogOutUser = () => {
    setAuthorization(null);
    localStorage.removeItem(config.LOCALSTORAGE_AUTH_USER);
    localStorage.removeItem(config.LOCALSTORAGE_CURRENT_CABINET);

    deleteCookie('BEARER');
}

export {APIClient, setAuthorization, getLoggedinUser, checkJwtToken, setLoggeedInUser, setLogOutUser};
