// types
import {StatPeerChannelState} from "./types";
import {StatPeerChannelContentActionTypes} from "./types";
import {add, differenceInDays} from "date-fns";
import {UTCDate} from "@date-fns/utc";

export const INIT_STATE_STAT_CHANNEL_CONTENT: StatPeerChannelState = {
  tiles: [],
  activitiesData: [],
  targets: [],
  activities: [],
  scheduleActivities:[],
  periodFrom: add(new UTCDate(), {days: -7}).getTime()/1000,
  periodTo: new UTCDate().getTime()/1000,
  deltaDays: 7,
  isStatPeerChannelContentFetched: false,
  isContentPeerChannelTargetFetched: false
};

const StatPeerChannelContent = (state = INIT_STATE_STAT_CHANNEL_CONTENT, action: any) => {
  switch (action.type) {
    case StatPeerChannelContentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TILES:
          return {
            ...state,
            tiles: action.payload.data.items,
            isStatPeerChannelContentFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA:
          return {
            ...state,
            activitiesData: action.payload.data.items,
            isStatPeerChannelContentFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS:
          return {
            ...state,
            targets: action.payload.data.items,
            isContentPeerChannelTargetFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES:
          return {
            ...state,
            activities: action.payload.data.items,
            isStatPeerChannelContentFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        case StatPeerChannelContentActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES:
          return {
            ...state,
            scheduleActivities: action.payload.data.items,
            isStatPeerChannelContentFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        default:
          return { ...state };
      }

    case StatPeerChannelContentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS:
          return {
            ...state,
            isContentPeerChannelTargetFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        default:
          return { ...state };
      }

    case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TILES:
    case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA:
    case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES:
    case StatPeerChannelContentActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES:
      return {
        ...state,
        isStatPeerChannelContentFetched: false,
        getStatPeerChannelContentLoading: true,
      };
    case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS:
      return {
        ...state,
        isContentPeerChannelTargetFetched: false,
        getStatPeerChannelContentLoading: true,
      };
    case StatPeerChannelContentActionTypes.SET_PERIOD:

      let periodFrom = new Date(action.payload.periodFrom*1000);
      let periodTo = new Date(action.payload.periodTo*1000);

      return {
        ...state,
        periodFrom: action.payload.periodFrom,
        periodTo: action.payload.periodTo,
        deltaDays: differenceInDays(periodTo, periodFrom).toString(),
        selectedDate: action.payload.selectedDate && action.payload.selectedDate
      };
    default:
      return { ...state };
  }
};

export default StatPeerChannelContent;
