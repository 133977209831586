import React, {useEffect, useRef, useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import * as route from "../../../api/routes";
import Flatpickr from "react-flatpickr";
import PeerActivityLine from "../../../blocks/Content/PeerActivity/PeerActivityLine";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {PeriodType} from "../../../redux/Stat/DashboardContent/types";
import {useRedux} from "../../../hooks";
import {StatPeerChannelPayloadType} from "../../../redux/Stat/PeerChannel/types";
import {
    getStatPeerChannelActivities,
    getStatPeerChannelScheduleActivities
} from "../../../redux/Stat/PeerChannel/actions";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import { convertToMomentLocale } from "../../../helpers/functions";
import {format} from "date-fns";

interface SchedulesCalendarProps {
    items: [] | Array<PeerActivityType>;
    channel?: ContentPeerChannelType,
}

const SchedulesCalendar = ({ items, channel }: SchedulesCalendarProps) => {
    const { t, i18n } = useTranslation(['common']);

    const calendarRef = useRef(null);

    const [currentPeriod, setCurrentPeriod] = useState<PeriodType>(null);

    const { dispatch, useAppSelector } = useRedux();

    const {
        statPeerChannel,
    } = useAppSelector(state => ({
        statPeerChannel: state.StatPeerChannelContent,
    }));

    const [selectedTimestamp, setSelectedTimestamp] = useState((new Date().getTime())/1000);
    const selectedDate = format(new Date(selectedTimestamp*1000),'dd-MM-yyyy')

    let newActivitiesDates=[];

    (items || []).map((item:PeerActivityType, key:number)=>{
        newActivitiesDates.push(new Date(item.activeFrom).setHours(0,0,0,0));
        return item;
    });

    useEffect(() => {

        setCurrentPeriod({
            periodFrom: statPeerChannel.periodFrom && statPeerChannel.periodFrom,
            periodTo: statPeerChannel.periodTo && statPeerChannel.periodTo,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {

        if (statPeerChannel.selectedDate>0){
            calendarRef.current.flatpickr.setDate(new Date(statPeerChannel.selectedDate*1000));
            setSelectedTimestamp(statPeerChannel.selectedDate);
        }

    },[statPeerChannel?.selectedDate]);

    // useEffect(() => {
    //         if (calendarRef !== undefined && calendarRef.current !== undefined){
    //
    //           //  console.dir(calendarRef);
    //
    //             // if (currentPeriod != null && (statPeerChannel.periodFrom !== currentPeriod.periodFrom)){
    //             //
    //             //     setCurrentPeriod({
    //             //         periodFrom: statPeerChannel.periodFrom && statPeerChannel.periodFrom,
    //             //         periodTo: statPeerChannel.periodTo && statPeerChannel.periodTo,
    //             //     });
    //             //
    //             //     const statPeerChannelPayload:StatPeerChannelPayloadType = {
    //             //         project : channel.project,
    //             //         channel: channel,
    //             //         periodFrom : statPeerChannel.periodFrom,
    //             //         periodTo: statPeerChannel.periodTo
    //             //     }
    //             //
    //             //     dispatch(getStatPeerChannelScheduleActivities(statPeerChannelPayload));
    //             // }
    //         }
    //
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[calendarRef]);


    const onCalendarChangeView = (instance : any) => {

        const periodFrom = instance.days.firstChild.dateObj.getTime()/1000;
        const periodTo = instance.days.lastChild.dateObj.getTime()/1000;

        const statPeerChannelPayload:StatPeerChannelPayloadType = {
            project : channel.project,
            channel: channel,
            periodFrom : periodFrom,
            periodTo: periodTo
        }

        dispatch(getStatPeerChannelScheduleActivities(statPeerChannelPayload));
    }

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('peerActivity:activities')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_ACTIVITY_PLAN}>{t('menu:activitiesPlan')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div className="upcoming-scheduled">
                    <Flatpickr
                        ref = {calendarRef}
                        className="form-control"
                        onDayCreate={(dObj, dStr, fp, dayElem)=>{

                            if (newActivitiesDates.indexOf(+dayElem.dateObj) !== -1) {
                                dayElem.className += " position-relative";

                                dayElem.innerHTML+='<span class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-1"><span class="visually-hidden">'+t('peerActivity:activities')+'</span></span>';
                            }
                        }}
                        options={{
                            altFormat: "F j, Y",
                            dateFormat: "U",
                            inline: true,
                            altInput: false,
                            allowInput: false,
                            mode: 'single'
                        }}
                        onReady={(selectedDates, dateStr, instance) =>{
                            onCalendarChangeView(instance);
                        }}
                        onYearChange={(selectedDates, dateStr, instance) =>{
                            onCalendarChangeView(instance);
                        }}
                        onMonthChange={(selectedDates, dateStr, instance) =>{
                            onCalendarChangeView(instance);
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                            setSelectedTimestamp(dateStr);
                        }}
                    />
                </div>

                <h6 className="text-uppercase fw-semibold mt-4 mb-3 text-muted">{t('peerActivity:activitiesOn')} {
                    format(new Date(selectedTimestamp*1000),'E, dd MMM yyyy', {locale: convertToMomentLocale(i18n.language)})
                }: </h6>
                {(items || []).map((item:PeerActivityType, key:number)=>(
                    format(new Date(),'dd-MM-yyyy') === selectedDate &&
                    <PeerActivityLine item={item} key={'calendarActivity_'+key}/>
                ))}

                <div className="mt-3 text-center">
                    <a href={route.CONTENT_ACTIVITY_PLAN} className="text-muted text-decoration-underline">{t('menu:activitiesPlan')}</a>
                </div>

            </CardBody>
        </Card>
    );
}

export default SchedulesCalendar;