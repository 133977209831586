// types
import {
  DashboardTaskActionTypes,
  DashboardTaskState,
} from "./types";
import {TaskMemberTypeType} from "../../Task/TaskMember/types";
import {UTCDate} from "@date-fns/utc";
import {add} from "date-fns";

export const INIT_STATE_DASHBOARD_TASK: DashboardTaskState = {
  tiles: [],
  lastCompleted: [],
  lastCompletedMembers:[],
  lastOverdue: [],
  lastOverdueMembers:[],
  lastPending: [],
  lastPendingMembers:[],
  lastReturned: [],
  lastReturnedMembers:[],
  currentInProgress: [],
  currentInProgressMembers:[],

  isFetchedLastCompleted: false,
  isFetchedLastOverdue: false,
  isFetchedLastPending: false,
  isFetchedLastReturned:false,
  isFetchedCurrentInProgress: false,

  periodFrom: add(new UTCDate(), {days: -7}).getTime()/1000,
  periodTo: new UTCDate().getTime()/1000,
  memberType: TaskMemberTypeType.TYPE_RESPONSIBLE,
  isFetched: false,
  isFetchedTasksTiles: false,
  isLoading: false
};

const DashboardTask = (state = INIT_STATE_DASHBOARD_TASK, action: any) => {
  switch (action.type) {
    case DashboardTaskActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case DashboardTaskActionTypes.GET_STAT_TASKS_TILES:
          return {
            ...state,
            tiles: [...action.payload.data.items],
            isFetchedTasksTiles: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_COMPLETED:
          return {
            ...state,
            lastCompleted: [...action.payload.data.items],
            lastCompletedMembers: action.payload.data.members,
            isFetchedLastCompleted: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_OVERDUE:
          return {
            ...state,
            lastOverdue: [...action.payload.data.items],
            lastOverdueMembers: action.payload.data.members,
            isFetchedLastOverdue: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_RETURNED:
          return {
            ...state,
            lastReturned: [...action.payload.data.items],
            lastReturnedMembers: action.payload.data.members,
            isFetchedLastReturned: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_PENDING:
          return {
            ...state,
            lastPending: [...action.payload.data.items],
            lastPendingMembers: action.payload.data.members,
            isFetchedLastPending: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_IN_PROGRESS:
          return {
            ...state,
            currentInProgress: [...action.payload.data.items],
            currentInProgressMembers: action.payload.data.members,
            isFetchedCurrentInProgress: true,
            isLoading: false
          };
        default:
          return { ...state };
      }

    case DashboardTaskActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case DashboardTaskActionTypes.GET_STAT_TASKS_TILES:
          return {
            ...state,
            isFetchedTasksTiles: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_COMPLETED:
          return {
            ...state,
            isFetchedLastCompleted: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_OVERDUE:
          return {
            ...state,
            isFetchedLastOverdue: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_RETURNED:
          return {
            ...state,
            isFetchedLastReturned: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_PENDING:
          return {
            ...state,
            isFetchedLastPending: true,
            isLoading: false
          };
        case DashboardTaskActionTypes.GET_STAT_TASKS_IN_PROGRESS:
          return {
            ...state,
            isFetchedCurrentInProgress: true,
            isLoading: false
          };
        default:
          return { ...state };
      }
    case DashboardTaskActionTypes.GET_STAT_TASKS_TILES:
      return {
        ...state,
        isFetchedTasksTiles: false
      };
    case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_COMPLETED:
      return {
        ...state,
        lastCompleted: [],
        isFetchedLastCompleted: false,
      };
    case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_OVERDUE:
      return {
        ...state,
        lastOverdue: [],
        isFetchedLastOverdue: false,
      };
    case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_RETURNED:
      return {
        ...state,
        lastReturned: [],
        isFetchedLastReturned: false,
      };
    case DashboardTaskActionTypes.GET_STAT_TASKS_LAST_PENDING:
      return {
        ...state,
        lastPending: [],
        isFetchedLastPending: false,
      };
    case DashboardTaskActionTypes.GET_STAT_TASKS_IN_PROGRESS:
      return {
        ...state,
        currentInProgress: [],
        isFetchedCurrentInProgress: false,
      };
    case DashboardTaskActionTypes.SET_PERIOD:
      return {
        ...state,
        periodFrom: action.payload.periodFrom,
        periodTo: action.payload.periodTo,
        selectedDate: action.payload.selectedDate && action.payload.selectedDate
      };
    case DashboardTaskActionTypes.SELECT_TASK_MEMBER_TYPE:
      return {
        ...state,
        memberType: action.payload
      };
    default:
      return { ...state };
  }
};

export default DashboardTask;
