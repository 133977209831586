import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import {
    hideRightModalTask,
    onSendDataTask
} from "../../../redux/Task/Task/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {TaskPriority, TaskStatus, TaskType} from "../../../redux/Task/Task/types";
import {TaskValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";
import ButtonSpinner from "../../../components/ButtonSpinner";
import InputFile from "../../../components/InputFile";
import {MainFileType} from "../../../redux/Main/File/types";
import NoticeTip from "../../../components/NoticeTip";
import InputDateTimePicker from "../../../components/InputDateTimePicker";
import InputSelect from "../../../components/InputSelect";
import Loader from "../../../components/Loader";
import {MainUserType} from "../../../redux/Main/User/types";
import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {
    NewTaskMemberAssignerItem, NewTaskMemberResponsibleItem,
    TaskMemberType,
    TaskMemberTypeType
} from "../../../redux/Task/TaskMember/types";
import UserItemLine from "../../Main/User/ListItem";
import InputSwitch from "../../../components/InputSwitch";
import {convertToMomentLocale} from "../../../helpers/functions";
import {formatISO} from "date-fns/formatISO";

const RightModal = () => {

    const { t, i18n } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();

    const {
        mainUser,
        isContactsFetched,
        taskState,
        isSending
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
        isContactsFetched: state.MainUser.isFetched,
        taskState: state.Task,
        isSending: state.Task.isSending
    }));

    const uuid = taskState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<TaskType>();
    const [contacts, setContacts] = useState<MainUserType[]>([]);
    const [task, setTask] = useState<TaskType>(NewTaskItem);
    const [statuses, setStatuses] = useState<any>([]);
    const [priorities, setPriorities] = useState<any>([]);

    useEffect(() => {

        let _statuses=[];

        Object.keys(TaskStatus).map((item:string)=>{
            if (TaskStatus[item].enabled) {
                _statuses.push({
                    id: TaskStatus[item].status,
                    colorClass: TaskStatus[item].class,
                    value: t('task:taskStatus' + TaskStatus[item].label)
                });
            }
            return item;
        })

        setStatuses([..._statuses]);

        let _priorities=[];

        Object.keys(TaskPriority).map((item:string)=>{
            if (TaskPriority[item].enabled) {
                _priorities.push({
                    id: TaskPriority[item].status,
                    colorClass: TaskPriority[item].class,
                    value: t('task:taskPriority' + TaskPriority[item].label)
                });
            }
            return item;
        })

        setPriorities([..._priorities]);
    },[t]);

    useEffect(() => {

        let _task = {...taskState.selectedItem};

        if (taskState?.selectedItem?.deadLine !== undefined && taskState.selectedItem.deadLine !== null) {
            _task.deadLine = (new Date(taskState.selectedItem.deadLine).getTime()/1000);
        }

        if (mainUser?.currentUser?.uuid) {

            if ((_task.members || []).length < 1) {
                _task = {
                    ..._task,
                    members: [
                        {
                            ...NewTaskMemberAssignerItem,
                            memberUuid: mainUser.currentUser.uuid,
                        },
                    ]
                }
            }
        }

        setTask(_task);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, dispatch, taskState?.selectedItem, mainUser?.currentUser?.uuid]);

    useEffect(() => {

        setFormValues(task);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task]);

    useEffect(() => {

        if (mainUser?.contacts && (mainUser?.contacts || []).length>0) {
            setContacts((mainUser.contacts || []).filter((item:MainUserType)=>{return item?.uuid !== undefined && item.uuid !== null && item.uuid.length>0}));
        } else {
            setContacts([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isContactsFetched, mainUser?.contacts]);

    const handleClose = () => dispatch(hideRightModalTask());

    const onDataSubmit = (formData:TaskType) => {

        let attachmentFiles=[];
        
        let params: TaskType = {
            enabled: formData.enabled,
            uuid: formData.uuid && formData.uuid,
            name: formData.name && formData.name,
            text: formData.text && formData.text,
            media: formData.media && formData.media,
            members: formData.members && formData.members,
            deadLine: formData.deadLine && formData.deadLine,
            status: formData.status && formData.status,
            tier: formData.tier && formData.tier,
            isNotifyAssignerClosure: formData.isNotifyAssignerClosure && formData.isNotifyAssignerClosure,
            completionPercent: formData.completionPercent && formData.completionPercent
        };

        (formData.media|| []).map((fileItem:MainFileType)=>{
            if (fileItem.uuid === undefined){
                attachmentFiles.push(fileItem);
            }
            return fileItem;
        });

        if (attachmentFiles.length>0){
            params['attachmentFiles']=attachmentFiles;
        }

        dispatch(onSendDataTask(params));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={taskState.rightModalShow}
                    id="taskStateEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={TaskValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('task:'+taskState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                                    <Row>
                                        <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-title-input">{t('common:nameTitle')}</Label>
                                            <Field
                                                tabIndex={1}
                                                id="form-title-input"
                                                name="name"
                                                placeholder={t('common:enterNameTitle')}
                                                component={InputText}
                                                value = {getFieldProps('name').value}
                                                onChange={(name:string) => {
                                                    setFieldValue('name', name);
                                                }}
                                                className={errors.name && touched.name && 'form-control is-invalid'}
                                            />
                                            {errors.name && touched.name ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.name)}
                                                </div>
                                            ) :  touched.name && !errors.name ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className={ "mb-3"+ (touched['members'] ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-assigner-input">{t('task:assigner')}</Label>
                                        { contacts && contacts.length>0 ?
                                            <InputSelect
                                                items={contacts}
                                                placeholder={t('task:chooseAssigner')}
                                                onChange={(value: any)=>{
                                                        let _members = getFieldProps('members').value;

                                                        _members.map((member:TaskMemberType)=>{
                                                            if (member.type === TaskMemberTypeType.TYPE_ASSIGNER){
                                                                member.memberUuid = value.value
                                                            }
                                                            return member;
                                                        })
                                                        setFieldValue('members', _members);
                                                    }
                                                }
                                                required={false}
                                                isLoading={!isContactsFetched}
                                                value={
                                                    ((getFieldProps('members').value || []).filter((taskMember:TaskMemberType)=>{
                                                        return taskMember.type === TaskMemberTypeType.TYPE_ASSIGNER
                                                    }) || []).length>0 &&
                                                    contacts.filter((contact:MainUserType)=>{
                                                        return contact.uuid === (getFieldProps('members').value || []).filter((taskMember:TaskMemberType)=>{
                                                            return taskMember.type === TaskMemberTypeType.TYPE_ASSIGNER
                                                        }).shift().memberUuid;
                                                    }).shift()
                                                 }
                                                readonly={false}
                                                className={'position-relative'}
                                            />
                                            :
                                            <Loader/>
                                        }
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className="mb-3 d-flex flex-column me-5">
                                            <Field
                                                id="form-isNotifyAssignerClosure-input"
                                                name="isNotifyAssignerClosure"
                                                placeholder={t('task:isNotifyAssignerClosure')}
                                                component={InputSwitch}
                                                value = {getFieldProps('isNotifyAssignerClosure').value}
                                                onChange={(enabled:boolean) => {
                                                    setFieldValue('isNotifyAssignerClosure', !getFieldProps('isNotifyAssignerClosure').value);
                                                }}
                                                className="switch-sm"
                                                required={false}
                                            />
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className={ "mb-3"+ (touched['members'] ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-responsible-input">{t('task:responsible')}</Label>

                                            { contacts && contacts.length>0 ?
                                                <>
                                                    <SimpleBar style={{height: contacts.length>2 && '115px'}}>
                                                    {contacts.map((contact:MainUserType)=>{
                                                        return(
                                                            <div key={"taskResponsible_"+contact.uuid} className={"mb-1"}>
                                                                <div className="d-flex flex-row flex-nowrap">
                                                                    <div className="d-flex flex-column justify-content-center">
                                                                        <Field
                                                                            id={"form-responsible-input-" + contact.uuid}
                                                                            name="taskResponsibles"
                                                                            component={InputSwitch}
                                                                            className={"switch-sm"}
                                                                            value={
                                                                                ((getFieldProps('members').value || []).filter((taskMember:TaskMemberType)=>{
                                                                                    return taskMember.type === TaskMemberTypeType.TYPE_RESPONSIBLE && taskMember.memberUuid === contact.uuid
                                                                                }) || []).length>0
                                                                            }
                                                                            onChange={(enabled: boolean) => {

                                                                                let _members = getFieldProps('members').value;

                                                                                if (((_members || []).filter((taskMember:TaskMemberType)=>{
                                                                                    return taskMember.type === TaskMemberTypeType.TYPE_RESPONSIBLE && taskMember.memberUuid === contact.uuid
                                                                                }) || []).length>0) {

                                                                                    _members = (_members || []).filter((taskMember:TaskMemberType)=>{
                                                                                        return !(taskMember.type === TaskMemberTypeType.TYPE_RESPONSIBLE && taskMember.memberUuid === contact.uuid)
                                                                                    })

                                                                                } else {
                                                                                    _members = [..._members,
                                                                                        {
                                                                                            ...NewTaskMemberResponsibleItem,
                                                                                            memberUuid: contact.uuid
                                                                                        }
                                                                                    ]
                                                                                }

                                                                                    setFieldValue('members', _members);
                                                                                }
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="d-flex flex-row">
                                                                        <UserItemLine item={contact}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                    </SimpleBar>
                                                </>
                                                :
                                                <Loader/>
                                            }
                                            {errors.members && touched.members ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.members)}
                                                </div>
                                            ) :  touched.members && !errors.members ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched['priority'] ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-priority-input">{t('common:priority')}</Label>
                                                <InputSelect
                                                    items={priorities}
                                                    placeholder={t('common:choosePriority')}
                                                    onChange={(value: any)=>{
                                                        setFieldValue('tier',value.value);
                                                        }
                                                    }
                                                    isLoading={false}
                                                    required={false}
                                                    value={
                                                        getFieldProps('tier').value
                                                    }
                                                    readonly={false}
                                                    className={'position-relative'}
                                                />
                                        </div>
                                    </Row>
                                    {task?.uuid !== undefined && task.uuid !== null && task.uuid.length>0 &&
                                    <Row>
                                        <div className={ "mb-3"+ (touched['status'] ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-status-input">{t('common:status')}</Label>
                                                <InputSelect
                                                    items={statuses}
                                                    placeholder={t('common:chooseStatus')}
                                                    onChange={(value: any)=>{
                                                        setFieldValue('status',value.value);
                                                        }
                                                    }
                                                    isLoading={false}
                                                    required={false}
                                                    value={
                                                        getFieldProps('status').value
                                                    }
                                                    readonly={false}
                                                    className={'position-relative'}
                                                />
                                        </div>
                                    </Row>
                                    }

                                    <Row>
                                        <div className={ "mb-3 position-relative"+ (touched['deadLine'] ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-deadLine-input">{t('task:deadLine')}</Label>
                                            <Field
                                                id="form-deadLine-input"
                                                name="deadLine"
                                                component={InputDateTimePicker}
                                                value={getFieldProps('deadLine').value}
                                                onChange={(value: number) => {
                                                    setFieldValue('deadLine', value);
                                                }}
                                                minDate={'today'}
                                                className={errors['deadLine'] && touched['deadLine'] && 'form-control is-invalid'}
                                            />
                                            {errors['deadLine'] && touched['deadLine'] ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors['deadLine'])}
                                                </div>
                                            ) :  touched['deadLine'] && !errors['deadLine'] ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.completionPercent ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-completionPercent-input">{t('task:completionPercent')}</Label>
                                            <Field
                                                id="form-completionPercent-input"
                                                name="completePercent"
                                                placeholder={t('task:enterCompletionPercent')}
                                                component={InputText}
                                                value = {getFieldProps('completionPercent').value}
                                                onChange={(name:string) => {
                                                    setFieldValue('completionPercent', name);
                                                }}
                                                pattern={"[0-9]"}
                                                className={errors.completionPercent && touched.completionPercent && 'form-control is-invalid'}
                                            />
                                            {errors.completionPercent && touched.completionPercent ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.completionPercent)}
                                                </div>
                                            ) :  touched.completionPercent && !errors.completionPercent ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.text ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-text-input">{t('common:description')}</Label>
                                    <div className="textAreaEmojiBlock">
                                        <Field
                                            id="form-text-input"
                                            tabIndex={2}
                                            name="text"
                                            component={InputEmojiArea}
                                            isShowEmoji={true}
                                            value = {getFieldProps('text').value}
                                            onChange={(text:string) => {
                                                setFieldValue('text', text);
                                            }}
                                            required={false}
                                            placeholder={t('common:enterDescription')}
                                        />
                                        {errors.text && touched.text ? (
                                            <div className="invalid-feedback">
                                                {t('validator:'+errors.text)}
                                            </div>
                                        ) :  touched.text && !errors.text ?
                                            <div className="valid-feedback">
                                                {t('common:looksGood')}
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </Row>

                                    <Row>
                                        <div className="mb-3">
                                            <Field
                                                id={"form-attachmentFiles-input"}
                                                name={'media'}
                                                placeholder={t('task:filePlaceholder')}
                                                isMultiple={true}
                                                component={InputFile}
                                                value={getFieldProps('media').value}
                                                onChange={(value: MainFileType[]) => {
                                                    setFieldValue('media', value);
                                                }}
                                                required={false}
                                            />
                                        </div>
                                    </Row>

                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;