import React, {useCallback, useEffect, useState} from "react";
import { useParams } from 'react-router';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    Card,
    CardBody,
    Col,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Progress
} from 'reactstrap';
import {TaskType, TaskStatus, TaskPriority} from "../../../redux/Task/Task/types";
import {useRedux} from "../../../hooks";
import classnames from 'classnames';
import {
    getTaskDetail,
    hideRewardModalTask,
    onSendTaskReward,
    showRewardTask,
    getTaskComments,
    sendTaskComment,
    removeTaskComment,
    showTaskCommentRemoveModal,
    hideTaskCommentRemoveModal,
    sendTaskTime,
    getTaskTimes,
    showTaskTimeRemoveModal,
    removeTaskTime,
    hideTaskTimeRemoveModal,
} from "../../../redux/Task/Task/actions";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import Loader from "../../../components/Loader";
import {MainFileType} from "../../../redux/Main/File/types";
import FileItem from "./fileItem";

import {default as RemoveCommentModal} from "../../../components/RemoveForm";
import {default as RemoveTaskTimeModal} from "../../../components/RemoveForm";
import BackButton from "../../../components/BackButton";
import {CommentType} from "../../../redux/Main/Comment/types";
import ListItem from "../../Main/Comment/ListItem";
import CommentForm from "../../Main/Comment/CommentForm";
import {NewCommentItem} from "../../../redux/Main/Comment/reducer";
import {TaskMemberType, TaskMemberTypeType} from "../../../redux/Task/TaskMember/types";
import {MainUserType} from "../../../redux/Main/User/types";
import UserItemLine from "../../Main/User/ListItem";
import {convertToMomentLocale, formatNum, showDateRelative} from "../../../helpers/functions";
import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import TaskTimeForm from "../TaskTime/TaskTimeForm";
import {TaskTimeType} from "../../../redux/Task/TaskTime/types";
import {default as TaskTimeListItem} from "../TaskTime/ListItem";
import {handleTaskChangeStatus, handleTaskEdit} from "./TaskActions";
import TaskProgress from "./TaskProgress";
import {format} from "date-fns";

const TaskDetailIndex = () => {

    const { t, i18n } = useTranslation(['common']);

    const { uuid } = useParams();
    const { dispatch, useAppSelector } = useRedux();

    const {
        mainUser,
        isLoadingComments,
        isLoadingTaskTimes,
        taskState,
        isSendingComment,
        isSendingTaskTime
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
        isLoadingComments: state.Task.isLoadingComments,
        isLoadingTaskTimes: state.Task.isLoadingTaskTimes,
        taskState: state.Task,
        isSendingComment: state.Task.isSendingComment,
        isSendingTaskTime: state.Task.isSendingTaskTime
    }));

    const commentTab = 'commentTab';
    const timeTab = 'timeTab';

    const [taskAssigner, setTaskAssigner] = useState<MainUserType>(null);
    const [taskResponsibles, setTaskResponsibles] = useState<MainUserType[]>([]);
    const [task, setTask] = useState<TaskType>(null);
    const [comments, setComments] = useState<CommentType[]>([]);
    const [taskTimes, setTaskTimes] = useState<TaskTimeType[]>([]);
    const [editComment, setEditComment] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(commentTab);
    const [editTaskTime, setEditTaskTime] = useState<boolean>(false);
    const [totalTaskTimeEstimated, setTotalTaskTimeEstimated] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isNeedAccept, setNeedAccept] = useState<boolean>(false);
    const [isOverdue, setOverdue] = useState<boolean>(false);

    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        if (uuid !== taskState?.selectedItem?.uuid){
            setIsLoading(true);
        }
        dispatch(getTaskDetail({uuid: uuid}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, uuid]);

    useEffect(() => {
        if (taskState.isNeedReload ) {
            dispatch(getTaskDetail({uuid: uuid}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, taskState.isNeedReload]);

    useEffect(() => {
        if ((taskState.selectedItem !== undefined) && (taskState.selectedItem !== null) && (taskState.selectedItem.uuid !== undefined)){
            setTask({...taskState.selectedItem});

            let _taskAssigner = null;
            let _taskResponsibles = [];
            let _isNeedAccept = false;

            (taskState?.selectedItem?.members || []).filter((member:TaskMemberType)=>{
                return member.type === TaskMemberTypeType.TYPE_ASSIGNER}).map((member:TaskMemberType)=>{
                let contact = (taskState?.selectedItemMembers || []).filter((user:MainUserType)=>{ return user.uuid === member.memberUuid}).shift();

                if (contact?.uuid) {
                    _taskAssigner=contact;
                }
                return member;
            });

            (taskState?.selectedItem?.members || []).filter((member:TaskMemberType)=>{
                return member.type === TaskMemberTypeType.TYPE_RESPONSIBLE}).map((member:TaskMemberType)=>{
                let contact = (taskState?.selectedItemMembers || []).filter((user:MainUserType)=>{ return user.uuid === member.memberUuid}).shift();

                if (contact?.uuid) {
                    _taskResponsibles.push(contact);
                }
                return member;
            });

            if ((
                taskState.selectedItem.status === TaskStatus.PENDING.status
                ||
                taskState.selectedItem.status === TaskStatus.NEW.status
                ||
                taskState.selectedItem.status === TaskStatus.RETURNED.status
            ) && (mainUser?.currentUser?.uuid !== undefined)) {

                _taskResponsibles.map((contact:MainUserType)=>{
                    if (contact.uuid === mainUser?.currentUser?.uuid){
                        _isNeedAccept = true;
                    }
                    return contact;
                })
            }

            if (
                (taskState.selectedItem.status === TaskStatus.OVERDUE.status)
                ||
                (
                    taskState.selectedItem.deadLine !== undefined
                    &&
                    taskState.selectedItem.deadLine !== null
                    &&
                    new Date(taskState.selectedItem.deadLine)<new Date()
                )
            ){
                setOverdue(true);
            }

            setTaskAssigner(_taskAssigner);
            setTaskResponsibles(_taskResponsibles);
            setNeedAccept(_isNeedAccept);

        } else {
           setTask(null);
           setTaskAssigner(null);
           setTaskResponsibles([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskState?.selectedItem, mainUser?.currentUser?.uuid ])

    useEffect(() => {
        if (task?.uuid === uuid && uuid !== null) {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, task]);

    useEffect(() => {
        if (task?.uuid === uuid && uuid !== null && taskState.isNeedReloadComments) {
            dispatch(getTaskComments(task));
        }
    }, [dispatch, uuid, task, taskState?.isNeedReloadComments]);

    useEffect(() => {
        if (task?.uuid === uuid && uuid !== null && taskState.isNeedReloadTaskTimes) {
            dispatch(getTaskTimes(task));
        }
    }, [dispatch, uuid, task, taskState?.isNeedReloadTaskTimes]);

    useEffect(() => {
        if (taskState?.selectedItem?.taskTimesMeta?.totalMinutes !== undefined && taskState?.selectedItem?.taskTimesMeta?.totalMinutes !== 0){

            let _totalTaskTimeEstimated = [];

            let hours  = taskState.selectedItem.taskTimesMeta.totalMinutes / 60 | 0;
            let minutes  = taskState.selectedItem.taskTimesMeta.totalMinutes % 60 | 0;

            if (hours>0) {
                _totalTaskTimeEstimated.push(hours+' '+t('common:hrs'));
            }
            if (minutes>0) {
                _totalTaskTimeEstimated.push(minutes+' '+t('common:min'));
            }

            setTotalTaskTimeEstimated(_totalTaskTimeEstimated.join(" "));

        } else {
            setTotalTaskTimeEstimated(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskState?.selectedItem?.taskTimesMeta?.totalMinutes, t])



    useEffect(() => {
        if (taskState?.isSendingComment === false && editComment) {
           setEditComment(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, taskState?.isSendingComment]);

    useEffect(() => {
        if (taskState?.isSendingTaskTime === false && editTaskTime) {
            setEditTaskTime(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, taskState?.isSendingTaskTime]);

    useEffect(() => {

        if ((taskState?.selectedItem?.comments !== undefined) && (taskState?.selectedItem?.comments !== null)){
            setComments([...taskState?.selectedItem?.comments]);
        } else {
            setComments([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskState?.selectedItem?.comments, dispatch])

    useEffect(() => {

        if ((taskState?.selectedItem?.taskTimes !== undefined) && (taskState?.selectedItem?.taskTimes !== null)){
            setTaskTimes([...taskState?.selectedItem?.taskTimes]);
        } else {
            setTaskTimes([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskState?.selectedItem?.taskTimes, dispatch])

    const handleTaskCommentForm = () => {
        setEditComment(!editComment);
    }
    const handleTaskTimeForm = () => {
        setEditTaskTime(!editTaskTime);
    }
    const handlerTaskCommentRemoveModal = (item:CommentType) => {
        dispatch(showTaskCommentRemoveModal({data: item, removeCommentModalTitle: t('comment:deleteComment')}));
    }
    const handlerTaskTimeRemoveModal = (item:TaskTimeType) => {
        dispatch(showTaskTimeRemoveModal({data: item, removeTaskTimeModalTitle: t('task:deleteTaskTime')}));
    }

    const handlerTaskRewardModal = (item:TaskType) => {
//        dispatch(showRewardTask({data: item, modalEditTitle: t('task:rewardTitle')}));
    }
    const handlerTaskRewardCancel = (dispatch:any) =>{
//        dispatch(hideRewardModalTask());
    }

    const handlerTaskCommentRemoveCancel = (dispatch:any) =>{
        dispatch(hideTaskCommentRemoveModal());
    }
    const handlerTaskTimeRemoveCancel = (dispatch:any) =>{
        dispatch(hideTaskTimeRemoveModal());
    }

    const handlerTaskCommentRemove = (event:any, dispatch:any) =>{
        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(removeTaskComment({data: event, removeCommentModalTitle: t('comment:deleteComment')}));
        }
    }
    const handlerTaskTimeRemove = (event:any, dispatch:any) =>{
        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(removeTaskTime({data: event, removeTaskTimeModalTitle: t('task:deleteTaskTime')}));
        }
    }

    const handlerTaskScoreSend = (event:any, dispatch:any) =>{
        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(onSendTaskReward(event));

            setTimeout(() => {
                dispatch(getTaskDetail({uuid: uuid}));
            }, 1000);
        }
    }

    const handleReadMessages = () => {

        // let isNeedRead = false;
        //
        //
        //     (comments || []).map((item:CommentType)=>{
        //         if (item.isSupportAnswer && item.status === TaskCommentStatus.NEW.status){
        //             isNeedRead = true;
        //         }
        //         return item;
        //     })
        //
        //     if (isNeedRead){
        //         setTimeout(() => {
        //             dispatch(readTaskComments({uuid: uuid}));
        //
        //             setTimeout(() => {
        //                 dispatch(getTaskComments(taskState.selectedItem));
        //                 dispatch(getSupportUserBadges());
        //             }, 1000);
        //
        //         }, 1000);
        //     }

    }

    useEffect(()=>{
        // if (comments!== undefined && comments.length>0){
        //     setTimeout(() => {
        //         handleReadMessages();
        //     }, 1000);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[comments])

    return (
        <>
            {isLoading ?
                <>
                    <BreadCrumb title={t('menu:taskState')} pageTitle={t('menu:main')} />
                    <Loader/>
                </>
                :
                task !== null ?
                    <>
                        <Row>
            <Col lg={12}>
                <Card className="mt-n4 mx-n4 mb-n5">
                    <div className="bg-soft-warning">
                        <CardBody className="pb-4 mb-5">
                            <Row>
                                <div className="col-md">
                                    <Row className="align-items-center">
                                        <div className="col-md-auto">
                                            <div className="avatar-md mb-md-0 mb-4">
                                                <div className="avatar-title rounded-circle">
                                                    { taskAssigner !== null &&
                                                        <>
                                                        { taskAssigner?.avatar ?
                                                            <img src={taskAssigner.avatar} alt="" className="img-fluid p-1 rounded-circle" />
                                                            :
                                                            <span className="fs-23 text-uppercase">{taskAssigner?.name ? taskAssigner.name[0] : '#'}</span>
                                                        }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <h4 className="fw-semibold" id="task-title">{task.name}</h4>
                                            <div className="hstack gap-3 flex-wrap">
                                                <div className="text-muted"><i className="ri-at-line align-bottom me-1"/> <span id="task-client">
                                                    { taskAssigner !== null &&
                                                        <>
                                                            {taskAssigner?.email && taskAssigner.email}
                                                        </>
                                                    }
                                                    </span>
                                                </div>
                                                <div className="vr"></div>
                                                <div className="text-muted">
                                                    <i className="ri-hashtag align-bottom me-1"/> <span id="task-code">
                                                   {task.id}</span></div>
                                                <div className="vr"></div>
                                                <div className="text-muted">{t('task:createdAt')} : <span className="fw-medium" id="create-date">{
                                                    format(new Date(task.createdAt),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})}</span></div>
                                                {task.updatedAt &&
                                                    <>
                                                        <div className="vr"></div>
                                                            <div className="text-muted">{t('task:updatedAt')} : <span className="fw-medium" id="due-date">
                                                                {
                                                                    showDateRelative(new Date(task.updatedAt),convertToMomentLocale(i18n.language))
                                                                }
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                                <div className="vr"></div>
                                                {// eslint-disable-next-line
                                                    (Object.keys(TaskStatus) as Array<keyof typeof TaskStatus>).map((key) => {
                                                    if (TaskStatus[key].status===task.status){
                                                        return (
                                                            <span key={'tickerStatus_'+task.status} className={"badge rounded-pill fs-12 "+TaskStatus[key].class}> {t('task:taskStatus'+TaskStatus[key].label)} </span>
                                                        );
                                                    }
                                                })
                                                }
                                                {// eslint-disable-next-line
                                                    (Object.keys(TaskPriority) as Array<keyof typeof TaskPriority>).map((key) => {
                                                    if (TaskPriority[key].status===task.tier){
                                                        return (
                                                            <span key={'taskPriority_'+task.tier} className={"badge rounded-pill fs-12 "+TaskPriority[key].class}> {t('task:taskPriority'+TaskPriority[key].label)} </span>
                                                        );
                                                    }
                                                })
                                                }
                                            </div>
                                        </div>
                                    </Row>
                                </div>

                                {task.status !== TaskStatus.FINAL.status &&
                                    <div className="col-md-auto d-flex align-items-end me-2">
                                        <button type="button"
                                                onClick={()=>{handleTaskEdit(task,dispatch)}}
                                                className="btn btn-primary btn-label waves-effect waves-light me-2 mb-2"><i
                                            className="ri-pencil-fill label-icon align-middle fs-16 me-2"/>{t('common:edit')}
                                        </button>
                                        <button type="button"
                                                onClick={()=>{handleTaskChangeStatus(task, TaskStatus.FINAL.status, dispatch)}}
                                                className="btn btn-primary btn-label waves-effect waves-light mb-2"><i
                                            className="ri-close-fill label-icon align-middle fs-16 me-2"/>{t('task:closeTask')}
                                        </button>
                                    </div>
                                }
                                {task.status === TaskStatus.FINAL.status &&
                                    <div className="col-md-auto d-flex align-items-end me-2 ">
                                        <button type="button"
                                                onClick={()=>{handleTaskChangeStatus(task, TaskStatus.RETURNED.status, dispatch)}}
                                                className="btn btn-primary btn-label waves-effect waves-light mb-2"><i
                                            className="ri-pencil-fill label-icon align-middle fs-16 me-2"/>{t('common:resume')}
                                        </button>
                                    </div>
                                }
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
                        </Row>
                        <Row>

                            <Col xxl={9}>
                                <Card>
                                    <CardBody className="p-4">
                                        <h6 className="fw-bold text-uppercase mb-3">{t('common:description')}</h6>
                                        <div style={{whiteSpace: "pre-wrap"}}>{task.text}</div>

                                        {isNeedAccept &&
                                        <button type="button"
                                                onClick={()=>{handleTaskChangeStatus(task, TaskStatus.INPROGRESS.status, dispatch)}}
                                                className="btn btn-success btn-label waves-effect waves-light mt-3"><i
                                            className="ri-check-fill label-icon align-middle fs-16"/>{t('common:accept')}
                                        </button>
                                        }
                                    </CardBody>
                                </Card>
                                { task.media && task.media.length>0 &&
                                <Card>
                                    <CardHeader>
                                        <h6 className="card-title fw-semibold mb-0">{t('task:attachedFiles')}</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                        {(task.media || []).map((item:MainFileType, key:number)=>(
                                            <Col xxl={4} sm={4} key={'fileItem_'+key}>
                                                <FileItem file={item} />
                                            </Col>
                                        ))}
                                        </Row>
                                    </CardBody>
                                </Card>
                                }

                                <Card>
                                    <CardHeader>
                                        <div>
                                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({ active: activeTab === commentTab })}
                                                        onClick={() => { toggleTab(commentTab); }}
                                                    >
                                                        {t('comment:comments')} {task?.commentsMeta?.totalCount !== null && task?.commentsMeta?.totalCount !== 0 && <>({formatNum(task.commentsMeta.totalCount,0)})</>}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({ active: activeTab === timeTab })}
                                                        onClick={() => { toggleTab(timeTab); }}
                                                    >
                                                        {t('task:timeSpent')} {task?.taskTimesMeta?.totalCount !== null && task?.taskTimesMeta?.totalCount!==0 && <>(
                                                        {totalTaskTimeEstimated !== null && totalTaskTimeEstimated}
                                                        )</>}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={commentTab}>
                                                <div className="d-flex justify-content-end flex-row">
                                                    <div className="flex-shrink-0">
                                                        <button type="button"
                                                                onClick={handleTaskCommentForm}
                                                                className="btn btn-primary btn-label waves-effect waves-light"><i
                                                            className="ri-edit-box-fill label-icon align-middle fs-16 me-2"/> {t('common:Add')}
                                                        </button>
                                                    </div>
                                                </div>
                                                {(editComment || (comments || []).length>0 || (isLoadingComments && ((comments || []).length<1))) &&
                                                    <>
                                                        {editComment &&
                                                            <div className={"mb-4"}>
                                                                <CommentForm
                                                                    isSending={isSendingComment}
                                                                    item={{...NewCommentItem, task: task}}
                                                                    onSend={sendTaskComment}
                                                                    onClose={handleTaskCommentForm}
                                                                />
                                                            </div>
                                                        }
                                                        {isLoadingComments && ((comments || []).length<1) &&
                                                            <Loader/>
                                                        }
                                                        {(comments || []).map((item:CommentType)=>(
                                                            <ListItem
                                                                item={item}
                                                                isReward={false}
                                                                onEdit={sendTaskComment}
                                                                onRemove={handlerTaskCommentRemoveModal}
                                                                isSending={isSendingComment}
                                                                selectedComment={taskState.selectedComment}
                                                                key={'taskComment_'+item.uuid}/>
                                                        ))
                                                        }
                                                    </>
                                                }
                                            </TabPane>
                                            <TabPane tabId={timeTab}>

                                                <div className="d-flex justify-content-end flex-row mb-3">
                                                    <div className="flex-shrink-0">
                                                        <button type="button"
                                                                onClick={handleTaskTimeForm}
                                                                className="btn btn-primary btn-label waves-effect waves-light"><i
                                                            className="ri-edit-box-fill label-icon align-middle fs-16 me-2"/> {t('common:Add')}
                                                        </button>
                                                    </div>
                                                </div>
                                                {(editTaskTime || (taskTimes || []).length>0 || (isLoadingTaskTimes && ((taskTimes || []).length<1))) &&
                                                    <>
                                                        {editTaskTime &&
                                                            <div className={"mb-4"}>
                                                                <TaskTimeForm
                                                                    isSending={isSendingTaskTime}
                                                                    item={{...NewTaskItem, task: task}}
                                                                    onSend={sendTaskTime}
                                                                    onClose={handleTaskTimeForm}
                                                                />
                                                            </div>
                                                        }
                                                        {isLoadingTaskTimes && ((taskTimes || []).length<1) &&
                                                            <Loader/>
                                                        }
                                                        {(taskTimes || []).map((item:TaskTimeType)=>(
                                                            <TaskTimeListItem
                                                                item={item}
                                                                onEdit={sendTaskTime}
                                                                onRemove={handlerTaskTimeRemoveModal}
                                                                key={'taskTime_'+item.uuid}/>
                                                        ))
                                                        }
                                                    </>
                                                }
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>

                                </Card>
                            </Col>
                            <Col xxl={3}>
                                {(task?.deadLine !== null
                                        ||
                                        (task?.completionPercent && task?.completionPercent !== undefined && task?.completionPercent)
                                    ) &&
                                <Card className={"position-relative"+(isOverdue?' ribbon-box right':'')}>
                                    <CardBody className="p-4">
                                        {isOverdue &&
                                        <span className="ribbon-three ribbon-three-danger"><span><div className="mw-0 text-truncate">
                                            {t('task:taskStatusOverDue')}</div></span></span>
                                        }
                                        {task?.deadLine !== null &&
                                            <>
                                            <h6 className="fw-bold text-uppercase mb-3">{t('task:deadLine')}</h6>
                                            <p className="mb-0">{
                                                format(new Date(task.deadLine),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
                                                }
                                            </p>
                                            </>
                                        }
                                        {task?.completionPercent && task?.completionPercent !== undefined && task?.completionPercent >0 &&
                                            <>
                                                <h6 className={"fw-bold text-uppercase mb-3"+(task?.deadLine !== null?" mt-4":"")}>{t('common:progress')}</h6>
                                                <TaskProgress
                                                    item={task}
                                                    isStriped={true}
                                                    isShowLabel={true}
                                                    className={"mt-4"}
                                                />
                                            </>
                                        }
                                    </CardBody>
                                </Card>
                                }
                                <Card>
                                    <CardBody className="p-4">
                                        <h6 className="fw-bold text-uppercase mb-3">{t('task:responsible')}</h6>
                                        {(taskResponsibles || []).map((contact: MainUserType)=>{
                                            return (
                                                <div key={"taskResponsible_"+contact.uuid} className={"mb-2"}>
                                                    <UserItemLine  item={contact}/>
                                                </div>
                                            )
                                        })}
                                    </CardBody>


                                    {/*<div className="progress bg-secondary-subtle rounded-0">*/}
                                    {/*    <div className="progress-bar bg-secondary" role="progressbar" style="width: 30%"*/}
                                    {/*         aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>*/}
                                    {/*</div>*/}
                                </Card>
                            </Col>
                        </Row>
                    </>
                    :
                    t('task:taskNotFound')
            }
            <Row>
                <Col xxl={9}>
                    <div className="pb-4">
                        <BackButton name={t('task:allTasks')}/>
                    </div>
                </Col>
            </Row>

            <RemoveCommentModal
                onRemove={handlerTaskCommentRemove}
                handleClose={handlerTaskCommentRemoveCancel}
                removeItem={taskState.selectedComment}
                isOpen={taskState.removeCommentModalShow}
                modalTitle={taskState.removeCommentModalTitle}/>

            <RemoveTaskTimeModal
                onRemove={handlerTaskTimeRemove}
                handleClose={handlerTaskTimeRemoveCancel}
                removeItem={taskState.selectedTaskTime}
                isOpen={taskState.removeTaskTimeModalShow}
                modalTitle={taskState.removeTaskTimeModalTitle}/>


            {/*<RewardForm*/}
            {/*    isOpen={isRewardTaskShow}*/}
            {/*    handleClose={handlerTaskRewardCancel}*/}
            {/*    modalTitle={taskState.modalEditTitle}*/}
            {/*    onSend={handlerTaskScoreSend}*/}
            {/*    rewardItem={task}*/}
            {/*/>*/}
        </>
    );
};

export default TaskDetailIndex;


