import { gsap, Expo } from "gsap";
import $ from 'jquery';
import {MainCabinetType} from "../redux/Main/Cabinet/types";
import {config, m9sGlobals} from "../config";
import i18next from 'i18next';
import {defaultCurrency, LocaleCurrency} from "../redux/Sale/Currency/types";
import {CartPlanType} from "../redux/Sale/Plan/types";
import {
    logoAvito,
    logoBitrix, logoChatGPT, logoCSVGoogleFeed,
    logoFacebook, logoGoogleAnalytics, logoGoogleBard, logoGoogleMerchant, logoIdea,
    logoInstagram, logoLinkedIn,
    logoOk, logoOzon, logoPinterest,
    logoTelegram, logoTikTok, logoTwitter, logoViber,
    logoVk, logoWhatsApp, logoWordPress, logoYandexEn, logoYandexMetrika,
    logoYouTube, safariLogo
} from "../data/images";
import {MainSettingsState} from "../redux/Main/Layout/types";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../data/settings";
import {ContentPeerChannelType} from "../redux/Content/PeerChannel/types";
import {connectorHandlerCode} from "../constants/ConnectorHandler";
import {SaleCategoryType} from "../redux/Sale/Category/types";
import {PartnerCodeStorageType} from "../redux/Partner/types";
import Cookies from 'js-cookie';
import {SaleIntegrationHandlerCodes} from "../redux/Sale/IntegrationHandler/types";
import {MainMetaType} from "../redux/Main/common";
import {MenuItem} from "../layouts/LayoutMenuData";
import * as route from "../api/routes";
import {UserDeviceLocalStorageType} from "../redux/Main/Device/types";
import {MainFileImageTypes, MainFileType, MainFileVideoTypes} from "../redux/Main/File/types";
import { UTCDate } from "@date-fns/utc";
import {add, differenceInDays, format, formatRelative, Locale} from "date-fns";
import { ru, enGB, ka, uk } from 'date-fns/locale'

function hexToRGB(hex: string, alpha: number) {

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return {
        r: r,
        g: g,
        b: b,
        a: alpha
    };
}

function rgb2hex(rgb) {
    if (  rgb.search("rgb") === -1 ) {
        return rgb;
    } else {
        rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
        function hex(x) {
            return ("0" + parseInt(x).toString(16)).slice(-2);
        }
        return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
    }
}

const getLayoutMode = () => {
    return localStorage.getItem("layoutMode");
};

const setCalendarView = (value : string) => {
    return localStorage.setItem("calendarView",value);
};
const getCalendarView = () => {
    return localStorage.getItem("calendarView");
};

const setCalendarStartDate = (value) => {
    return localStorage.setItem("fcDefaultStartDate",value);
};
const getCalendarStartDate = () => {
    return localStorage.getItem("fcDefaultStartDate");
};

function showLoadingBar(options)
{
    let defaults = {
        pct: 0,
        delay: 1.3,
        wait: 0,
        before: function(data:any){},
        finish: function(data:any){},
        resetOnEnd: true
    };

    if(typeof options == 'object') {
        defaults = $.extend(defaults, options);
    } else if(typeof options == 'number') {
        defaults.pct = options;
    }

    if(defaults.pct > 100) {
        defaults.pct = 100;
    } else if(defaults.pct < 0) {
        defaults.pct = 0;
    }

    let $loading_bar = $(".mc-loading-bar");

    if($loading_bar.length === 0)
    {
        $loading_bar = $('<div class="mc-loading-bar progress-is-hidden"><span data-pct="0"></span></div>');
        $('body').append( $loading_bar );
    }

    let $pct = $loading_bar.find('span'),
        current_pct = $pct.data('pct'),
        is_regress = current_pct > defaults.pct;

    defaults.before(current_pct);

    gsap.to($pct, defaults.delay, {css: {width: defaults.pct + '%'}, delay: defaults.wait, ease: is_regress ? Expo.easeOut : Expo.easeIn,
        onStart: function()
        {
            $loading_bar.removeClass('progress-is-hidden');
        },
        onComplete: function()
        {
            let pct = $pct.data('pct');

            if(pct === 100 && defaults.resetOnEnd)
            {
                hideLoadingBar();
            }

            defaults.finish(pct);
        },
        onUpdate: function()
        {
            $pct.data('pct', parseInt($pct.get(0).style.width, 10));
        }});
}

function hideLoadingBar()
{
    let $loading_bar = $(".mc-loading-bar"),
        $pct = $loading_bar.find('span');

    $loading_bar.addClass('progress-is-hidden');
    $pct.width(0).data('pct', 0);
}

function removeTagsFromPostBody(body:string, tags: string)
{
    if (tags.length<1) {
        return body;
    }

    tags.split(/,/).map((tag:string) => {

        if (!tag.match(/^#/)) {
            tag = '#' + tag;
        }

        return body.replace(tag,'');
    });

    return body;
}

function getQueryVariable(variable:string)
{
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] === variable){return pair[1];}
    }
    return false;
}

const formatBytes = (bytes, decimals:number = 2) =>{
    if ((bytes === undefined) || (bytes === 0)) return "0b";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["b", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const formatNum = (number, decimals:number = 2) =>{
    if ((number === undefined) || (number === 0)) return 0;
    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["", "k", "M", "G", "T", "P", "E", "Z", "Y"];

    const i = Math.floor(Math.log(number) / Math.log(k));

    return [parseFloat((number / Math.pow(k, i)).toFixed(dm)),sizes[i]]
}

const getCurrentIdFromUrl = (numFromEnd?:number) =>{

    const currentPath = window.location.pathname;
    let item = null;

    if (currentPath && currentPath.length>0){

        if (numFromEnd !== undefined){
            const arPathElements = currentPath.split('/');
            item = arPathElements[arPathElements.length - numFromEnd];

        } else {
            item = (currentPath.split('/').pop());
        }

        if (!isNaN(parseInt(item)) && isFinite(parseInt(item))) {
            return item;
        }

    }

    return 0;
}

const getCookie = (name) => {
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
    });
}

const deleteCookie = (name) => {
    if (getCookie(name)) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

const getMessengerUserData = (messengerUser: any) =>{

    const imagePlaceholder = require("../resources/images/user/profile-placeholder.png");

    let fullName = "-";
    let avatar = imagePlaceholder;
    let handlerImage = undefined;

    if (messengerUser !== undefined) {
        if (messengerUser.platformUser !== undefined) {
            fullName = messengerUser.platformUser.name;

            if (messengerUser.platformUser?.avatar && messengerUser.platformUser?.avatar.length > 0) {
                avatar = messengerUser.platformUser.avatar;
            }
        } else if (messengerUser.contentUser !== undefined) {
            fullName = messengerUser.contentUser.name;
            handlerImage = getHandlerIconByCode(messengerUser.contentUser.handler.code);

            if (messengerUser.contentUser?.avatar && messengerUser.contentUser?.avatar.length > 0) {
                avatar = messengerUser.contentUser.avatar;
            }
        } else if (messengerUser.contentChannel !== undefined) {

            if (messengerUser.avatar !== undefined) {
                avatar = messengerUser.avatar;
                fullName = messengerUser.name;
            } else {
                fullName = messengerUser.contentChannel.name;
                if (messengerUser.contentChannel?.avatar && messengerUser.contentChannel?.avatar.length > 0) {
                    avatar = messengerUser.contentChannel.avatar;
                }
            }

            handlerImage = getHandlerIconByCode(messengerUser.handler.code);

        }
    }
    return {fullName, avatar, handlerImage}
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const setCurrentCabinet = (cabinet: MainCabinetType) => {
    if (cabinet.url!== undefined){
        m9sGlobals.CURRENT_CABINET = config.ID_API_URL+"/cabinet_"+cabinet.uuid;
        localStorage.setItem(config.LOCALSTORAGE_CURRENT_CABINET, JSON.stringify(cabinet));
    }
}
const getCurrentCabinet = () => {
    const currentCabinetJson = localStorage.getItem(config.LOCALSTORAGE_CURRENT_CABINET);

    try {
        let currentCabinet:MainCabinetType = JSON.parse(currentCabinetJson);

        if (currentCabinet.url !== undefined){
            m9sGlobals.CURRENT_CABINET = config.ID_API_URL+"/cabinet_"+currentCabinet.uuid;
        }

        return currentCabinet;
    } catch (e) {
        return false;
    }
}

const getCurrentLanguage = () => {

    let language = localStorage.getItem(config.LOCALSTORAGE_LANGUAGE);

    if ((!language) || (language.length<2)) {
        language = "en";

        setCurrentLanguage(language);
    }



    return language;
}

const setCurrentLanguage = (language: string) =>{

    if (language === undefined){
        language = 'en';
    }

    i18next.options.lng = language;
    localStorage.setItem(config.LOCALSTORAGE_LANGUAGE, language);
}

const getCurrentCurrencyCode = () =>{
    if (i18next.language in LocaleCurrency){
        return LocaleCurrency[i18next.language];
    }

    return defaultCurrency;
}

const addM9SCartProduct = (product: CartPlanType) =>{
    let cartJson = localStorage.getItem(config.LOCALSTORAGE_M9S_CART);
    let cart = [];
    let presentInCart = false;

    if ((cartJson !== undefined) && (cartJson!==null) && (cartJson.length>0)){
        cart = JSON.parse(cartJson);
        (cart || []).map((item:CartPlanType)=> {
            if (item.product.uuid === product.product.uuid) {
                presentInCart = true;
                item.quantity++;
            }
            return item
        });
    }

    if (!presentInCart) {
        cart.push(product);
    }
    localStorage.setItem(config.LOCALSTORAGE_M9S_CART, JSON.stringify(cart));

}

const clearM9SCartProduct = () =>{
    localStorage.removeItem(config.LOCALSTORAGE_M9S_CART);
}

const getM9SCartProduct = () =>{
    let cartJson = localStorage.getItem(config.LOCALSTORAGE_M9S_CART);
    if ((cartJson !== undefined) && (cartJson!==null) && (cartJson.length>0)){
        return JSON.parse(cartJson);
    }
}

const getHandlerIconByCode = (code: string) => {

    switch (code){
        case connectorHandlerCode.CONNECTOR_HANDLER_VK:
        case SaleIntegrationHandlerCodes.VK_SIMPLE:
            return logoVk.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_OK:
            return logoOk.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_AVITO:
            return logoAvito.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_BITRIX:
            return logoBitrix.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK:
            return logoFacebook.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_INSTAGRAM:
            return logoInstagram.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM:
        case 'telegrambot':
        case connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT:
            return logoTelegram.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_YOUTUBE:
            return logoYouTube.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_YANDEX_METRIKA:
            return logoYandexMetrika.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_GOOGLE_ANALYTICS:
            return logoGoogleAnalytics.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_LINKEDIN:
            return logoLinkedIn.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_PINTEREST:
            return logoPinterest.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_WHATSAPP:
            return logoWhatsApp.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_VIBER:
            return logoViber.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_OZON:
            return logoOzon.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_TWITTER:
            return logoTwitter.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_TIKTOK:
            return logoTikTok.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_CHAT_GPT:
            return logoChatGPT.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_GOOGLE_BARD:
            return logoGoogleBard.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_IDEAS:
            return logoIdea.default;
        case SaleIntegrationHandlerCodes.GOOGLE_FEED:
            return logoGoogleMerchant.default;
        case SaleIntegrationHandlerCodes.YANDEX_FEED:
            return logoYandexEn.default;
        case SaleIntegrationHandlerCodes.WIDGET_TELEGRAM:
            return logoTelegram.default;
        case SaleIntegrationHandlerCodes.WIDGET_WEB:
            return safariLogo.default;
        case SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED:
            return logoCSVGoogleFeed.default;
        case SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER:
        case SaleIntegrationHandlerCodes.FACEBOOK_WIDGET_WEB:
            return logoFacebook.default;
        case connectorHandlerCode.CONNECTOR_HANDLER_WORDPRESS:
            return logoWordPress.default;
    }

    return null;
}

function getEnumKeyByValue(enumObject: object, value: string) {
    const indexOfS = Object.values(enumObject).indexOf(value);
    return Object.keys(enumObject)[indexOfS];
}

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

const setCurrentSettings = (settings: MainSettingsState) =>{
    localStorage.setItem(config.LOCALSTORAGE_SETTINGS, JSON.stringify(settings));
}

const getCurrentSettings = () =>{
    let currentSettingsJson = localStorage.getItem(config.LOCALSTORAGE_SETTINGS);
    let currentSettings = null;

    if ((currentSettingsJson !== undefined) && (currentSettingsJson!==null) && (currentSettingsJson.length>0)){
        currentSettings = JSON.parse(currentSettingsJson);
    }

    return currentSettings;
}

const setParentPartner= (partnerCode: string) =>{

    let partnerMark : PartnerCodeStorageType= {
        partnerCode: partnerCode,
        expiresIn: add(new UTCDate(),{days: 30}).getTime() / 1000
    }

    localStorage.setItem(config.LOCALSTORAGE_PARTNER, JSON.stringify(partnerMark));
}

const getParentPartner = () =>{
    let partnerMarkJson = localStorage.getItem(config.LOCALSTORAGE_PARTNER);
    let partnerMark = null;

    if ((partnerMarkJson !== undefined) && (partnerMarkJson!==null) && (partnerMarkJson.length>0)){
        partnerMark = JSON.parse(partnerMarkJson);
        if (partnerMark?.expiresIn && partnerMark?.expiresIn>(new UTCDate().getTime()/1000)){
            return partnerMark;
        } else {
            localStorage.removeItem(config.LOCALSTORAGE_PARTNER);
        }
    } else {
        let partnerCodeCookie = Cookies.get(config.LOCALSTORAGE_PARTNER);

        if (partnerCodeCookie !== undefined && partnerCodeCookie !== null && partnerCodeCookie.length>0){
            return partnerCodeCookie;
        }
    }

    return null;
}

const capitalizeFirstLowercaseRest = str => {
    return (
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    );
};

const capitalizeFirstLetter = str => {
    return (
        str.charAt(0).toUpperCase() + str.slice(1)
    );
};


const setHistoryHash = (hash:HistoryHashType) => {
    window.location.hash = window.btoa(unescape(encodeURIComponent(JSON.stringify(hash))));
};

const getHistoryHash = () => {
    let hashString = window.location.hash.substring(1) // substring removes the initial "#"

    let decoded = decodeURIComponent(escape(window.atob(hashString)));

    if (decoded !== undefined && decoded !== null && decoded.length>3){
        return JSON.parse(decoded);
    }

    return null;
};

const saveCompareChannels = (data: ContentPeerChannelType[]) =>{

    let param = config.LOCALSTORAGE_COMPARE_CHANNELS;

    let currentCabinet = getCurrentCabinet();
    if (currentCabinet !== false && currentCabinet?.uuid && currentCabinet?.uuid !== null){
        param+='_'+currentCabinet.uuid;
    }


    if (data.length>0) {
        localStorage.setItem(param, JSON.stringify(data));
    } else {
        localStorage.removeItem(param);
    }

}

const getCompareChannels = () =>{

    let param = config.LOCALSTORAGE_COMPARE_CHANNELS;

    let currentCabinet = getCurrentCabinet();
    if (currentCabinet !== false && currentCabinet?.uuid && currentCabinet?.uuid !== null){
        param+='_'+currentCabinet.uuid;
    }

    let jsonCompareChannels = localStorage.getItem(param);

    if ((jsonCompareChannels !== undefined) && (jsonCompareChannels!==null) && (jsonCompareChannels.length>0)){
        return JSON.parse(jsonCompareChannels);
    }

    return [];
}

const makeSelectCategoryItem = (responseData:any, item: any, depthLevel : number) => {

    responseData.push({
        uuid: item.uuid,
        name: "-".repeat(depthLevel+1)+" "+item.name
    });

    if (item?.children && (item.children || []).length>0){
        item.children.map((children)=>{
            makeSelectCategoryItem(responseData, children, depthLevel+1);
            return children;
        })
    }
}
const prepareSelectCategories = (data:SaleCategoryType[]) => {
    return function (data, root) {
        let obj = {};
        data.forEach(i => {
            Object.assign(obj[i.uuid] = obj[i.uuid] || {}, i);

            const parent = i?.parentCategory?.uuid ? i.parentCategory.uuid : null;

            obj[parent] = obj[parent] || {};
            obj[parent].children = obj[parent].children || [];
            obj[parent].children.push(obj[i.uuid]);
        });
        return obj[root].children;
    }(data, null);
}

const createPaginationQuery = (meta: MainMetaType) => {

    const searchParams = new URLSearchParams();

    if (meta?.maxResults && meta.maxResults>0){
        searchParams.append("maxResults", meta.maxResults.toString());
    } else {
        searchParams.append("maxResults", PAGINATION_DEFAULT_ITEMS_PER_PAGE.toString());
    }

    if (meta?.page && meta.page>0){
        searchParams.append("page", meta.page.toString());
    } else {
        searchParams.append("page", '1');
    }

    if (meta?.sortField && meta.sortField.length>0){
        searchParams.append("sortField", meta.sortField);
    }

    if (meta?.sortDirection && meta.sortDirection.length>0){
        searchParams.append("sortDirection", meta.sortDirection);
    }

    if (meta?.status && meta.status.length>0){
        searchParams.append("status", meta.status);
    }
    if (meta?.tab && meta.tab.length>0){
        searchParams.append("tab", meta.tab);
    }
    if (meta?.searchPhrase && meta.searchPhrase.length>0){
        searchParams.append("searchPhrase", meta.searchPhrase);
    }
    if (meta?.cabinetUuid && meta.cabinetUuid.length>0){
        searchParams.append("cabinetUuid", meta.cabinetUuid);
    }

    return searchParams.toString(); // "maxResults=10&page=1"
}

function isHTML(str) {
    let a = document.createElement('div');
    a.innerHTML = str;

    for (let c = a.childNodes, i = c.length; i--; ) {
        if (c[i].nodeType === 1) return true;
    }

    return false;
}

function redirectToDefaultRoute(menuItem:string){

    switch (menuItem){
        case MenuItem.DASHBOARD_TASKS:
            window.location.href = route.MAIN_DASHBOARD_TASKS;
            break;
        case MenuItem.DASHBOARD_SALES:
            window.location.href = route.MAIN_DASHBOARD_SALES;
            break;
        default:
            window.location.href = route.MAIN_DASHBOARD_CONTENT_MARKETING;
    }

}

const getCancelledDevices = () =>{
    let cancelledDevicesJson = localStorage.getItem(config.LOCALSTORAGE_CANCELLED_DEVICES);
    let cancelledDevices = null;

    if ((cancelledDevicesJson !== undefined) && (cancelledDevicesJson!==null) && (cancelledDevicesJson.length>0)){
        cancelledDevices = JSON.parse(cancelledDevicesJson);
    }
    return cancelledDevices;
}

const addCancelledDevice = (data: UserDeviceLocalStorageType) =>{
    let cancelledDevices = getCancelledDevices();

    if (cancelledDevices === null){
        cancelledDevices = [];
    }
    if ((cancelledDevices).filter((item: UserDeviceLocalStorageType)=>{
        return item.userUuid===data.userUuid && item.deviceFingerPrint === data.deviceFingerPrint
    }).length<1){
        cancelledDevices.push(data);
    }
    saveCancelledDevice(cancelledDevices);
}

const removeCancelledDevice = (data: UserDeviceLocalStorageType) =>{
    let cancelledDevices = getCancelledDevices();

    if (cancelledDevices === null){
        cancelledDevices = [];
    }

    cancelledDevices = (cancelledDevices).filter((item: UserDeviceLocalStorageType)=>{
        return !(item.userUuid===data.userUuid && item.deviceFingerPrint === data.deviceFingerPrint)
    });

    saveCancelledDevice(cancelledDevices);
}

const saveCancelledDevice = (data: UserDeviceLocalStorageType[]) =>{
    if (data.length>0) {
        localStorage.setItem(config.LOCALSTORAGE_CANCELLED_DEVICES, JSON.stringify(data));
    } else {
        localStorage.removeItem(config.LOCALSTORAGE_CANCELLED_DEVICES);
    }
}

const getApprovedDeviceUsers = () =>{
    let approvedDeviceUsersStr = localStorage.getItem(config.LOCALSTORAGE_APPROVED_DEVICE_USERS);
    let approvedDeviceUsers = [];

    if ((approvedDeviceUsersStr !== undefined) && (approvedDeviceUsersStr!==null) && (approvedDeviceUsersStr.length>0)){
        approvedDeviceUsers = approvedDeviceUsersStr.split(',');
    }
    return approvedDeviceUsers;
}

const saveApprovedDeviceUsers = (data: any[]) =>{
    if (data.length>0) {
        localStorage.setItem(config.LOCALSTORAGE_APPROVED_DEVICE_USERS, data.join(','));
    } else {
        localStorage.removeItem(config.LOCALSTORAGE_APPROVED_DEVICE_USERS);
    }
}

const addApprovedDeviceUser = (userUuid: string) =>{
    let approvedDeviceUsers = getApprovedDeviceUsers();

    if (approvedDeviceUsers === null){
        approvedDeviceUsers = [];
    }
    if ((approvedDeviceUsers).filter((uuid: string)=>{
        return uuid===userUuid
    }).length<1){
        approvedDeviceUsers.push(userUuid);
    }
    saveApprovedDeviceUsers(approvedDeviceUsers);
}

const removeApprovedDeviceUser = (userUuid: string) =>{
    let approvedDeviceUsers = getApprovedDeviceUsers();

    if (approvedDeviceUsers === null){
        approvedDeviceUsers = [];
    }

    approvedDeviceUsers = (approvedDeviceUsers).filter((uuid: string)=>{
        return uuid!==userUuid
    });

    saveApprovedDeviceUsers(approvedDeviceUsers);
}

const saveIsNeedRefresh = () =>{
    localStorage.setItem(config.LOCALSTORAGE_IS_NEED_REFRESH, 'true');
}
const removeIsNeedRefresh = () =>{
    localStorage.removeItem(config.LOCALSTORAGE_IS_NEED_REFRESH);
}

const getIsNeedRefresh = () =>{
    let isNeedRefresh = localStorage.getItem(config.LOCALSTORAGE_IS_NEED_REFRESH);

    if (isNeedRefresh !== undefined && isNeedRefresh !== null && isNeedRefresh === 'true'){
        return true;
    }
    return false;
}

const isImage = (item:MainFileType):boolean => {
    if (
        (Object.values(MainFileImageTypes) as string[]).includes(item.contentType.split('/').pop())
        ||
        (Object.values(MainFileImageTypes) as string[]).includes(item.fileName.split('.').pop())
    ) {
        return true;
    }
    return false;
}

const isVideo = (item:MainFileType):boolean => {
    if (
        (Object.values(MainFileVideoTypes) as string[]).includes(item.contentType.split('/').pop())
        ||
        (Object.values(MainFileVideoTypes) as string[]).includes(item.fileName.split('.').pop())
    ) {
        return true;
    }
    return false;
}

const getHexColorByClass = (value:string) => {
    let elem = document.getElementsByClassName(value)[0];

    if (elem && elem !== null) {
        let color = window.getComputedStyle(elem, null).getPropertyValue("color");
        if (color !== undefined && color !== null){
            return rgb2hex(color);
        }
    }
    return null;
}
const getHexBackgroundColorByClass = (value:string) => {
    let elem = document.getElementsByClassName(value)[0];

    if (elem && elem !== null) {
        let color = window.getComputedStyle(elem, null).getPropertyValue("background-color");

        if (color !== undefined && color !== null){
            return rgb2hex(color);
        }
    }
    return null;
}

const textTruncate = (text: string, maxLength: number) =>
    text.length >= maxLength ? `${text.substring(0, maxLength)}...` : text;

function normalizeTag(tag: string): string {
    return (tag.startsWith('#')) ? tag : '#' + tag;
}

function convertToMomentLocale(lang: string): Locale {
    switch (lang){
        case 'ua':
            return uk;
        case 'ru':
            return ru;
        case 'ka':
            return ka;
        default:
            return enGB;
    }
}

function showDateRelative(date: Date, locale:any): string {

    const currentDate = new Date();
    const relativeDate = date;

    if (differenceInDays(currentDate,relativeDate)<8) {
        return formatRelative(relativeDate,currentDate,{locale: locale})
    }

    return format(relativeDate,'E, dd MMM yyyy, HH:mm', {locale: locale})
}

export {
    hexToRGB,
    rgb2hex,
    getLayoutMode,
    showLoadingBar,
    hideLoadingBar,
    setCalendarView,
    getCalendarView,
    setCalendarStartDate,
    getCalendarStartDate,
    removeTagsFromPostBody,
    getQueryVariable,
    formatBytes,
    formatNum,
    getCurrentIdFromUrl,
    getCookie,
    deleteCookie,
    getMessengerUserData,
    validateEmail,
    setCurrentCabinet,
    getCurrentCabinet,
    getCurrentLanguage,
    setCurrentLanguage,
    getCurrentCurrencyCode,
    addM9SCartProduct,
    clearM9SCartProduct,
    getM9SCartProduct,
    getHandlerIconByCode,
    getEnumKeyByValue,
    isNumeric,
    setCurrentSettings,
    getCurrentSettings,
    capitalizeFirstLowercaseRest,
    capitalizeFirstLetter,
    setHistoryHash,
    getHistoryHash,
    saveCompareChannels,
    getCompareChannels,
    prepareSelectCategories,
    makeSelectCategoryItem,
    setParentPartner,
    getParentPartner,
    createPaginationQuery,
    isHTML,
    redirectToDefaultRoute,
    getCancelledDevices,
    addCancelledDevice,
    removeCancelledDevice,
    saveCancelledDevice,
    getApprovedDeviceUsers,
    saveApprovedDeviceUsers,
    addApprovedDeviceUser,
    removeApprovedDeviceUser,
    saveIsNeedRefresh,
    getIsNeedRefresh,
    removeIsNeedRefresh,
    isImage,
    isVideo,
    getHexColorByClass,
    getHexBackgroundColorByClass,
    normalizeTag,
    textTruncate,
    convertToMomentLocale,
    showDateRelative
}