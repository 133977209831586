import React, {useEffect, useState} from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {TaskType, TaskPriority, TaskStatus} from "../../../redux/Task/Task/types";
import {MainUserType} from "../../../redux/Main/User/types";
import {TaskMemberType, TaskMemberTypeType} from "../../../redux/Task/TaskMember/types";
import UserItem from "../../Main/User/userItem";
import TaskProgress from "./TaskProgress";
import {convertToMomentLocale} from "../../../helpers/functions";
import {format} from "date-fns";

interface TableColumnsProps {
    members?: [] | Array<MainUserType>;
    onDetail: (item:TaskType) => void
}


const TaskColumnsSimple = ({members, onDetail}:TableColumnsProps) => {

    const { t, i18n } = useTranslation(['common']);

    return [
        {
            id: 'id',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:code')}</span>,
            maxWidth: '60px',
            width: '60px',
            sortable: false,
            cell: (row:TaskType) => {
                return row.id
            },
        },
        {
            id: 'status',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:status')}</span>,
            maxWidth: '70px',
            sortable: false,
            cell: (row:TaskType) => {
                let renderTaskStatus = null;
                for (let taskStatusKey in TaskStatus) {
                    if (TaskStatus[taskStatusKey].status===row.status){
                        renderTaskStatus = <span className={"badge rounded-pill fs-12 "+TaskStatus[taskStatusKey].class}> {t('task:taskStatus'+TaskStatus[taskStatusKey].label)} </span>;
                    }
                }
                if (renderTaskStatus === null) {
                    renderTaskStatus =
                        <span className="badge rounded-pill fs-12 bg-info"> {row.status} </span>;
                }
                return <> {renderTaskStatus}</>;
            },
        },
        {
            id: 'assigner',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:assigner')}</span>,
            maxWidth: '50px',
            sortable: false,
            cell: (row:TaskType) => {

                let resp = [];

                row.members.filter((member:TaskMemberType)=>{ return member.type === TaskMemberTypeType.TYPE_ASSIGNER}).map((member:TaskMemberType)=>{
                    let contact = (members || []).filter((user:MainUserType)=>{ return user.uuid === member.memberUuid}).shift();

                    if (contact?.uuid) {
                        resp.push(contact);
                    }
                    return member;
                })

                return <div className="d-flex flex-row flex-wrap avatar-group">{(resp || []).map((contact:MainUserType)=>{ return (<UserItem item={contact} key={"taskAssigner_"+row.uuid+'_'+contact.uuid}/>)})}</div>
            },
        },
        {
            id: 'responsible',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:resp')}</span>,
            sortable: false,
            cell: (row:TaskType) => {

                let resp = [];

                 row.members.filter((member:TaskMemberType)=>{ return member.type === TaskMemberTypeType.TYPE_RESPONSIBLE}).map((member:TaskMemberType)=>{
                    let contact = (members || []).filter((user:MainUserType)=>{ return user.uuid === member.memberUuid}).shift();

                    if (contact?.uuid) {
                        resp.push(contact);
                    }
                    return member;
                })

                return <div className="d-flex flex-row flex-wrap avatar-group">{(resp || []).map((contact:MainUserType)=>{ return (<UserItem item={contact} key={"taskResponsible_"+row.uuid+'_'+contact.uuid}/>)})}</div>
            },
        },
        {
            id: 'priority',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('common:priority')}</span>,
            maxWidth: '70px',
            sortable: false,
            cell: (row:TaskType) => {
                let renderTaskPriority = null;
                for (let taskPriorityKey in TaskPriority) {
                    if (TaskPriority[taskPriorityKey].status===row.tier){
                        renderTaskPriority = <span className="d-flex flex-row align-items-center text-truncate"><span className={"badge fs-8 me-2 "+TaskPriority[taskPriorityKey].class}>&nbsp;</span> {t('task:taskPriority'+TaskPriority[taskPriorityKey].label)} </span>;
                    }
                }

                if (renderTaskPriority === null) {
                    renderTaskPriority =
                        <span className="d-flex flex-row align-items-center text-truncate"><span className="badge rounded-pill fs-8 me-2 bg-info"></span> {row.tier} </span>;
                }

                return <div > {renderTaskPriority} </div>;
            },
        },
        {
            id: 'name',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:taskSubject')}</span>,
            sortable: false,
            cell: (row:TaskType) => {
                let taskBadge = null;
                if (row.unread !== undefined && row.unread !== null && row.unread >0){
                    taskBadge = <span className={"badge rounded-pill fs-10 bg-primary me-1"}>{row.unread}</span>
                }

                let isTaskTextPresent = row?.text !== undefined && row?.text !== null && row?.text?.length>0;

                return <div className="pt-2 pb-2" onClick={() => onDetail(row)}><h6 className={!isTaskTextPresent?'mb-0':''}>{taskBadge && taskBadge}{row.name}</h6>{isTaskTextPresent && <span className="text-truncate text-muted" style={{whiteSpace: "pre-wrap"}}>{row.text.substring(0, 50)}{row.text.length >= 50 && '...'}</span>}</div>;
            },
        },
        {
            id: 'deadLine',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:deadLine')}</span>,
            maxWidth: '200px',
            sortable: false,
            cell: (row:TaskType) => {

                let deadLine = null;
                if (row.deadLine !== undefined && row.deadLine  !== null) {
                    deadLine = <div className="text-truncate"> {
                        format(new Date(row.deadLine),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
                        } </div>;
                }
                let progressBar = null;
                if (row?.completionPercent && row?.completionPercent !== undefined && row?.completionPercent >0){
                    progressBar =
                    <TaskProgress
                        item={row}
                        isShowInLineLabel={true}
                        className={"mt-1 mb-1"}
                    />
                }
                return <div className={"d-flex flex-column flex-grow-1"}>{deadLine ? deadLine : ''} {progressBar && progressBar}</div>
            },
        },
    ];
}

export default TaskColumnsSimple;