import React, { useEffect, useState, useCallback, useRef } from "react";
import Board from 'react-trello'
import { Row, Col } from 'reactstrap';
import FlatpickrLanguages from "flatpickr/dist/l10n";
import Flatpickr from "react-flatpickr";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    PeerActivityRequestPayload,
    PeerActivityType
} from "../../../redux/Content/PeerActivity/types";
import {useRedux} from "../../../hooks";
import {getContentConnectors} from "../../../redux/Content/Connector/actions";
import {getPeerActivities, onUpdatePeerActivityItems} from "../../../redux/Content/PeerActivity/actions";
import PeerActivityCardViewItem from "./Card";
import PeerActivityAddCardLink from "./AddCardLink";
import PeerActivityLaneHeader from "./LaneHeader";
import {HistoryHashType} from "../../../data/settings";
import {convertToMomentLocale, getHistoryHash, setHistoryHash} from "../../../helpers/functions";
import {KanBanDataLanesType, KanBanLaneType} from "../../../redux/Main/KanBan/lane";
import {PeerActivityStatus} from "../../../redux/Content/PeerActivityStatus/types";
import {UTCDate} from "@date-fns/utc";
import {add, format} from "date-fns";
import {formatISO} from "date-fns/formatISO";

interface InputProps {
    handleNewActivity: (eventDate?:number, mode?:string) => void
}


const makeLane = (day, cards, currentDate, dispatch) => {

    const date = new Date(day*1000);

    return {
        id: format(date,'yyyy-MM-dd'),
        title: format(date,'MMM d, yyyy'),
        label: cards.length + "",
        cards: (cards || []).map((item: PeerActivityType) => {
            return {
                ...item,
                id: item.uuid,
                dispatch: dispatch,
                laneId: format(date,'yyyy-MM-dd'),
                draggable: (item.status === PeerActivityStatus.STATUS_NEW ||
                    item.status === PeerActivityStatus.STATUS_READY ||
                    item.status === PeerActivityStatus.STATUS_ERROR)
            }
        }),
        laneDraggable: false,
        hideCardDeleteIcon: true,
        cardDraggable: day<currentDate
    };
}
const KanBanActivities = ({handleNewActivity}:InputProps) => {

    const { t,i18n } = useTranslation();
    const { dispatch, useAppSelector } = useRedux();
    const [activities, setActivities] = useState<PeerActivityType[]>([]);
    const [boardLanes, setBoardLanes] = useState<KanBanDataLanesType>({lanes:[]});

    const dateSelectRef = useRef(null);
    const componentType = 'kanbanPeerActivities';
    const [eventBus, setEventBus] = useState(undefined);
    const [stateHistoryHash, setStateHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [peerActivityRequestPayload, setPeerActivityRequestPayload] = useState<PeerActivityRequestPayload>({
        periodFrom: new UTCDate().setHours(0,0,0,0)/1000,
        periodTo: add(new UTCDate().setHours(0,0,0,0),{days: 7}).getTime()/1000
    });

    useEffect(() => {
        if (stateHistoryHash.type === componentType){
            let tempPeerActivityPayload: PeerActivityRequestPayload=peerActivityRequestPayload;
            let isPayloadModified = false;
            if (stateHistoryHash.periodFrom !== undefined){
                tempPeerActivityPayload.periodFrom =
                    new Date(stateHistoryHash.periodFrom).getTime()/1000;
                isPayloadModified = true;
            }
            if (stateHistoryHash.periodTo !== undefined){
                tempPeerActivityPayload.periodTo =
                    new Date(stateHistoryHash.periodTo).getTime()/1000;

                isPayloadModified = true;
            }
            if (isPayloadModified) {
                setPeerActivityRequestPayload(tempPeerActivityPayload);
            }
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    },[stateHistoryHash]);

    useEffect(() => {

        if (peerActivityRequestPayload !== undefined && peerActivityRequestPayload.periodFrom !== undefined && peerActivityRequestPayload.periodTo !== undefined) {
            dispatch(getPeerActivities(peerActivityRequestPayload));
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivityRequestPayload,dispatch]);

    const {
        peerActivity,
        contentActivitiesFilter
    } = useAppSelector(state => ({
        peerActivity: state.PeerActivity,
        contentActivitiesFilter: state.ContentActivitiesFilter,
    }));

    useEffect(() => {
        dispatch(getContentConnectors());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setStateHistoryHash(browserHistoryHash)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();

        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (peerActivity.items !== undefined) {
            filterActivities()
        } else {
            setActivities([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivity?.items, t]);

    useEffect(() => {
        makeBoardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activities]);


    const makeBoardData = () => {

        if (peerActivityRequestPayload !== undefined) {
            const currentDate = new Date().setHours(0,0,0,0)/1000;
            let day =
                new Date(peerActivityRequestPayload.periodFrom*1000).setHours(0,0,0,0)/1000;
            let lanes = [];
            let lanesCount = 1;

            while (day <=  new Date(peerActivityRequestPayload.periodTo*1000).getTime()/1000) {

                let cards = (activities || []).filter((item: PeerActivityType, day) => {
                    return (day <=
                            new UTCDate(item.activeFrom).getTime() / 1000)
                        &&
                        (
                            add(new UTCDate(day * 1000), {days: 1}).getTime() > new UTCDate(item.activeFrom).getTime()
                        )
                });

                let lane:KanBanLaneType=makeLane(day,cards,currentDate, dispatch);
                lanes.push(lane);

                lanesCount++;

                day = add(new Date(day*1000),{days: 1}).getTime()/1000;
            }

            const newData: KanBanDataLanesType = {lanes: lanes}

            setBoardLanes({...newData});
        }
    }

    const handleCardAdd = ({...props})=>{

        const boardLane = (boardLanes.lanes || []).filter((item:KanBanLaneType)=>{
            return item.id === props.laneId
        }).pop();

        return (<PeerActivityAddCardLink laneId={props.laneId} onClick={handleNewActivity} t={props.t} boardLane={boardLane}/>)
    }

    const componentsBoard = {
        LaneHeader: PeerActivityLaneHeader,
        Card: PeerActivityCardViewItem,
        AddCardLink: handleCardAdd
    }

    const handlerDateRangeChange = (selectedDates, dateStr, instance) => {

        if (selectedDates.length===2) {

            const browserHistoryHash: HistoryHashType = {
                periodFrom: new UTCDate(selectedDates[0]).getTime()/1000,
                periodTo: new UTCDate(selectedDates[1]).getTime()/1000,
                type: componentType
            }

            setHistoryHash(browserHistoryHash);

            setPeerActivityRequestPayload({
                periodFrom: browserHistoryHash.periodFrom,
                periodTo: browserHistoryHash.periodTo,
            });

        }
    }

    const filterActivities = () => {
        let filteredActivities = peerActivity.items;

        if (contentActivitiesFilter.modes !== undefined && contentActivitiesFilter.modes.length>0){
            filteredActivities = (filteredActivities || []).filter((item:PeerActivityType)=>{

                if (item.mode && item.mode.uuid.length>0){
                    return contentActivitiesFilter.modes.indexOf(item.mode.uuid) === -1
                }
                return true;

            })
        }
        if (contentActivitiesFilter.types !== undefined && contentActivitiesFilter.types.length>0){
            filteredActivities = (filteredActivities || []).filter((item:PeerActivityType)=>{

                if (item.type && item.type.uuid.length>0){
                    return contentActivitiesFilter.types.indexOf(item.type.uuid) === -1
                }
                return true;

            })
        }
        if (contentActivitiesFilter.channels !== undefined && contentActivitiesFilter.channels.length>0){
            filteredActivities = (filteredActivities || []).filter((item:PeerActivityType)=>{

                if (item.channel && item.channel.uuid.length >0){
                    return contentActivitiesFilter.channels.indexOf(item.channel.uuid) === -1
                }
                return true;

            })
        }
        if (contentActivitiesFilter.projects !== undefined && contentActivitiesFilter.projects.length>0){
            filteredActivities = (filteredActivities || []).filter((item:PeerActivityType)=>{

                if (item.channel && item.channel.project && item.channel.uuid.length>0){
                    return contentActivitiesFilter.projects.indexOf(item.channel.project.uuid) === -1
                }
                return true;

            })
        }


        if (filteredActivities !== undefined && filteredActivities.length>0) {
            setActivities([...filteredActivities]);
        } else {
            setActivities([]);
        }
    }

    useEffect(() => {

        filterActivities();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        contentActivitiesFilter?.projects,
        contentActivitiesFilter?.modes,
        contentActivitiesFilter?.types,
        contentActivitiesFilter?.channels,
    ]);

    const onDataChange = useCallback(( newData: any ) => {

        if (JSON.stringify(boardLanes) === JSON.stringify(newData))
        {
            return;
        }

        newData.lanes.forEach((lane : any) => {
            lane.label = `${lane.cards.length}`;
        });

        eventBus.publish({ type: "UPDATE_LANES", lanes: newData.lanes });
    },[boardLanes, eventBus]);

    useEffect(()=>{
        if (boardLanes !== undefined && boardLanes.lanes !== undefined && eventBus!== undefined) {
            eventBus.publish({type: "UPDATE_LANES", lanes: boardLanes.lanes});
        }
    },[boardLanes,eventBus]);

    const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {

        let newDate =
            add(new UTCDate(targetLaneId),{minutes: 30});

        let peerActivity: PeerActivityType = {
            ...cardDetails,
            activeFrom: formatISO(newDate),
            isCheckMedia: false
        };

        dispatch(onUpdatePeerActivityItems(peerActivity));
    }

    const handleDateSelectButton = () => {
        dateSelectRef.current.flatpickr.open();
    }

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-row rangePickerInputCont">
                            <div className="input-group">
                                <Flatpickr
                                    className="form-control border-0 dash-filter-picker shadow rangePickerInput"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        handlerDateRangeChange(selectedDates, dateStr, instance);
                                    }}
                                    ref={dateSelectRef}
                                    value ={[
                                        format(new Date(peerActivityRequestPayload.periodFrom*1000),'yyyy-MM-dd'),
                                        format(new Date(peerActivityRequestPayload.periodTo*1000),'yyyy-MM-dd')
                                    ]
                                    }
                                    options={{
                //                        altFormat: "F Y",
                                        dateFormat: "Y-m-d",
                                        inline: false,
                                        enableTime: false,
                                        altInput: true,
                                        allowInput: false,
                                        mode: 'range',
                                        defaultDate: [
                                            format(new Date(peerActivityRequestPayload.periodFrom*1000),'yyyy-MM-dd'),
                                            format(new Date(peerActivityRequestPayload.periodTo*1000),'yyyy-MM-dd')
                                            ],
                                        locale : {...FlatpickrLanguages[convertToMomentLocale(i18n.language).code]},
                                    }}
                                />
                                <a className="input-group-text bg-primary border-primary text-white"
                                    onClick={handleDateSelectButton}
                                ><i className="ri-calendar-2-line"/>
                                </a>
                            </div>
                        </div>
                        <div className="d-flex flex-row flex-grow-1"></div>
                </div>
                </Col>
            </Row>
            <div className="kanbanWrapper">
                <Board className="kanbanCont"
                       data={boardLanes}
                       components={componentsBoard}
                       editable={true}
                       onCardAdd={handleCardAdd}
                       handleDragEnd={handleDragEnd}
                       eventBusHandle={setEventBus}
                       onDataChange={onDataChange}
                       t={t}
                />
            </div>
        </>
    )
}

export default KanBanActivities;