import React from 'react';
import {useTranslation} from "react-i18next";

const TermsBlock = () => {
    const { t } = useTranslation(['auth']);

    return (
        <>
            <p className="mb-0">
                <a href="https://marketcells.io/license" target="_blank" rel="noreferrer"
                   className="fs-12 text-light opacity-50">{t('auth:termsOfUse')}</a>
                &nbsp; &nbsp;
                <a href="https://marketcells.io/policy" target="_blank" rel="noreferrer"
                   className="fs-12 text-light opacity-50">{t('auth:privacyPolicy')}</a>
            </p>
        </>
    );
};

export default TermsBlock;