import React, {useEffect, useState} from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {TaskType, TaskPriority, TaskStatus} from "../../../redux/Task/Task/types";
import {MainUserType} from "../../../redux/Main/User/types";
import {TaskMemberType, TaskMemberTypeType} from "../../../redux/Task/TaskMember/types";
import {useRedux} from "../../../hooks";
import UserItem from "../../Main/User/userItem";
import TaskProgress from "./TaskProgress";
import { convertToMomentLocale } from "../../../helpers/functions";
import {format} from "date-fns";


const TaskColumns = ({ onDetail, onEdit, onCopy, onRemove }:EntityActionsHandlersType) => {

    const { t, i18n } = useTranslation(['common']);
    const [members, setMembers] = useState<MainUserType[]>([]);

    const { dispatch, useAppSelector } = useRedux();

    const {
        taskState,
        isLoading,
    } = useAppSelector(state => ({
        taskState: state.Task,
        isLoading: state.Task.isLoading,
    }));

    useEffect(() => {
        if ((taskState?.members || []).length>0) {
            setMembers(taskState.members);
        } else {
            setMembers([]);
        }

    }, [dispatch,taskState.members]);


    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            ignoreRowClick: true,
            selector: (cell:any) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-light btn-sm" tag="a">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" container="root">
                            <DropdownItem tag="a" className='edit-item-btn' onClick={() => onDetail(cell,dispatch)}><i
                                className="ri-eye-fill align-bottom me-2 text-muted"/> {t('task:taskDetails')} </DropdownItem>
                            { cell.actions && cell.actions.canEdit &&
                                <DropdownItem className='edit-item-btn' onClick={() => onEdit(cell,dispatch)}><i
                                    className="ri-pencil-fill align-bottom me-2 text-muted"/> {t('common:edit')} </DropdownItem>
                            }
                            { cell.actions && cell.actions.canEdit &&
                                <DropdownItem className='edit-item-btn' onClick={() => onCopy(cell,dispatch)}><i
                                    className="ri-file-copy-2-line align-bottom me-2 text-muted"/> {t('common:copy')} </DropdownItem>
                            }
                            {cell.actions && cell.actions.canRemove &&
                                <DropdownItem className='remove-item-btn' onClick={() => onRemove(cell,dispatch)}><i className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')} </DropdownItem>
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
        {
            id: 'id',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('sale:code')}</span>,
            maxWidth: '30px',
            sortable: true,
            cell: (row:TaskType) => {
                return <Link to="#"  onClick={()=>{onDetail(row)}} className="fw-medium link-primary">{row.id}</Link>
            },
        },
        {
            id: 'status',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:status')}</span>,
            maxWidth: '70px',
            cell: (row:TaskType) => {
                let renderTaskStatus = null;
                for (let taskStatusKey in TaskStatus) {
                    if (TaskStatus[taskStatusKey].status===row.status){
                        renderTaskStatus = <span className={"badge rounded-pill fs-12 "+TaskStatus[taskStatusKey].class}> {t('task:taskStatus'+TaskStatus[taskStatusKey].label)} </span>;
                    }
                }
                if (renderTaskStatus === null) {
                    renderTaskStatus =
                        <span className="badge rounded-pill fs-12 bg-info"> {row.status} </span>;
                }
                return <> {renderTaskStatus}</>;
            },
        },
        {
            id: 'assigner',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:assigner')}</span>,
            maxWidth: '50px',
            sortable: false,
            center: true,
            cell: (row:TaskType) => {

                let resp = [];

                row.members.filter((member:TaskMemberType)=>{ return member.type === TaskMemberTypeType.TYPE_ASSIGNER}).map((member:TaskMemberType)=>{
                    let contact = (members || []).filter((user:MainUserType)=>{ return user.uuid === member.memberUuid}).shift();

                    if (contact?.uuid) {
                        resp.push(contact);
                    }
                    return member;
                })

                return <div className="d-flex flex-row flex-wrap avatar-group">{(resp || []).map((contact:MainUserType)=>{ return (<UserItem item={contact} key={"taskAssigner_"+row.uuid+'_'+contact.uuid}/>)})}</div>
            },
        },
        {
            id: 'responsible',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:resp')}</span>,
            maxWidth: '50px',
            sortable: false,
            center: true,
            cell: (row:TaskType) => {

                let resp = [];

                 row.members.filter((member:TaskMemberType)=>{ return member.type === TaskMemberTypeType.TYPE_RESPONSIBLE}).map((member:TaskMemberType)=>{
                    let contact = (members || []).filter((user:MainUserType)=>{ return user.uuid === member.memberUuid}).shift();

                    if (contact?.uuid) {
                        resp.push(contact);
                    }
                    return member;
                })

                return <div className="d-flex flex-row flex-wrap avatar-group">{(resp || []).map((contact:MainUserType)=>{ return (<UserItem item={contact} key={"taskResponsible_"+row.uuid+'_'+contact.uuid}/>)})}</div>
            },
        },
        {
            id: 'priority',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('common:priority')}</span>,
            maxWidth: '70px',
            cell: (row:TaskType) => {
                let renderTaskPriority = null;
                for (let taskPriorityKey in TaskPriority) {
                    if (TaskPriority[taskPriorityKey].status===row.tier){
                        renderTaskPriority = <span className="d-flex flex-row align-items-center text-truncate"><span className={"badge fs-8 me-2 "+TaskPriority[taskPriorityKey].class}>&nbsp;</span> {t('task:taskPriority'+TaskPriority[taskPriorityKey].label)} </span>;
                    }
                }

                if (renderTaskPriority === null) {
                    renderTaskPriority =
                        <span className="d-flex flex-row align-items-center text-truncate"><span className="badge rounded-pill fs-8 me-2 bg-info"></span> {row.tier} </span>;
                }

                return <div > {renderTaskPriority} </div>;
            },
        },
        {
            id: 'name',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:taskSubject')}</span>,
            sortable: true,
            cell: (row:TaskType) => {
                let taskBadge = null;
                if (row.unread !== undefined && row.unread !== null && row.unread >0){
                    taskBadge = <span className={"badge rounded-pill fs-10 bg-primary me-1"}>{row.unread}</span>
                }

                let isTaskTextPresent = row?.text !== undefined && row?.text !== null && row?.text?.length>0;


                return <div className="pt-2 pb-2" onClick={() => onDetail(row)}><h6 className={!isTaskTextPresent?'mb-0':''}>{taskBadge && taskBadge}{row.name}</h6>{isTaskTextPresent && <span className="text-truncate text-muted" style={{whiteSpace: "pre-wrap"}}>{row.text.substring(0, 50)}{row.text.length >= 50 && '...'}</span>}</div>;
            },
        },
        // {
        //     name: <span className='font-weight-bold fs-14'>{t('task:createdAt')}</span>,
        //     maxWidth: '200px',
        //     sortable: true,
        //     selector: (row:TaskType) => {
        //         return moment(row.createdAt).format('E, dd MMM yyyy, HH:mm');
        //     },
        // },
        // {
        //     name: <span className='font-weight-bold fs-14'>{t('task:updatedAt')}</span>,
        //     maxWidth: '200px',
        //     sortable: true,
        //     selector: (row:TaskType) => {
        //         if (row.updatedAt !== undefined && row.updatedAt  !== null) {
        //             return moment(row.updatedAt).format('E, dd MMM yyyy, HH:mm');
        //         }
        //     },
        // },
        {
            id: 'deadLine',
            name: <span className='font-weight-bold fs-14 text-truncate'>{t('task:deadLine')}</span>,
            maxWidth: '200px',
            sortable: true,
            cell: (row:TaskType) => {

                let deadLine = null;
                if (row.deadLine !== undefined && row.deadLine  !== null) {
                    deadLine = <div className="text-truncate"> {
                        format(new Date(row.deadLine),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
                    } </div>;
                }
                let progressBar = null;
                if (row?.completionPercent && row?.completionPercent !== undefined && row?.completionPercent >0){
                    progressBar =
                    <TaskProgress
                        item={row}
                        isShowInLineLabel={true}
                        className={"mt-1 mb-1"}
                    />
                }
                return <div className={"d-flex flex-column flex-grow-1"}>{deadLine ? deadLine : ''} {progressBar && progressBar}</div>
            },
        },
    ];
}

export default TaskColumns;