import React, {useState} from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {EventNotificationStatus, EventNotificationType} from "../../../redux/Event/Notification/types";
import {convertToMomentLocale, showDateRelative} from "../../../helpers/functions";

interface ItemProps {
    item: EventNotificationType;
}
const ListItem = ({ item }: ItemProps) => {
    const { t, i18n } = useTranslation(['common']);
    return (

        <div className={"text-reset notification-item d-block dropdown-item position-relative"+(item.status === EventNotificationStatus.SEND?" notificationItemunread":"")} data-notification-id={item.uuid}>
            <div className="d-flex">
                <div className="avatar-xs me-3">
                    <span className={"avatar-title rounded-circle fs-16 text-bg-"+item.eventType.class}>
                        <i className={item.eventType.icon}/>
                    </span>
                </div>
                <div className="flex-1">
                    <a href={undefined} className="stretched-link keepopen">
                        <h6 className={"mt-0 mb-2 lh-basefw-semibold "+(item.status!==EventNotificationStatus.FINAL ? '' : ' text-muted')}>
                            {t('eventType:'+item.eventType.code)}
                        </h6>
                    </a>
                    <p className="mb-0 fs-11 fw-medium text-muted">
                        <span><i className="mdi mdi-clock-outline me-1"/>
                            {
                                showDateRelative(new Date(item.dateTime),convertToMomentLocale(i18n.language))
                            }
                            </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ListItem;
