//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import FullCalendar, { EventApi, DateSelectArg, EventClickArg, EventContentArg, formatDate } from '@fullcalendar/react'
import ruLocale from '@fullcalendar/core/locales/ru';
import enLocale from '@fullcalendar/core/locales/en-gb';
import uaLocale from '@fullcalendar/core/locales/uk';
import kaLocale from '@fullcalendar/core/locales/ka';
import trLocale from '@fullcalendar/core/locales/tr';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import bootstrapPlugin from "@fullcalendar/bootstrap";
import adaptivePlugin from '@fullcalendar/adaptive';
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';

import {useRedux} from "../hooks";
import {
    dontSetReloadAfterAction,
    getPeerActivities, onUpdatePeerActivityItems, setEditPeerActivityItems,
    setNeedReloadPeerActivities, setShowMoreChannels, showPeerActivityEditModal
} from "../redux/Content/PeerActivity/actions";
import {getContentConnectors} from "../redux/Content/Connector/actions";
import {OverlayTrigger, Tooltip, Popover} from "react-bootstrap";
import {Card, CardBody, Button, Col} from 'reactstrap';
import { BiCrown, BiStreetView } from "react-icons/bi";
import PopupItem from "../blocks/Content/PeerActivity/Popup";
import {
    convertToMomentLocale,
    setCalendarView,
    getCalendarView,
    setCalendarStartDate,
    getCalendarStartDate,
    getHandlerIconByCode, getEnumKeyByValue
} from "../helpers/functions";
// import {
//     onSendActivityPeerActivity,
//     selectContentActivity,
//     showRightModalContentActivity
// } from "../redux/Content/Activity/actions";
import {PeerActivityModeCode} from "../redux/Content/PeerActivityMode/types";
import {PeerActivityType} from "../redux/Content/PeerActivity/types";
import '../i18/config';
import {useTranslation} from "react-i18next";
import {PeerActivityStatus, PeerActivityStatusClass} from "../redux/Content/PeerActivityStatus/types";
import {formatISO} from "date-fns/formatISO";
import {UTCDate} from "@date-fns/utc";
import {format} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";

interface InputCalendarProps {
  id: string;
  handleNewActivity: (eventDate?:number, mode?:string) => void
  toggleIdeasBlock: () => void;
}

const Calendar = ({ id, handleNewActivity, toggleIdeasBlock }: InputCalendarProps) => {

    // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    //
    // console.log(timeZone);

    // const timezoneOffset = (new Date()).getTimezoneOffset();
    //
    // console.log(timezoneOffset);

    const { t, i18n } = useTranslation(['common']);

    let locale;

    switch (i18n.language){
        case 'ru':
            locale=ruLocale;
            break;
        case 'ua':
            locale=uaLocale;
            break;
        case 'ka':
            locale=kaLocale;
            break;
        case 'tr':
            locale=trLocale;
            break;
        default:
            locale=enLocale;
    }


    const { dispatch, useAppSelector } = useRedux();
    const calendar = useRef(null);
    const [activities, setActivities] = useState<PeerActivityType[]>([])
    const [isInitialized, setInitialized] = useState<boolean>(false)
    const [ peerActivityIdeas, setPeerActivityIdeas] = useState<PeerActivityType[]>([]);

    const {
        contentConnectors,
        peerActivity,
        contentActivitiesFilter,
        peerActivityIdea
    } = useAppSelector(state => ({
        contentConnectors: state.ContentConnector,
        peerActivity: state.PeerActivity,
        contentActivitiesFilter: state.ContentActivitiesFilter,
        peerActivityIdea: state.PeerActivityIdea
    }));

    useEffect(() => {
        dispatch(getContentConnectors());
    }, [dispatch]);

    useEffect(() => {

        if (getCalendarStartDate() && getCalendarStartDate().length>0) {
            calendar.current.getApi().gotoDate(
                formatISO(new Date(getCalendarStartDate()))
            );
        }

        fetchData(calendar.current.getApi().currentDataManager.state.dateProfile.activeRange);
        setInitialized(true);
        // eslint-disable-next-line
    },[]);

    useEffect(()=>{
        if ( peerActivityIdea?.items &&  peerActivityIdea.items.length>0){
            setPeerActivityIdeas([...peerActivityIdea.items]);
        } else {
            setPeerActivityIdeas([]);
        }
    },[
        peerActivityIdea.items,
    ])

    useEffect(()=>{

        if (peerActivity.isNeedReload){
            fetchData(calendar.current.getApi().currentDataManager.state.dateProfile.activeRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivity.isNeedReload]);

    const fetchData = (rangeInfo) =>{

        if (rangeInfo.view !== undefined && rangeInfo.view.type !== undefined) {
            setCalendarView(rangeInfo.view.type);
        }

        peerActivity.startDate = new UTCDate(rangeInfo.start).setHours(0,0,0,0);
        peerActivity.endDate = new UTCDate(rangeInfo.end).setHours(0,0,0,0);

        dispatch(getPeerActivities(peerActivity));
    }

    const handleDates = (rangeInfo) => {
        if (isInitialized) {
            if (rangeInfo.view !== undefined) {
                setCalendarStartDate(rangeInfo.view.currentStart);
            }
            fetchData(rangeInfo);
        }
    }

    useEffect(() => {

        if (peerActivity?.isNeedReload && peerActivity.isNeedReload === true){
            calendar.current.getApi().refetchEvents();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivity?.isNeedReload]);

    useEffect(() => {

        if (peerActivity.items !== undefined) {

            (peerActivity.items || []).map((item:PeerActivityType)=>{
                if ((item.end === undefined) ||  (item.end === null)){
                    if (new Date(item.activeFrom).getHours()===23){
                        item.end = formatISO(new Date(item.activeFrom).setMinutes(59))
                    }
                }
                return item;
            })

            setActivities(peerActivity.items);
        } else {
            setActivities([]);
        }

        filterActivities();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivity?.items]);

    const filterActivities = () => {
        let filteredActivities = peerActivity.items;

        if (contentActivitiesFilter.modes !== undefined && contentActivitiesFilter.modes.length>0){
            filteredActivities = (filteredActivities || []).filter((item:PeerActivityType)=>{

                if (item.mode && item.mode.uuid.length>0){
                    return contentActivitiesFilter.modes.indexOf(item.mode.uuid) === -1
                }
                return true;

            })
        }
        if (contentActivitiesFilter.types !== undefined && contentActivitiesFilter.types.length>0){
            filteredActivities = (filteredActivities || []).filter((item:PeerActivityType)=>{

                if (item.type && item.type.uuid.length>0){
                    return contentActivitiesFilter.types.indexOf(item.type.uuid) === -1
                }
                return true;

            })
        }
        if (contentActivitiesFilter.channels !== undefined && contentActivitiesFilter.channels.length>0){
            filteredActivities = (filteredActivities || []).filter((item:PeerActivityType)=>{

                if (item.channel && item.channel.uuid.length >0){
                    return contentActivitiesFilter.channels.indexOf(item.channel.uuid) === -1
                }
                return true;

            })
        }
        if (contentActivitiesFilter.projects !== undefined && contentActivitiesFilter.projects.length>0){
            filteredActivities = (filteredActivities || []).filter((item:PeerActivityType)=>{

                if (item.channel && item.channel.project && item.channel.uuid.length>0){
                    return contentActivitiesFilter.projects.indexOf(item.channel.project.uuid) === -1
                }
                return true;

            })
        }


        if (filteredActivities !== undefined && filteredActivities.length>0) {
            setActivities([...filteredActivities]);
        } else {
            setActivities([]);
        }
    }

    useEffect(() => {

        filterActivities();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        contentActivitiesFilter?.projects,
        contentActivitiesFilter?.modes,
        contentActivitiesFilter?.types,
        contentActivitiesFilter?.channels,
    ]);

    const handleEventDrop = (event:any) => {
        if ((event.event.extendedProps.uuid !== undefined) && (event.event.extendedProps.uuid.length > 0)) {

            let peerActivity: PeerActivityType = {
                ...event.event.extendedProps,
                activeFrom: formatISO(new UTCDate(event.event.startStr)),
                isCheckMedia: false
            };

            dispatch(dontSetReloadAfterAction());
            dispatch(onUpdatePeerActivityItems(peerActivity));
        }

        return false;
    }

    const handleDateClick = (event:any) => {

        let eventDate = new Date();
        let eventMode = PeerActivityModeCode.ACTIVITY;

        if (event.date > eventDate){
            eventDate = event.date;
        }

        if (event.jsEvent !== undefined){
            if (event.jsEvent.altKey === true) {
                eventMode = PeerActivityModeCode.BIO;
            } else if (event.jsEvent.shiftKey === true) {
                eventMode = PeerActivityModeCode.STORY;
            }
        }

       handleNewActivity(eventDate.getTime()/1000, eventMode);
    }

    const handleEventClick = (event:any) => {
        if (!event.jsEvent.target.className.match(/ri-external-link-line/)) {
            if ((event.event.extendedProps.uuid !== undefined) && (event.event.extendedProps.uuid.length > 0)) {

                let peerActivity: PeerActivityType = {
                    ...event.event.extendedProps
                };

                if (event.event.title && event.event.title !== 'null'){
                    peerActivity.title = event.event.title;
                }

                dispatch(setEditPeerActivityItems([peerActivity]));
                dispatch(showPeerActivityEditModal());
            }
        }

    }

    const renderEventContentOld = (eventInfo) =>
    {
        const peerActivity = {
            ...eventInfo.event.extendedProps
        }

        const popoverEvent = (
             <Popover id="popover-positioned-right"
                      title={eventInfo.event.extendedProps.name}
                      style={{ maxWidth: 400 }}>
                 <PopupItem item={peerActivity}/>
             </Popover>
        );

        return (
            <>
                <Card
                    className="profile-project-card shadow-none profile-project-success mb-0 d-flex flex-grow-1"
                    style={{borderLeftColor:eventInfo.event.extendedProps.type.color}}>
                    <CardBody className="p-1">
                        <div className="d-flex position-relative">
                            <div
                                className="flex-grow-1 text-muted overflow-hidden">
                                <div className="d-flex flex-shrink-0 flex-row mb-1">
                                    {eventInfo.event.extendedProps.handler.code ?
                                        <div className="avatar-xxs d-flex me-2 flex-shrink-0">
                                            <img src={getHandlerIconByCode(eventInfo.event.extendedProps.handler.code)} className="rounded-circle img-fluid"
                                            alt=""
                                            />
                                        </div> :
                                        <div className="avatar-xxs d-flex me-2 flex-shrink-0">
                                            <span className={"avatar-title rounded-circle"}>{eventInfo.event.extendedProps.handler.name[0]}</span>
                                        </div>
                                    }
                                    {eventInfo.event.extendedProps.channel &&
                                        <div className="flex-1 d-flex align-items-center">
                                            {eventInfo.event.extendedProps.channel.isManaged ?
                                                <BiCrown className="me-1 text-primary" title={t('channel:managedChannel')}/>
                                                :
                                                <BiStreetView className="me-1 text-primary" title={t('channel:competitorChannel')}/>
                                            }
                                            {eventInfo.event.extendedProps.channel.name}
                                        </div>
                                    }
                                </div>

                                <h5
                                    className="fs-14 text-truncate d-flex mb-2">
                                    {eventInfo.event.extendedProps.mode.code === PeerActivityModeCode.ACTIVITY &&
                                        <i className="text-info align-bottom ri-article-line fs-13 ms-1 me-1"/>
                                    }
                                    {eventInfo.event.extendedProps.mode.code === PeerActivityModeCode.STORY &&
                                        <i className="text-info align-bottom ri-star-smile-line fs-13 ms-1 me-1"/>
                                    }
                                    {eventInfo.event.extendedProps.mode.code === PeerActivityModeCode.BIO &&
                                        <i className="text-info align-bottom mdi mdi-bio fs-15 ms-1 me-1"/>
                                    }

                                    <span className="text-dark text-truncate">{eventInfo.event.extendedProps.text}</span>
                                </h5>
                            </div>
                            <div className="flex-shrink-0 ms-2 d-flex flex-column position-absolute top-0 end-0 fc-right-time-section">

                                <div className="d-flex flex-row flex-nowrap align-items-center ">
                                    <div className="me-1 fs-10 text-dark d-flex">{
                                        format(new Date(eventInfo.event.start*1000),'HH:mm', {locale: convertToMomentLocale(i18n.language)})}</div>

                                    {eventInfo.event.extendedProps.status &&
                                    <div className="avatar-xxxxs d-flex flex-shrink-0" style={{marginTop:'-2px'}}>
                                        <OverlayTrigger
                                            placement={'right'}
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip>
                                                    {t('peerActivityStatus:'+eventInfo.event.extendedProps.status)}
                                                </Tooltip>
                                            }
                                        >
                                        <span className={"statusIndicator rounded-circle d-flex flex-grow-1 bg-" + PeerActivityStatusClass[getEnumKeyByValue(PeerActivityStatus,eventInfo.event.extendedProps.status)]}/>
                                        </OverlayTrigger>
                                    </div>
                                    }
                                </div>
                                    <div className="d-flex d-inline-flex justify-content-end" onClick={e => e.stopPropagation()}>
                                    <OverlayTrigger
                                        trigger={["click"]}
                                        placement="right"
                                        rootClose
                                        overlay={popoverEvent}>
                                        <div className="d-flex flex-row justify-content-end"><i className="ri-external-link-line"/></div>
                                    </OverlayTrigger>
                                    </div>
                            </div>

                        </div>
                    </CardBody>
                </Card>


                {/*<div className="fc-event-title fc-sticky"></div>*/}
            </>
        )
    }

    const renderEventContent = (eventInfo) =>
    {
        const peerActivity = {
            ...eventInfo.event.extendedProps
        }

        const popoverEvent = (
            <Popover id="popover-positioned-right"
                     title={eventInfo.event.extendedProps.name}
                     style={{ maxWidth: 400 }}>
                <PopupItem item={peerActivity}/>
            </Popover>
        );

        return (
            <>
                <Card
                    className="profile-project-card shadow-none profile-project-success mb-0 d-flex flex-grow-1"
                    style={{borderLeftColor:eventInfo.event.extendedProps.type.color}}>
                    <CardBody className="p-1">
                        <div className="d-flex flex-row mw-0">
                            <div className="d-flex flex-shrink-0 flex-row align-items-center position-relative mw-0">
                                {eventInfo.event.extendedProps.handler.code ?
                                    <div className="avatar-xxxs d-flex me-1 flex-shrink-0">
                                        <img src={getHandlerIconByCode(eventInfo.event.extendedProps.handler.code)} className="rounded-circle img-fluid"
                                             alt=""
                                        />
                                    </div> :
                                    <div className="avatar-xxs d-flex me-1 flex-shrink-0">
                                        <span className={"avatar-title rounded-circle"}>{eventInfo.event.extendedProps.handler.name[0]}</span>
                                    </div>
                                }
                                {eventInfo.event.extendedProps.status &&
                                    <div className="statusIndicatorCont">
                                        <div className="avatar-xxxxs d-flex flex-shrink-0" style={{marginTop:'-2px'}}>
                                            <OverlayTrigger
                                                placement={'right'}
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip>
                                                        {t('peerActivityStatus:'+eventInfo.event.extendedProps.status)}
                                                    </Tooltip>
                                                }
                                            >
                                                <span className={"statusIndicator rounded-circle d-flex flex-grow-1 bg-" + PeerActivityStatusClass[getEnumKeyByValue(PeerActivityStatus,eventInfo.event.extendedProps.status)]}/>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="flex-shrink-0 calendarTimeBadge me-1">
                                <span className={"badge border border-primary text-primary"}>{
                                    format(new Date(eventInfo.event.start),'HH:mm', {locale: convertToMomentLocale(i18n.language)})}</span>
                            </div>
                            <div className="flex-shrink-0">
                                {eventInfo.event.extendedProps.mode.code === PeerActivityModeCode.ACTIVITY &&
                                    <i className="text-info align-bottom ri-article-line fs-13  me-1"/>
                                }
                                {eventInfo.event.extendedProps.mode.code === PeerActivityModeCode.STORY &&
                                    <i className="text-info align-bottom ri-star-smile-line fs-13 me-1"/>
                                }
                                {eventInfo.event.extendedProps.mode.code === PeerActivityModeCode.BIO &&
                                    <i className="text-info align-bottom mdi mdi-bio fs-15 me-1"/>
                                }
                            </div>

                                {eventInfo.event.extendedProps.channel &&
                                    <div className="flex-1 d-flex align-items-center text-truncate text-muted mw-0">
                                        {eventInfo.event.extendedProps.channel.name}
                                    </div>
                                }

                            <div className="d-flex d-inline-flex justify-content-end ms-1" onClick={e => e.stopPropagation()}>
                                <OverlayTrigger
                                    trigger={["click"]}
                                    placement="right"
                                    rootClose={true}
                                    overlay={popoverEvent}>
                                    <div className="d-flex flex-row justify-content-end"><i className="ri-external-link-line text-muted"/></div>
                                </OverlayTrigger>
                            </div>
                        </div>
                        {eventInfo.event.extendedProps?.text && eventInfo.event.extendedProps?.text.length>0 &&
                        <div className="d-flex flex-row mw-0 text-truncate">
                            <span className="text-truncate text-muted">
                            {eventInfo.event.extendedProps?.text}
                            </span>
                        </div>
                        }
                    </CardBody>
                </Card>
            </>
        )
    }

    const renderResourceLabelContent = (resourceInfo) =>
    {
        return (
            <>
                <OverlayTrigger
                    placement={'top'}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip>
                            {resourceInfo.resource.extendedProps.name}
                        </Tooltip>
                    }
                >
                <div className="d-flex flex-shrink-0 flex-column align-items-center">
                    {resourceInfo.resource.extendedProps.handler.code ?
                        <div className="avatar-xxs d-flex flex-shrink-0">
                            <img src={getHandlerIconByCode(resourceInfo.resource.extendedProps.handler.code)} className="rounded-circle img-fluid" alt=""/>
                        </div> :
                        <div className="avatar-xxs d-flex flex-shrink-0">
                            <span className={"avatar-title rounded-circle"}>{resourceInfo.resource.extendedProps.handler.name[0]}</span>
                        </div>
                    }
                    <div className="flex-1 justify-content-center d-flex text-muted">
                        #{resourceInfo.resource.uuid}
                    </div>
                </div>
                </OverlayTrigger>
            </>
        )
    }

    const handleExternalDrop = (eventInfo) => {

        let uuid = eventInfo.draggedEl.dataset.uuid;
        let date = eventInfo.dateStr;

        const ideaItem = (peerActivityIdeas || []).filter((item:PeerActivityType)=>{return item.uuid === uuid}).shift();

        if (ideaItem?.uuid && ideaItem.uuid !== null){
            ideaItem.activeFrom = formatISO(new Date());

            dispatch(setShowMoreChannels());
            dispatch(setEditPeerActivityItems([{...ideaItem}]));
            dispatch(showPeerActivityEditModal());
        }
    };

    const customButtons = {
        ideaButton: {
            text: <i className="mdi mdi-diamond-stone align-middle"/>,
            hint: t('menu:activitiesIdeas'),
            click: function() {
                toggleIdeasBlock();
            }
        }
    }

  return (
      <>
      <FullCalendar schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
          ref={calendar}
          plugins={[
              dayGridPlugin,
              adaptivePlugin,
              timeGridPlugin,
              resourceTimeGridPlugin,
              interactionPlugin,
              bootstrapPlugin
//              listPlugin
          ]}
          initialView= { getCalendarView() && getCalendarView().length>0 ? getCalendarView() :"dayGridMonth"}
//          titleFormat={'MMMM D, yyyy'}
          datesSet={handleDates}
          handleWindowResize={true}
          themeSystem="bootstrap"
          eventContent={renderEventContent}
          resourceLabelContent={renderResourceLabelContent}
          customButtons={customButtons}
          headerToolbar={{
              left: "ideaButton prev,next today",
              center: "title",
              right: "timeGridDay,timeGridWeek,dayGridMonth",
          }}
          events={activities || []}
          // resources={(contentConnectors.items || []).filter((item, key)=>
          //     (Object.values(ContentConnectorSocialCalendar) as string[]).includes(item.handler.type)
          // )}
          eventResourceEditable={false}
          eventDurationEditable={false}
          displayEventTime={true}
          nowIndicator={true}
          //eventTimeFormat={{hour: 'numeric', minute: '2-digit', timeZoneName: 'short'}}
          // eventTimeFormat = {{ // like '14:30:00'
          //     hour: '2-digit',
          //     minute: '2-digit',
          //     meridiem: false
          //   }
          // }
          eventStartEditable={true}
          scrollTimeReset={false}
          editable={false}
          droppable={true}
          //selectable={true}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
          locale={locale && locale}
          drop={handleExternalDrop}
      />
      </>
  );
};
export default Calendar;
