import React from "react";
import {PeerActivityModeCode} from "../../../redux/Content/PeerActivityMode/types";
import {KanBanLaneType} from "../../../redux/Main/KanBan/lane";
import {UTCDate} from "@date-fns/utc";

interface ItemProps {
    laneId: string;
    onClick: (eventDate?:number, mode?:string) => void;
    boardLane: KanBanLaneType;
    t: any
}

const PeerActivityAddCardLink = ({onClick, t, laneId, boardLane}:ItemProps) => {

    const currentDate = new UTCDate().setHours(0,0,0,0)/1000;
    const day = new UTCDate(laneId).getTime()/1000;
    const eventDate = new UTCDate(laneId).setHours(23,0,0,0)/1000;

    const count = boardLane.cards.length;

    const generateButton = (mode: PeerActivityModeCode, icon: string, btnClass: string = '') => (
        <button className={`btn btn-soft-info me-1 p-1 ${btnClass}`} onClick={()=>{onClick(eventDate, mode)}}>
            <i className="ri-add-line label-icon align-middle fs-16 me-1"/>
            <i className={`${icon} label-icon align-middle fs-16`}/>
        </button>
    );

    return (
        <>
            {(day >= currentDate) ?
                <div className="mb-3">
                    {generateButton(PeerActivityModeCode.ACTIVITY, "ri-article-line")}
                    {generateButton(PeerActivityModeCode.SHORTS, "ri-video-line")}
                    {generateButton(PeerActivityModeCode.STORY, "ri-star-smile-line")}
                    {generateButton(PeerActivityModeCode.BIO, "mdi mdi-bio", "p-0 ps-1 pe-1")}
                </div>
                :
                count<1 &&
               <div className="detailActivityViewCard p-4 border border-1 border-dashed d-flex flex-column align-items-center justify-content-center rounded-2">
                   <i className="text-muted ri-camera-off-line detailActivityViewCardEmptyIcon"/>
                   <div className="text-muted pt-1 fs-13 opacity-50">{t('peerActivity:noDataPostedOrUploaded')}</div>
               </div>
            }
        </>
    );
};

export default PeerActivityAddCardLink;
