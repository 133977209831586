import React, {useEffect, useState} from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {ProductStatus, ProductType} from "../../../redux/Sale/Product/types";
import * as route from "../../../api/routes";
import {Link} from "react-router-dom";
import {ProductPriceType} from "../../../redux/Sale/Price/types";
import {ProductParamListValueType, ProductParamType, ProductParamTypes} from "../../../redux/Sale/ProductParam/types";
import {ProductParamValueType} from "../../../redux/Sale/ProductParamValue/types";
import {useRedux} from "../../../hooks";
import {handleCreateNewTaskByProduct} from "./ProductActions";
import {convertToMomentLocale} from "../../../helpers/functions";
import {format} from "date-fns";

const ProductColumns = ({ onDetail, onCopy, onEdit, onRemove }:EntityActionsHandlersType) => {

    const { t, i18n } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();
    const [productParams, setProductParams] = useState<ProductParamType[]>([]);
    const {saleProductParam } = useAppSelector(state => ({ saleProductParam: state.SaleProductParam  }));

    useEffect(() => {
        if (saleProductParam?.items && (saleProductParam?.items || []).length>0){
            setProductParams([...saleProductParam.items]);
        } else {
            setProductParams([]);
        }
    }, [saleProductParam?.items]);

    const FormatCurrencyValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const caseOrderPriceSort = (rowA:ProductType, rowB:ProductType) => {

        const priceA = (rowA.productPrices || []).filter((productPrice:ProductPriceType)=>{return productPrice.priceType.isBase && productPrice.currency.isDefault}).shift();
        const priceB = (rowB.productPrices || []).filter((productPrice:ProductPriceType)=>{return productPrice.priceType.isBase && productPrice.currency.isDefault}).shift();

        const a = (priceA?.price && priceA.price) || 0;
        const b = (priceB?.price && priceB.price) || 0;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            ignoreRowClick: true,
            selector: (cell:any) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-light btn-sm" tag="a">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" container="root">
                            <DropdownItem tag="a" className='edit-item-btn' onClick={() => onDetail(cell)}><i
                                className="ri-eye-fill align-bottom me-2 text-muted"/> {t('product:productDetails')} </DropdownItem>
                            <DropdownItem tag="a" className='edit-item-btn' onClick={() => onEdit(cell)}><i
                                className="ri-pencil-fill align-bottom me-2 text-muted"/> {t('common:edit')} </DropdownItem>
                            <DropdownItem tag="a" className='edit-item-btn' onClick={() => onCopy(cell)}><i
                                className="ri-file-copy-2-line align-bottom me-2 text-muted"/> {t('common:copy')} </DropdownItem>
                            <DropdownItem tag="a" className='edit-item-btn' onClick={() => handleCreateNewTaskByProduct(cell,dispatch)}><i
                                className="ri-survey-line align-bottom me-2 text-muted"/> {t('task:addNewTask')} </DropdownItem>
                            <DropdownItem className='remove-item-btn'
                                          onClick={() => onRemove(cell)}><i
                                className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('sale:status')}</span>,
            maxWidth: '50px',
            selector: (row:ProductType) => {
                for (let productStatusKey in ProductStatus) {
                    if (ProductStatus[productStatusKey].status===row.status){
                        return <span className={"badge text-uppercase badge-soft-"+ProductStatus[productStatusKey].class}> {t('product:productPublishStatus'+ProductStatus[productStatusKey].label)} </span>;
                    }
                }
                return <span className="badge text-uppercase badge-soft-info"> {row.status} </span>;
            },
        },
        {
            id: 'name',
            name: <span className='font-weight-bold fs-14'>{t('common:nameTitle')}</span>,
            maxWidth: '400px',
            sortable: true,
            ignoreRowClick: true,
            selector: (row:ProductType) => {

                let _cartProductParamsValues=[];

                (row?.productParamValues || []).map((productParamValue:ProductParamValueType) => {

                    if (productParamValue?.productParam?.isCart && productParamValue.productParam.isCart) {
                        switch (productParamValue.productParam.type) {
                            case ProductParamTypes.LIST:
                                let _productParam = (productParams || []).filter((productParam: ProductParamType)=>{
                                    return productParam.uuid ===productParamValue?.productParam?.uuid
                                }).shift();

                                if (_productParam?.uuid){
                                    let _productParamListValue = (_productParam?.values || []).filter((productParamListValue: ProductParamListValueType)=>{
                                        return productParamListValue.uuid === productParamValue.value
                                    }).shift();

                                    if (_productParamListValue?.value){
                                        _cartProductParamsValues.push(_productParamListValue.value);
                                    }
                                }
                                break
                            default:
                                _cartProductParamsValues.push(productParamValue.value);
                        }
                    }

                    return productParamValue;
                })

                return (
                    <>
                    <Link to={route.SALE_PRODUCT_DETAIL.replace(/:uuid/g, row.uuid)} className="fw-medium link-primary">
                        <div className="d-flex align-items-center mt-2 mb-2">
                            <div className="flex-shrink-0 me-3">
                                    <div className="d-flex avatar-sm bg-light rounded p-1 align-items-center justify-content-center">
                                        {(row.media && (row.media ||[]).length>0) ?
                                            <img
                                                src={[...row.media].shift().url} alt="" className="img-fluid d-block rounded"/>
                                            :
                                            <i className="ri-camera-off-line text-muted fs-18"/>
                                        }
                                    </div>
                            </div>
                            <div className="flex-grow-1">
                                <h5 className="fs-14 mb-1">
                                      {row.name}
                                    {(_cartProductParamsValues || []).length>0 &&
                                        <span className="text-muted fs-12 carProductProperties ps-1 text-truncate">
                                                {
                                                    (_cartProductParamsValues || []).map((item:string, key:number)=>{
                                                        return(<span key={'cartProductParamsValue_'+key}>{item}</span>)
                                                    })
                                                }
                                            </span>
                                    }
                                    </h5>
                                <p className="text-muted mb-0">{t('category:category')}: <span
                                    className="fw-medium text-truncate">
                                    {row?.category?.name
                                        ?
                                        row.category.name
                                        :
                                        <>{t('category:rootCategory')}</>
                                    }
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Link>
                    </>
                )
            },
        },
        {
            id: 'article',
            name: <span className='font-weight-bold fs-14'>{t('sale:code')}</span>,
            sortable: true,
            maxWidth: '50px',
            selector: (row:ProductType) => {
                return (
                    <>
                        <Link to={route.SALE_PRODUCT_DETAIL.replace(/:uuid/g, row.uuid)} className="fw-medium link-primary">{row.article}</Link>
                    </>
                )
            },
        },
        {
            id: 'price',
            minWidth: '80px',
            maxWidth: '100px',
            name: <span className='font-weight-bold fs-14'>{t('price:price')}</span>,
            sortable: false,
            wrap: true,
            selector: (row:ProductType) => {
                let productPrice = (row?.productPrices || []).filter((productPrice: ProductPriceType)=>{
                    return productPrice.currency.isDefault && productPrice.priceType.isBase
                }).shift();

                if (productPrice === undefined){
                    productPrice = (row?.productPrices || []).filter((productPrice: ProductPriceType)=>{
                        return productPrice.priceType.isBase && productPrice.price !== undefined && productPrice.price !== null
                    }).shift();
                }

                return (
                    productPrice?.uuid &&
                    <>
                        <div className="text-nowrap text-truncate">
                            {productPrice.currency.currencyFormat.replace(/#/,FormatCurrencyValue(productPrice.price))}
                        </div>
                        <div className="text-muted fs-12">{t('sale:itemPrice')}</div>
                    </>
                );
            },
            sortFunction: caseOrderPriceSort
        },
        {
            id: 'createdAt',
            name: <span className='font-weight-bold fs-14'>{t('sale:createdAt')}</span>,
            maxWidth: '200px',
            sortable: true,
            selector: (row:ProductType) => {
                return format(new Date(row.createdAt),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
            },
        },
    ];

}

export default ProductColumns;