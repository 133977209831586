import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../../api/routes";
import {wsMessageEventTypes} from "../../../api/WebSocket/webSocketTypes";
import {CommentType} from "../../../redux/Main/Comment/types";
import UserAvatar from "../User/userAvatar";
import {MainCabinetType} from "../../../redux/Main/Cabinet/types";
import {useRedux} from "../../../hooks";
import {textTruncate} from "../../../helpers/functions";

interface ItemProps {
    item: CommentType;
    action?: string;
    cabinet?: MainCabinetType
}
const CommentItem = ({ item, cabinet, action }: ItemProps) => {

    const { t,i18n } = useTranslation(['common']);

    const { useAppSelector } = useRedux();

    const [link, setLink] = useState<string>(null);
    const [text, setText] = useState<string>(null);

    const cabinetState = useAppSelector(state => state.MainCabinet);

    const hasUuid = (cabinet?: MainCabinetType): boolean => cabinet?.uuid?.length > 0;
    const externalCabinet = cabinetState.items.find(hasUuid);

    useEffect(()=>{
        let _link=null;

        if (item?.task?.uuid !== undefined && item.task.uuid !== null) {
            _link = route.TASK_DETAIL.replace(/:uuid/g, item.task.uuid);
        } else if (item?.saleOrder?.uuid !== undefined && item.saleOrder.uuid !== null) {
            _link = route.SALE_ORDER_DETAIL.replace(/:uuid/g, item.saleOrder.uuid);
        }

        if (externalCabinet && externalCabinet?.uuid){
            _link=null;
        }

        if (item?.text !== undefined && item.text !== null && item.text.length>0){

            let _text = item.text.replace(/(\]\([a-z0-9|-]+\))/gm, '</span>');
            _text = _text.replace(/(@\[)/g, '<span class="mentions__mention">');

            setText(_text);

        } else {
            setText(null);
        }

        setLink(_link);

    },[item, externalCabinet])


    return (
        <>
            { externalCabinet && externalCabinet?.uuid &&
                <div className="position-absolute top-0 end-0 mt-1 me-1" style={{zIndex:1}}>
                    <span className="badge rounded-pill text-bg-info">{textTruncate(externalCabinet.name, 10)}</span>
                </div>
            }

            <Link to={link && link}
                  className="fw-medium link-primary">
            <div className={"d-flex mb-1 p-0 rounded"}>
                <div className="flex-shrink-0 avatar-sm position-relative">
                    {item?.owner &&
                        <UserAvatar item={item.owner}/>
                    }
                </div>
                <div className="flex-grow-1 ms-3">
                    <h5 className="fs-14 mb-2">
                        {item?.owner && item?.owner?.name && item.owner.name}
                        {action === wsMessageEventTypes.MENTIONED_IN_COMMENT &&
                            " "+t('comment:mentionedYou')
                        }
                        {action === wsMessageEventTypes.COMMENT_ADDED &&
                            " "+t('comment:addedNewComment')
                        }
                    </h5>
                    { text !== null &&
                    <p className="text-muted text-truncate p-0 m-0">
                        <span className="text-body">{textTruncate(text,23)}</span>
                    </p>
                    }
                </div>
            </div>
            </Link>
        </>
    );
};

export default CommentItem;
