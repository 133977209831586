// types
import {TaskActionTypes, TaskPriority, TaskState, TaskStatus, TaskType} from "./types";
import {CommentType} from "../../Main/Comment/types";
import {getLoggedinUser} from "../../../api/apiCore";
import {NewCommentItem} from "../../Main/Comment/reducer";
import {NewTaskTimeItem, TaskTimeType} from "../TaskTime/types";
import {formatISO} from "date-fns/formatISO";
import {UTCDate} from "@date-fns/utc";

export const NewTaskItem:TaskType = {
  enabled: true,
  name: '',
  text: '',
  members: [],
  completionPercent: null,
  status: TaskStatus.NEW.status,
  tier: TaskPriority.TIER2.status,
  isNotifyAssignerClosure: false
}

export const INIT_STATE_TASK: TaskState = {
  items: [],
  members: [],
  isLoading: false,

  selectedItem: NewTaskItem,
  selectedItemMembers: [],
  rightModalShow: false,
  removeModalShow: false,
  rewardModalShow: false,
  isSending: false,
  modalEditTitle:'',
  isNeedReload: false,

  isLoadingTaskTimes: false,
  isSendingTaskTime: false,
  selectedTaskTime: null,
  isNeedReloadTaskTimes: false,
  removeTaskTimeModalShow:false,
  removeTaskTimeModalTitle:'',

  isLoadingComments: false,
  isSendingComment: false,
  selectedComment: null,
  isNeedReloadComments: false,
  removeCommentModalShow:false,
  removeCommentModalTitle:'',

  isModalGroupCloseShow: false,
  isModalGroupRemoveShow: false
};


const calcTaskTimeMinutes = (taskTimes:TaskTimeType[]) => {

  let _taskTimesTotalMinutes = 0;

  (taskTimes || []).map((taskTime:TaskTimeType)=>{
    if (taskTime?.minutes && taskTime?.minutes>0){
      _taskTimesTotalMinutes+=taskTime.minutes
    }
    return taskTime;
  });

  return _taskTimesTotalMinutes;
}

const Task = (state = INIT_STATE_TASK, action: any) => {

  let _comments = [];
  let _taskTimes = [];

  switch (action.type) {
    case TaskActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case TaskActionTypes.GET_TASKS:
          return {
            ...state,
            items: action.payload.data.items,
            members: action.payload.data.members,
            meta: action.payload.data.meta,
            isLoading: false,
            isNeedReload: false
          };
        case TaskActionTypes.GET_TASK_TIMES:
          return {
            ...state,
            selectedItem:{
              ...state.selectedItem,
              taskTimes: [...action.payload.data.items,...state.selectedItem.taskTimes]
            },
            isLoadingTaskTimes: false,
            isNeedReloadTaskTimes: false
          };
        case TaskActionTypes.UPDATE_TASK:
          return {
            ...state,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case TaskActionTypes.CHANGE_TASK_STATUS:
          return {
            ...state,
            isSending: false,
            isNeedReload: true
          };
        case TaskActionTypes.UPDATE_TASK_TIME:

          if (state?.selectedTaskTime?.uuid !== undefined && state?.selectedTaskTime?.uuid !== null){
            _taskTimes = state.selectedItem.taskTimes.map((taskTime:TaskTimeType)=>{
              if (taskTime.uuid === state.selectedTaskTime.uuid) {
                return state.selectedTaskTime
              } else {
                return taskTime
              }
            });

            state = {
              ...state,
              selectedItem:{
                ...state.selectedItem,
                taskTimes: _taskTimes,
                taskTimesMeta:{
                  ...state.selectedItem.taskTimesMeta,
                  totalCount: (_taskTimes || []).length,
                  totalMinutes: calcTaskTimeMinutes(_taskTimes)
                }
              },
            }

          } else {

            let currentUser = getLoggedinUser();

            let selectedTaskTime:TaskTimeType = {
              ...state.selectedTaskTime,
              uuid: action.payload.data.uuid,
              owner: {
                uuid: currentUser.uuid,
                name: currentUser.name && currentUser.name,
                email: currentUser.email && currentUser.email,
                avatar: currentUser.avatar && currentUser.avatar
              },
              actions:{
                canEdit:true,
                canRemove:true
              }
            }

            _taskTimes = [selectedTaskTime, ...state.selectedItem.taskTimes];

            state = {
              ...state,
              selectedTaskTime: {
                ...selectedTaskTime
              },
              selectedItem:{
                ...state.selectedItem,
                taskTimes: _taskTimes,
                taskTimesMeta:{
                  ...state.selectedItem.taskTimesMeta,
                  totalCount: (_taskTimes || []).length,
                  totalMinutes: calcTaskTimeMinutes(_taskTimes)
                }
              }
            }
          }

          return {
            ...state,
            isSendingTaskTime: false,
            isNeedReload: false
          };

        case TaskActionTypes.REMOVE_TASK_TIME:

          _taskTimes = (state.selectedItem.taskTimes || []).filter((taskTime:TaskTimeType)=>{return taskTime.uuid !== state?.selectedTaskTime?.uuid});

          return {
            ...state,
            isSendingTaskTime: false,
            selectedTaskTime: NewTaskTimeItem,
            removeTaskTimeModalShow: false,
            selectedItem:{
              ...state.selectedItem,
              taskTimes: _taskTimes,
              taskTimesMeta:{
                ...state.selectedItem.taskTimesMeta,
                totalCount: (_taskTimes || []).length,
                totalMinutes: calcTaskTimeMinutes(_taskTimes)
              }
            },
            isNeedReload: false
          };
        case TaskActionTypes.GET_TASK_DETAIL:
          return {
            ...state,
            selectedItem: action.payload.data.items.pop(),
            selectedItemMembers: action.payload.data.members,
            isLoading: false,
            isNeedReloadComments: true,
            isNeedReloadTaskTimes: true,
            isNeedReload:false
          };
        case TaskActionTypes.REMOVE_TASK:
          return {
            ...state,
            selectedItem: NewTaskItem,
            removeModalShow: false,
            isNeedReload: true
          };
        case TaskActionTypes.COPY_TASK:
          return {
            ...state,
            isSending: false,
            isNeedReload: true,
          };
        case TaskActionTypes.SEND_REWARD_TASK:
          return {
            ...state,
            rewardModalShow:false,
            isSending: false
          };
        case TaskActionTypes.GET_COMMENTS:
          _comments = [...action.payload.data.items,...state.selectedItem.comments];

          return {
            ...state,
            selectedItem:{
              ...state.selectedItem,
              comments: _comments,
              commentsMeta:{
                ...state.selectedItem.commentsMeta,
                totalCount: (_comments || []).length
              }
            },
            isLoadingComments: false,
            isNeedReloadComments: false
          };
        case TaskActionTypes.REMOVE_COMMENT:

          _comments = (state.selectedItem.comments || []).filter((comment:CommentType)=>{return comment.uuid !== state?.selectedComment?.uuid})

          return {
            ...state,
            selectedItem:{
              ...state.selectedItem,
              comments: _comments,
              commentsMeta:{
                ...state.selectedItem.commentsMeta,
                totalCount: (_comments || []).length
              }
            },
            selectedComment: NewCommentItem,
            removeCommentModalShow: false,
            isSendingComment: false,
            isNeedReload: false
          };
        case TaskActionTypes.UPDATE_COMMENT:

          if (state?.selectedComment?.uuid !== undefined && state?.selectedComment?.uuid !== null){

            _comments = state.selectedItem.comments.map((comment:CommentType)=>{if (comment.uuid === state.selectedComment.uuid) {return state.selectedComment} else return comment});

            state = {
              ...state,
              selectedItem:{
                ...state.selectedItem,
                comments: _comments,
                commentsMeta:{
                  ...state.selectedItem.commentsMeta,
                  totalCount: (_comments || []).length
                }
              },
            }

          } else {

            let currentUser = getLoggedinUser();

            let selectedComment:CommentType = {
              ...state.selectedComment,
              uuid: action.payload.data.uuid,
              owner: {
                uuid: currentUser.uuid,
                name: currentUser.name && currentUser.name,
                email: currentUser.email && currentUser.email,
                avatar: currentUser.avatar && currentUser.avatar
              },
              dateTime: formatISO(new UTCDate()),
              actions:{
                canEdit:true,
                canRemove:true
              }
            }

            _comments = [selectedComment, ...state.selectedItem.comments];

            state = {
                ...state,
                selectedComment: {
                  ...selectedComment
                },
                selectedItem:{
                  ...state.selectedItem,
                  comments: _comments,
                  commentsMeta:{
                    ...state.selectedItem.commentsMeta,
                    totalCount: (_comments || []).length
                  }
                }
              }
          }

          return {
            ...state,
            isSendingComment: false,
            isNeedReload: false
          };

        case TaskActionTypes.CLOSE_TASK_GROUP:
          return {
            ...state,
            isModalGroupCloseShow: false,
            isSending: false,
            isNeedReload: true
          };

        case TaskActionTypes.REMOVE_TASK_GROUP:
          return {
            ...state,
            isModalGroupRemoveShow: false,
            isSending: false,
            isNeedReload: true
          };

        default:
          return { ...state };
      }

    case TaskActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case TaskActionTypes.GET_TASKS:
          return {
            ...state,
            isLoading: false
          };
        case TaskActionTypes.GET_TASK_DETAIL:
          return {
            ...state,
            isLoading: false
          }
        case TaskActionTypes.GET_TASK_TIMES:
          return {
            ...state,
            isLoadingTaskTimes: false,
          }
        case TaskActionTypes.UPDATE_TASK:
        case TaskActionTypes.COPY_TASK:
        case TaskActionTypes.CLOSE_TASK_GROUP:
        case TaskActionTypes.REMOVE_TASK_GROUP:
        case TaskActionTypes.REMOVE_TASK:
        case TaskActionTypes.CHANGE_TASK_STATUS:
          return {
            ...state,
            isSending: false
          };
        case TaskActionTypes.SEND_REWARD_TASK:
          return {
            ...state,
            isSending: false
          };
        case TaskActionTypes.GET_COMMENTS:
          return {
            ...state,
            isLoadingComments: false
          }
        case TaskActionTypes.UPDATE_COMMENT:
        case TaskActionTypes.REMOVE_COMMENT:
          return {
            ...state,
            isSendingComment: false
          }
        case TaskActionTypes.UPDATE_TASK_TIME:
        case TaskActionTypes.REMOVE_TASK_TIME:
          return {
            ...state,
            isSendingTaskTime: false
          }
        default:
          return { ...state };
      }
    case TaskActionTypes.EDIT_TASK:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case TaskActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case TaskActionTypes.REMOVE_TASK_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case TaskActionTypes.REMOVE_TASK_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewTaskItem,
        removeModalShow: false
      }
    case TaskActionTypes.REWARD_TASK_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rewardModalShow: true
      }
    case TaskActionTypes.REWARD_TASK_MODAL_HIDE:
      return {
        ...state,
        rewardModalShow: false
      }
    case TaskActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case TaskActionTypes.COPY_TASK:
    case TaskActionTypes.REMOVE_TASK:
    case TaskActionTypes.UPDATE_TASK:
    case TaskActionTypes.REMOVE_TASK_GROUP:
    case TaskActionTypes.CLOSE_TASK_GROUP:
    case TaskActionTypes.CHANGE_TASK_STATUS:
      return {
        ...state,
        isSending: true
      };
    case TaskActionTypes.GET_TASKS:
    case TaskActionTypes.GET_TASK_DETAIL:
      return {
        ...state,
        isLoading: true
      };
    case TaskActionTypes.GET_TASK_TIMES:
      return {
        ...state,
        isLoadingTaskTimes: true,
        isNeedReloadTaskTimes: false
      }
    case TaskActionTypes.GET_COMMENTS:
      return {
        ...state,
        isLoadingComments: true,
        isNeedReloadComments: false
      }
    case TaskActionTypes.UPDATE_COMMENT:
      return {
        ...state,
        selectedComment: action.payload,
        isSendingComment: true
      }
    case TaskActionTypes.REMOVE_COMMENT:
      return {
        ...state,
        selectedComment: action.payload.data,
        isSendingComment: true
      }
    case TaskActionTypes.REMOVE_COMMENT_MODAL_SHOW:
      return {
        ...state,
        selectedComment: action.payload.data,
        removeCommentModalTitle: action.payload.removeCommentModalTitle,
        removeCommentModalShow: true
      }
    case TaskActionTypes.REMOVE_COMMENT_MODAL_HIDE:
      return {
        ...state,
        selectedComment: NewCommentItem,
        removeCommentModalShow: false
      }
    case TaskActionTypes.UPDATE_TASK_TIME:
      return {
        ...state,
        selectedTaskTime: action.payload,
        isSendingTaskTime: true
      }
    case TaskActionTypes.REMOVE_TASK_TIME:
      return {
        ...state,
        selectedTaskTime: action.payload.data,
        isSendingTaskTime: true
      }
    case TaskActionTypes.REMOVE_TASK_TIME_MODAL_SHOW:
      return {
        ...state,
        selectedTaskTime: action.payload.data,
        removeTaskTimeModalTitle: action.payload.removeTaskTimeModalTitle,
        removeTaskTimeModalShow: true
      }
    case TaskActionTypes.REMOVE_TASK_TIME_MODAL_HIDE:
      return {
        ...state,
        selectedTaskTime: NewTaskTimeItem,
        removeTaskTimeModalShow: false
      }
    case TaskActionTypes.CLOSE_TASK_GROUP_MODAL_SHOW:
      return {
        ...state,
        isModalGroupCloseShow: true
      }
    case TaskActionTypes.CLOSE_TASK_GROUP_MODAL_HIDE:
      return {
        ...state,
        isModalGroupCloseShow: false
      }
    case TaskActionTypes.REMOVE_TASK_GROUP_MODAL_SHOW:
      return {
        ...state,
        isModalGroupRemoveShow: true
      }
    case TaskActionTypes.REMOVE_TASK_GROUP_MODAL_HIDE:
      return {
        ...state,
        isModalGroupRemoveShow: false
      }
    default:
      return { ...state };
  }
};

export default Task;
