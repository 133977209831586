import React, {useEffect, useState} from "react";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination, Autoplay, Thumbs} from "swiper/modules";

import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import ChannelItemBigLine from "../PeerChannel/channelItemBigLine";
import {MainFileImageTypes, MainFileType, MainFileVideoTypes} from "../../../redux/Main/File/types";
import StatItem from "../PeerActivity/StatItem";
import {Icon} from "ts-react-feather-icons";
import * as route from "../../../api/routes";
import TagItem from "../../../components/TagItem";
import {convertToMomentLocale, getEnumKeyByValue, isHTML, removeTagsFromPostBody} from "../../../helpers/functions";
import {showRightModalContentPeerTarget} from "../../../redux/Content/PeerTarget/actions";
import {useRedux} from "../../../hooks";
import {ContentPeerTargetStatusTypes, ContentPeerTargetType} from "../../../redux/Content/PeerTarget/types";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {ContentTemplatePayload, ContentTemplateType} from "../../../redux/Content/Template/types";
import {showRightModalContentTemplates} from "../../../redux/Content/Template/actions";
import {PeerActivityModeCode} from "../../../redux/Content/PeerActivityMode/types";
import {PeerActivityStatus, PeerActivityStatusClass} from "../../../redux/Content/PeerActivityStatus/types";
import {
    copyActivity,
    removeActivity,
    setEditPeerActivityItems, setShowMoreChannels,
    showPeerActivityEditModal
} from "../../../redux/Content/PeerActivity/actions";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {handleCreateNewTaskByPeerActivity} from "./PeerActivityActions";
import PeerActivityLink from "./PeerActivityLink";
import {add, format} from "date-fns";
import {UTCDate} from "@date-fns/utc";

interface ItemProps {
    item: PeerActivityType;
    dispatchExternal? : any;
}

const DetailViewItem = ({ item, dispatchExternal}: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);
    const [showMore, setShowMore] = useState(false);
    const [text, setText] = useState<string>('');
    const maxShowLessSize = 150;

    const { dispatch } = useRedux();
    const getDispatch = () => dispatchExternal ? dispatchExternal : dispatch;

    let successTargetCount = 0;
    let failTargetCount = 0;
    let progressTargetCount = 0;

    (item.targets || []).map((targetItem:ContentPeerTargetType)=>{
        if (targetItem.targetStatus === ContentPeerTargetStatusTypes.SUCCESS){
            successTargetCount++;
        } else if (targetItem.targetStatus === ContentPeerTargetStatusTypes.PROGRESS){
            progressTargetCount++;
        } else if (targetItem.targetStatus === ContentPeerTargetStatusTypes.FAIL){
            failTargetCount++;
        }
        return targetItem;
    });

    useEffect(()=>{

        if (item?.text && item?.text !== null && item.text.length>0) {
            if (item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_INSTAGRAM) {
                setText('<span class="fw-bold pe-1">' + item.channel.name + '</span>' + item.text.replace(/\n/g,'<br>'));
            } else {
                setText(item.text);
            }
        } else {
            setText('');
        }

    },[item?.text, item?.channel])

    const onTargetAdd = () => {
        document.body.click();
        const data = { 'activity': item };
        // @ts-ignore: Unreachable code error
        getDispatch()(showRightModalContentPeerTarget({ data: data, modalEditTitle: 'newTarget' }));
    }

    const handleAddToTemplate = () =>{
        document.body.click();

        let textTemplate = '';

        if (item.mode.code === PeerActivityModeCode.ACTIVITY){
            textTemplate = item.text && item.text;
        } else if (item.mode.code === PeerActivityModeCode.BIO){
            textTemplate = item.description && item.description;
        }

        const newTemplate:ContentTemplateType ={
            enabled: true,
            name: '',
            text: textTemplate
        }

        const contentTemplatePayload : ContentTemplatePayload={
            data: newTemplate,
            modalEditTitle: 'newTemplate'
        }

        getDispatch()(showRightModalContentTemplates(contentTemplatePayload));
    }

    const handleEdit = (currentItem:PeerActivityType) =>{
        document.body.click();
        getDispatch()(setEditPeerActivityItems([{...currentItem}]));
        getDispatch()(showPeerActivityEditModal());
    }

    const handleCreateNewByActivity = (currentItem:PeerActivityType) =>{

        document.body.click();

        let newItem = {
            ...currentItem,
            uuid: null,
            status: PeerActivityStatus.STATUS_NEW,
            activeFrom: add(new Date(),{minutes: 30}).getTime() / 1000
        }

        getDispatch()(setEditPeerActivityItems([newItem]));
        getDispatch()(setShowMoreChannels());
        getDispatch()(showPeerActivityEditModal());
    }

    const handleRemove = (currentItem:PeerActivityType) =>{
        document.body.click();
        getDispatch()(removeActivity(currentItem));
    }

    const handleCopy = (currentItem:PeerActivityType) =>{
        document.body.click();
        getDispatch()(copyActivity({peerActivity: currentItem}));
    }

    const handleCopyToActivitiesPlan = (currentItem:PeerActivityType) => {
        document.body.click();
        getDispatch()(setShowMoreChannels());
        handleEdit(currentItem);
    }

    const handleShowMore = (value : boolean) => {
        setShowMore(value);
    }

    return (
            <Card className="mb-0 detailActivityViewCard">
                <CardHeader>
                    <div className="d-flex flex-row flex-nowrap flex-grow-1">
                    {item.channel &&
                        <ChannelItemBigLine item={item.channel}/>
                    }

                    <div className="d-flex flex-shrink-0">
                        <UncontrolledDropdown direction='start'>
                            <DropdownToggle tag="button" className="btn btn-sm btn-link text-muted text-decoration-none fs-15">
                                <Icon name="more-horizontal" />
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">

                                {(item.status === PeerActivityStatus.STATUS_NEW || item.status === PeerActivityStatus.STATUS_READY || item.status === PeerActivityStatus.STATUS_ERROR) &&
                                    <DropdownItem onClick={()=>{handleEdit(item)}}><i className="ri-pencil-fill align-bottom text-muted me-2"/> {t('common:edit')}</DropdownItem>
                                }
                                {!item.channel.isManaged ?
                                    <DropdownItem className='edit-item-btn' onClick={()=>handleCopy(item)}><i
                                        className="ri-file-copy-2-line align-bottom text-muted me-2"/> {t('common:copyToIdeas')}
                                    </DropdownItem>
                                    :
                                <DropdownItem className='edit-item-btn' onClick={()=>handleCopy(item)}><i
                                    className="ri-file-copy-2-line align-bottom text-muted me-2"/> {t('common:copy')}
                                </DropdownItem>
                                }
                                <DropdownItem className='edit-item-btn' onClick={()=>handleCreateNewByActivity(item)}><i
                                    className="ri-file-copy-2-line align-bottom text-muted me-2"/> {t('peerActivity:currentBasedNew')}
                                </DropdownItem>

                                { item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS &&
                                    <DropdownItem className='edit-item-btn' onClick={()=>handleCopyToActivitiesPlan(item)}>
                                        <i className="ri-pages-line align-bottom text-muted me-2"/> {t('peerActivity:copyToActivitiesPlan')}
                                    </DropdownItem>
                                }
                                <DropdownItem className='edit-item-btn' onClick={()=>handleCreateNewTaskByPeerActivity(item, getDispatch())}><i
                                    className="ri-survey-line align-bottom text-muted me-2"/> {t('task:addNewTask')}
                                </DropdownItem>
                                {item.actions && item.actions.canTargetAdd && item.channel.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS &&
                                <DropdownItem className='edit-item-btn' onClick={onTargetAdd}><i
                                    className="ri-pencil-ruler-2-line align-bottom text-muted me-2"/> {t('target:newTarget')}
                                </DropdownItem>
                                }
                                { item.channel.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS &&
                                <DropdownItem tag="a" href={route.STAT_CONTENT_ACTIVITY.replace(/:uuidActivity/g, item.uuid)} ><i className="ri-pie-chart-line align-bottom text-muted  me-2"/> {t('stat:stat')} </DropdownItem>
                                }
                                {(item.status === PeerActivityStatus.STATUS_NEW || item.status === PeerActivityStatus.STATUS_READY || item.status === PeerActivityStatus.STATUS_ERROR || item.status === PeerActivityStatus.STATUS_UNPUBLISHED) &&
                                    <DropdownItem onClick={()=>{handleRemove(item)}}><i className="ri-delete-bin-fill align-bottom text-muted me-2"/> {t('common:delete')}</DropdownItem>
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="d-flex flex-row flex-wrap justify-content-between">
                        {item.activeFrom &&
                        <div className="badge badge-soft-primary me-2 mb-3">{
                            format(new Date(item.activeFrom),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
                            }</div>
                        }
                        {item.status &&
                        <div className="d-flex flex-shrink-0 mb-3">
                            <span className={"badge bg-" + PeerActivityStatusClass[getEnumKeyByValue(PeerActivityStatus,item.status)]}>
                                {t('peerActivityStatus:'+item.status)}
                            </span>
                        </div>
                        }
                        <PeerActivityLink item={item}/>
                    </div>

                    {item.media && item.media.length>0 &&
                    <Swiper pagination={{ clickable: true, dynamicBullets: true, }}
                            modules={[Pagination, Autoplay]}
                            slidesPerView={1}
                            loop={false}
                            autoplay={{ delay: 2500, disableOnInteraction: false }}
                            className="mySwiper swiper pagination-dynamic-swiper rounded mb-2" >

                            { item.media.map((mediaItem:MainFileType, key:number)=> (
                                <SwiperSlide key={"swiperActivitySlide"+mediaItem.uuid+"_"+key} className="text-center">
                                    {(Object.values(MainFileImageTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                            <img src={mediaItem.url} alt="" className="img-fluid"/>
                                    }
                                    {(Object.values(MainFileVideoTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                    <video controls width="auto" height="auto" style={{maxWidth:'100%'}}>
                                        <source src={mediaItem.downLoadUrl} type={"video/"+mediaItem.fileName.split('.').pop()}/>
                                    </video>
                                    }
                                </SwiperSlide>
                            ))}

                    </Swiper>
                    }
                    <div className="mw-0">
                        {item?.name !== undefined && item.name !== null && item.name.length>0 &&
                        <h6 className="card-title text-truncate">{item.name}</h6>
                        }
                        {text !== undefined && text !== null && text.length>0 &&
                            <div>
                                {isHTML(text) ?
                                    <div
                                        className={"card-text "+(item.text.length > maxShowLessSize ? '' : ' pb-2')}
                                        dangerouslySetInnerHTML={{__html: showMore?text:text.substring(0, maxShowLessSize)+((text.length > maxShowLessSize) && !showMore ? '...': '')}}
                                    />
                                    :
                                    <div
                                        className={"card-text "+(text.length > maxShowLessSize ? '' : ' pb-2')}
                                    >{showMore
                                        ?
                                        <>
                                            {text.split('\n').map((str: string, key:number) => {
                                                return (<p className="mb-0" key={key}>{str || '\u00A0'}</p>)})}
                                        </>
                                        :
                                        <>
                                            {
                                                text.substring(0, maxShowLessSize).split('\n').slice(0, 2).map((str: string, key:number) => {
                                                    return (<p className="mb-0" key={key}>{str || '\u00A0'}</p>)})
                                            }
                                            {(text.length > maxShowLessSize) && !showMore ? '...': ''}
                                        </>
                                    }
                                    </div>
                                }
                                {text.length > maxShowLessSize &&
                                    <div className="d-flex flex-row align-items-center justify-content-center p-1 mb-3">
                                        {showMore
                                    ?
                                    <button
                                        onClick={()=>handleShowMore(false)}
                                        type="button"
                                        className="btn btn-sm btn-soft-primary waves-effect waves-light">
                                        {t('common:showLess')}
                                    </button>
                                    :
                                    <button
                                        onClick={()=>handleShowMore(true)}
                                        type="button"
                                        className="btn btn-sm btn-soft-primary waves-effect waves-light fs-11">
                                        {t('common:showMore')}
                                    </button>
                                        }
                                    </div>
                                }
                            </div>
                        }
                            {/*{ item.tags &&*/}
                            {/*    <p className="card-text text-muted text-truncate mb-3">*/}
                            {/*        {removeTagsFromPostBody(item.text, item.tags)}*/}
                            {/*    </p>*/}
                            {/*}*/}
                        <div className="d-flex flex-shrink-0">
                            <button
                                onClick={handleAddToTemplate}
                                type="button"
                                title={t('template:newTemplate')}
                                className="btn btn-outline-primary btn-icon waves-effect waves-light">
                                <i className="ri-character-recognition-line"/>
                            </button>
                            {item.actions && item.actions.canTargetAdd && item.channel.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS &&
                                <button
                                    onClick={onTargetAdd}
                                    type="button"
                                    title={t('target:newTarget')}
                                    className="btn btn-outline-primary btn-icon waves-effect waves-light ms-2">
                                    <i className="ri-pencil-ruler-2-line"/>
                                </button>
                            }
                        </div>
                    </div>
                    {item.tags && item.tags.length>0 &&
                        <div className="mt-1">
                            {item.tags.split(/,/).map((tag: string, key: number)=>(
                            <TagItem tag={tag} key={key}/>
                            ))}
                        </div>
                    }
                </CardBody>
                {item.stat &&
                <CardFooter className="pt-1 pb-2">
                    <div className="d-flex align-items-center mt-1">
                        <div className="flex-shrink-0">
                            <StatItem item={item}/>
                        </div>
                    </div>

                    {item.targets && item.targets.length>0 &&
                    <div className="d-flex align-items-center mt-1">
                        <ul className="link-inline mb-0 ps-0">
                            <li className="list-inline-item">
                                <span className="text-info me-1"><i className="ri-pencil-ruler-2-line"/></span>
                                <span className="text-muted">{progressTargetCount}</span>
                            </li>
                            <li className="list-inline-item">
                                <span className="text-danger me-1"><i className="ri-pencil-ruler-2-line"/></span>
                                <span className="text-muted">{failTargetCount}</span>
                            </li>
                            <li className="list-inline-item">
                                <span className="text-success me-1"><i className="ri-pencil-ruler-2-line"/></span>
                                <span className="text-muted">{successTargetCount}</span>
                            </li>
                        </ul>
                    </div>
                    }
                </CardFooter>
                }
            </Card>

    );
};

export default DetailViewItem;
