// types
import {
  SaleProductActionTypes,
  SaleProductState,
  ProductType,
  ProductTypeStatus,
  ProductPublishStatus,
  ProductPrivacyStatus
} from "./types";
import {PeerActivityActionTypes} from "../../Content/PeerActivity/types";

export const NewSaleProductItem:ProductType = {
  enabled: true,
  name: '',
  order: 500,
  type: ProductTypeStatus.TYPE_PRODUCT,
  status: ProductPublishStatus.STATUS_NEW,
  privacyStatus: ProductPrivacyStatus.PRIVACY_STATUS_VISIBLE_ALL,
  isHtml: false
}

export const INIT_STATE_SALE_PRODUCT_TYPE: SaleProductState = {
  items: [],
  rightModalShow: false,
  rightModalIntegrationChannelShow: false,
  rightModalPropertiesShow: false,
  rightModalGroupIntegration: false,
  removeModalShow: false,
  selectedItem: NewSaleProductItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false,
  isModalGroupProductsShow: false,
  isGroupProductsType: true,
  isModalGroupRemoveProductsShow: false
};

const SaleProduct = (state = INIT_STATE_SALE_PRODUCT_TYPE, action: any) => {
  switch (action.type) {
    case SaleProductActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleProductActionTypes.GET_PRODUCTS:
        case SaleProductActionTypes.GET_PRODUCT:

          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SaleProductActionTypes.UPDATE_PRODUCT:

          if (action?.payload?.data?.uuid) {
            state.selectedItem.uuid = action?.payload?.data?.uuid
          } else {
            state.selectedItem = NewSaleProductItem
          }

          return {
            ...state,
            isSending: false,
            isNeedReload: true
          };

        case SaleProductActionTypes.GET_EDIT_PRODUCT:

          if ((action.payload.data.items || []).length>0) {
            return {
              ...state,
              isFetched: true,
              selectedItem: {...action.payload.data.items[0]},
              isNeedReload: false
            };
          }

          return {
            ...state,
            selectedItem: NewSaleProductItem,
            isSending: false,
            isNeedReload: true
          };
        case SaleProductActionTypes.COPY_PRODUCT:
        case SaleProductActionTypes.REMOVE_PRODUCT:
          return {
            ...state,
            selectedItem: NewSaleProductItem,
            removeModalShow: false,
            isNeedReload: true
          };
        case SaleProductActionTypes.UPDATE_PRODUCT_INTEGRATION_CHANNEL:
          return {
            ...state,
            rightModalIntegrationChannelShow: false,
            isSending: false,
            selectedIntegrationChannel: null,
            isNeedReload: true
          }
        case SaleProductActionTypes.UPDATE_GROUP_PRODUCT_INTEGRATIONS:
          return {
            ...state,
            rightModalGroupIntegration: false,
            isSending: false,
            isNeedReload: true
          }
        case SaleProductActionTypes.SET_GROUP_PRODUCTS:
        case SaleProductActionTypes.UNSET_GROUP_PRODUCTS:
          return {
            ...state,
            isModalGroupProductsShow: false,
            isSending: false,
            isNeedReload: true
          }
        case SaleProductActionTypes.REMOVE_GROUP_PRODUCTS:
          return {
            ...state,
            isModalGroupRemoveProductsShow: false,
            isSending: false,
            isNeedReload: true
          }
        case SaleProductActionTypes.UPDATE_PRODUCT_PROPERTIES:
          return {
            ...state,
            rightModalPropertiesShow: false,
            selectedProductProperties: null,
            isSending: false,
            isNeedReload: true
          }
        default:
          return { ...state };
      }

    case SaleProductActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleProductActionTypes.GET_PRODUCTS:
        case SaleProductActionTypes.GET_PRODUCT:
        case SaleProductActionTypes.GET_EDIT_PRODUCT:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case SaleProductActionTypes.UPDATE_PRODUCT:
          return {
            ...state,
            isSending: false
          };
        case SaleProductActionTypes.COPY_PRODUCT:
        case SaleProductActionTypes.REMOVE_PRODUCT:
          return {
            ...state,
            removeModalShow: false
          };
        case SaleProductActionTypes.UPDATE_PRODUCT_INTEGRATION_CHANNEL:
        case SaleProductActionTypes.UPDATE_GROUP_PRODUCT_INTEGRATIONS:
        case SaleProductActionTypes.SET_GROUP_PRODUCTS:
        case SaleProductActionTypes.UNSET_GROUP_PRODUCTS:
        case SaleProductActionTypes.REMOVE_GROUP_PRODUCTS:
          return {
            ...state,
            isSending: false,
          }
        case SaleProductActionTypes.UPDATE_PRODUCT_PROPERTIES:
          return {
            ...state,
            isSending: false,
          }
        default:
          return { ...state };
      }
    case SaleProductActionTypes.EDIT_PRODUCT:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleProductActionTypes.EDIT_PRODUCT_INTEGRATION_CHANNEL:
      return {
        ...state,
        selectedIntegrationChannel: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalIntegrationChannelShow: true
      }
    case SaleProductActionTypes.EDIT_PRODUCT_PROPERTIES:
      return {
        ...state,
        selectedProductProperties: action.payload.data,
        rightModalPropertiesShow: true
      }
    case SaleProductActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleProductItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleProductActionTypes.RIGHT_MODAL_GROUP_INTEGRATION_SHOW:
      return {
        ...state,
        rightModalGroupIntegration: true
      }
    case SaleProductActionTypes.REMOVE_PRODUCT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleProductActionTypes.REMOVE_PRODUCT_MODAL_HIDE:
      return {
        ...state,
//        selectedItem: NewSaleProductItem,
        removeModalShow: false
      }
    case SaleProductActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleProductActionTypes.PRODUCT_INTEGRATION_CHANNEL_MODAL_HIDE:
      return {
        ...state,
        selectedIntegrationChannel:null,
        rightModalIntegrationChannelShow: false
      }
    case SaleProductActionTypes.PRODUCT_PROPERTIES_MODAL_HIDE:
      return {
        ...state,
        selectedProductProperties:[],
        rightModalPropertiesShow: false
      }
    case SaleProductActionTypes.RIGHT_MODAL_GROUP_INTEGRATION_HIDE:
      return {
        ...state,
        rightModalGroupIntegration: false
      }
    case SaleProductActionTypes.MODAL_SET_GROUP_PRODUCTS_SHOW:
      return {
        ...state,
        isGroupProductsType: true,
        isModalGroupProductsShow: true
      }
    case SaleProductActionTypes.MODAL_UNSET_GROUP_PRODUCTS_SHOW:
      return {
        ...state,
        isGroupProductsType: false,
        isModalGroupProductsShow: true
      }
    case SaleProductActionTypes.MODAL_GROUP_PRODUCTS_HIDE:
      return {
        ...state,
        isModalGroupProductsShow: false
      }
    case SaleProductActionTypes.MODAL_REMOVE_PRODUCTS_SHOW:
      return {
        ...state,
        isModalGroupRemoveProductsShow: true
      }
    case SaleProductActionTypes.MODAL_REMOVE_PRODUCTS_HIDE:
      return {
        ...state,
        isModalGroupRemoveProductsShow: false
      }
    case SaleProductActionTypes.UPDATE_PRODUCT_INTEGRATION_CHANNEL:
    case SaleProductActionTypes.UPDATE_PRODUCT_PROPERTIES:
    case SaleProductActionTypes.UPDATE_PRODUCT:
    case SaleProductActionTypes.COPY_PRODUCT:
    case SaleProductActionTypes.UPDATE_GROUP_PRODUCT_INTEGRATIONS:
    case SaleProductActionTypes.SET_GROUP_PRODUCTS:
    case SaleProductActionTypes.UNSET_GROUP_PRODUCTS:
    case SaleProductActionTypes.REMOVE_GROUP_PRODUCTS:
      return {
        ...state,
        isSending: true
      }
    case SaleProductActionTypes.GET_PRODUCTS:
    case SaleProductActionTypes.GET_PRODUCT:
    case SaleProductActionTypes.GET_EDIT_PRODUCT:
    case SaleProductActionTypes.CLEAR_PRODUCT_FETCHED:

      return {
        ...state,
        isLoading: true,
        isFetched: false
      };
    default:
      return { ...state };
  }
};

export default SaleProduct;
