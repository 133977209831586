import React from "react";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {DeliveryType, DeliveryStatus} from "../../redux/Sale/Delivery/types";
import {useRedux} from "../../hooks";
import {convertToMomentLocale} from "../../helpers/functions";
import {editDataSaleOrderDeliveryItem, removeSaleOrderDeliveryItemModalShow} from "../../redux/Sale/Delivery/actions";
import {format} from "date-fns";

interface ItemProps {
    item: DeliveryType;
    number: number;
    isCanOrderEdit:boolean;
}
const DeliveryItem = ({ item, number, isCanOrderEdit = false }: ItemProps) => {

    const { t, i18n } = useTranslation(['plan']);
    const { dispatch } = useRedux();

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const handleEdit = () => {
        dispatch(editDataSaleOrderDeliveryItem({data: Object(item), modalEditTitle: 'changeDeliveryItem'}));
    }

    const handleRemove = () => {
        dispatch(removeSaleOrderDeliveryItemModalShow({data: Object(item), modalEditTitle: 'deleteDeliveryItem'}));
    }

    return (
        <>
            {item?.deliverySystem &&
                <div className="orderDeliveryItemCont mb-4">
            <div className="d-flex align-items-center mb-2">
                <div className="flex-grow-1 me-2">
                    <p className="text-muted mb-0">#{number} {t('sale:deliveryMethod')}:</p>
                    <h6 className="mb-0">{item.deliverySystem.name}</h6>
                </div>
                {isCanOrderEdit &&
                <div className="flex-shrink-0">
                    <button
                        onClick={handleEdit}
                        type="button"
                        title={t('common:edit')}
                        className="btn btn-outline-primary btn-icon waves-effect waves-light me-2">
                        <i className="ri-pencil-fill"/>
                    </button>
                    <button
                        onClick={handleRemove}
                        type="button"
                        title={t('common:delete')}
                        className="btn btn-outline-danger btn-icon waves-effect waves-light">
                        <i className="ri-delete-bin-5-line"/>
                    </button>
                </div>
                }
            </div>

            <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                    <p className="text-muted mb-0">{t('sale:amount')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">{item.currency.currencyFormat.replace(/#/,FormatValue(item.amount))}</h6>
                </div>
            </div>
            {item?.address && item.address.length>0 &&
                <div className="d-flex flex-column mb-2">
                    <p className="text-muted mb-1">{t('common:address')}:</p>
                    <h6 className="mb-0 lh-1-5">{item.address}</h6>
                </div>
            }

                    {/*<Card>*/}
                    {/*    <CardHeader>*/}
                    {/*        <h5 className="card-title mb-0">*/}
                    {/*            <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}*/}
                    {/*            Shipping Address*/}
                    {/*        </h5>*/}
                    {/*    </CardHeader>*/}
                    {/*    <CardBody>*/}
                    {/*        <ul className="list-unstyled vstack gap-2 fs-13 mb-0">*/}
                    {/*            <li className="fw-semibold fs-15">Joseph Parker</li>*/}
                    {/*            <li>+(256) 245451 451</li>*/}
                    {/*            <li>2186 Joyce Street Rocky Mount</li>*/}
                    {/*            <li>California - 24567</li>*/}
                    {/*            <li>United States</li>*/}
                    {/*        </ul>*/}
                    {/*    </CardBody>*/}
                    {/*</Card>*/}

            <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                    <p className="text-muted mb-0">{t('sale:deliveryStatus')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                    {// eslint-disable-next-line
                        (Object.keys(DeliveryStatus) as Array<keyof typeof DeliveryStatus>).map((key) => {
                            if (DeliveryStatus[key].status===item.status){
                            return (
                                <span key={'deliveryStatus_'+item.status} className={"badge rounded-pill fs-12 text-bg-"+DeliveryStatus[key].class}> {t('delivery:deliveryStatus'+DeliveryStatus[key].label)} </span>
                            )}
                            }
                        )
                    }
                </div>
            </div>
                    {item?.trackingNumber && item.trackingNumber.length>0 &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t('delivery:trackingNumber')}:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">{item.trackingNumber}</div>
                        </div>
                    }
                    {item?.idTracking && item.idTracking.length>0 &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t('delivery:idTracking')}:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">{item.idTracking}</div>
                        </div>
                    }
                    {item?.shipmentDate && item.shipmentDate.length>0 &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t('delivery:shipmentDate')}:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">{
                                format(new Date(item.shipmentDate),'E, dd MMM yyyy', {locale: convertToMomentLocale(i18n.language)})
                               }</div>
                        </div>
                    }
                    {item?.deliveryDate && item.deliveryDate.length>0 &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t('delivery:deliveryDate')}:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">{
                                format(new Date(item.deliveryDate),'E, dd MMM yyyy', {locale: convertToMomentLocale(i18n.language)})
                                }</div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default DeliveryItem;