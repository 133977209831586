import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";
import {MainUserType} from "../../../redux/Main/User/types";
import UserItem from "../User/userItem";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {MainRoleType} from "../../../redux/Main/Role/types";
import {convertToMomentLocale} from "../../../helpers/functions";
import {format} from "date-fns";


const MainUserColumns = ({ onEdit, onRemove }:EntityActionsHandlersType) => {

    const { t, i18n } = useTranslation(['common']);

    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            selector: (cell:any) => {
                if (
                    cell.actions
                    &&
                    (
                        cell.actions.canEdit
                        ||
                        cell.actions.canRemove
                    )
                ) {
                    return (
                        <UncontrolledDropdown className="dropdown d-inline-block">
                            <DropdownToggle className="btn btn-light btn-sm" tag="a">
                                <i className="ri-more-fill align-middle"/>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end" container="root">
                                {cell.actions && cell.actions.canEdit &&
                                <DropdownItem tag="a" className='edit-item-btn' onClick={() => onEdit(cell)}><i
                                    className="ri-pencil-fill align-bottom me-2 text-muted"/> {t('common:edit')} </DropdownItem>
                                }
                                {cell.actions && cell.actions.canRemove &&
                                <DropdownItem tag="a" className='remove-item-btn' onClick={() => onRemove(cell)}><i
                                    className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')}
                                </DropdownItem>
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                } else {
                    return;
                }
            },
        },
        {
            id: 'name',
            sortable: true,
            name: <span className='font-weight-bold fs-14'>{t('common:name')}</span>,
            selector: (row:MainUserType) => {
                return (
                    <>
                        <div className="d-flex flex-nowrap">
                            <UserItem item={row} key={'user_' + row.uuid}/>
                            <div className="d-flex flex-column justify-content-center ms-2">
                                    <p className={"lh-base mb-0"}>
                                        {row.name}
                                    </p>
                                {row?.position !== undefined && row.position !== null && row.position.length>0 &&
                                    <p className={"lh-base text-muted mb-0"}>
                                        {row.position}
                                    </p>
                                }
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('role:roles')}</span>,
            sortable: false,
            wrap: true,
            selector: (row:MainUserType) => {
                return (
                    <>
                        {row.roles && (row.roles || []).map((role:MainRoleType, key: number) => {
                            return (
                                <span
                                    className="badge bg-primary me-1 mb-1"
                                    key={key}
                                > {t('role:'+role.code)} </span>
                            );
                        })}
                    </>
                );
            }
        },
        {
            id: 'enabled',
            name: <span className='font-weight-bold fs-14'>{t('common:status')}</span>,
            sortable: true,
            maxWidth: '50px',
            center: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }],
            selector: (cell:any) => {
                switch (cell.enabled) {
                    case true:
                        return <span className="badge badge-soft-success"> {t('common:active')} </span>;
                    default:
                        return <span className="badge badge-soft-danger"> {t('common:inActive')} </span>;
                }
            },
        },
        {
            id: 'lastLogin',
            name: <span className='font-weight-bold fs-14'>{t('user:lastLogin')}</span>,
            selector: (row:MainUserType) => row.lastLogin,
            format: ((row:MainUserType) => {
                if ((row.lastLogin>0)) {
                    format(new Date(row.lastLogin*1000),'E, dd MMM yyyy, HH:mm', {locale: convertToMomentLocale(i18n.language)})
                }
            }),
            sortable: true,
            maxWidth: '200px',
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }]
        },

    ];

}

export default MainUserColumns;