import React, {useEffect, useState} from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {TaskType} from "../../../redux/Task/Task/types";
import {Progress} from "reactstrap";

interface ItemProps {
    item: TaskType;
    isShowLabel?: boolean;
    isShowInLineLabel?: boolean;
    className?:string;
    isStriped?: boolean;
}

const getProgressColor = (completionPercent: number = 0) => {
    if (completionPercent >= 80) return 'success';
    if (completionPercent >= 60) return 'info';
    if (completionPercent >= 40) return 'primary';
    if (completionPercent >= 20) return 'warning';

    return 'danger';
}

const TaskProgress = ({ item, isShowLabel = false, isShowInLineLabel = false, isStriped=false, className = '' }: ItemProps) => {

    const { t,i18n } = useTranslation(['common']);
    const [color, setColor] = useState<string>('primary');

    useEffect(()=>{
        let progressColor = getProgressColor(item?.completionPercent);
        setColor(progressColor);
    },[item])

    return (
        <>
            {item?.completionPercent !== undefined && item?.completionPercent !== null &&
                <div className={className && className}>
                    <Progress
                        animated={isStriped && isStriped}
                        className={"animated-progress d-flex flex-grow-1 flex-row w-100"+(isShowLabel?' custom-progress progress-label':'')}
                        value={item.completionPercent}
                        color={color}
                    >
                        {isShowLabel &&
                        <div className="label">{item.completionPercent}%</div>
                        }
                        {isShowInLineLabel &&
                            <>{item.completionPercent}%</>
                        }
                    </Progress>
                </div>
            }
        </>
    );
};

export default TaskProgress;
