// types
import {OrderType, SaleOrderActionTypes, SaleOrderStateType} from "./types";
import {CommentType} from "../../Main/Comment/types";
import {NewCommentItem} from "../../Main/Comment/reducer";
import {getLoggedinUser} from "../../../api/apiCore";
import {SaleProductActionTypes} from "../Product/types";
import {UTCDate} from "@date-fns/utc";
import {formatISO} from "date-fns/formatISO";

export const newOrder:OrderType={
  isPaid:false,
  enabled:true,
  sum:0,
  orderItems:[]
}

export const INIT_STATE_SALE_ORDER: SaleOrderStateType = {
  items: [],
  current: null,
  isFetched: false,
  isLoading: false,
  cancelModalShow: false,
  isNeedReload: false,
  searchProducts: [],
  isSearchProducts: false,
  isSending: false,
  removeModalShow: false,
  isModalGroupRemoveOrdersShow: false,
  isLoadingComments: false,
  isSendingComment: false,
  selectedComment: null,
  isNeedReloadComments: false,
  removeCommentModalShow:false,
  removeCommentModalTitle:'',
};

const SaleOrder = (state = INIT_STATE_SALE_ORDER, action: any) => {

  let _comments = [];

  switch (action.type) {
    case SaleOrderActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleOrderActionTypes.GET_ORDERS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
          };
        case SaleOrderActionTypes.GET_ORDER_DETAIL:
          return {
            ...state,
            current: action.payload.data.items.pop(),
            isFetched: true,
            isLoading: false,
            isNeedReload: false,
            isNeedReloadComments: true
          };
        case SaleOrderActionTypes.UPDATE_ORDER:
          return {
            ...state,
            current: action.payload.data.items.pop(),
            isFetched: true,
            isLoading: false,
            isNeedReload: true
          };
        case SaleOrderActionTypes.CANCEL_ORDER:
          return {
            ...state,
            isLoading: false,
            isSending: false,
            cancelModalShow: false,
            isNeedReload: true,
            removeModalShow: false
          };
        case SaleOrderActionTypes.REMOVE_ORDER:
          return {
            ...state,
            current:null,
            isLoading: false,
            isSending: false,
            isNeedReload: true,
            removeModalShow: false
          };
        case SaleOrderActionTypes.CHANGE_ORDER_STATUS:
          return {
            ...state,
            isLoading: false,
            isNeedReload: true
          };
        case SaleOrderActionTypes.SEARCH_SALE_PRODUCTS:
          return {
            ...state,
            searchProducts: action.payload.data.items,
            isSearchProducts: false,
          };
        case SaleOrderActionTypes.CHANGE_ORDER_ITEMS:
          return {
            ...state,
            isSending: false,
            isNeedReload: true,
            current:{
              ...state.current,
              uuid: action.payload.data.uuid
            }
          };
        case SaleOrderActionTypes.REMOVE_GROUP_ORDERS:
          return {
            ...state,
            isModalGroupRemoveOrdersShow: false,
            isSending: false,
            isNeedReload: true
          }
        case SaleOrderActionTypes.GET_COMMENTS:
          _comments = [...action.payload.data.items,...state.current.comments];

          return {
            ...state,
            current:{
              ...state.current,
              comments: _comments,
              commentsMeta:{
                ...state.current.commentsMeta,
                totalCount: (_comments || []).length
              }
            },
            isLoadingComments: false,
            isNeedReloadComments: false
          };
        case SaleOrderActionTypes.REMOVE_COMMENT:

          _comments = (state.current.comments || []).filter((comment:CommentType)=>{return comment.uuid !== state?.selectedComment?.uuid})

          return {
            ...state,
            current:{
              ...state.current,
              comments: _comments,
              commentsMeta:{
                ...state.current.commentsMeta,
                totalCount: (_comments || []).length
              }
            },
            selectedComment: NewCommentItem,
            removeCommentModalShow: false,
            isSendingComment: false,
            isNeedReload: false
          };
        case SaleOrderActionTypes.UPDATE_COMMENT:

          if (state?.selectedComment?.uuid !== undefined && state?.selectedComment?.uuid !== null){

            _comments = state.current.comments.map((comment:CommentType)=>{if (comment.uuid === state.selectedComment.uuid) {return state.selectedComment} else return comment});

            state = {
              ...state,
              current:{
                ...state.current,
                comments: _comments,
                commentsMeta:{
                  ...state.current.commentsMeta,
                  totalCount: (_comments || []).length
                }
              },
            }

          } else {

            let currentUser = getLoggedinUser();

            let selectedComment:CommentType = {
              ...state.selectedComment,
              uuid: action.payload.data.uuid,
              owner: {
                uuid: currentUser.uuid,
                name: currentUser.name && currentUser.name,
                email: currentUser.email && currentUser.email,
                avatar: currentUser.avatar && currentUser.avatar
              },
              dateTime: formatISO(new UTCDate()),
              actions:{
                canEdit:true,
                canRemove:true
              }
            }

            _comments = [selectedComment, ...state.current.comments];

            state = {
              ...state,
              selectedComment: {
                ...selectedComment
              },
              current:{
                ...state.current,
                comments: _comments,
                commentsMeta:{
                  ...state.current.commentsMeta,
                  totalCount: (_comments || []).length
                }
              }
            }
          }

          return {
            ...state,
            isSendingComment: false,
            isNeedReload: false
          };

        default:
          return { ...state };
      }
    case SaleOrderActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleOrderActionTypes.GET_ORDERS:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        case SaleOrderActionTypes.UPDATE_ORDER:
        case SaleOrderActionTypes.CANCEL_ORDER:
          return {
            ...state,
            isFetched: true,
            isLoading: false,
            cancelModalShow: false,
            isNeedReload: false
          };
        case SaleOrderActionTypes.REMOVE_ORDER:
          return {
            ...state,
            isFetched: true,
            isLoading: false,
            isNeedReload: false,
            removeModalShow: false
          };
        case SaleOrderActionTypes.CHANGE_ORDER_STATUS:
          return {
            ...state,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SaleOrderActionTypes.SEARCH_SALE_PRODUCTS:
          return {
            ...state,
            isSearchProducts: false,
          };
        case SaleOrderActionTypes.CHANGE_ORDER_ITEMS:
        case SaleOrderActionTypes.REMOVE_GROUP_ORDERS:
          return {
            ...state,
            isSending: false,
          };
        case SaleOrderActionTypes.GET_COMMENTS:
          return {
            ...state,
            isLoadingComments: false
          }
        case SaleOrderActionTypes.UPDATE_COMMENT:
        case SaleOrderActionTypes.REMOVE_COMMENT:
          return {
            ...state,
            isSendingComment: false
          }
        default:
          return { ...state };
      }
    case SaleOrderActionTypes.GET_ORDERS:
      return {
        ...state,
        isFetched: false,
        isLoading: true,
      };
    case SaleOrderActionTypes.GET_ORDER_DETAIL:
      return {
        ...state,
        isFetched: false,
        isLoading: true,
      };
    case SaleOrderActionTypes.REMOVE_ORDER:
    case SaleOrderActionTypes.UPDATE_ORDER:
    case SaleOrderActionTypes.CANCEL_ORDER:
    case SaleOrderActionTypes.CHANGE_ORDER_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case SaleOrderActionTypes.CANCEL_MODAL_SHOW:
      return {
        ...state,
        cancelModalShow: true,
      };
    case SaleOrderActionTypes.CANCEL_MODAL_HIDE:
      return {
        ...state,
        cancelModalShow: false,
      };
    case SaleOrderActionTypes.REMOVE_MODAL_SHOW:
      return {
        ...state,
        current: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true,
      };
    case SaleOrderActionTypes.REMOVE_MODAL_HIDE:
      return {
        ...state,
        removeModalShow: false,
      };
    case SaleOrderActionTypes.CLEAR_NEED_ORDER_RELOAD:
      return {
        ...state,
        isNeedReload: false,
      };
    case SaleOrderActionTypes.SET_NEED_ORDER_RELOAD:
      return {
        ...state,
        isNeedReload: true,
      };
    case SaleOrderActionTypes.SEARCH_SALE_PRODUCTS:
      return {
        ...state,
        isSearchProducts: true,
      };
    case SaleOrderActionTypes.CHANGE_ORDER_ITEMS:
    case SaleOrderActionTypes.REMOVE_GROUP_ORDERS:
      return {
        ...state,
        isSending: true,
      };
    case SaleOrderActionTypes.MODAL_REMOVE_ORDERS_SHOW:
      return {
        ...state,
        isModalGroupRemoveOrdersShow: true
      }
    case SaleOrderActionTypes.MODAL_REMOVE_ORDERS_HIDE:
      return {
        ...state,
        isModalGroupRemoveOrdersShow: false
      }
    case SaleOrderActionTypes.GET_COMMENTS:
      return {
        ...state,
        isLoadingComments: true,
        isNeedReloadComments: false
      }
    case SaleOrderActionTypes.UPDATE_COMMENT:
      return {
        ...state,
        selectedComment: action.payload,
        isSendingComment: true
      }
    case SaleOrderActionTypes.REMOVE_COMMENT:
      return {
        ...state,
        selectedComment: action.payload.data,
        isSendingComment: true
      }
    case SaleOrderActionTypes.REMOVE_COMMENT_MODAL_SHOW:
      return {
        ...state,
        selectedComment: action.payload.data,
        removeCommentModalTitle: action.payload.removeCommentModalTitle,
        removeCommentModalShow: true
      }
    case SaleOrderActionTypes.REMOVE_COMMENT_MODAL_HIDE:
      return {
        ...state,
        selectedComment: NewCommentItem,
        removeCommentModalShow: false
      }
    default:
      return { ...state };
  }
};

export default SaleOrder;
